import React, { useState, useRef, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { mainData } from "./NavData";
export const NavProfissional = () => {
  const [isOpen, setIsOpen] = useState([]);
  const collapseRef = useRef([]);
  const toggleCollapse = (index) => {
    setIsOpen((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };
  const location = useLocation();
  const { pathname, search } = location;
  useEffect(() => {
    const initialIsOpen = mainData.map((data) =>
      data.subData.some((subData) => subData.href + search === pathname)
    );
    setIsOpen(initialIsOpen);
  }, [pathname, search]);
  return (
    <div>
      {mainData.map((data, index) => {
        const isCurrentPath = data.href === `../..${pathname}`;
        return (
          <div
            className={`${data.topDist} ${isCurrentPath ? 'bg-primary w-100' : 'col-md-12'}`}
            style={{ padding: '0px', borderRadius: '0.25rem' }}
            key={data.title}
          >
            {data.subData.length === 0 ? (
              <Link
                to={`${data.href}${search}`}
                className={`navbar-brand ${isCurrentPath ? 'font-weight-bold text-white' : 'text-dark'}`}
                style={{ cursor: 'pointer', marginTop: '0px' }}
              >
                {data.icon === "" ? data.iconComponent : <i className={`fa ${data.icon} ml-2`} />}
                <span className={`ml-1 ${isCurrentPath ? 'text-white' : 'text-dark'}`}>
                  {data.title}
                  {isOpen[index] ? (
                    <i className="fas fa-chevron-down align-right" style={{ scale: "0.5" }}></i>
                  ) : data.subData.length === 0 ? (
                    <>
                      {isCurrentPath && <i className="fa fa-circle align-right mt-1" style={{ transform: 'scale(40%)', marginRight: '10px' }}></i>}
                      {!isCurrentPath && <i className="fa fa-circle align-right mt-1" style={{ transform: 'scale(40%)' }}></i>}
                    </>
                  ) : (
                    <i className="fas fa-chevron-right align-right mr-1" style={{ scale: "0.5" }}></i>
                  )}
                </span>
              </Link>
            ) : (
              <>
                <ul
                  onClick={() => toggleCollapse(index)}
                  className={`navbar-brand ${isCurrentPath ? 'font-weight-bold text-white' : 'text-dark'} ${data.topDist}`}
                  style={{ backgroundColor: 'none', cursor: 'pointer', marginBottom: '0px' }}
                >
                  <i className={`fa ${data.icon} text-dark ml-2`} />
                  <span className="ml-1 text-dark">
                    {data.title}
                    {isOpen[index] ? (
                      <i className="fas fa-chevron-down align-right" style={{ scale: "0.5" }}></i>
                    ) : (
                      <i className="fas fa-chevron-right align-right" style={{ scale: "0.5" }}></i>
                    )}
                  </span>
                </ul>
                <ul
                  className={`collapse ${isOpen[index] ? "show" : ""}`}
                  ref={(el) => (collapseRef.current[index] = el)}
                  style={{ padding: '0px', marginBottom: '0px' }}
                >
                  {data.subData.map((subData) => (
                    <li className="text-dark text-left mt-1 ml-3" style={{cursor:'pointer'}} key={subData.id}>
                      <a href={`${subData.href}${search}`}>
                        <i className={`fa ${subData.icon}`} />
                        <span className="ml-1 text-dark">{subData.title}</span>
                        <span className="badge badge-pill bg-danger text-dark">
                          {subData.count}
                        </span>
                      </a>
                    </li>
                  ))}
                </ul>
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};
export default NavProfissional;
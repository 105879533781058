import { Typography } from 'antd';
const { Title } = Typography;
export const TitleL2 = (props) => {
  return (<>
    <Title level={2}
      style={{
        textAlign: 'center',
        fontWeight: '800',
        fontFamily: 'AardvarkCafe, Arial, Helvetica',
        fontSize: '28px',
        ...props.style
      }}
      className={`mt-2 font-weight-bold ${props.className}`} id={props.id}  >
      <strong
        style={{
          fontFamily: 'AardvarkCafe, Arial, Helvetica', // Usando a fonte Aardvark Cafe
          fontWeight: '800', ...props.style
        }} >    {props.children}</strong>
    </Title>
  </>);
};
export default TitleL2;
import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';
import ProductCard from './ProductCard';
import axios from 'axios';
import { baseURL } from '@services';
import { InputPadraoSearch, MaisInfoMenosInfo } from '@components';
import ProdutosInfo from '../../info/ProdutosInfo';
import { AutoComplete, Pagination } from 'antd';
import { AuthContext } from '../../../../../../guard';
import { post_cart } from '../../../../Carrinho/partials/services/post_cart';
const { Option } = AutoComplete;
const ProductList = () => {
    const [products, setProducts] = useState([]);
    const [totalProducts, setTotalProducts] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [resultadosPorPagina] = useState(10);
    const [filterName, setFilterName] = useState('');
    const [filterType, setFilterType] = useState('');
    const [filterPriceMin, setFilterPriceMin] = useState('');
    const [filterPriceMax, setFilterPriceMax] = useState('');
    const [filterCompany, setFilterCompany] = useState('');
    const [filterCompanyUnit, setFilterCompanyUnit] = useState('');
    const [filterProfessional, setFilterProfessional] = useState('');
    const [filtersActive, setFiltersActive] = useState(false);
    const [cart, setCart] = useState(JSON.parse(localStorage.getItem('cart')) || []);
    const { getUserId } = useContext(AuthContext);
    const user_id = getUserId();
    const [screenY, setScreenY] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
            setScreenY(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const fetchProducts = useCallback(async () => {
        try {
            const response = await axios.get(`${baseURL}get_products`, {
                params: {
                    pagina: currentPage,
                    resultadosPorPagina,
                    products_type: filterType,
                    professional_id: filterProfessional,
                    company_id: filterCompany,
                    company_unit_id: filterCompanyUnit,
                    products_name: filterName,
                    price_min: filterPriceMin,
                    price_max: filterPriceMax,
                },
            });
            const formattedProducts = response.data.produtos.map((product) => ({
                ...product,
                value: parseFloat(product.value),
            }));
            setProducts(formattedProducts);
            setTotalProducts(response.data.totalProdutos);
        } catch (error) {
            console.error('Error fetching products:', error);
        }
    }, [currentPage, resultadosPorPagina, filterName, filterType, filterPriceMin, filterPriceMax, filterCompany, filterCompanyUnit, filterProfessional]);
    // useEffect to trigger fetchProducts when dependencies change
    useEffect(() => {
        fetchProducts();
    }, [fetchProducts]);
    useEffect(() => {
        if (!filtersActive) {
            setFilterName('');
            setFilterType('');
            setFilterPriceMin('');
            setFilterPriceMax('');
            setFilterCompany('');
            setFilterCompanyUnit('');
            setFilterProfessional('');
            setCurrentPage(1);
        }
    }, [filtersActive]);
    useEffect(() => {
        if (!filterName && !filterType && !filterCompany && !filterCompanyUnit && !filterProfessional && !filterPriceMin && !filterPriceMax) {
            setFiltersActive(false);
        } else {
            setFiltersActive(true);
        }
    }, [filterName, filterType, filterPriceMin, filterCompany, filterCompanyUnit, filterProfessional, filterPriceMax]);
    const handlePageChange = (page) => {
        setCurrentPage(page);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    const [isExpanded, setIsExpanded] = useState(false);
    const buttonRef = useRef(null);
    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };
    const filteredProducts = products.filter((product) => {
        const nameMatch = filterName === '' || product.products_name.toLowerCase().includes(filterName.toLowerCase());
        const typeMatch = filterType === '' || product.products_type === filterType;
        const priceMinMatch = filterPriceMin === '' || parseFloat(product.value) >= parseFloat(filterPriceMin);
        const priceMaxMatch = filterPriceMax === '' || parseFloat(product.value) <= parseFloat(filterPriceMax);
        const companyMatch = filterCompany === '' || product.company_id === parseInt(filterCompany);
        return nameMatch && typeMatch && priceMinMatch && priceMaxMatch && companyMatch;
    });
    const handleAddToCart = (product) => {
        post_cart(product, cart, setCart, user_id, baseURL);
      };
    return (
        <section style={{ minHeight: '95dvh' }}>
            <InputPadraoSearch
                type="text"
                placeholder="Busca produtos por nome..."
                value={filterName}
                onChange={(e) => {
                    setFilterName(e.target.value);
                }}
            />
            <div className="product-list">
                <div id="mostraMaisOpcoesBusca" className={`collapse mt-2  align-items-center justify-content-center ${isExpanded ? 'show' : ''}`}>
                    <div className="d-flex flex-wrap justify-content-center ">
                        <div className='flex-wapper col-md-3 mt-2'>
                            <small>Tipo</small><br />
                            <AutoComplete
                                className="w-100 text-dark text-center"
                                value={filterType}
                                onChange={(value) => setFilterType(value)}
                                placeholder="Selecione o Tipo"
                            >
                                {Array.from(new Set(products.map((product) => product.products_type))).map((products_type) => (
                                    <Option key={products_type} value={products_type}>
                                        {products_type}
                                    </Option>
                                ))}
                            </AutoComplete>
                        </div>
                        <div className='flex-wapper col-md-3 mt-2'>
                            <small>Empresa</small><br />
                            <AutoComplete
                                className="w-100 text-dark text-center"
                                value={filterCompany}
                                onChange={(value) => setFilterCompany(value)}
                                placeholder="Selecione a Empresa"
                            >
                                {Array.from(new Set(products.map((product) => product.company_id))).map((company_id, index) => {
                                    const companyName = products.find((product) => product.company_id === company_id)?.company_unit_name;
                                    return (
                                        <Option key={`${company_id}_company_id_${index}`} value={companyName}>
                                            {companyName}
                                        </Option>
                                    );
                                })}
                            </AutoComplete>
                        </div>
                        <div className='flex-wapper col-md-3 mt-2'>
                            <small>Empresa unidade</small><br />
                            <AutoComplete
                                className="w-100 text-dark text-center"
                                value={filterCompanyUnit}
                                onChange={(value) => setFilterCompanyUnit(value)}
                                placeholder="Selecione a Empresa Unidade"
                            >
                                {Array.from(new Set(products.map((product) => product.company_unit_id))).map((company_unit_id, index) => (
                                    <Option key={`${company_unit_id}_company_unit_id_${index}`} value={company_unit_id}>
                                        {company_unit_id}
                                    </Option>
                                ))}
                            </AutoComplete>
                        </div>
                        <div className='flex-wapper col-md-3 mt-2'>
                            <small>Profissional</small><br />
                            <AutoComplete
                                className="w-100 text-dark text-center"
                                value={filterProfessional}
                                onChange={(value) => setFilterProfessional(value)}
                                placeholder="Selecione o Profissional"
                            >
                                {Array.from(new Set(products.map((product) => product.professional_id))).map((professional_id, index) => (
                                    <Option key={`${professional_id}_professional_id_${index}`} value={professional_id}>
                                        {professional_id}
                                    </Option>
                                ))}
                            </AutoComplete></div>
                        <div className='flex-wapper col-md-3 mt-2'>
                            <small>Valor mínimo</small><br />
                            <AutoComplete
                                className="w-100 text-dark text-center"
                                type="number"
                                placeholder="a partir"
                                value={filterPriceMin}
                                onChange={(value) => setFilterPriceMin(value)}
                            >
                                {Array.from(new Set(products.map((product) => product.value))).map((price_min, index) => (
                                    <Option key={`${price_min}_price_min_${index}}`} value={price_min}>
                                        {price_min}
                                    </Option>
                                ))}
                            </AutoComplete>
                        </div>
                        <div className='flex-wapper col-md-3 mt-2'>
                            <small>Valor máximo</small><br />
                            <AutoComplete
                                className="w-100 text-dark text-center"
                                type="number"
                                placeholder="até"
                                value={filterPriceMax}
                                onChange={(value) => setFilterPriceMax(value)}
                            >
                                {Array.from(new Set(products.map((product) => product.value))).map((price_max) => (
                                    <Option key={`${price_max}_price_max`} value={price_max}>
                                        {price_max}
                                    </Option>
                                ))}
                            </AutoComplete>
                        </div>
                    </div>
                </div>
                <div className="col-md-12" style={{ padding: '10px' }}>
                    <div className='d-flex flex-wrap '>
                        <div className={`input-group-append h-100 w-100 col-md-6`} style={{ padding: '0px', alignItems: 'center' }}>
                            <h5 className=' text-left'>
                                <ul className={`${isExpanded ? "text-danger " : 'text-primary'}`}
                                    ref={buttonRef}
                                    onClick={toggleExpand}
                                    style={{ cursor: "pointer", border: 'none', background: 'none', padding: 0, margin: 0, fontFamily: ' Arial, Helvetica' }}                             >
                                    {`${isExpanded ? 'Menos opções de filtros' : 'Mais opções de filtros'}`} ...<i className="fa fa-search flip-horizontal mr-2"></i>
                                </ul>
                            </h5>
                        </div>
                        <div className={`col-md-6     font-weight-bold `} style={{ padding: '0px' }}><h5 className={` ${screenY > 1058 ? 'text-right' : ''}`} style={{ fontFamily: ' Arial, Helvetica' }}>Produtos encontrados: <b style={{ fontFamily: ' Arial, Helvetica' }}>{totalProducts} </b> </h5> </div>
                    </div>
                </div>
            </div>
            <div className="col-md-12" style={{ padding: '0px' }}>
                <div className="d-flex flex-wrap justify-content-center">
                    {filteredProducts.map((product, index) => (
                        <ProductCard
                            cart={cart}
                            key={`${product.id}_produto_id_${index}`}
                            product_id={product.id}
                            product_code_id={product.id}
                            products_name={product.products_name}
                            image={product.products_image}
                            description={product.products_description}
                            value={product.value}
                            quantity={product.quantity}
                            products_type={product.products_type}
                            company_name={product.company_name}
                            onAddToCart={handleAddToCart}
                        />
                    ))}
                </div>
            </div>
            {totalProducts > resultadosPorPagina && <><div className="col-md-12 font-weight-bold text-center mt-5 mb-3" style={{ fontFamily: ' Arial, Helvetica' }}>Total de páginas: {Math.ceil(filtersActive
                ? filteredProducts.length / resultadosPorPagina
                : totalProducts / resultadosPorPagina)}</div>
                <div className='d-flex text-center justify-content-center mb-5 '>
                    <Pagination
                        current={currentPage}
                        total={totalProducts}
                        pageSize={resultadosPorPagina}
                        onChange={handlePageChange}
                    />
                </div>
            </>}
            <MaisInfoMenosInfo text={<><ProdutosInfo /></>} />
        </section>
    );
};
export default ProductList;
import { NavBreadcrumb, MaisInfoMenosInfo } from '@components';
import ProspeccaoClientesInfo from '../info/ProspeccaoClientesInfo';
import RecomendacoesInfo from '../info/RecomendacoesInfo';
import { ProspeccaoClientesEmail } from './ProspeccaoClientesEmail';
import ProspeccaoClientesTelefone from './ProspeccaoClientesTelefone';
import ProspeccaoClienteSeleciona from './ProspeccaoClienteSeleciona';
export const ProspeccaoClientesMaster = () => {
    return (<>
        <NavBreadcrumb icon={'rocket fa'} text={'I.A. BI'} />
        <div className='py-4 mt-3'>
            <MaisInfoMenosInfo text={<ProspeccaoClientesInfo />}></MaisInfoMenosInfo>
        </div>
        <ProspeccaoClienteSeleciona />
        <ProspeccaoClientesEmail />
        <ProspeccaoClientesTelefone />
        <RecomendacoesInfo />
    </>);
}
export default ProspeccaoClientesMaster;
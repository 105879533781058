export function drawAttentionIcon(doc, x, y, size) {
    const halfSize = size / 2;
    // Desenhar o triângulo de atenção
    doc.setFillColor(255, 204, 0); // Cor amarela para o triângulo
    doc.triangle(x, y - size, x - halfSize, y + halfSize, x + halfSize, y + halfSize, 'F');
    // Desenhar o ponto de exclamação
    doc.setFillColor(0, 0, 0); // Cor preta para o ponto
    const circleSize = size / 8;
    doc.circle(x, y + size / 4, circleSize, 'F'); // Ponto no final
    doc.rect(x - circleSize / 2, y - halfSize, circleSize, halfSize / 1.5, 'F'); // Traço do ponto de exclamação
}
import { apiToken } from "../../../../../contexts";
export const fetchImages = async (
    setLoading,
    setImages,
    onImageUpdated,
    setError,
    routeGetSendSingleImage
) => {
    setLoading(true);
    try {
        const response = await apiToken().get(routeGetSendSingleImage);
        if (response.status === 200) {
            const images = response.data;
            setImages(images);
            onImageUpdated();
        }
    } catch (error) {
        if (error.response && error.response.status === 401) {
            setError(error)
        } else {
            // alert('Erro ao buscar as fotos do usuário.');
        }
    }
    setLoading(false);
};
export default fetchImages;
import { useState, useEffect } from "react";
import GraficosDinamicos from "../graficosDinamicos/GraficosDinamicos.js";
import MontarGraficosDinamicos from "./MontarGraficosDinamicos.jsx";
import { baseURL } from "@services";
import ConexaoDB from "./ConexaoDB.jsx";
import { ButtonAPI, ReferenciaLocal } from "@components";
export function GraficosFinanceiros() {
  const [questions, setQuestions] = useState([]);
  const [responses, setResponses] = useState([]);
  useEffect(() => {
    async function fetchDataFromAPI() {
      try {
        const response = await fetch(`${baseURL}montarDashboard`);
        const data = await response.json();
        setQuestions(data);
      } catch (error) {
        console.error('Erro ao buscar dados da API:', error);
      }
    }
    fetchDataFromAPI();
  }, []);
  useEffect(() => {
    async function fetchDataForTableAndColumn() {
      if (Array.isArray(questions) && questions.length > 0) {
        try {
          const fetchPromises = questions.map(async (question) => {
            const url = `${baseURL}buscarDados/${question.tabela}/${question.coluna}`;
            const response = await fetch(url);
            const data = await response.json();
            return { question, response: data };
          });
          const questionResponsePairs = await Promise.all(fetchPromises);
          setResponses(questionResponsePairs);
        } catch (error) {
          console.error('Erro ao buscar dados da tabela e coluna:', error);
        }
      }
    }
    fetchDataForTableAndColumn();
  }, [questions]);
  return (<>
    <ReferenciaLocal text="Redenrização De Gráficos" />
    <div className=" text-center col-md-12 w-100 mb-5" style={{
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }}>
      <ButtonAPI>Criar Gráficos Padrões</ButtonAPI>
    </div>
    <div className=" text-center col-md-12 w-100" style={{
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }}>
      {questions && responses.length > 0 && (
        <MontarGraficosDinamicos questions={questions} responses={responses} />
      )}
      {/* <h5>Esta de conferência das respostas JSON back-end</h5>
        {Array.isArray(responses) && (
          <div className="col-md-12">
            <ul>
              {responses.map(({ question, response }) => (
                <li className="col-md-12 mt-5" key={question.id}>
                  <h5 className="col-md-12">Question Coluna: {JSON.stringify(question)}</h5>
                  <h5 className="col-md-12">Response: {JSON.stringify(response)}</h5>
                </li>
              ))}
            </ul>
          </div>
        )} */}
    </div>
    <GraficosDinamicos />
    <ReferenciaLocal text="Config. Avançadas">
      <ConexaoDB />
    </ReferenciaLocal>
  </>);
}
export default GraficosFinanceiros;
import React from 'react';
const ProntuarioTomandoMedicamentoInfo = () => {
  return (
    <div>
      <h5>Usando o Componente <strong>Tomando Medicamento</strong></h5>
      <p>
        O componente  <strong>Tomando Medicamento</strong> permite que você registre suas medicações. Você pode adicionar, editar e remover medicações conforme necessário. Abaixo, você encontrará um guia de como usar este componente.
      </p>
      <hr />
      <ol className='ml-3'>
        <li>
          <strong>Marque se está tomando algum medicamento</strong>
          <p>
            Quando você abrir o componente, você verá a pergunta "Está tomando algum medicamento?" e três opções: "Sim," "Não" e "Não Sei." Marque a opção correspondente para indicar se você está tomando medicamentos.
          </p>
        </li>
        <li><strong>Adicionar medicamentos</strong>
          <p>
            Se você marcar "Sim," uma seção adicional aparecerá onde você pode adicionar medicamentos. Preencha os campos abaixo de "Se Sim, quais medicamentos (finalidade/nome/dose/posologia)" com as informações do medicamento que você está tomando e clique no botão "Adicionar Medicamento" para inserir o medicamento na lista.
          </p>
        </li>
        <li><strong>Editar medicamentos</strong>
          <p>
            Se você desejar editar um medicamento existente na lista, clique no botão "Editar" ao lado dele. Isso preencherá os campos de texto com as informações do medicamento para edição. Faça as alterações necessárias e clique no botão "Adicionar Medicamento" para salvar as alterações.
          </p>
        </li>
        <li><strong>Remover medicamentos</strong>
          <p>
            Se você quiser remover um medicamento da lista, clique no botão "Remover" ao lado dele. O medicamento será excluído da lista.
          </p>
        </li>
        <li><strong> Finalização</strong>
          <p>
            Depois de adicionar, editar ou remover medicamentos, você pode continuar usando o componente conforme necessário. Quando terminar, você pode clicar no botão "ADICIONAR MEDICAÇÃO" na parte inferior do componente para concluir o processo.
          </p>
        </li>
      </ol>
      <hr />
      <p>
        Certifique-se de preencher todos os campos necessários para cada medicamento adicionado. Este componente é projetado para ajudá-lo a gerenciar informações sobre medicamentos de forma fácil e conveniente.
      </p>
      <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />
    </div>
  );
};
export default ProntuarioTomandoMedicamentoInfo;
import React, { useState } from "react";
import { NavBreadcrumb, NavUserMain } from "@components";
import "../BuscarPaciente/style.css";
import { Button } from "antd";
import { MdDateRange } from "react-icons/md";
import {api} from "@services";
import {GetUsers} from "../../../../Search/GetUsers";
export const BuscarPacienteFilho = () => {
  const [selectedOption, setSelectedOption] = useState({
    value: "Nome",
    label: "Nome"
  });
  const updateSelectedOption = (newSelectedOption) => {
    setSelectedOption(newSelectedOption);
  };
  const isNameValid = (value) => {
    const nameRegex = /^[A-Za-zÀ-ÖØ-öø-ÿ\s]*$/;
    return nameRegex.test(value);
  };
   const customColumns = [
    {
      title: "Agendar",
      dataIndex: "Consulta",
      key: "consultas",
      render: () => (
        <Button
          // type="primary"
          icon={<MdDateRange style={{ fontSize: '17px' }} />}
          onClick={() => handleAgendarConsulta()}
          style={{ marginLeft: '8px' }}
        >
        </Button>
      ),
    },
    // Add other custom columns as needed
  ];
  const [data, setData] = useState([]);
  const [dataTableVisible, setDataTableVisible] = useState(false);
  const onSearch = async (value) => {
    // Certifique-se de que o nome seja válido antes de fazer a busca
    if (isNameValid(value)) {
      try {
        const pacientesFiltrados = await api.get(value);
        setData(pacientesFiltrados);
        setDataTableVisible(true); // Mostrar a tabela quando pesquisar
      } catch (error) {
        console.error("Erro ao buscar pacientes:", error);
        setData([]);
        setDataTableVisible(false);
      }
    } else {
      console.log("Nome inválido");
    }
  };
  const handleAgendarConsulta = () => {
    window.location.href = "/usuario/consultas/agendar-consulta";
  };
  return (
    <>
      <div className="consulta-wrapper">
        <NavUserMain />
        <div className="consulta-container">
          <NavBreadcrumb text={"Minhas Consultas"} icon={"search fa"} />
          <GetUsers
            props={{onSearch, isNameValid,customColumns,data, dataTableVisible,}}selectedOption={selectedOption}
            updateSelectedOption={updateSelectedOption}
            title="Minhas Consultas"
          />
        </div>
      </div>
    </>
  );
};
import React, { useState } from 'react';
export const TermoDeResponsabilidadeProntuario = () => {
  const [verificado, setVerificado] = useState(false);
  const handleVerificacaoChange = () => {
    setVerificado(!verificado);
  };
  return (
    <><div>
      <small>
        Declaro, para fins legais, que as informações a seguir serão fornecidas de forma verdadeira e completa! 
        <p>
          É fundamental que todas as informações fornecidas sejam verdadeiras e precisas. Fornecer informações incorretas pode ter as seguintes consequências:
        </p>
        <ol className='ml-3'>
          <li>Diagnóstico e tratamento inadequados;</li>
          <li>Riscos para a saúde devido a alergias, interações medicamentosas, etc;</li>
          <li>Problemas legais decorrentes de informações falsas;</li>
          <li>Comprometimento da qualidade do atendimento médico, entre muitas outras.</li>
        </ol>
        <p>
          Portanto, verifique cuidadosamente todas as informações antes de concordar com este termo.
        </p>
      </small>
    </div>
      <div className="form-check mt-3 text-center">
        <input
          type="checkbox"
          className="form-check-input"
          id="verificadoCheckbox"
          defaultChecked={verificado}
          onChange={handleVerificacaoChange}
        />
        <small className='text-center'>
          <label className="form-check-label" htmlFor="verificadoCheckbox">
            Li e concordo com as informações fornecidas acima.
          </label>
        </small>
      </div>
      {
        verificado && (
          <div className="alert alert-success mt-2 text-center">
           <small>Termo aceito. Você assume total responsabilidade pelo uso dos sistemas.</small> 
          </div>
        )
      }
    </>);
};
export default TermoDeResponsabilidadeProntuario;
import React from 'react';
import { Divider, Image } from 'antd';
import { TitleL3 } from '@components';
export const DocumentosSoftwareAnaliseFacialRefTeorica = () => (
  <section style={{ marginTop: '20px' }}>
    <TitleL3 id="DocumentosSoftwareAnaliseFacialRefTeorica">Ref. Teórica</TitleL3>
       <Divider style={{ margin: '0px', borderColor: 'black', borderWidth: '3px', marginBottom: '10px' }}  />
    <div>
      <ul>
        <li><strong>Livros de Harmonização Facial:</strong> Base referencial para validação.</li>
      </ul>
      <div className='row text-center'>
        <div className='col-md-1 border py-1' style={{ padding: '0px' }}>
          <Image src={require('../assets/ref1.jpg')} alt="Exemplo 2" width={100} height={100} /><br />
          <small>Ortodontia Contemporânea</small>
        </div>
        <div className='col-md-1 border py-1' style={{ padding: '0px' }}>
          <Image src={require('../assets/ref2.jpg')} alt="Exemplo 2" width={100} height={100} /><br />
          <small>Cirurgia Oral e Maxilofacial Contemporânea</small>
        </div>
        <div className='col-md-1 border py-1' style={{ padding: '0px' }}>
          <Image src={require('../assets/ref3.jpg')} alt="Exemplo 2" width={100} height={100} /><br />
          <small>Toxina Botulínica na Dermatologia</small>
        </div>
      </div>
      <ul>
        <li><strong>Artigos de Harmonização Facial:</strong> Base referencial diferencial para validação.</li>
        <li>
          PALUD Alice, et al. Management of otitis media with effusion in cleft palate children.
          Acta Otorhinolaryngologica Italica, [S.l.], v. 29, n. 1, p. 38-42, 2009.
          Disponível em <a href="https://www.actaitalica.it/article/view/38" target="_blank" rel="noopener noreferrer">https://www.actaitalica.it/article/view/38</a>
        </li>
      </ul>
    </div>
  </section>
);
export default DocumentosSoftwareAnaliseFacialRefTeorica;
import React from 'react';
import { ButtonScrollToTop } from '..';
import { Tooltip } from 'antd';
export const Footer = () => {
    return (<>
        <ButtonScrollToTop />
        <footer className=" bg-primary" style={{ minHeight: "10dvh", borderRadius: '0.25em', display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
            <div style={{ alignContent: 'center', justifyContent: 'center' }}>
                <h5 className="text-white text-center  " style={{ marginBottom: '0' }}><small>Redes sociais outros contatos</small> </h5>
                <div className='d-flex'>
                    <div className="d-flex mx-auto text-center">
                        <Tooltip
                            title="Linkedin">
                            <a aria-label={'Siga para as redes sociais'} target={'_blank'} rel={'noopener noreferrer'} href={'https://www.linkedin.com/company/thot-ia-corp/'}><i
                                className="icon  text-white mr-3 ion-social-linkedin fa-2x"></i></a>
                        </Tooltip>
                        <Tooltip
                            title="Instragram">      <a aria-label={'Siga para as redes sociais'} target={'_blank'} rel={'noopener noreferrer'} href={'https://www.instagram.com/rede_social_profissional/'}><i
                                className="icon  text-white mr-3 ion-social-instagram fa-2x"></i></a>
                        </Tooltip>
                        <Tooltip
                            title="Github">
                            <a aria-label={'Siga para as redes sociais'} target={'_blank'} rel={'noopener noreferrer'} href={'https://github.com/THOTIACORP'}><i
                                className="icon  mr-3  text-white fa-2x  ion-social-github"></i></a>
                        </Tooltip>
                        <Tooltip
                            title="WhatsAPP">
                            <a aria-label={'Siga para as redes sociais'} target={'_blank'} rel={'noopener noreferrer'}
                                href={'https://api.whatsapp.com/send?phone=5566996675486&text=Estou%20entrando%20em%20contato%20para%20'}><i
                                    className="ion-social-whatsapp  text-white fa-2x"></i></a>
                        </Tooltip>
                    </div>
                </div >
                <div className="d-flex inlyn bg-primary" style={{ lineHeight: '0' }}>
                    <div className="d-flex align-items-center mx-auto text-center">
                        <p className="text-white mt-1  text-center">
                            <Tooltip
                                title="Sobre nós - compliance? Clique"
                            >
                                <a href='./sobre'><small>© THOT I.A. CORP. &nbsp;- Todos direitos reservados</small></a>
                            </Tooltip>
                        </p>
                    </div>
                </div>
            </div >
        </footer >
    </>)
}
export default Footer;
import React, { useState } from 'react';
import { ButtonAdicionarCampo, ButtonEditarCampo, ButtonRemoverCampo } from '@components';
const ProntuarioDenstitaReacaoAnestesia = () => {
    const [reacaoAnestesia, setReacaoAnestesia] = useState(false);
    const [reacoes, setReacoes] = useState([]);
    const [novaReacao, setNovaReacao] = useState('');
    const [indiceEditando, setIndiceEditando] = useState(-1);
    const handleCheckboxChange = () => {
        setReacaoAnestesia(!reacaoAnestesia);
    };
    const handleAdicionarReacao = () => {
        if (novaReacao.trim() !== '') {
            if (indiceEditando === -1) {
                setReacoes([...reacoes, novaReacao]);
            } else {
                const novaLista = [...reacoes];
                novaLista[indiceEditando] = novaReacao;
                setReacoes(novaLista);
                setIndiceEditando(-1);
            }
            setNovaReacao('');
        }
    };
    const handleRemoverReacao = (index) => {
        const novaLista = [...reacoes];
        novaLista.splice(index, 1);
        setReacoes(novaLista);
    };
    const handleEditarReacao = (index) => {
        setNovaReacao(reacoes[index]);
        setIndiceEditando(index);
    };
    return (
        <>
            <h5 style={{ fontWeight: "bold" }}>Já teve alguma reação com anestesia dental?</h5>
            <label>
                <input type="checkbox" defaultChecked={reacaoAnestesia} onChange={handleCheckboxChange} /> Sim
            </label>
            <br />
            <label>
                <input type="checkbox" defaultChecked={!reacaoAnestesia} onChange={() => setReacaoAnestesia(false)} /> Não
            </label>
            {reacaoAnestesia && (
                <><h5 style={{ fontWeight: "bold" }}>Reações:</h5>
                    <div>
                        <input
                            type="text"
                            value={novaReacao}
                            onChange={(e) => setNovaReacao(e.target.value)}
                            placeholder="Nova reação"
                        />
                        <ButtonAdicionarCampo onClick={handleAdicionarReacao} />
                    </div>
                </>)}
            <ul>
                {reacoes.map((reacao, index) => (
                    <li key={index}>
                        {reacao}
                        <ButtonEditarCampo onClick={() => handleEditarReacao(index)} />
                        <ButtonRemoverCampo onClick={() => handleRemoverReacao(index)} />
                    </li>
                ))}
            </ul>
        </>
    );
};
export default ProntuarioDenstitaReacaoAnestesia;
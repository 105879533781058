import { NavUserMain, NavBreadcrumb, FooterUser } from '@components';
export const ExamesFinalizados = () => {
    return (<>
        <div className="d-flex">
            <NavUserMain />
            <div className='w-100'>
                <NavBreadcrumb text={'Exames Finalizados'} icon={'database fa'} />
                <div className="py-3">
                    <div className="text-center">
                        <div className="container-fluid">
                            <div className="row d-inline-flex justify-content-center w-100">
                            </div>
                        </div>
                    </div>
                    <FooterUser />
                </div>
            </div>
        </div>
    </>)
}
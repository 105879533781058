import React, { useEffect, useState } from 'react';
import GDPRModules from './GDPRModules';
import LGPDModules from './LGPDModules';
import PoliticaDePrivacidade from './PoliticaDePrivacidade';
import { CheckCircleOutlined } from '@ant-design/icons';
import { Checkbox, Tag } from 'antd';
import TitleL2 from '../../text/TitleL2';
export const TermoDeResponsabilidadeCadastro = ({ aceitouTermo, setAceitouTermo, getUserId }) => {
  const [showModules, setShowModules] = useState(false);
  const toggleModules = () => {
    setShowModules(!showModules);
  };
  const [showSubModules, setShowSubModules] = useState(false);
  const toggleSubModules = () => {
    setShowSubModules(!showSubModules);
  };
  const handleAceitarTermo = () => {
    setAceitouTermo((prevAceitouTermo) => !prevAceitouTermo);
  };
  useEffect(() => {
    setAceitouTermo(aceitouTermo);
}, [getUserId,aceitouTermo, setAceitouTermo ]);
  return (<>
    <div id={'TermoResposabilidadeCadastro'}>
      <div className='text-center mr-2 ml-2 mt-3'>
        <label className='font-weight-bold text-center'>
          <Checkbox
            defaultChecked={aceitouTermo}
            onChange={handleAceitarTermo}
          />
         &nbsp; Eu aceito os Termos de Consetimentos Livre e Esclarecido [ TCLEs ] de Cadastro
        </label>
      </div>
      {aceitouTermo &&(<Tag className='mt-3 mb-3 d-flex ' style={{
        width: '100%',
        whiteSpace: 'pre-line'
      }} icon={<    CheckCircleOutlined style={{ fontSize: '48px', paddingInlineEnd: '50px' }} />} color="green">
        <div className='col-md-12'>
          <TitleL2 style={{ color: "green", textAlign: "left" }}>Termos de Consentimento Livre e Esclarecido aceito</TitleL2>
          <li className='text-success'> TCLEs - Cadastro Resumo</li>
          <li className='text-dark'> Você assumiu total responsabilidade pelo uso dos sistemas e está pronto para desfrustar dos beneficíos desse</li>
          <li className='text-dark'> Você pode escalonar qualquer serviço nosso de cadastro com esse termo único, unificado de cadastro</li>
          <li className='text-dark'> Você assumiu compartilhamento total de dados com a THOT I.A. CORP.</li>
          <li className='text-warning'> Você também pode discordar a qualquer momento dos nossos termos e inativar seu serviços</li>
          <li className='text-dark py-3'><strong>Data aceita: </strong>{ }</li>
        </div>
      </Tag>) }
      <p className='text-center'><abbr title="Clique para ver mais informações"><small className={`${showModules ? 'font-weight-bold text-center text-success mb-5' : 'font-weight-bold text-center text-primary mb-5'}`} onClick={toggleModules}>Termos de Consentimento Livre e Esclarecido [  TCLEs ] de Cadastro <i className='fa fa-info-circle'></i></small></abbr></p>
      {
        showModules && <>
          <div className="text-dark text-justify mt-5 mb-5 col-md-12">
            <div className='mt-5'>
              <h5 className='text-center'><abbr title="Clique para ver mais informações"><h5 className={`${!showSubModules ? 'font-weight-bold text-justify text-success mb-5 ' : 'font-weight-bold text-justify text-primary'}`} onClick={toggleSubModules}>Termo de Responsabilidade Cadastro &nbsp;<i className='fa fa-info-circle'></i></h5></abbr></h5>
              {
                !showSubModules && <>
                  <p className='text-justify mt-5'>
                    Bem-vindo(a) ao Termo de Responsabilidade para Cadastro  da THOT I.A. CORP.
                    Este documento estabelece os termos e condições para a utilização dos nossos serviços ao realizar o cadastro  em nossos sistemas digitais.
                    Leia atentamente antes de prosseguir com o cadastro.
                  </p>
                  <br />  <br />  <br />
                  <h5 className='font-weight-bold'>Termos e Condições</h5>
                  <ul className='ml-3'>
                    <hr />
                    <li className='text-justify mt-5'>
                      <strong>Introdução e Aceitação</strong>
                      <p className='text-justify py-5 '>
                        Ao prosseguir com o cadastro, você concorda em cumprir todos os termos e condições estabelecidos neste documento. Você reconhece que leu e compreendeu o conteúdo deste termo e concorda em ficar vinculado por ele.
                      </p>
                    </li>
                    <hr />
                    <li className='text-justify mt-5'>
                      <strong>Cadastro e Uso dos Sistemas Digitais</strong>
                      <p className='text-justify py-5 '>
                        Ao se cadastrar em nossos sistemas digitais, você se compromete a fornecer informações precisas e atualizadas. O acesso e uso dos sistemas estão sujeitos aos termos estabelecidos neste documento.
                      </p>
                      <ul className='ml-3 mb-5'>
                        <li className='text-justify mt-2'>✅ Forneça informações precisas e atualizadas ao se cadastrar.</li>
                        <li className='text-justify mt-2'>✅ Utilize os sistemas de acordo com as regras e regulamentos.</li>
                        <li className='text-justify mt-2'>✅ Acesso e uso dos sistemas sujeitos aos termos estabelecidos.</li>
                        <li className='text-justify mt-2'>✅ Você é o único responsável por manter suas informações de cadastro atualizadas.</li>
                      </ul>
                    </li>
                    <hr />
                    <li className='text-justify mt-5'>
                      <strong>Responsabilidade do Usuário</strong>
                      <p className='text-justify py-5 '>
                        Você é o único responsável por manter a confidencialidade das credenciais de acesso (login e senha). Não compartilhe suas credenciais com terceiros. Você é responsável por todas as atividades realizadas com suas credenciais de acesso.
                      </p>
                      <ul className='ml-3 mb-5'>
                        <li className='text-justify mt-2'>✅ Mantenha suas credenciais de acesso em segurança.</li>
                        <li className='text-justify mt-2'>✅ Não compartilhe suas credenciais com terceiros.</li>
                        <li className='text-justify mt-2'>✅ Assuma a responsabilidade por atividades realizadas com suas credenciais.</li>
                        <li className='text-justify mt-2'>✅ Proteja suas informações de acesso contra uso não autorizado.</li>
                      </ul>
                    </li>
                    <hr />
                    <li className='text-justify mt-5'>
                      <strong>Uso Adequado dos Sistemas</strong>
                      <p className='text-justify py-5 '>
                        O uso dos nossos sistemas deve ser adequado e respeitar as leis e regulamentos. Conteúdo ofensivo, ilegal ou prejudicial não será tolerado.
                      </p>
                      <ul className='ml-3 mb-5'>
                        <li className='text-justify mt-2'>✅ Utilize os sistemas de forma ética e legal.</li>
                        <li className='text-justify mt-2'>✅ Evite compartilhar conteúdo ofensivo ou difamatório.</li>
                        <li className='text-justify mt-2'>✅ Não utilize os sistemas para atividades ilegais.</li>
                        <li className='text-justify mt-2'>✅ Respeite os direitos de privacidade de outros usuários.</li>
                      </ul>
                    </li>
                    <hr />
                    <li className='text-justify mt-5'>
                      <strong>Propriedade Intelectual e Conteúdo</strong>
                      <p className='text-justify py-5 '>
                        Reconheça que o conteúdo presente nos sistemas pode estar sujeito a direitos autorais e propriedade intelectual. Não copie, reproduza ou distribua o conteúdo sem autorização.
                      </p>
                      <ul className='ml-3 mb-5'>
                        <li className='text-justify mt-2'>✅ Respeite os direitos autorais e propriedade intelectual do conteúdo nos sistemas.</li>
                        <li className='text-justify mt-2'>✅ Obtenha autorização antes de copiar, reproduzir ou distribuir qualquer conteúdo.</li>
                        <li className='text-justify mt-2'>✅ Evite violar os direitos dos detentores de propriedade intelectual.</li>
                        <li className='text-justify mt-2'>✅ Utilize o conteúdo apenas de acordo com as permissões fornecidas.</li>
                      </ul>
                    </li>
                    <hr />
                    <li className='text-justify mt-5'>
                      <strong>Privacidade e Proteção de Dados</strong>
                      <p className='text-justify py-5 '>
                        Entenda como coletamos, armazenamos e processamos seus dados. Consulte nossa política de privacidade para saber como suas informações são utilizadas e protegidas.
                      </p>
                      <ul className='ml-3 mb-5'>
                        <li className='text-justify mt-2'>✅ Leia e compreenda nossa política de privacidade.</li>
                        <li className='text-justify mt-2'>✅ Conheça como suas informações pessoais são coletadas e utilizadas.</li>
                        <li className='text-justify mt-2'>✅ Compreenda os direitos que você possui sobre seus dados pessoais.</li>
                        <li className='text-justify mt-2'>✅ Garanta que suas informações estejam protegidas de acordo com nossos padrões de segurança.</li>
                      </ul>
                    </li>
                    <hr />
                    <li className='text-justify mt-5'>
                      <strong>Limitação de Responsabilidade</strong>
                      <p className='text-justify py-5 '>
                        Não somos responsáveis por danos ou prejuízos decorrentes do uso dos sistemas. Você utiliza os sistemas por sua própria conta e risco.
                      </p>
                      <ul className='ml-3 mb-5'>
                        <li className='text-justify mt-2'>✅ Compreenda que você utiliza os sistemas por sua própria responsabilidade.</li>
                        <li className='text-justify mt-2'>✅ Não nos responsabilizamos por perdas, danos ou prejuízos resultantes do uso dos sistemas.</li>
                        <li className='text-justify mt-2'>✅ Esteja ciente dos riscos associados ao uso de sistemas digitais.</li>
                        <li className='text-justify mt-2'>✅ Aceite a responsabilidade por qualquer resultado decorrente de suas ações nos sistemas.</li>
                      </ul>
                    </li>
                    <hr />
                    <li className='text-justify mt-5'>
                      <strong>Alterações nos Termos</strong>
                      <p className='text-justify py-5 '>
                        Os termos podem ser atualizados periodicamente. Você será notificado sobre alterações significativas nos termos.
                      </p>
                      <ul className='ml-3 mb-5'>
                        <li className='text-justify mt-2'>✅ Esteja ciente de que os termos podem ser revisados e atualizados.</li>
                        <li className='text-justify mt-2'>✅ Comprometa-se a verificar regularmente os termos para estar informado sobre as mudanças.</li>
                        <li className='text-justify mt-2'>✅ Será notificado sobre alterações significativas nos termos por meio de comunicação adequada.</li>
                        <li className='text-justify mt-2'>✅ Continue a utilizar os sistemas somente se concordar com os termos atualizados.</li>
                      </ul>
                    </li>
                    <hr />
                    <li className='text-justify mt-5'>
                      <strong>Encerramento de Conta</strong>
                      <p className='text-justify py-5 '>
                        Sua conta pode ser encerrada em caso de violações dos termos ou inatividade prolongada.
                      </p>
                      <ul className='ml-3 mb-5'>
                        <li className='text-justify mt-2'>✅ Compreenda as condições sob as quais sua conta pode ser encerrada.</li>
                        <li className='text-justify mt-2'>✅ Evite viuar os termos para manter o acesso à sua conta ativo.</li>
                        <li className='text-justify mt-2'>✅ Entenda que a inatividade prolongada pode levar ao encerramento da conta.</li>
                        <li className='text-justify mt-2'>✅ Entre em contato conosco se precisar reativar sua conta após encerramento por inatividade.</li>
                      </ul>
                    </li>
                    <hr />
                    <li className='text-justify mt-5'>
                      <strong>Contato e Suporte</strong>
                      <p className='text-justify py-5 '>
                        Para dúvidas, suporte técnico ou questões relacionadas aos termos, entre em contato conosco.
                      </p>
                      <ul className='ml-3 mb-5'>
                        <li className='text-justify mt-2'>✅ Encontre informações de contato para buscar suporte e esclarecer dúvidas.</li>
                        <li className='text-justify mt-2'>✅ Use os canais de comunicação fornecidos para obter assistência técnica.</li>
                        <li className='text-justify mt-2'>✅ Entre em contato conosco se tiver perguntas sobre os termos ou necessitar de orientações.</li>
                        <li className='text-justify mt-2'>✅ Estamos aqui para auxiliá-lo(a) e fornecer o suporte necessário.</li>
                      </ul>
                    </li>
                    <hr />
                  </ul>
                </>}
              <PoliticaDePrivacidade />
              <GDPRModules />
              <LGPDModules />
            </div>
          </div>
        </>}
    </div >
  </>);
};
export default TermoDeResponsabilidadeCadastro;
export const pdf_download_13 = (
    doc,
    imageData,
    pdf_download_footer,
    qrCode,
    professionalInfo,
    imgWidth,
    imgHeight,
    modifiedLandmarks,
    landmarks_botox_color,
    pontoDestacado,
) => {
    if (
        !doc ||
        !imageData ||
        !pdf_download_footer ||
        !qrCode ||
        !professionalInfo ||
        !imgWidth ||
        !imgHeight ||
        !modifiedLandmarks ||
        !modifiedLandmarks.FaceFrontal ||
        !modifiedLandmarks.FaceFrontal.landmarks_botox_frontal ||
        !modifiedLandmarks.FaceFrontal.landmarks_botox_glabela ||
        !modifiedLandmarks.FaceFrontal.landmarks_botox_periorbital ||
        !modifiedLandmarks.FaceFrontal.landmarks_botox_nasal ||
        !landmarks_botox_color
    ) {
        console.warn("Parâmetros ausentes, página não adicionada.");
        return;
    }
    doc.addPage();
    doc.addImage(require('../assets/fundoPDF.png'), 'PNG', 0, 0, doc.internal.pageSize.width, doc.internal.pageSize.height);
    doc.setFont('times', 'bold');
    doc.setFontSize(25);
    doc.text('Resumo: Botox', 100, 15, { align: 'center' });
    doc.setFont('times', 'bold');
    doc.setFontSize(12);
    doc.text('[ Pontos ]', 15, 25);
    doc.setFont('times', 'normal');
    doc.setFontSize(8);
    const textResumoBotox = `É importante ressaltar que a abordagem personalizada busca equilibrar a suavização das linhas e a manutenção das microexpressões, permitindo que o paciente continue a exibir emoções de forma genuína. Além disso, a técnica leva em consideração as diferentes forças de contração em cada área do rosto, ajustando as doses de forma a garantir resultados eficazes, mas sutis. Essa personalização, proporciona uma harmonia facial que valoriza a beleza natural, ao mesmo tempo que promove um rejuvenescimento discreto e elegante. O objetivo é que o paciente se sinta renovado, mas sem perder a expressão que o torna único`
    const splitText = doc.splitTextToSize(textResumoBotox, 180);
    doc.text(splitText, 15, 30); // Texto quebrado dentro do limite de 180 de largura
    doc.addImage(imageData, 'JPEG', 15, 70, imgWidth, imgHeight);
    const botox_category = [
        'landmarks_botox_frontal',
        'landmarks_botox_glabela',
        'landmarks_botox_periorbital',
        'landmarks_botox_nasal',
        'landmarks_botox_periorbicular',
        'landmarks_botox_oralemento',
        'landmarks_botox_platisma'
    ];
    botox_category.forEach(category => {
        modifiedLandmarks.FaceFrontal[category].landmarks.forEach((landmark, index) => {
            landmarks_botox_color(doc, landmark, index, imgWidth, imgHeight, pontoDestacado);
        });
    });
    doc.setTextColor(0, 0, 0);  // Define a cor do texto
    doc.addPage();
    doc.addImage(require('../assets/fundoPDF.png'), 'PNG', 0, 0, doc.internal.pageSize.width, doc.internal.pageSize.height);
    doc.setFont('times', 'bold');
    doc.setFontSize(25);
    doc.text('Indicações: Botox - Funcional', 100, 15, { align: 'center' });
    doc.setFont('times', 'bold');
    doc.setFontSize(12);
    doc.text('[ Pontos ]', 15, 25);
    doc.setFont('times', 'normal');
    doc.setFontSize(8);
    pdf_download_footer(doc, qrCode, professionalInfo);
};
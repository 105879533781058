import React, { useState } from 'react';
export const TermoDeParcerias = () => {
  const [showModules, setShowModules] = useState(false);
  const toggleModules = () => {
    setShowModules(!showModules);
  };
  return (
    <div>
      <abbr title="Clique para ver mais informações"><h5 className={`${showModules ? 'font-weight-bold text-center text-success py-5' : 'font-weight-bold text-center text-primary py-5'}`} onClick={toggleModules}>Termos de Responsabilidade Parcerias <i className='fa fa-info-circle'></i></h5></abbr>
      {showModules && <><div className="text-dark text-justify mt-5 mb-5">
        <h5>
          <strong>Termos de Responsabilidade Parcerias 📋:</strong> A THOT I.A. CORP. tem como princípio o estímulo à colaboração e ao compartilhamento de ideias. Acreditamos que a inovação é alimentada pelo intercâmbio de conhecimento e experiência entre diversos colaboradores. No entanto, é importante ressaltar que, ao participar de nossos projetos e iniciativas, você concorda com os seguintes termos de responsabilidade:
        </h5>
        <h5 className='py-5'>
          <li className='mt-3'>✅ Não nos responsabilizamos por nenhuma informação trocada entre os participantes. Encorajamos a prática do bom senso e da cultura aberta de ideias, mas cada indivíduo é responsável por suas ações e contribuições.</li>
          <li className='mt-3'>✅ A THOT I.A. CORP. não garante a precisão ou veracidade das informações compartilhadas durante o processo de colaboração. Recomendamos a verificação independente e a validação de dados críticos.</li>
          <li className='mt-3'>✅ Não toleramos a disseminação de conteúdo ofensivo, discriminatório ou ilegal. Qualquer comportamento inadequado será tratado com seriedade, e medidas apropriadas serão tomadas.</li>
        </h5>
        <h5>
          Ao aceitar e enviar sua participação, você reconhece e concorda com esses termos, comprometendo-se a respeitá-los durante toda a colaboração.
        </h5>
        <h5 className='mt-5 py-5'>
          <strong>Referências 📚: </strong> Entendemos que a troca de informações é fundamental para o sucesso de projetos colaborativos. Mantemos todos os registros adequados de todas as interações e informações compartilhadas durante o processo de desenvolvimento pois é essencial. Essas referências podem incluir, mas não se limitam a:
        </h5>
        <ul className='mb-5'>
          <li>✅ Emails, mensagens ou documentos que contenham detalhes relevantes sobre o projeto 📧 <i className="ion-social-whatsapp  text-success"></i> <i className="ion-social-linkedin  text-primary"></i></li>
          <li>✅ Notas de reuniões, incluindo tópicos discutidos, decisões tomadas e ações atribuídas 📒</li>
          <li>✅ Arquivos e documentos compartilhados que contribuam para o progresso do projeto 🗄️<i className='fa fa-database'></i> <i className='fa fa-cloud'></i></li>
        </ul>
        <h5>
          As referências servem como um repositório de informações valiosas que podem ser consultadas no futuro. Elas garantem a transparência e a integridade das colaborações, ajudando a manter um registro claro e organizado do trabalho realizado.
        </h5>
      </div></>}
    </div>
  );
};
export default TermoDeParcerias;
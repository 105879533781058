import React, { useState } from 'react';
import { ButtonAdicionarCampo, ButtonEditarCampo, ButtonRemoverCampo } from '@components';
const ProntuarioDentistaFuma = () => {
  const [fuma, setFuma] = useState(false);
  const [historicoFuma, setHistoricoFuma] = useState([]);
  const [novaQuantidade, setNovaQuantidade] = useState('');
  const [indiceEditando, setIndiceEditando] = useState(-1);
  const handleCheckboxChange = () => {
    setFuma(!fuma);
  };
  const handleAdicionarQuantidade = () => {
    if (novaQuantidade.trim() !== '') {
      if (indiceEditando === -1) {
        setHistoricoFuma([...historicoFuma, novaQuantidade]);
      } else {
        const novaLista = [...historicoFuma];
        novaLista[indiceEditando] = novaQuantidade;
        setHistoricoFuma(novaLista);
        setIndiceEditando(-1);
      }
      setNovaQuantidade('');
    }
  };
  const handleRemoverQuantidade = (index) => {
    const novaLista = [...historicoFuma];
    novaLista.splice(index, 1);
    setHistoricoFuma(novaLista);
  };
  const handleEditarQuantidade = (index) => {
    setNovaQuantidade(historicoFuma[index]);
    setIndiceEditando(index);
  };
  return (
    <>
      <h5 style={{ fontWeight: "bold" }}>Fuma?</h5>
      <label>
        <input type="checkbox" defaultChecked={fuma} onChange={handleCheckboxChange} /> Sim
      </label>
      <br />
      <label>
        <input type="checkbox" defaultChecked={!fuma} onChange={() => setFuma(false)} /> Não
      </label>
      {fuma && (
        <>
          <p>Quantidade cigarros/cachimbo/vap dia:</p>
          <input
            type="text"
            value={novaQuantidade}
            onChange={(e) => setNovaQuantidade(e.target.value)}
            placeholder="Informe a quantidade"
          />
          <ButtonAdicionarCampo onClick={handleAdicionarQuantidade} />
        </>
      )}
      <ul>
        {historicoFuma.map((quantidade, index) => (
          <li key={index}>
            {quantidade}
            <ButtonEditarCampo onClick={() => handleEditarQuantidade(index)} />
            <ButtonRemoverCampo onClick={() => handleRemoverQuantidade(index)} />
          </li>
        ))}
      </ul>
    </>
  );
};
export default ProntuarioDentistaFuma;
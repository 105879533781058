export const post_analise_facial = async (
    setCurrentType,
    setLandmarks,
    setModifiedLandmarks,
    photos,
    setApiResults,
    setApiImages,
    setOthers,
    setLoading,
    type,
    apiToken,
    setUploadCount,
    serviceControl,
    setServiceControl
) => {
    setCurrentType(type);
    setLoading(true);
    const selectedPhoto = photos[type];
    if (!selectedPhoto) {
        console.log(`Nenhuma foto encontrada para o tipo ${type}.`);
        return;
    }
    const formData = new FormData();
    formData.append('image', selectedPhoto);
    formData.append('type', type);
    formData.append('control', JSON.stringify(serviceControl));
    alert(serviceControl)
    try {
        const response = await apiToken().post(`analiseFacial`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
            timeout: 50000000,
        });
        if (response.data?.res_ia) {
            setApiResults((prev) => ({ ...prev, [type]: response.data.res_ia }));
            setOthers((prev) => ({ ...prev, [type]: response.data.res_ia.others }));
            setApiImages((prev) => ({ ...prev, [type]: response.data.res_ia.images }));
            setLandmarks((prev) => ({ ...prev, [type]: response.data.res_ia.landmarks }));
            setModifiedLandmarks((prev) => ({ ...prev, [type]: response.data.res_ia.landmarks }));
            setServiceControl(response.data.control); // Atualizando diretamente sem associar ao tipo
            alert(JSON.stringify(response.data.control)); // Adicionando alerta para verificar
        }
        else {
            console.warn('Unexpected analysis type:', type);
        }
        setUploadCount((prevCount) => ({
            ...prevCount,
            [type]: (prevCount[type] || 0) + 1,
        }));
    } catch (error) {
        console.error(`Erro ao enviar a foto ${type}:`, error);
    } finally {
        setLoading(false);
    }
};
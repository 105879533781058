import { MaisInfoMenosInfo } from "@components";
import {
  DocumentosSoftwareAnaliseFacialAnalisesDoSorriso,
  DocumentosSoftwareAnaliseFacialAnalisesFrontal,
  DocumentosSoftwareAnaliseFacialVirtualReality,
  DocumentosSoftwareAnaliseFacialAnalisesFrontalBravo,
  DocumentosSoftwareAnaliseFacialAnalisesFrontalSurpresa,
  DocumentosSoftwareAnaliseFacialAnalisesLaterais,
  FrontalFechadaPhoto,
  SuperiorPhoto,
  InferiorPhoto,
  LateralEsquerdaPhoto,
  LateralDireitaPhoto
} from '../info';
export const FacePhotoCollectorMaisInfo = (type, piscaMaisInfo) => {
  switch (type) {
    // Capturas da Face
    case 'FaceFrontal':
      return <MaisInfoMenosInfo destacar={piscaMaisInfo} text={<DocumentosSoftwareAnaliseFacialAnalisesFrontal />} />;
    case 'FaceFrontalAR':
      return <MaisInfoMenosInfo destacar={piscaMaisInfo} text={<DocumentosSoftwareAnaliseFacialVirtualReality />} />;
    case 'FaceSorrindo':
      return <MaisInfoMenosInfo destacar={piscaMaisInfo} text={<DocumentosSoftwareAnaliseFacialAnalisesDoSorriso />} />;
    case 'FaceBravo':
      return <MaisInfoMenosInfo destacar={piscaMaisInfo} text={<DocumentosSoftwareAnaliseFacialAnalisesFrontalBravo />} />;
    case 'FaceSurpresa':
      return <MaisInfoMenosInfo destacar={piscaMaisInfo} text={<DocumentosSoftwareAnaliseFacialAnalisesFrontalSurpresa />} />;
    case 'FaceEsquerda':
      return <MaisInfoMenosInfo destacar={piscaMaisInfo} text={<DocumentosSoftwareAnaliseFacialAnalisesLaterais />} />;
    case 'FaceDireita':
      return <MaisInfoMenosInfo destacar={piscaMaisInfo} text={<DocumentosSoftwareAnaliseFacialAnalisesLaterais />} />;
    case 'BocaFrontalFechada':
      return <MaisInfoMenosInfo destacar={piscaMaisInfo} text={<FrontalFechadaPhoto />} />;
    case 'BocaSuperior':
      return <MaisInfoMenosInfo destacar={piscaMaisInfo} text={<SuperiorPhoto />} />;
    case 'BocaInferior':
      return <MaisInfoMenosInfo destacar={piscaMaisInfo} text={<InferiorPhoto />} />;
    case 'BocaFechadaEsquerda':
      return <MaisInfoMenosInfo destacar={piscaMaisInfo} text={<LateralEsquerdaPhoto />} />;
    case 'BocaFechadaDireita':
      return <MaisInfoMenosInfo destacar={piscaMaisInfo} text={<LateralDireitaPhoto />} />;
    default:
      return <p>Análise sem informações !!!</p>;
  }
};
import { ButtonCadastrar, CardFragmentIdentifier, LinkHref, TitleL1, TitleL3 } from "@components";
import {
    MdOutlineCoPresent,
    MdArchitecture,
    MdOutlineDiversity2,
} from "react-icons/md";
import { MdHistoryEdu } from "react-icons/md";
export const Historia = () => {
    const catalogFilePath = './assets/pdf/PesquisasOndasEletromagnéticas-THOT.pdf';
    const handleDownloadClick = () => {
        const downloadLink = document.createElement('a');
        downloadLink.href = process.env.PUBLIC_URL + catalogFilePath;
        downloadLink.download = 'Pré-Pesquisas-InluênciaDasRadiaçõesEletromagnéticas.pdf';
        downloadLink.click();
    };
    return (<>
        <section style={{ minHeight: '95dvh' }}>
            <TitleL1 icon={<MdHistoryEdu style={{ scale: '2' }} />}>História da Empresa</TitleL1>
            <div>
                <h5 className="text-justify mt-2 mr-2 ml-2 text-dark">
                    &nbsp; A empresa no formato startup teve origem
                    da vontade de auxiliar o próximo, sendo à idéia inicial modificar a iluminação noturna nos
                    hospitais durante a pandemia. Para isto foras redigido um livro com os todos os benefícios e
                    as propostas de pesquisa.
                    Esse livro apesar de várias referências internacionais e tese de ritmo circadiano foi intensamente questionado, não era uma idéia comercial <br>
                    </br><br></br>&nbsp;Sabendo das dificuldades de aprovar toda e qualquer pesquisa independente dela e tendo
                    o fundador experiências passadas com a confecção de eletroeletrônicos e pesquisas para área
                    da saúde, surgiu a idéia; confeccionar os equipamentos para monitorar as pesquisas teses de ritmo circadiano
                    propostas. Foi sábiamente na época uma boa escolha pivotar as pesquisas de
                    trocar a luminosidade noturna do hospital momentaneamente e desenvolver os equipamentos que
                    pudessem mensurar essas pesquisas; para isto desenvolvemos os produtos    <LinkHref to={'../parcerias#Pesquisadores#equipamentosPesquisasAnimais'}>[ Biotério Forte ]</LinkHref>  e                  <LinkHref to={'../parcerias#Pesquisadores#equipamentosMedicos'}>[ Infinity Life Healthcare ]</LinkHref> . Desenvolvendo
                    os equipamentos percebemos que eles poderiam monitorar nossas pesquisas de ritmo circadiano e todas as outras
                    fazendo disso um modelo para startup<br>
                    </br><br></br>&nbsp;Como todo início houve dificuldades, tinhamos em mãos dois inventos dois equipamentos, e apesar dos equipamentos possuir um sistema robusto bem tecnológico faltava a
                    integração e a popularização do uso. Dias e noite trabalhando e pensando "só poderiamos
                    popularizar este sistema de equipamentos se tivesse algum benefício muito grande que fosse
                    fácil de mexer e que todos pudessem utilizar"; assim nasceu o escossistema da Rede Social Profissional. Ofertando uma Rede Social Profissional de um forma simples e
                    direcionada para  ERP + CRM + E-commerce + Dropshipping + Meta Criações de I.A. - White label com atualizações constantes.
                    <LinkHref to={'../servicos#apresentacaoServicos#SolucaoGerencimamentoAvancado'}>[ Veja nosso sistema de gerenciamento altamente eficiente ]</LinkHref>
                </h5>
                <small className=" d-flex text-justify mt-4 mr-2 ml-2 text-dark"><b>O esboço do livro mencionado no
                    início que nos deu força e trouxe até aqui está em cache para ser publicado
                    extra-oficialmente na arquitetura da página [Artigos]. O spoiler deste livro antes de para na página artigos pode ser visto e está disponível no
                    botão download. Caso seja um pesquisador de radiações eletromagnéticas e seus efeitos no corpo, também nomeado de cronoterapia, temos a inteção de fazer a editoria e publicar esse livro entre em contatos conosco</b></small>
                <div className="d-flex text-center mx-auto justify-content-center mt-2">
                    <button className='btn btn-success mt-5' onClick={handleDownloadClick}>Baixar Pré-Pesquisas <i className="fa fa-download " aria-hidden="true"></i></button>
                </div>
                <TitleL3 className='mt-5'>⏣ Mais informações gerais da empresa</TitleL3>
                <div className='mb-5 d-flex flex-wrap justify-content-center '>
                    <CardFragmentIdentifier href={'significados'} title={'Significados'} text={'Branding marketing, criação do nome, logo, paleta de cores'} icon={<MdOutlineCoPresent style={{ marginTop: '28px', scale: '4' }} />} />
                    <CardFragmentIdentifier href={'condutas'} title={'Condutas'} text={'Modo de agir da empresa matriz, objetivo, missão, visão'} icon={<MdArchitecture style={{ marginTop: '28px', scale: '4' }} />} />
                    <CardFragmentIdentifier href={'Team'} title={'Equipe'} text={'Pessoas que participaram de alguma forma na construção'} icon={<MdOutlineDiversity2 style={{ marginTop: '28px', scale: '4' }} />} />
                </div>
                <ButtonCadastrar />
            </div>
        </section>
    </>)
}
// import React, { useState, useEffect, useRef } from 'react';
function VideoStream() {
  // const [videoUrl, setVideoUrl] = useState('http://192.168.15.253:81/stream');
  // const [isFullScreen, setIsFullScreen] = useState(false);
  // useEffect(() => {
  //   const socket = io();
  //   socket.on('videoUrl', (url) => {
  //     setVideoUrl(url);
  //   });
  //   return () => {
  //     socket.disconnect();
  //   };
  // }, []);
  // const iframeRef = useRef(null);
  // const handleFullScreen = () => {
  //   const streamIframe = iframeRef.current;
  //   setTimeout(() => {
  //     const imgElement = streamIframe.contentWindow.document.querySelector('#stream img');
  //     if (imgElement) {
  //       imgElement.style.width = '100%';
  //       imgElement.style.height = '100%';
  //     }
  //   }, 1000);
  // };
  // function exitFullScreen() {
  //   if (document.exitFullscreen) {
  //     document.exitFullscreen();
  //   } else if (document.webkitExitFullscreen) {
  //     document.webkitExitFullscreen();
  //   } else if (document.mozCancelFullScreen) {
  //     document.mozCancelFullScreen();
  //   } else if (document.msExitFullscreen) {
  //     document.msExitFullscreen();
  //   }
  //   setIsFullScreen(false);
  // }
  return (<></>
    // <div className='fixed-center holder d-flex'>
    //   <iframe
    //   ref={iframeRef}
    //     className=''
    //     id='stream-iframe'
    //     title='Stream'
    //     src={videoUrl}
    //     width='100%'
    //     height='100%'
    //     allowFullScreen
    //     onfullscreenchange={handleFullScreen}
    //   />
    //   {isFullScreen && (
    //     <div
    //       className='fullscreen-controls'
    //       style={{ position: 'absolute', top: '0', left: '0', zIndex: '9999' }}
    //     >
    //       <button className='btn a-86' onClick={exitFullScreen}>
    //         Exit Fullscreen
    //       </button>
    //     </div>
    //   )}
    //   {videoUrl && !isFullScreen && (
    //     <button
    //       className='btn a-86 fixed-bottom mb-2'
    //       onClick={handleFullScreen}
    //       style={{ position: 'fixed', maxWidth: '20%', marginLeft: '40%' }}
    //     >
    //       Iniciar Captura
    //     </button>
    //   )}
    // </div>
  );
}
export default VideoStream;
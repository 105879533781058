// For each step add text
export const tutorial_config = [
  "Veja todos os elementos 'Mais Info' destacados, piscando nos cards. Eles mostram como a imagem deve ser obtida e enviada.",
  `Vamos colocar uma imagem frontal para demonstração olhe o card frontal está destacado, contornado de vermelho 
  \n Agora você deve notar os pontos vermelhos no rosto, se eles estão contornando bem o rosto [ caso não estiver tente enviar a imagem novamente repita se for preciso ] 
  \n Para alterar os pontos da análise frontal arrasteos. Para separar o ponto abra o botão mostrar alterar pontos frontais [ Botão Amarelo ]
  `,
  `Para inserir informações do paciente no laudo veja o botão inserir informações do paciente [ Botão Verde ] 
  \n Você já pode fazer o download do laudo ele vai vir em formato de medida em pixel [ Botão Azul ]. Continue no tutorial para transformar pixel em cm [ Realidade Aumentada ]
  `,
  "Vamos simular a [ Realidade Aumentada ] transformar o laudo de pixel para cm inserindo uma imagem com cartão crédito/debito posicionado entre o nariz e o queixo  \n A qualquer momento você pode fechar esse tutorial e começar suas análises",
  "Vamos simular a [ Frontal Sorrindo ] ",
  "Por fim, este é o último passo do tutorial. Obrigado por participar!"
];
import { apiToken } from "../../../../../contexts";
export const updateImageStatus = async (id, imageId) => {
  try {
    const shouldUpdate = window.confirm(`Tem certeza que deseja inativar a imagem? ${id}/${imageId}`);
    if (!shouldUpdate) {
      return;
    }
    const response = await apiToken().put(
      `inativarFotoFaceAll/${id}/${imageId}/inactive`,
      { status: 'inactive' },
    );
    if (response.status === 200) {
      alert(`Image with ID ${id} marked as inactive on the backend.`);
    }
  } catch (error) {
    alert('Error updating image status:', error);
  }
};
import React, { useEffect, useRef } from 'react';
import { Chart } from 'chart.js/auto';
export const ChartBarData = ({ data }) => {
    const chartRef = useRef(null);
    useEffect(() => {
        const ctx = chartRef.current.getContext('2d');
        const existingChart = chartRef.current.chart;
        if (existingChart) {
            existingChart.destroy();
        }
        const chartData = {
            labels: data.length > 0 ? data.slice(1).map(([element]) => element) : [], 
            datasets: [
                {
                    label: 'Density',
                    data: data.slice(1).map(([, density]) => density), 
                    backgroundColor: data.slice(1).map(([, , style]) => {
                        if (typeof style === 'string') {
                            return style;
                        } else if (style && typeof style === 'object') {
                            return style.color || 'blue';
                        } else {
                            return 'blue'; 
                        }
                    }),
                },
            ],
        };
        const newChart = new Chart(ctx, {
            type: 'bar',
            data: chartData,
            options: {
                scales: {
                    x: { title: { display: true, text: 'Element' } },
                    y: { title: { display: true, text: 'Density' } },
                },
            },
        });
        chartRef.current.chart = newChart;
        return () => {
            newChart.destroy();
        };
    }, [data]);
    return (<>
        <canvas ref={chartRef}  />
    </>);
};
export default ChartBarData;
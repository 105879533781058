export const pdf_download_16 = (
    doc,
    pdf_download_footer,
    qrCode,
    professionalInfo,
    imageData,
    imageX,
    imageY,
    imgWidthHalf,
    imgHeightHalf,
    drawAttentionIcon,
    photos,
    modifiedLandmarks,
    others,
    generateHeatmapPDF,
    textY,
    imageWidth,
    imageHeight,
) => {
    if (
        !doc ||
        !pdf_download_footer ||
        !qrCode ||
        !professionalInfo ||
        !imageData ||
        !imageX ||
        !imageY ||
        !imgWidthHalf ||
        !imgHeightHalf ||
        !drawAttentionIcon ||
        !photos ||
        !modifiedLandmarks ||
        !others ||
        !generateHeatmapPDF ||
        !textY
    ) {
        console.warn("Parâmetros ausentes, página não adicionada.");
        return;
    }
    doc.addPage();
    doc.addImage(require('../assets/fundoPDF.png'), 'PNG', 0, 0, doc.internal.pageSize.width, doc.internal.pageSize.height);
    doc.setFont('times', 'bold');
    doc.setFontSize(25);
    doc.text('Cuidados Diários', 100, 15, { align: 'center' });
    doc.setFont('times', 'bold');
    doc.setFontSize(12);
    doc.text('[ Pele ]', 10, 25);
    doc.addImage(imageData, 'JPEG', imageX + 5, imageY, imgWidthHalf, imgHeightHalf);
    function addImageOrSquare(doc, imageDataArray, startX, startY, imgWidth, imgHeight) {
        let xOffset = startX;
        let yOffset = startY;
        const cols = 3;
        imageDataArray.forEach((imageData, index) => {
            if (imageData.url) {
                doc.addImage(imageData.url, 'JPEG', xOffset, yOffset, imgWidth, imgHeight);
            } else {
                doc.setFillColor(255, 255, 255); // Fundo branco
                doc.rect(xOffset, yOffset, imgWidth, imgHeight, 'F'); // Desenha o quadrado
                doc.setFont('times', 'normal');
                doc.setFontSize(6);
                doc.text(`[ Falta Foto ]`, xOffset + imgWidth / 4, yOffset + imgHeight / 2); // Texto com nome
                doc.text(`[${imageData.name} ]`, xOffset + imgWidth / 4 - 2, yOffset + imgHeight / 2 + 3); // Texto com nome
                drawAttentionIcon(doc, xOffset + imgWidth / 2 - 2, yOffset + imgHeight / 3, 4); // Ícone de atenção
            }
            if ((index + 1) % cols === 0) {
                xOffset = startX; // Reinicia X
                yOffset += imgHeight; // Avança para a próxima linha
            } else {
                xOffset += imgWidth; // Avança X
            }
        });
    }
    const isURL = (str) => {
        try {
            new URL(str, window.location.origin); // Tenta criar um objeto URL, mesmo para URLs relativas
            return true;
        } catch {
            return false;
        }
    };
    // Verifica se a string é Base64
    const isBase64 = (str) => {
        const base64Regex = /^data:image\/[a-zA-Z]+;base64,/;
        return base64Regex.test(str);
    };
    const imageArray = [
        {
            name: 'Frontal Sorrindo',
            url: photos['FaceSorrindo']
                ? isURL(photos['FaceSorrindo']) || isBase64(photos['FaceSorrindo'])
                    ? photos['FaceSorrindo'] // Já é uma URL válida (relativa, absoluta ou Base64)
                    : URL.createObjectURL(photos['FaceSorrindo']) // Caso contrário, cria URL temporária
                : null, // Fallback se não existir a chave em photos
        },
        {
            name: 'Frontal Bravo',
            url: photos.FaceBravo ? URL.createObjectURL(photos.FaceBravo) : null,
        },
        {
            name: 'Frontal Atenção',
            url: photos.FaceAtencao ? URL.createObjectURL(photos.FaceAtencao) : null, // Exemplo: Imagem ausente
        },
        {
            name: 'Lateral Esquerda',
            url: photos.FaceEsquerdaFaceEsquerda ? URL.createObjectURL(photos.FaceEsquerda) : null, // Exemplo: Imagem ausente
        },
        {
            name: 'Lateral Direita',
            url: photos.FaceDireita ? URL.createObjectURL(photos.FaceDireita) : null, // Exemplo: Imagem ausente
        },
    ];
    const startX = imgWidthHalf + 15;
    const startY = 30;
    addImageOrSquare(doc, imageArray, startX, startY, imgWidthHalf / 3, imgHeightHalf / 3);
    doc.setLineWidth(0.2);
    doc.setDrawColor(255, 0, 0);
    modifiedLandmarks.FaceFrontal.landmarks_wrinkles.forEach((coord, index) => {
        const x = 10 + coord.x * imgWidthHalf;
        const y = 30 + coord.y * imgHeightHalf;
        doc.setDrawColor(255, 0, 0);
        doc.setFillColor(255, 0, 0);
        doc.circle(x, y, 0.3, 'F');
        doc.setFontSize(5);
        doc.setTextColor(0, 0, 255);
        // Desenha o número do ponto próximo ao círculo
        // doc.text(`${index + 1}`, x, y);
    });
    doc.setFont('times', 'normal');
    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0);  // Define a cor do texto
    if (typeof others['FaceFrontal'].occupied_percentage_rugas_testa === "number") {
        console.log("Valor válido:", others['FaceFrontal'].occupied_percentage_rugas_testa);
        const formattedValue = Number(others['FaceFrontal'].occupied_percentage_rugas_testa).toFixed(0); // Certifique-se de converter para número antes de usar toFixed
        doc.text(`Análises de Rugas e Manchas Estáticas: ${formattedValue} %`, 10, textY - 1);
    } else {
        console.log("Valor inválido:", others['FaceFrontal'].occupied_percentage_rugas_testa);
        doc.text(`Análises de Rugas e Manchas Estáticas: Valor inválido`, 10, textY - 1);
    }
    doc.text(`Idade Ap.:${others['FaceFrontal'].predictionsOthers.age}`, 10, textY + 3);
    doc.text(`Sexo Ap.: ${others['FaceFrontal'].predictionsOthers.gender}`, 10, textY + 7);
    doc.text(`Etinia Ap.:  ${others['FaceFrontal'].predictionsOthers.ethnicity}`, 10, textY + 11);
    doc.text(`Tipo de Pele Ap.: `, 10, textY + 15);
    const pageWidth = doc.internal.pageSize.width;
    // Dimensões das seções
    const sectionWidth = (pageWidth - 40) / 3;
    const sectionHeight = 120;
    const sectionY = 150;
    const marginX = 10;
    // Cores
    const colors = [
        { r: 200, g: 230, b: 255 }, // Dia
        { r: 255, g: 240, b: 200 }, // Tarde
        { r: 220, g: 220, b: 255 }  // Noite
    ];
    const sections = ["Dia", "Tarde", "Noite"];
    sections.forEach((section, index) => {
        const x = marginX + index * (sectionWidth + 10);
        // Fundo da seção
        doc.setFillColor(colors[index].r, colors[index].g, colors[index].b);
        doc.roundedRect(x, sectionY, sectionWidth, sectionHeight, 5, 5, 'F');
        // Título da seção
        doc.setFont("helvetica", "bold");
        doc.setFontSize(14);
        doc.setTextColor(50, 50, 50);
        doc.text(section, x + sectionWidth / 2, sectionY + 10, { align: 'center' });
        // Espaço para descrição
        doc.setFont("helvetica", "normal");
        doc.setFontSize(10);
        doc.setTextColor(80, 80, 80);
        doc.text(`Descrição do produto\nIdeal para ${section.toLowerCase()}`, x + 5, sectionY + 20, { maxWidth: sectionWidth - 10 });
        // Espaço para imagem
        doc.setFillColor(255, 255, 255);
        doc.setDrawColor(255, 255, 255); // Cor do contorno (branca)
        doc.rect(x + 5, sectionY + 40, sectionWidth - 10, 30);
        doc.setFontSize(8);
        doc.setTextColor(150, 150, 150);
        doc.text("Imagem aqui", x + sectionWidth / 2, sectionY + 55, { align: 'center' });
    });
    generateHeatmapPDF(doc, others['FaceFrontal']?.heatmap_wrinkles, imgWidthHalf, imgHeightHalf, imageWidth, imageHeight);
    pdf_download_footer(doc, qrCode, professionalInfo);
};
import React, { useState } from "react";
import Calendar from "react-calendar";
import "./Styles/Agenda.css";
import "./Styles/Modal.css";
import Modal from "react-modal";
import DualTimeInput from "./DualTimeInput";
export function Agenda() {
  const [date, setDate] = useState(new Date());
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [inputCount, setInputCount] = useState(1);
  const [inputList, setInputList] = useState([]);
  const openModal = () => {
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };
  const handleAddInputs = () => {
    // Adicione um novo conjunto de inputs
    setInputCount(inputCount + 1);
    setInputList([...inputList]);
  };
  return (<>
    <div className="calendar-container">
      <Calendar
        onChange={setDate}
        value={date}
        selectRange={true}
        onClickDay={openModal}
      />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Selected Date Modal"
      >
        <div>
          {/* Div para os inputs */}
          <div>
            {Array.from({ length: inputCount }).map((_, index) => (
              <div key={index}>
                {/* Renderize os inputs aqui */}
                <input style={{ marginBottom: '1px', overflowY: 'auto', visibility: "hidden" }}
                  type="text"
                  name={`nome-${index}`}
                  placeholder=""
                />
                <DualTimeInput index={index} />
              </div>
            ))}
          </div>
          <div>
            <button
              className="button-style"
              style={{ marginTop: '-35px', left: '600px' }}
              onClick={handleAddInputs}
            >
              <i className="fa fa-plus"></i>
            </button>
          </div>
        </div>
      </Modal>
    </div>
  </>
  );
}
export default Agenda;
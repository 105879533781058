import React from 'react';
export const ProdutosInfo = () => {
    return (<>
        <div>
            <h5 className='font-weight-bold' ><strong>Produtos</strong></h5>
            <p>Nossa plataforma oferece uma ampla variedade de produtos de alta qualidade disponibilizados por profissionais e empresas renomadas. Aqui estão as informações essenciais para você explorar nossa seleção de produtos:</p>
            <h5 className='font-weight-bold' ><strong> Informações</strong></h5>
            <ol className="ml-3">
                <li>
                    <strong>Filtrar por Nome:</strong> Utilize essa opção para buscar produtos específicos pelo nome.
                </li>
                <li>
                    <strong>Selecione o Tipo:</strong> Filtre os produtos com base em categorias, como eletrônicos, roupas, brinquedos, e muito mais.
                </li>
                <li>
                    <strong>Filtrar por Preço:</strong> Configure o intervalo de preços para encontrar produtos que se encaixam no seu orçamento.
                </li>
                <li>
                    <strong>Selecione a Empresa:</strong> Escolha produtos de uma empresa específica, se assim desejar.
                </li>
                <li>
                    <strong>Total de produtos:</strong> Atualmente, oferecemos uma seleção com vários produtos de alta qualidade.
                </li>
            </ol>
            <section>
                <br /><br />
                <p className='text-center'>
                    <img
                        src={require('../layout/vender/Apresentacao/assets/sacolaPapelao.jpg')}
                        alt="Produtos em Destaque"
                        style={{ maxHeight: '650px', maxWidth: '650px' }}
                        className='mt-5 col-md-12'
                    />
                </p>
                <h1 className='font-weight-bold text-center mb-5 mt-5'>Loja de Produtos</h1>
                <br />
                <p className='mt-5 mb-5 text-center'> 🛒 Compras Online<p className='py-5'>&nbsp;<i className='fa fa-shopping-cart text-primary'></i> Disponível para clientes com acesso fácil e intuitivo</p></p>
                <ul className='ml-3 mr-3 text-justify'>
                    <li className='mt-2'><i className='fa fa-check-square text-success'></i>&nbsp;<strong>Compra Simplificada: </strong>Compre produtos com facilidade, escolhendo entre uma variedade de opções</li>
                    <li className='mt-2'><i className='fa fa-check-square text-success'></i>&nbsp;<strong>Promoções e Descontos: </strong>Aproveite ofertas especiais e descontos em seus produtos favoritos</li>
                    <li className='mt-2'><i className='fa fa-check-square text-success'></i>&nbsp;<strong>Gerenciamento de Pedidos: </strong>Acompanhe e gerencie seus pedidos de forma organizada</li>
                    <li className='mt-2'><i className='fa fa-check-square text-success'></i>&nbsp;<strong>Avaliações de Produtos: </strong>Leia as avaliações de outros clientes antes de fazer sua escolha</li>
                    <li className='mt-2'><i className='fa fa-check-square text-success'></i>&nbsp;<strong>Pagamento Seguro: </strong>Proteja suas transações com medidas avançadas de segurança</li>
                    <li className='mt-2'><i className='fa fa-check-square text-success'></i>&nbsp;<strong>Atendimento ao Cliente: </strong>Receba suporte e assistência quando necessário</li>
                    <li className='mt-2'><i className='fa fa-check-square text-success'></i>&nbsp;<strong>Entrega Conveniente: </strong>Receba seus produtos no local de sua escolha</li>
                    <li className='mt-2'><i className='fa fa-check-square text-success'></i>&nbsp;<strong>Variedade de Produtos: </strong>Encontre uma ampla seleção de produtos para atender às suas necessidades</li>
                </ul>
                <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br />
            </section>
        </div>
    </>);
};
export default ProdutosInfo;
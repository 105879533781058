import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Divider } from 'antd';
import { ButtonCadastrar, TitleL1, TitleL2 } from '@components';
import background from '../assets/images/background.webp'; // Importe sua imagem aqui
import Cases from './Cases';
import ClientsCarousel from './ClientsCarousel';
const Main = () => {
  const location = useLocation();
  const { search } = location;
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
  }, [search]);
  const handleWindowResize = () => {
    setScreenWidth(window.innerWidth);
  };
  return (<>
    <section style={{
      width: "100%",
      minHeight: "85dvh",
      display: 'flex',
      alignItems: 'center',
      padding: '0px',
      justifyContent: 'center'
    }}>
      <div
        className='w-100'
      >
        <div
          className="d-flex mx-auto justify-content-center"
          style={{
            position: 'relative',
            width: '100%',
            minHeight: '700px'
          }}>
          <img
            src={background}
            alt="Professional social network background"
            loading="lazy"
            style={{
              width: '100%',
              minHeight: '700px',
              height: '100%',
              objectFit: 'cover',
              zIndex: -1,
            }} />
          <div style={{ position: 'absolute', textAlign: 'center', zIndex: 1, width: '100%', height: '100%', background: screenWidth <= 1058 && 'rgba(51, 75, 144, 0.8)' }}>
            <TitleL1 className=' text-white mt-4 col-md-12' style={{ marginBottom: '0px', padding: screenWidth <= 374 && '0px', textAlign: screenWidth <= 374 ? 'center' : 'left', }}>
              Rede Social Profissional
            </TitleL1>
            <div className='col-md-6'
              style={{ marginTop: '0px' }}>
              <Divider style={{ margin: '0px', borderColor: 'white', borderWidth: '3px', marginBottom: '10px' }} />  </div>
            <h5
              className='text-justify  mb-4 col-md-6  text-white'
            >
              Conquiste seu sucesso agora com Inteligências Artificiais e Realidade Mista!
              Gerencie vários negócios de forma imbatível e fature muito mais do que você jamais imaginou!
              As vendas massivas estão a um clique de distância!
              Entre para a plataforma que está transformando profissionais em líderes de mercado!
              Comece agora e veja seu futuro mudar em minutos:</h5>
            <div className='col-md-6 py-3'><ButtonCadastrar style={{ color: 'white' }} /></div>
          </div>
        </div>
        <div className='text-center py-2'>
          <a href={'./servicos#apresentacaoServicos#SolucaoIAAvancadaServicos' + search}
            className='text-secondary mt-3 mb-2'
            style={{ textDecorationLine: 'underline', textDecorationSkipInk: ' none', cursor: 'pointer' }}>
            <strong  >[ &nbsp;Conheça nosso sistema de Inteligência Artificial e Realidade Mista Integradas &nbsp;]</strong>
          </a>
        </div>
        {/* <ClientsCarousel /> */}
        <div className="bg-light py-5 mt-5 mb-5">
          <div className="d-flex flex-wrap justify-content-center bg-light col-md-12 mt-1">
            <div
              className="col-md-4 mt-4 "
            > <p className='text-center'><i className="fas fa-user fa-3x"></i></p>
              <TitleL2 className="text-center">Para Usuários</TitleL2>
              <p className="text-justify mt-2 mb-4">
                Compre serviços e produtos de alta qualidade com recorrência mantenha-se sempre seguro, tenha histórico
              </p>
              <p className={`text-center mt-1 ${screenWidth <= 1058 && 'mb-5'}`}> <a href={'./servicos' + search} className="btn-primary rounded mr-2 ml-2 py-2 no-drag mt-5" style={{  boxShadow: "0px 4px 4px 0px  rgb(21, 120, 67), inset  0px 0px 6px 0px  rgb(128, 229, 165)", cursor: 'pointer' }}>
                &nbsp;👉<b>  Saiba mais </b> 👈&nbsp;
              </a></p>
            </div>
            <div
              className="col-md-4 mt-4 "
            > <p className='text-center'><i className="fas fa-briefcase fa-3x"></i></p>
              <TitleL2 className="text-center">Para Profissionais</TitleL2>
              <p className="text-justify mt-2 mb-4">
                Atenda seus clientes com máxima performance, conte com várias I.As para auxílio, incluindo I.As para vender - laudos
              </p>
              <p className={`text-center mt-1 ${screenWidth <= 1058 && 'mb-5'}`}> <a href={'./servicos#apresentacaoServicos' + search} className="btn-primary rounded mr-2 ml-2 py-2 no-drag mt-5" style={{  boxShadow: "0px 4px 4px 0px  rgb(21, 120, 67), inset  0px 0px 6px 0px  rgb(128, 229, 165)", cursor: 'pointer' }}>
                &nbsp;👉<b>Saiba mais</b>  👈&nbsp;
              </a></p>
            </div>
            <div
              className="col-md-4 mt-4 "
            > <p className='text-center'><i className="fas fa-building fa-3x"></i></p>
              <TitleL2 className="text-center">Para Empresas</TitleL2>
              <p className="text-justify mt-2 mb-4">
                Seja um representante, fornecedor e ou ainda aprimore, desenvolva softwares e equipamentos conosco
              </p>
              <p className={`text-center mt-1 ${screenWidth <= 1058 && 'mb-5'}`}>
                <a href={'./parcerias' + search} className=" btn-primary rounded mr-2 ml-2 py-2 no-drag mt-5" style={{  boxShadow: "0px 4px 4px 0px  rgb(21, 120, 67), inset  0px 0px 6px 0px  rgb(128, 229, 165)", cursor: 'pointer' }}>
                  &nbsp;👉<b>  Saiba mais </b> 👈&nbsp;
                </a></p>
            </div>
          </div>
        </div>
        {/* <Cases/> */}
      </div>
    </section>
  </>);
};
export default Main;
import { FooterProfissional, NavProfissionalMain } from '@components';
import EditarAtestados from './EditarAtestados';
export const AtestadosProfissionaisArmazenado = () => {
    return (<>
        <div className="d-flex">
            <NavProfissionalMain />
            <div className="w-100">
              <EditarAtestados/>
                <FooterProfissional />
            </div>
        </div>
    </>)
};
export const landmarks_media_pipe_point = (modifiedLandmarksFrontal) => ({
    // Lábio superior
    point186: modifiedLandmarksFrontal[185],
    point41: modifiedLandmarksFrontal[40],
    point40: modifiedLandmarksFrontal[39],
    point38: modifiedLandmarksFrontal[37],
    point1: modifiedLandmarksFrontal[0],
    point268: modifiedLandmarksFrontal[267],
    point270: modifiedLandmarksFrontal[269],
    point271: modifiedLandmarksFrontal[270],
    point316: modifiedLandmarksFrontal[315],
    point273: modifiedLandmarksFrontal[272],
    point272: modifiedLandmarksFrontal[271],
    point269: modifiedLandmarksFrontal[268],
    point14: modifiedLandmarksFrontal[13],
    point39: modifiedLandmarksFrontal[38],
    point42: modifiedLandmarksFrontal[41],
    point43: modifiedLandmarksFrontal[42],
    point79: modifiedLandmarksFrontal[78],
    // Lábio inferior
    point181: modifiedLandmarksFrontal[180],
    point17: modifiedLandmarksFrontal[16],
    point88: modifiedLandmarksFrontal[87],
    point318: modifiedLandmarksFrontal[317],
    point410: modifiedLandmarksFrontal[409],
    point179: modifiedLandmarksFrontal[178],
    point325: modifiedLandmarksFrontal[324],
    point62: modifiedLandmarksFrontal[61],
    point147: modifiedLandmarksFrontal[146],
    point86: modifiedLandmarksFrontal[85],
    point322: modifiedLandmarksFrontal[321],
    point376: modifiedLandmarksFrontal[375],
    point292: modifiedLandmarksFrontal[292],
    // Linhas
    point3: modifiedLandmarksFrontal[2],
    point5: modifiedLandmarksFrontal[8],
    point9: modifiedLandmarksFrontal[8],
    point15: modifiedLandmarksFrontal[14],
    point44: modifiedLandmarksFrontal[43],
    point47: modifiedLandmarksFrontal[46],
    point49: modifiedLandmarksFrontal[48],
    point53: modifiedLandmarksFrontal[52],
    point54: modifiedLandmarksFrontal[53],
    point56: modifiedLandmarksFrontal[55],
    point128: modifiedLandmarksFrontal[127],
    point136: modifiedLandmarksFrontal[135],
    point133: modifiedLandmarksFrontal[132],
    point64: modifiedLandmarksFrontal[63],
    point67: modifiedLandmarksFrontal[66],
    point71: modifiedLandmarksFrontal[70],
    point101: modifiedLandmarksFrontal[100],
    point105: modifiedLandmarksFrontal[104],
    point106: modifiedLandmarksFrontal[105],
    point117: modifiedLandmarksFrontal[116],
    point131: modifiedLandmarksFrontal[130],
    point134: modifiedLandmarksFrontal[133],
    point153: modifiedLandmarksFrontal[152],
    point157: modifiedLandmarksFrontal[156],
    point161: modifiedLandmarksFrontal[160],
    point177: modifiedLandmarksFrontal[176],
    point222: modifiedLandmarksFrontal[221],
    point208: modifiedLandmarksFrontal[207],
    point223: modifiedLandmarksFrontal[222],
    point227: modifiedLandmarksFrontal[226],
    point248: modifiedLandmarksFrontal[247],
    point264: modifiedLandmarksFrontal[263],
    point274: modifiedLandmarksFrontal[273],
    point279: modifiedLandmarksFrontal[278],
    point286: modifiedLandmarksFrontal[285],
    point294: modifiedLandmarksFrontal[293],
    point297: modifiedLandmarksFrontal[296],
    point301: modifiedLandmarksFrontal[300],
    point330: modifiedLandmarksFrontal[329],
    point335: modifiedLandmarksFrontal[334],
    point341: modifiedLandmarksFrontal[340],
    point354: modifiedLandmarksFrontal[353],
    point360: modifiedLandmarksFrontal[359],
    point363: modifiedLandmarksFrontal[362],
    point375: modifiedLandmarksFrontal[374],
    point384: modifiedLandmarksFrontal[383],
    point388: modifiedLandmarksFrontal[387],
    point398: modifiedLandmarksFrontal[397],
    point401: modifiedLandmarksFrontal[400],
    point402: modifiedLandmarksFrontal[401],
    point428: modifiedLandmarksFrontal[427],
    point447: modifiedLandmarksFrontal[446],
    point448: modifiedLandmarksFrontal[447],
    point442: modifiedLandmarksFrontal[441],
    point443: modifiedLandmarksFrontal[442],
    point444: modifiedLandmarksFrontal[443],
    point445: modifiedLandmarksFrontal[444],
    point446: modifiedLandmarksFrontal[445],
    point469: modifiedLandmarksFrontal[468],
});
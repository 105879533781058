// Arquivo de direcionamento completo para pré-operações
// Em operação fazer a função de trocar preferências de cor dos card e ou ainda posição 
// Estudar para ver como vai ser feito até pro próprio darklabel 
export const ModuloVisualImersivo = () => {
    return (
        <>
            <div className=' w-100' >
                <div className="py-3">
                    <div className="text-center">
                         <i className="fa fa-globe fa-5x text-dark"></i><br/><br/>
                        <div className="container-fluid">
                            <div className="row d-inline-flex justify-content-center w-100">
                                {/* Complexo A - sintonia NAV */}
                              <h5 className="text-dark text-center">Bem vindo ao universo imersivo</h5>
                              <div className="col-md-12 d-flex justify-content-center mx-auto align-items-center"> <button
                        className="btn btn-primary btn-block w-50 mb-2 mt-3 mx-auto a-86 text-center" type="submit" style={{ boxShadow: "inset 0px -6px 3px 5px rgba(0, 0, 0, 0.5)" }}>Entrar</button></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
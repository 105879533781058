import React from "react";
import '../styles/Intercambialidade.css'
const InfoIntercambialidade = ({ nomeDoMedicamento, substGenerico }) => {
    return (
        <div className="principalIntercambi">
            <div className="containerMedicamentoDiv">
                <p>O MEDICAMENTO: {nomeDoMedicamento}</p>
                <p>FOI SUBSTITUIDO PELO GENÉRICO: {substGenerico}</p>
                <p>DE ACORDO COM A LEI 9787/99</p>
            </div>
        </div>
    );
    }
    export default InfoIntercambialidade;
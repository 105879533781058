export const pdf_download_4 = (
    doc,
    imageData,
    points,
    pdf_download_footer,
    qrCode,
    professionalInfo,
    calcularVolumeLandmarks,
    imgWidthHalf,
    imgHeightHalf,
    imageX,
    imageY,
    imageXDir,
    imageYDir,
    linhaMediaPag,
    yEndVerticalLineMediaPag,
    textY,
    landmarks_position,
    drawSquareWithCheckmark,
    drawBlueSquareWithArrow,
    drawBlueSquareWithArrowDown,
    calcularPontoMaisBaixo,
    labioInferior,
    labioSuperior
) => {
    doc.addPage();
    doc.addImage(require('../assets/fundoPDF.png'), 'PNG', 0, 0, doc.internal.pageSize.width, doc.internal.pageSize.height);
    doc.setLineWidth(0.5);
    doc.setDrawColor(0, 0, 0);
    doc.line(linhaMediaPag, imageYDir, linhaMediaPag, yEndVerticalLineMediaPag + 5);// Linha média pag.
    pdf_download_footer(doc, qrCode, professionalInfo);
    doc.addImage(imageData, 'JPEG', 5, imageYDir, imgWidthHalf, imgHeightHalf);
    doc.setTextColor(0, 0, 0);
    doc.setFontSize(12);
    doc.setFont('times', 'bold');
    doc.setDrawColor(255, 0, 0);
    doc.text('Análises Verticais Do Terço Inferior: ', 5, textY + 80);
    doc.setFont('times', 'normal');
    const lineLength9to11 = Math.sqrt(Math.pow(landmarks_position.x9 - landmarks_position.x469, 2) + Math.pow(landmarks_position.y469 - landmarks_position.y9, 2));
    const distancePixels = Math.sqrt(Math.pow(landmarks_position.x3 - landmarks_position.x9, 2) + Math.pow(landmarks_position.y3 - landmarks_position.y9, 2));
    const lineLength3to153 = Math.sqrt(Math.pow(landmarks_position.x153 - landmarks_position.x3, 2) + Math.pow(landmarks_position.y153 - landmarks_position.y3, 2));
    const totalLength = lineLength9to11 + distancePixels + lineLength3to153;
    const lineLength3to15 = Math.abs(landmarks_position.y15 - landmarks_position.y3);
    const percentTercoInferior = (lineLength3to153 / totalLength) * 100;
    const percentTercoInferiorS = 100 * (lineLength3to15 / percentTercoInferior);
    if (percentTercoInferiorS >= 32 && percentTercoInferiorS <= 34) {
        drawSquareWithCheckmark(doc, 5 + 85, textY + 81, 5);
    } else if (percentTercoInferiorS > 34) {
        drawBlueSquareWithArrow(doc, 5 + 85, textY + 81, 5);
    } else {
        drawBlueSquareWithArrowDown(doc, 5 + 85, textY + 81, 5);
    }
    const lineLength3to15Text = `Terço Inf. Sup. - Tam.: ${lineLength3to15.toFixed(2)} unidades (${percentTercoInferiorS.toFixed(2)}%)`;
    const valorRestante = 100 - percentTercoInferiorS;
    const lineLength15to153 = Math.abs(landmarks_position.y153 - landmarks_position.y15);
    const lineLength15to153Text = `Terço Inf. Inf. - Tam.: ${lineLength15to153.toFixed(2)} unidades (${valorRestante.toFixed(2)}%)`;
    if (valorRestante >= 65 && valorRestante <= 67) {
        drawSquareWithCheckmark(doc, 5 + 85, textY + 92, 5);
    } else if (valorRestante > 67) {
        drawBlueSquareWithArrow(doc, 5 + 85, textY + 92, 5);
    } else {
        drawBlueSquareWithArrowDown(doc, 5 + 85, textY + 92, 5);
    }
    doc.text(lineLength3to15Text, 5, textY + 85);
    doc.setTextColor(255, 0, 0);
    doc.setFontSize(8);
    doc.text('Ref - Dist. entre Ponto 3 e Ponto 15', 5, textY + 90);
    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0);
    doc.text(lineLength15to153Text, 5, textY + 95);
    doc.setTextColor(255, 0, 0);
    doc.setFontSize(8);
    doc.text('Ref - Dist. entre Ponto 15 e Ponto 153', 5, textY + 100);
    doc.setTextColor(0, 0, 0);
    doc.setFont('times', 'bold');
    doc.text('Tam. total: ' + lineLength3to153.toFixed(2), 5, textY + 105);
    doc.setFont('times', 'normal');
    doc.text('O terço inf. sup. deve ter 1/3 33.3% e o inf.inf. 2/3 66.6% entre si para harmonia', 5, textY + 109);
    doc.text('facial,  proporções áureas. Assim o terço  inferior pode ser dividido  em terços:', 5, textY + 112);
    doc.text('a boca deve estar  a um  terço da  distância entre a base do nariz e o queixo', 5, textY + 115);
    doc.setFontSize(5);
    doc.text('Ref: Proffit, W. R. Ortodontia Contemporânea (8ª ed.). Elsevier, 2018. pag. 167', 5, textY + 126);
    doc.setFontSize(12);
    doc.setLineWidth(0.2);
    doc.setFont('times', 'bold');
    doc.text('Análises Horizontais Terço Inferior Lábios - Vo-', 5, imgHeightHalf + 34,);
    doc.text('lume', 5, imgHeightHalf + 38,);
    const labioInferiorArray = labioInferior(points, imageX, imageY, imgWidthHalf, imgHeightHalf);
    const labioSuperiorArray = labioSuperior(points, imageX, imageY, imgWidthHalf, imgHeightHalf);
    const pontoMaisAlto = calcularPontoMaisBaixo(labioInferiorArray);
    const pontoMaisAltoY = pontoMaisAlto.y
    labioInferiorArray.forEach((landmark, index) => {
        if (!landmark || typeof landmark.x === 'undefined' || typeof landmark.y === 'undefined') {
            console.error(`Ponto inválido no índice ${index}:`, landmark);
            return; // Pule para o próximo ponto se o atual for inválido
        }
        const { x, y } = landmark;
        const newY = y;
        doc.setDrawColor(255, 0, 0);
        doc.setFillColor(255, 0, 0);
        doc.setFontSize(5);
        doc.setTextColor(0, 0, 255);
        // Verifique se o próximo ponto existe antes de conectar a linha
        if (index < labioInferiorArray.length - 1) {
            const nextLandmark = labioInferiorArray[index + 1];
            if (!nextLandmark || typeof nextLandmark.x === 'undefined' || typeof nextLandmark.y === 'undefined') {
                console.error(`Próximo ponto inválido no índice ${index + 1}:`, nextLandmark);
                return;
            }
            doc.setDrawColor(0, 0, 0);
            doc.line(x, newY, nextLandmark.x, nextLandmark.y);
        }
    });
    labioSuperiorArray.forEach((landmark, index) => {
        const { x, y } = landmark;
        const newY = y;
        doc.setDrawColor(255, 0, 0);
        doc.setFillColor(255, 0, 0);
        // doc.circle(x, newY, 0.5, 'F');
        doc.setFontSize(5);
        doc.setTextColor(0, 0, 255);
        // doc.text(`${landmark.name}`, x + 0, newY + 0);
        if (index < labioSuperiorArray.length - 1) {
            const nextLandmark = labioSuperiorArray[index + 1];
            doc.setDrawColor(0, 0, 0);
            doc.line(x, newY, nextLandmark.x, nextLandmark.y);
        }
    });
    const volumelabioSuperior = calcularVolumeLandmarks(labioSuperiorArray);
    const volumelabioInferior = calcularVolumeLandmarks(labioInferiorArray);
    const volumeTotalLabios = volumelabioSuperior + volumelabioInferior;
    let pontoMaisBaixoY = Infinity;
    labioSuperiorArray.forEach((landmark) => {
        const { y } = landmark;
        const newY = y + 60 - pontoMaisAltoY;
        if (newY < pontoMaisBaixoY) {
            pontoMaisBaixoY = newY;
        }
    });
    const percentLabioSuperior = 100 * (volumelabioSuperior / volumeTotalLabios);
    const percentLabioInferior = 100 * (volumelabioInferior / volumeTotalLabios);
    doc.setLineWidth(0.5);
    doc.setDrawColor(255, 0, 0);
    doc.setFont('times', 'normal');
    doc.setTextColor(0, 0, 0);
    doc.setFontSize(8);
    doc.text('Lábio Sup.: ' + volumelabioSuperior.toFixed(2) + ' (' + percentLabioSuperior.toFixed(2) + '%)', 5, imgHeightHalf + 40 + 3);
    if (percentLabioSuperior >= 32 && percentLabioSuperior <= 34) {
        drawSquareWithCheckmark(doc, 91, imgHeightHalf + 37 + 2, 3);
    } else if (percentLabioSuperior > 34) {
        drawBlueSquareWithArrow(doc, 91, imgHeightHalf + 37 + 2, 3);
    } else {
        drawBlueSquareWithArrowDown(doc, 91, imgHeightHalf + 37 + 2, 3);
    }
    doc.text('Lábio Inf.: ' + volumelabioInferior.toFixed(2) + ' (' + percentLabioInferior.toFixed(2) + '%)', 5, imgHeightHalf + 40 + 7);
    if (percentLabioInferior >= 65 && percentLabioInferior <= 67) {
        drawSquareWithCheckmark(doc, 91, imgHeightHalf + 37 + 6, 3);
    } else if (percentLabioInferior > 67) {
        drawBlueSquareWithArrow(doc, 91, imgHeightHalf + 37 + 6, 3);
    } else {
        drawBlueSquareWithArrowDown(doc, 91, imgHeightHalf + 37 + 6, 3);
    }
    doc.setFont('times', 'bold');
    doc.text('Volume total dos lábios: ' + volumeTotalLabios.toFixed(2), 5, imgHeightHalf + 51);
    doc.setFont('times', 'normal');
    doc.text('A diferença volumétrica do lábio deve lábio sup. 1:1.6.  A aparência dos lábios', 5, imgHeightHalf + 40 + 15);
    doc.text('determinam em parte a atratividade do rosto de uma pessoa', 5, imgHeightHalf + 40 + 18);
    doc.setFontSize(5);
    doc.text('Refs: PALUD Alice, et al. Management of otitis media with effusion in cleft palate children. Acta Otorhinolaryngologica Ita-', 5, imgHeightHalf + 40 + 21);
    doc.text('lica, [S.l.], v. 29, n. 1, p. 38-42, 2009. Disponível em https://www.actaitalica.it/article/view/38', 5, imgHeightHalf + 40 + 23);
    doc.setFontSize(8);
    doc.addImage(imageData, 'JPEG', imageXDir, imageYDir, imgWidthHalf, imgHeightHalf);
    function calcularAnguloEntreLinhas(x1, y1, x2, y2, x3, y3, x4, y4) {
        const vetor1 = { x: x2 - x1, y: y2 - y1 };
        const vetor2 = { x: x4 - x3, y: y4 - y3 };
        const produtoEscalar = (vetor1.x * vetor2.x) + (vetor1.y * vetor2.y);
        const magnitudeVetor1 = Math.sqrt(Math.pow(vetor1.x, 2) + Math.pow(vetor1.y, 2));
        const magnitudeVetor2 = Math.sqrt(Math.pow(vetor2.x, 2) + Math.pow(vetor2.y, 2));
        const cosTheta = produtoEscalar / (magnitudeVetor1 * magnitudeVetor2);
        const anguloRadianos = Math.acos(cosTheta);
        const anguloGraus = (anguloRadianos * 180) / Math.PI;
        return anguloGraus;
    }
    const anguloMentoEqs = calcularAnguloEntreLinhas(landmarks_position.x177 + imageXDir, landmarks_position.y177, landmarks_position.x401, landmarks_position.y401, landmarks_position.x398, landmarks_position.y398, landmarks_position.x401, landmarks_position.y401);
    const anguloMentoDir = calcularAnguloEntreLinhas(landmarks_position.x401, landmarks_position.y401, landmarks_position.x177 + imageXDir, landmarks_position.y177, landmarks_position.x136, landmarks_position.y136, landmarks_position.x177 + imageXDir, landmarks_position.y177);
    const anguloMandibulaEqs = calcularAnguloEntreLinhas(landmarks_position.x133, landmarks_position.y133, landmarks_position.x136, landmarks_position.y136, landmarks_position.x177 + imageXDir, landmarks_position.y177, landmarks_position.x136, landmarks_position.y136);
    const anguloMandibulaDir = calcularAnguloEntreLinhas(landmarks_position.x401, landmarks_position.y401, landmarks_position.x398, landmarks_position.y398, landmarks_position.x402, landmarks_position.y402, landmarks_position.x398, landmarks_position.y398);
    doc.setFontSize(8);
    doc.setFont('times', 'bold');
    doc.setTextColor(255, 0, 0);
    doc.text(` ${anguloMentoDir.toFixed(2)}°`, landmarks_position.x401, landmarks_position.y401 + 3);
    doc.text(` ${anguloMentoEqs.toFixed(2)}°`, landmarks_position.x177 + imageXDir - 5, landmarks_position.y177 + 3);
    doc.text(` ${anguloMandibulaEqs.toFixed(2)}°`, landmarks_position.x136, landmarks_position.y136 + 3);
    doc.text(` ${anguloMandibulaDir.toFixed(2)}°`, landmarks_position.x398, landmarks_position.y398 + 3);
    doc.setLineWidth(0.1);
    doc.setDrawColor(255, 255, 255);
    doc.line(landmarks_position.x401, landmarks_position.y401, landmarks_position.x177 + imageXDir - 5, landmarks_position.y177); //mento
    doc.line(landmarks_position.x136, landmarks_position.y136, landmarks_position.x177 + imageXDir - 5, landmarks_position.y177); //mento ang. mandibula
    doc.line(landmarks_position.x401, landmarks_position.y401, landmarks_position.x398, landmarks_position.y398); //mento ang. mandíbula
    doc.line(landmarks_position.x136, landmarks_position.y136, landmarks_position.x133, landmarks_position.y133); //ang. mandíbula esq
    doc.line(landmarks_position.x398, landmarks_position.y398, landmarks_position.x402, landmarks_position.y402); //ang. mandíbula dir
    doc.setLineWidth(0.2);
    doc.setDrawColor(255, 0, 0);
    doc.line(landmarks_position.x448, imageYDir, landmarks_position.x448, yEndVerticalLineMediaPag - 140); // Linha externa da face
    doc.line(landmarks_position.x128, imageYDir, landmarks_position.x128, yEndVerticalLineMediaPag - 140); // Linha externa da face
    doc.setTextColor(0, 0, 0);
    doc.setFontSize(12);
    doc.setFont('times', 'bold');
    doc.text(`Análises dos Ângulos da Mandíbula e Mento`, imageXDir, textY);
    doc.setTextColor(0, 0, 0);
    doc.setFont('times', 'normal');
    doc.setFontSize(12);
    doc.addImage(imageData, 'JPEG', imageX, imageY + 130, imgWidthHalf, imgHeightHalf - 50);
    // Cálculo para diminuir 50% da distância Y
    doc.line(5, imageYDir + 105 + pontoMaisBaixoY, imageX + imgWidthHalf, imageYDir + 105 + pontoMaisBaixoY); //boca
    const novoY153 = landmarks_position.y153 / 2; // Subtrai 50% da distância Y original
    const novoY3 = landmarks_position.y3 / 2;     // Subtrai 50% da distância Y original
    // Linhas ajustadas com 50% da distância Y
    doc.line(imageX, novoY153 + 143, imageX + imgWidthHalf, novoY153 + 143); // Baixo do queixo ajustado
    doc.line(imageX, novoY3 + 143, imageX + imgWidthHalf, novoY3 + 143);     // Base do nariz ajustado  
    pdf_download_footer(doc, qrCode, professionalInfo);
};
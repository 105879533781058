import { TitleL4 } from "@components";
export const DocumentosSoftwareAnaliseFacialRegraImagesFrontal = () => {
    return (<>
        <ul className='ml-3'>
            <TitleL4>Pré-requisitos da imagem:</TitleL4>
            <li >
                <strong>✅ Identificação Fácil:</strong> Use uma foto com as mesmas regras da foto 3/4 de identidade,
                o rosto centralizado em um fundo homogêneo que permita que as pessoas o identifiquem facilmente.
                Evite maquiagem, óculos, chapéus ou outros artefatos que possam obscurecer seu rosto. Para cabelos compridos
                matenha o cabelo acima da linha de inserção do couro cabeludo e atrás da orelha
            </li>
            <li >
                <strong>✅ Enquadramento Adequado:</strong> Certifique-se de que sua foto esteja bem enquadrada, com seu rosto centralizado
            </li>
            <li >
                <strong>✅ Fundo Homogêneo:</strong> Escolha um local que contenha um fundo homogêneo
            </li>
            <li >
                <strong>✅ Iluminação:</strong> Escolha uma foto tirada em boa iluminação, pois isso realça sua aparência
            </li>
            <li >
                <strong>✅ Resolução Adequada:</strong> Use uma foto de alta resolução para garantir que sua imagem seja nítida e clara
            </li>
            <li >
                <strong>✅ Formato Adequado:</strong> Use uma foto com resulução quadrada exemplo: 512x512 - 1024x1024 ...
                caso a imagem não represente esse formato no ato da inserção pediremos seu auxílio para aplicar cortes
            </li>
        </ul>
    </>);
};
export default DocumentosSoftwareAnaliseFacialRegraImagesFrontal;
import { ButtonCadastrar, TitleL1, TitleL3 } from '@components'
import TRL from './TRL'
import {
    MdHome,
    MdRoomService,
    MdWidgets,
    MdHistoryEdu,
    MdHandshake,
    MdAddShoppingCart,
    MdAccountCircle,
    MdOutlinePersonAddAlt,
    MdEmojiEvents,
    MdInsights
} from "react-icons/md";
export const NivelMaturidadeTecnologica = () => {
    return (
        <> <section style={{ minHeight: '95dvh' }}>
            <div className='mt-2'>
                <TitleL1 icon={<MdInsights style={{ scale: '2' }} />}>Maturidade Téc.</TitleL1>
                <h5 className='text-justify mb-5'>
                    A maturidade tecnológica indica o estágio de desenvolvimento de nossos serviços ou produtos.
                    Essa classificação fornece transparência aos usuários sobre o nível de evolução e confiabilidade da tecnologia que estamos oferecendo
                </h5>
                <TitleL3>Serviços</TitleL3>
                <div className="d-flex flex-wrap justify-content-center">
                    <div className="col-md-5  mt-2 align-items-center border rounded  " style={{ padding: '0px' }}>
                        <img className="img-fluid  col-md-12 d-block mx-auto d-flex justify-content-center align-items-center w-100 rounded"
                            src={require('../assets/background.jpg')} alt='some value'
                            style={{ maxHeight: '280px', width: '100%', minHeight: "280px", margin: '0px', padding: '0px' }} />
                        <TitleL1>Rede Social Profissional</TitleL1>
                        <div className='col-md-12'>
                            <h5 className='py-3 text-center font-weight-bold'><b><strong>TRL 7 indo para 8</strong></b><br></br></h5>
                            <h5 className='text-justify'> Serviço inaugurado em 2024 com escala de
                                [ TRL - 7 ] - Demonstração de protótipo de sistema em ambiente operacional<br /> <br /> Subindo escalonamento para [ TRL 8] - produto pronto para produção - sistema completo, testado, qualificado e demonstrado</h5>
                        </div>
                        <div className='iconTRL-links'>
                            <a href='../' className='iconTRL-link'>
                                <MdHome className='iconTRL' />
                                <span>Home</span>
                            </a>
                            <a href='../servicos' className='iconTRL-link'>
                                <MdRoomService className='iconTRL' />
                                <span>Serviços</span>
                            </a>
                            <a href='../produtos' className='iconTRL-link'>
                                <MdWidgets className='iconTRL' />
                                <span>Produtos</span>
                            </a>
                            <a href='../artigos' className='iconTRL-link' >
                                <i className="fa-researchgate fab text-center iconTRL" style={{ scale: '0.8' }} />
                                <span>Artigos</span>
                            </a>
                            <a href='../parcerias' className='iconTRL-link'>
                                <MdHandshake className='iconTRL' />
                                <span>Parcerias</span>
                            </a>
                            <a href='../sobre' className='iconTRL-link'>
                                <MdHistoryEdu className='iconTRL' />
                                <span>Sobre</span>
                            </a>
                            <a href='../carrinho' className='iconTRL-link'>
                                <MdAddShoppingCart className='iconTRL' />
                                <span>Carrinho</span>
                            </a>
                            <a href='../login' className='iconTRL-link'>
                                <MdAccountCircle className='iconTRL' />
                                <span>Login</span>
                            </a>
                            <a href='../cadastrar' className='iconTRL-link'>
                                <MdOutlinePersonAddAlt className='iconTRL' />
                                <span>Cadastrar</span>
                            </a>
                        </div>
                    </div>
                </div>
                <TitleL3>Produtos</TitleL3>
                <div className="d-flex flex-wrap justify-content-center">
                    <div className="col-md-5  mt-2 align-items-center border rounded ">
                        <img className="img-fluid col-md-12 d-block mx-auto d-flex justify-content-center align-items-center mt-2 rounded"
                            src={require('../assets/nivelMaturidadeTecnologica/BioterioForteSF.png')} alt='some value'
                            style={{ maxHeight: '280px', maxWidth: '580px', minHeight: "280px" }} />
                        <div>
                            <TitleL1>BIOTÉRIO FORTE</TitleL1>
                            <h5 className='py-3 text-center'><b>TRL 3 / 4</b><br></br></h5>
                            <h5 className='text-justify'> Projeto mantido por colaboradores internos e sendo avaliado pelo programa Centelha -
                                MT. Em caso positivo saída do projeto Centelha MT com produto
                                TRL - 5 produto pronto para industrializar</h5>
                        </div>
                        <div className='iconTRL-links'>
                            <a href='../parcerias#Pesquisadores#equipamentosPesquisasAnimais' className='iconTRL-link'>
                                <MdHandshake className='iconTRL' />
                                <span>Parcerias</span>
                            </a>
                            <a href='../sobre#premios' className='iconTRL-link'>
                                <MdEmojiEvents className='iconTRL' />
                                <span>Reconhecimentos</span>
                            </a>
                        </div >
                    </div >
                    <div className='col-md-1'></div>
                    <div className="col-md-5  mt-2 align-items-center border rounded  ">
                        <img className="img-fluid d-block mx-auto d-flex justify-content-center align-items-center mt-2 rounded"
                            src={require('../assets/nivelMaturidadeTecnologica/InfinityLifeHealthcareSF.png')} alt='some value'
                            style={{ maxHeight: '280px', maxWidth: '580px', minHeight: "280px" }} />
                        <div>
                            <TitleL1>INFINITY LIFE</TitleL1>
                            <h5 className='py-3 text-center'><b>TRL 3 / 4</b><br></br></h5>
                            <h5 className='text-justify'> Projeto mantido por colaboradores internos
                                com lançamento do produto projetado para 2023; 2023 -
                                TRL - 5 produto pronto para industrializar</h5>
                        </div>
                        <div className='iconTRL-links'>
                            <a href='../parcerias#Pesquisadores#equipamentosMedicos' className='iconTRL-link'>
                                <MdHandshake className='iconTRL' />
                                <span>Parcerias</span>
                            </a>
                            <a href='../sobre#premios' className='iconTRL-link'>
                                <MdEmojiEvents className='iconTRL' />
                                <span>Reconhecimentos</span>
                            </a>
                        </div >
                    </div >
                </div>
            </div>
            <TRL />
            <ButtonCadastrar />
        </section>
        </>)
}
import { Index } from "./Index";
import { FooterProfissional, Nav, NavProfissionalMain } from "@components";
export const ProfissionaisServicosOrcamento = () => {
    return (<>
        <Nav />
        <div className="d-flex mt-3">
            <NavProfissionalMain />
            <div className="w-100 ">
                <Index />
                <FooterProfissional />
            </div>
        </div>
    </>)
}
import { ButtonCadastrar, CardFragmentIdentifier, TitleL1, TitleL3 } from '@components';
import {
    MdOutlineDiversity2,
    MdOutlineWorkspacePremium,
    MdOutlineYard
} from "react-icons/md";
export const Team = () => {
    return (<>
        <section className='ml-3 mr-3' style={{ minHeight: '95dvh' }}>
            <TitleL1 icon={<MdOutlineDiversity2 style={{ scale: '2' }} />}>Equipe</TitleL1>
            <h5 className='text-justify'>
                Na THOT I.A. CORP., acreditamos que o segredo do sucesso está em nossa equipe campeã e no mindset
                de excelência que adotamos diariamente. Cada membro de nossa equipe é dedicado,
                comprometido e apaixonado pelo que faz, trabalhando incansavelmente para alcançar resultados
                excepcionais. Nosso time é composto por profissionais altamente qualificados, que trazem uma vasta
                experiência e habilidades diversificadas, permitindo-nos abordar desafios com criatividade e eficácia
                <br /><br /><br />
                Aqui também fomentamos uma cultura de colaboração e aprendizado contínuo, onde cada opinião é valorizada e novas
                ideias são sempre bem-vindas. Nosso mindset de crescimento nos impulsiona a constantemente buscar melhorias,
                inovar e adaptar-nos às mudanças do mercado. Acreditamos que o desenvolvimento pessoal e profissional de cada
                colaborador é fundamental para o sucesso coletivo da THOT I.A. CORP.
                <br /><br /><br />
                Essa mentalidade vencedora nos permite não apenas superar obstáculos, mas também
                transformar desafios em oportunidades. Com um foco inabalável na excelência e na satisfação
                de nossos clientes, estamos sempre prontos para ir além e entregar resultados que excedem as expectativas.
                Juntos, como uma equipe unida e determinada, a THOT I.A. CORP. se destaca no mercado, demonstrando que o verdadeiro
                poder de uma empresa está em sua capacidade de cultivar um espírito de equipe campeão e um mindset de excelência
            </h5    >
            <TitleL3 className='mt-5'>⏣ Mais informações gerais da equipe</TitleL3>
            <div className='mb-5 d-flex flex-wrap justify-content-center '>
                <CardFragmentIdentifier href={'TeamFundadoresProjetos'} title={'Fundadores'} text={'Idealistas mantenedores dos sistemas'} icon={<MdOutlineWorkspacePremium style={{ marginTop:'28px',scale: '5' }} />} />
                <CardFragmentIdentifier href={'TeamBolsistasContratados'} title={'Colaboradores'} text={'Fonte de inspirações continua'} icon={<MdOutlineYard style={{ marginTop:'28px',scale: '4' }} />} />
            </div>
            <ButtonCadastrar />
        </section>
    </>)
};
import React, { useState } from "react";
import { InputPadraoSearch } from '@components';
import { MdFaceRetouchingNatural, MdImageSearch } from "react-icons/md";
import MainImagemIA from "./Classificacao/MainImagemIA";
import AnaliseFacial from "./AnaliseFacial/AnaliseFacial";
const cardClassificacaoDeImagens = () => <MainImagemIA />;
const cardAnaliseFacial = () => <AnaliseFacial />;
const cards = [
    { text: 'Análise Orofacial', icon: <MdFaceRetouchingNatural size={40} />, component: cardAnaliseFacial },
    { text: 'Classificação de Imagens', icon: <MdImageSearch size={40} />, component: cardClassificacaoDeImagens },
    // Adicione mais cards conforme necessário
];
export const EscolheIAImagem = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [SelectedComponent, setSelectedComponent] = useState(null);
    const filteredCards = cards.filter(card => card.text.toLowerCase().includes(searchTerm.toLowerCase()));
    const handleCardClick = (Component) => {
        setSelectedComponent(() => Component);
    };
    return (<>
        {SelectedComponent ? (<>
            <div style={{ position: 'absolute', top: '140px', right: '5px', zIndex: '1000' }} >
                <button className="btn btn-danger" onClick={() => setSelectedComponent(null)}>Voltar</button>
            </div>
            <div className="component-container text-right" >
                <SelectedComponent />
            </div>
        </>) : (
            <div className="">
                <InputPadraoSearch
                    type="text"
                    placeholder="Filtre páginas"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                <div className="row d-inline-flex justify-content-center w-100">
                    {filteredCards.map((card, index) => (
                        <div
                            key={index}
                            className="bg-info card no-drag"
                            style={{ cursor: 'pointer', margin: '10px', padding: '20px', border: '1px solid #ccc', borderRadius: '8px', textAlign: 'center' }}
                            onClick={() => handleCardClick(card.component)}
                        >
                            {card.icon}
                            <div>{card.text}</div>
                        </div>
                    ))}
                </div>
            </div>
        )}
    </>);
};
export default EscolheIAImagem;
import React from 'react';
const ProntuarioDiabetesCicatrizacaoInfo = () => {
  return (
    <div>
      <h5>Usando o Componente <strong>Diabetes Cicatrizacao</strong></h5>
      <p>
        O componente <strong>Diabetes Cicatrizacao</strong> permite que você registre sua cicratização. Você pode adicionar, editar e remover leituras da sua cicratização conforme necessário. Abaixo, você encontrará um guia de como usar este componente.
      </p>
      <hr/>
      <ol className='ml-3'>
        <li><strong>Indicar se tem diabetes </strong></li>
        <p>
          Quando você abrir o componente, você verá a pergunta "Tem diabetes?" e três opções: "Sim," "Não" e "Não Sei." Selecione a opção correspondente para indicar se você tem diabetes.
        </p>
        <li><strong>Avaliar o sangramento</strong></li>
        <p>
          Em seguida, você verá a pergunta "Quando se corta há um sangramento?" com duas opções: "Normal" e "Excessivo." Escolha a opção que melhor descreve o seu sangramento em caso de cortes.
        </p>
        <li><strong>Avaliar a cicatrização</strong></li>
        <p>
          Logo após, você verá a pergunta "Sua cicatrização é?" com duas opções: "Normal" e "Complicada." Selecione a opção que melhor descreve a sua cicatrização.
        </p>
        <li><strong>Finalização </strong></li>
        <p>
          Após indicar se tem diabetes, avaliar o sangramento e a cicatrização, você pode clicar no botão "ADICIONAR INFORMAÇÕES DE SAÚDE" na parte inferior do componente para concluir o processo de registro de informações de saúde.
        </p>
      </ol>
      <hr/>
      <p>
        Certifique-se de seguir os passos corretamente e de selecionar as opções que melhor correspondem à sua condição de diabetes, sangramento e cicatrização.
      </p>
      <br /><br /><br /><br /><br /><br /><br /><br />
    </div>
  );
};
export default ProntuarioDiabetesCicatrizacaoInfo;
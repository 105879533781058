import React, { useState } from 'react';
import { ButtonAPI, InputPadrao, MaisInfoMenosInfo } from '@components';
import ShareAITextInfo from '../Text/info/ShareAITextInfo';
export function ShareAI({ nameIAText, setNameIAText }) {
  const [contacts, setContacts] = useState([{ type: '', value: '' }]);
  const [isLoading, setIsLoading] = useState(false);
  const [shareMessage, setShareMessage] = useState('');
  const [showContacts, setShowContacts] = useState(false);
  const handleContactChange = (index, field, value) => {
    const updatedContacts = [...contacts];
    updatedContacts[index][field] = value;
    setContacts(updatedContacts);
  };
  const handleAddContact = () => {
    setContacts([...contacts, { type: '', value: '' }]);
    setShowContacts(true);
  };
  const handleRemoveContact = (index) => {
    const updatedContacts = contacts.filter((_, i) => i !== index);
    setContacts(updatedContacts);
  };
  const handleShareAIText = () => {
    setIsLoading(true);
    // Lógica para compartilhar aqui
    setIsLoading(false);
    setShareMessage('')
  };
  return (<>
    <InputPadrao
      title={'Compartilhar I.A'}
      className={`input-field form-control mt-1 form-control-lg text-dark text-center`}
      type="text"
      placeholder="Nome da I.A. ?"
      value={nameIAText}
      onChange={(e) => setNameIAText(e.target.value)}
    />
    <button className={` ${'success'
      } generate-button`} onClick={handleShareAIText}>
      <i className={` ${'input-filled'
        } fa fa-rocket mt-2`}></i>
    </button>
    <div className="contact-options text-center">
      {showContacts && <> {contacts.map((contact, index) => (
        <div key={index} className="contact-input container mt-3">
          <select
            className="custom-select text-center border border-dark mx-auto font-weight-bold rounded   mt-1"
            value={contact.type}
            onChange={(e) => handleContactChange(index, 'type', e.target.value)}
          >
            <option value="">Escolha o tipo</option>
            <option value="email">E-mail</option>
            <option value="whatsapp">WhatsApp</option>
          </select>
          <input
            className={`input-field form-control mt-1 form-control-lg text-dark text-center`}
            type={contact.type === 'email' ? 'email' : 'tel'}
            placeholder={contact.type === 'email' ? 'E-mail' : 'Número de WhatsApp'}
            value={contact.value}
            onChange={(e) => handleContactChange(index, 'value', e.target.value)}
          />
          <button className="btn btn-danger" onClick={() => handleRemoveContact(index)}>
            Remover Contato
          </button>
        </div>
      ))} </>}
      <button className="btn btn-secondary mt-3" onClick={handleAddContact}>
        Adicionar Contato
      </button>
      {showContacts && <>
        <ButtonAPI isLoading={isLoading} className="btn btn-primary" onClick={handleShareAIText}>
          Compartilhar I.A.
        </ButtonAPI>
        <p>{shareMessage}</p></>}
    </div>
    <MaisInfoMenosInfo text={<ShareAITextInfo />}></MaisInfoMenosInfo>
  </>);
}
export default ShareAI;
import { apiToken } from "../../../../contexts";
export const postDashboardData = async (dashboardData) => {
  try {
    const response = await apiToken().post(`dashboard`, dashboardData);
    console.log("Response Status:", response.status);
    if (!response.ok) {
      console.error("Response Body:", response.data);
      throw new Error("Erro ao salvar os dados do dashboard");
    }
    const data = response.data;
    return data;
  } catch (error) {
    console.error("Erro ao salvar os dados do dashboard:", error);
    throw error;
  }
};
export default postDashboardData;
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { InputPadraoSearch } from '@components';
export const SearchBar = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  const searchSite = () => {
    // Guarda a informação de busca no localStorage
    localStorage.setItem('searchTerm', searchTerm);
    // Navega para a página de busca
    navigate(`/search`);
  };
  const handleEnterPress = (event) => {
    if (event.key === 'Enter') {
      searchSite();
    }
  };
  return (
    <InputPadraoSearch
      type="text"
      placeholder="Busco no site por..."
      value={searchTerm || ''}
      onKeyDown={handleEnterPress}
      onChange={event => setSearchTerm(event.target.value)}
      typeButton="button"
      onClick={searchSite}
    />
  );
};
export default SearchBar;
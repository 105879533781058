import { ButtonCadastrar, TitleL1 } from '@components';
const SobrePublicacoesInfo = () => {
    return (<>
        <section style={{ minHeight: '95dvh' }}>
            <div className={`py-5 rounded align-items-center    mx-auto text-center`}>
                <i className="fa-researchgate fab text-center"> THOT I.A. CORP.</i> <br />
                <h5 className={`col-md-12 mt-2 text-dark  align-items-center  text-center justify-content-center`}>
                    <TitleL1>
                        Sobre as publicações
                    </TitleL1>
                    <h5 className=" text-justify mt-2 text-dark">
                        <br /><br />
                        Os artigos compartilhados através desta plataforma são gentilmente fornecidos
                        pela THOT. I.A. CORP., essa empresa que sempre busca a liderança no campo das pesquisas e publicações. Assim
                        cada artigo abrange tanto informações sobre seus inovadores equipamentos quanto diversos aspectos de dados de atendimento BIGDATA.
                        O processo de pesquisa é meticuloso e conduzido por talentosos pesquisadores associados.
                        A dedicação deles é evidente, pois cada dado é minuciosamente tratado.
                        Além disso, esses dados são revisados por especialistas internos e externos, garantindo uma qualidade excepcional antes de serem compartilhados com você aqui
                        <br /> <br />
                        A THOT. I.A. CORP. orgulha-se em cumprir os mais altos padrões quando se trata de compartilhar informações.
                        Essa integridade é refletida na forma como prezamos pela precisão das informações fornecidas tanto para o público em geral quanto para pesquisadores.
                        Por essa razão, nossa plataforma apenas aceita a publicação de artigos que emergem a partir do profundo poço de dados conhecido como BIGDATA, ou que
                        derivam de nossos confiáveis equipamentos
                        <br /><br />
                        No coração de nossas ações estão as diretrizes e regulamentos mais estritos, incluindo os princípios da LGPD.
                        A privacidade é uma prioridade, e é por isso que todos os dados sensíveis são tratados com extremo cuidado,
                        mesmo quando compartilhados com nossa equipe interna de pesquisadores.
                        Se você está curioso para saber mais sobre as oportunidades emocionantes para publicação
                        e como se envolver como pesquisador, todas as informações estão disponíveis na nossa página de artigos
                        <strong>[ Como fazer publicações ]</strong>. Estamos ansiosos para ter você conosco nessa jornada de descobertas delicadas e estimulantes
                    </h5>
                </h5>
            </div >
            <ButtonCadastrar/>
        </section>
    </>);
};
export default SobrePublicacoesInfo;
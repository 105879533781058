import React from "react";
import { Input } from "antd";
export function InputPadrao(props) {
  return (<>
    <small>{props.title}</small><br/>
    <Input
      className=" text-center col-md-12"
      id={props.id}
      name={props.name}
      type={props.type}
      maxLength={props.maxLength}
      value={props.value}
      onChange={props.onChange}
      onClick={props.onClick}
      validate={props.validate}
      onFocus={props.handleInputFocus}
      onBlur={props.handleInputBlur}
      placeholder={props.placeholder}
      ref={props.ref}
      max={props.max}
    />
  </>);
}
export default InputPadrao;
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { FaBarcode } from 'react-icons/fa';
import { ImageDragDrop, ButtonAPI, MaisInfoMenosInfo } from '@components';
import CalculadoraTaxas from './CalculadoraTaxas';
import { AutoComplete } from 'antd';
import BarcodeScanner from './BarcodeScanner';
import { AuthContext } from '../../../../../../../../guard';
import { api, apiToken } from '@services';
export const CadastrarProdutoUnitario = () => {
  const [images, setImages] = useState([]);
  const [productName, setProductName] = useState('');
  const [productType, setProductType] = useState('');
  const [productDescription, setProductDescription] = useState('');
  const [productStock, setProductStock] = useState('');
  const [productValueCusto, setProductValueCusto] = useState('');
  const [productValueLucro, setProductValueLucro] = useState('');
  const [productValue, setProductValue] = useState('');
  const [productOptions, setProductOptions] = useState([]);
  const [showBarcodeScanner, setShowBarcodeScanner] = useState(false);
  const [scannedQRCode, setScannedQRCode] = useState('');
  const [productInfo, setProductInfo] = useState('');
  const [productNcm, setProductsNcm] = useState(null);
  const [productBula, setProductBula] = useState(null);
  const { getProfessionalId } = useContext(AuthContext);
  const professional_id = getProfessionalId();
  useEffect(() => {
    api
      .get(`get_products`)
      .then((response) => {
        setProductOptions(response.data.produtos);
      })
      .catch((error) => {
        console.error('Erro ao buscar tipos de produtos:', error);
      });
  }, []);
  const handleSubmitBula = useCallback(async () => {
    const formData = new FormData();
    formData.append('products_bula', productBula);
    try {
      const response = await apiToken().post(`post_products_bula/${professional_id}`, formData);
      alert('Bula do produto criada:', response.data);
      return response.data.products_bula_id; // Retorna o ID da bula criada
    } catch (error) {
      console.error('Erro ao criar a bula do produto:', error);
      return null; // Em caso de erro, retorna null
    }
  }, [productBula, professional_id]);
  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    const products_bula_id = await handleSubmitBula();
    const formData = new FormData();
    images.forEach((image) => {
      formData.append('images', image.file, image.filename);
    });
    formData.append('products_ncm_code', productNcm);
    formData.append('products_name', productName);
    formData.append('products_type', productType);
    formData.append('products_description', productDescription);
    formData.append('products_stock', productStock);
    formData.append('value_base', parseFloat(productValueCusto));
    formData.append('value', parseFloat(productValue));
    formData.append('products_bula_id', products_bula_id);
    try {
      const response = await apiToken().post(`post_products/${professional_id}`, formData);
      alert('Produto criado:', response.data);
    } catch (error) {
      console.error('Erro ao criar o produto:', error);
    }
  }, [handleSubmitBula, productName, productType, productDescription, productStock, productValue, images, professional_id, productNcm, productValueCusto]);
  const handleProductNameInputChange = useCallback((inputValue) => {
    setProductName(inputValue);
  }, []);
  useEffect(() => {
    const custo = parseFloat(productValueCusto);
    const lucroPercentual = parseFloat(productValueLucro);
    if (!isNaN(custo) && !isNaN(lucroPercentual)) {
      const lucro = custo * (lucroPercentual / 100);
      const valorTotal = custo + lucro;
      setProductValue(valorTotal.toFixed(2));
    } else {
      setProductValue('');
    }
  }, [productValueCusto, productValueLucro]);
  const fetchProductNCM = useCallback(async () => {
    try {
      if (!professional_id || !productInfo || !productInfo.Ncm) {
        console.error('ID profissional ou NCM do produto ausentes');
        return;
      }
      const response = await apiToken().get(`get_products_ncm_info/${professional_id}/${productInfo.Ncm}`);
      const descriptions = response.data.map(item => item.descricao);
      const allDescriptions = descriptions.join(' ');
      setProductType(allDescriptions);
      console.log(productInfo, response.data);
    } catch (error) {
      console.error('Erro ao buscar informações do NCM:', error);
    }
  }, [professional_id, productInfo]);
  const handleScanSuccess = useCallback(async (result) => {
    console.log('Scan success:', result);
    setProductName(productInfo.Nome);
    setProductsNcm(productInfo.Ncm);
    await fetchProductNCM();
  }, [productInfo, fetchProductNCM]);
  useEffect(() => {
    if (productInfo) {
      handleScanSuccess(); // Call handleScanSuccess only if productInfo is not null
    }
    setShowBarcodeScanner(false);
  }, [handleScanSuccess, productInfo]);
  const handleScanError = (error) => {
    console.error('Scan error:', error);
  };
  const toggleBarcodeScanner = () => {
    setShowBarcodeScanner((prev) => !prev);
  };
  return (
    <>
      <div className="col-md-12">
        <div className="d-flex flex-wrap justify-content-center">
          <div className="col-md-12 mt-2 rounded justify-content-center text-center"
            style={{
              backgroundColor: '#f1f1f1',
              padding: '5px',
              marginLeft: '5px',
              marginRight: "5px",
            }}>
            <form className='mt-3 text-center' onSubmit={handleSubmit} encType="multipart/form-data">
              <ImageDragDrop images={images} setImages={setImages} />
              <br />
              <div style={{ cursor: 'pointer' }} onClick={toggleBarcodeScanner}>
                <FaBarcode size={24} color="#007bff" />
                <span style={{ marginLeft: '5px' }}>Scanner de Código de Barras</span>
              </div>
              {showBarcodeScanner &&
                <BarcodeScanner
                  scannedQRCode={scannedQRCode}
                  setScannedQRCode={setScannedQRCode}
                  productInfo={productInfo}
                  setProductInfo={setProductInfo}
                  onScanSuccess={handleScanSuccess}
                  onScanError={handleScanError}
                />
              }
              <br />
              <small>Código de barras do produto</small>
              <AutoComplete
                className='w-100 mb-2 text-center'
                style={{ height: '50px' }}
                options={productOptions.map((product) => ({ value: product.products_type }))}
                placeholder="Código de barras"
                value={scannedQRCode}
                onChange={(value) => setScannedQRCode(value)}
                required
              />
              <small>NCM do produto</small>
              <AutoComplete
                className='w-100 mb-2 text-center'
                style={{ height: '50px' }}
                options={productOptions.map((product) => ({ value: product.products_ncm_code }))}
                placeholder="NCM do produto"
                value={productNcm}
                onChange={(value) => setProductsNcm(value)}
                required
              />
              <small>Tipo do produto</small>
              <AutoComplete
                className='w-100 mb-2 text-center'
                style={{ height: '50px' }}
                options={productOptions.map((product) => ({ value: product.products_type }))}
                placeholder="Tipo do Produto"
                value={productType}
                onChange={(value) => setProductType(value)}
                required
              />
              <small>Nome do produto</small>
              <AutoComplete
                className='w-100 mb-2 text-center'
                style={{ height: '50px' }}
                options={productOptions.map((product) => ({ value: product.products_name }))}
                placeholder="Nome do Produto"
                value={productName}
                onChange={handleProductNameInputChange}
                required
              />
              <MaisInfoMenosInfo text={'Para cadastrar produtos de forma ágil colocar o tipo com ajuda da lista, então procure pelo nome abrirá a lista de ajuda ao cliclar no nome a relação será estabelecida buscando descrição e imagens do produto'} />
              <br />
              <small>Valor de custo do produto und.</small>
              <input
                className='form-control form-control-lg text-dark text-center'
                type="number"
                placeholder="Valor de Custo do Produto"
                value={productValueCusto}
                onChange={(e) => setProductValueCusto(e.target.value)}
                required
              />
              <small>Lucro Pretendido em %</small>
              <input
                className='form-control mt-1 form-control-lg text-dark text-center'
                type="number"
                placeholder="Lucro Pretendido em %"
                value={productValueLucro}
                onChange={(e) => setProductValueLucro(e.target.value)}
              />
              <small>Valor de Venda do Produto sem Taxas</small>
              <input
                className='form-control mt-1 form-control-lg text-dark text-center'
                type="number"
                placeholder="Valor de Venda do Produto"
                value={productValue}
                onChange={(e) => setProductValue(e.target.value)}
              />
              <MaisInfoMenosInfo text={<> <CalculadoraTaxas productType={productType} productValue={productValue} />  </>}></MaisInfoMenosInfo>
              <input
                className='form-control mt-3 form-control-lg text-dark text-center'
                type="number"
                placeholder="Estoque do Produto"
                value={productStock}
                onChange={(e) => setProductStock(e.target.value)}
                required
              />
              <textarea
                className='form-control mt-1 form-control-lg text-dark text-center'
                placeholder="Descrição do Produto"
                value={productDescription}
                onChange={(e) => setProductDescription(e.target.value)}
                required
              />
              <div>
                <small>Enviar Bula</small>
                <input
                  type="file"
                  className="form-control mt-1 form-control-lg text-dark text-center"
                  onChange={(e) => setProductBula(e.target.files[0])}
                  required
                />
              </div>
              <ButtonAPI type="submit">Cadastrar</ButtonAPI>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default CadastrarProdutoUnitario;
import React from 'react'
import { Header } from '../../components/Header/Header'
import { MenuItens } from '../../components/MenuItens/MenuItens'
import { SideMenu } from '../../components/MenuItens/SideMenu'
// import { Table } from '../Table/Table'
import './Projetos.css' 
import { FooterWiki } from '../Footer/Footer'
export function Projetos() {
  return (
    <>
      <Header pagina="projetos"/>
        <main className="main-projeto">
          <MenuItens.Container titulo='Projetos'>
            <SideMenu className="left-menu-link" link='#' title='Documento de Visão'/>
            <SideMenu className="left-menu-link" link='#' title='Documento de Arquitetura'/>
            <SideMenu className="left-menu-link" link='#' title='Documento de Metodologias'/>
            <SideMenu className="left-menu-link" link='#' title='Planejamento de riscos'/>
            <SideMenu className="left-menu-link" link='#' title='Roadmap'/>
            <SideMenu className="left-menu-link" link='#' title='Termo de Abertura'/>
          </MenuItens.Container>
          <MenuItens.Container>
            <h1>Documento de Visão</h1>
            <h3>Histórico de Versões</h3>
          </MenuItens.Container>
          <MenuItens.Container titulo='Table of Contents'>
            <h2>Histórico de Versões</h2>
            <MenuItens.TableContent section='Introdução' />
            <MenuItens.TableContent section='Posicionamento' />
            <MenuItens.TableContent section='Descrição dos Usuários e Envolvidos' />
            <MenuItens.TableContent section='Visão Geral do Produto' />
            <MenuItens.TableContent section='Recursos do Produto' />
            <MenuItens.TableContent section='Restrições' />
            <MenuItens.TableContent section='Requisitos do Produto' />
            <MenuItens.TableContent section='Referências' />
          </MenuItens.Container>
        </main>
        <FooterWiki/>
    </>
  )
}
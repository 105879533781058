import React from 'react';
import { Typography, Image, Divider } from 'antd';
import { TitleL3, TitleL4 } from '@components';
import { imgFaceFrontalAR1, imgFrontalAR } from '../assets';
import { DocumentosSoftwareAnaliseFacialRegraImagesFrontal } from './DocumentosSoftwareAnaliseFacialRegraImagesFrontal';
const { Title, Paragraph, Text } = Typography;
export const DocumentosSoftwareAnaliseFacialVirtualReality = (id) => {
  return (<>
    <section style={{ marginTop: '20px' }}>
      <TitleL3 id='DocumentosSoftwareAnaliseFacialVirtualReality'>Análise Frontal com Expressão de Realidade Virtual (VR)</TitleL3>
      <Divider style={{ margin: '0px', borderColor: 'black', borderWidth: '3px', marginBottom: '10px' }} />
      <h4> Entrada de imagem para análisar, calcular as proporções
        reais da face através de outros objetos de tamanhos conhecidos - ponto de referência para demais análises e sugestões</h4>
    </section>
    <Title level={4}>Detecção de Cartão Bancário - Crédito/Débito</Title>
    <DocumentosSoftwareAnaliseFacialRegraImagesFrontal />
    <TitleL4>Imagens exemplo:</TitleL4>
    <div className='ml-2'>
      <div className='row text-center'>
        <div className='col-md-1 border py-1' style={{ padding: '0px' }}>
          <Image src={imgFaceFrontalAR1} alt="Exemplo 2" width={100} height={100} /><br />
          <small>Foto com Cartão</small>
        </div>
        <div className='col-md-1 border py-1' style={{ padding: '0px' }}>
          <Image src={imgFrontalAR} alt="Exemplo 2" width={100} height={100} /><br />
          <small>Foto com Cartão</small>
        </div>
      </div>
      <Paragraph><strong>Como Funciona:</strong> Identificação do cartão próximo ao rosto.</Paragraph>
      <Paragraph>
        <Text>Referência de Tamanho Padronizado:</Text> Um cartão de crédito tem dimensões padronizadas
        internacionalmente (85.60 mm × 53.98 mm). A tecnologia VR usa o cartão como referência para calcular as proporções
        reais de outros objetos e características faciais na imagem.
      </Paragraph>
      <Paragraph>
        <strong>Posicionamento na Foto:</strong> O usuário ou profissional insere um cartão de crédito visível na frente da câmera
        (preferencialmente entre o nariz e o queixo), permitindo que o sistema calcule a escala correta e ajuste a medição
      </Paragraph>
      <Paragraph><strong>Cálculo de Dimensões Reais:</strong>
        <ul>
          <li>Medir distâncias entre olhos, nariz, boca e outros pontos faciais</li>
          <li>Analisar o tamanho e o formato do rosto com precisão milimétrica</li>
          <li>Fornecer sugestões personalizadas e precisas de harmonização estética (por exemplo, tamanho adequado de óculos ou procedimentos faciais)</li>
        </ul>
      </Paragraph>
      <Paragraph><strong>Benefícios do Uso do Cartão de Crédito para Medição VR</strong>
        <ul>
          <li><strong>Precisão Elevada:</strong> Ao usar um objeto de tamanho fixo e conhecido, o sistema VR fornece medições mais precisas e consistentes.</li>
          <li><strong>Facilidade de Uso:</strong> O cartão de crédito é um objeto acessível e comum, o que facilita o uso dessa tecnologia para qualquer pessoa.</li>
          <li><strong>Simulação em Tempo Real:</strong> A tecnologia VR processa os dados e aplica as medidas instantaneamente, mostrando sugestões diretamente na tela em tempo real.</li>
        </ul>
      </Paragraph>
    </div>
    <Title level={4}>Imagens 3D Scanner</Title>
    <div style={{ backgroundColor: '#fff3cd', padding: '10px', borderRadius: '5px', marginBottom: '10px' }}>
      <strong>⚠️ IMPORTANTE:</strong> Leia antes de começar:
      <ul>
        <li>
          <img src="https://placehold.co/15x15/f03c15/f03c15.png" alt="Ícone de alerta" style={{ verticalAlign: 'middle' }} />
          <span style={{ color: '#f03c15' }}>  Modelo de entrada não disponível para o cliente</span>
        </li>
        <li>
          <img src="https://placehold.co/15x15/f03c15/f03c15.png" alt="Ícone de alerta" style={{ verticalAlign: 'middle' }} />
          <span style={{ color: '#f03c15' }}> Servidor [ nuvem ] ainda não comporta o modelo de serviço</span>
        </li>
      </ul>
    </div>
    <div className='col-md-1 border py-1' style={{ padding: '0px' }}>
      <Image src={require('../assets/stl.jpg')} alt="Exemplo 2" width={100} height={100} /><br />
      <small>Scanner 3D</small>
    </div>
    <div className='ml-2'>
      <Paragraph>
        <strong>Como Funciona:</strong> Com o uso do scanner 3D, o sistema consegue capturar detalhes como profundidade, contornos e simetrias dentais que não são obtidos em imagens 2D comuns.
        Essa tecnologia gera uma referência volumétrica dos dentes, permitindo uma análise mais completa e exata das características faciais ao ser ligado com o rosto.
      </Paragraph>
      <Paragraph>
        <strong>Cálculo de Dimensões Reais:</strong> Com a imagem tridimensional, o sistema calcula as proporções reais do rosto, o que possibilita:
        <ul>
          <li><strong>Medir distâncias e ângulos faciais com precisão.</strong></li>
          <li><strong>Analisar o formato do rosto, observando aspectos como volume e contorno.</strong></li>
          <li><strong>Fornecer recomendações estéticas personalizadas, adaptadas às características reais do rosto (como sugestões de tratamentos de harmonização facial).</strong></li>
        </ul>
      </Paragraph>
      <Paragraph>
        <strong>Benefícios do Uso do Scanner 3D para Medição:</strong>
        <ul>
          <li><strong>Precisão Elevada:</strong> A captura volumétrica permite ao sistema calcular medidas faciais com um grau de precisão muito superior ao das imagens 2D.</li>
          <li><strong>Detalhamento Completo:</strong> A tecnologia 3D registra contornos e assimetrias que contribuem para um diagnóstico estético mais detalhado e completo.</li>
        </ul>
      </Paragraph>
    </div>
    <section style={{ marginTop: '20px' }}>
      <Title level={4}>Casos de Uso</Title>
      <Paragraph>
        <ul>
          <li><strong>Saúde:</strong> Identificação e monitoramento de doenças da pele e ou mucosa orofaciais</li>
          <li><strong>Estética e Harmonização:</strong> Medição real do rosto para procedimentos como preenchimentos, botox, e outros tratamentos estéticos</li>
          <li><strong>Comércio de Óculos:</strong> Recomendações precisas de armações que se ajustam ao rosto, considerando proporções reais</li>
          <li><strong>Moda e Beleza:</strong> Sugestões de maquiagem que respeitam as dimensões reais do rosto para criar simetria</li>
        </ul>
      </Paragraph>
    </section>
  </>);
};
export default DocumentosSoftwareAnaliseFacialVirtualReality;
import React from 'react';
export const ConexaoDBInfo = () => {
  return (
    <div>
      <h5>Usando o Componente<strong> Conexão Com Banco de Dados</strong></h5>
      <p>
        O componente Conexão Com Banco de Dados permite que você configure informações de host, usuário, senha e nome do banco de dados. Ele oferece uma maneira conveniente de definir essas configurações.
      </p>
      <hr />
      <ol className='ml-3'>
        <li><strong>Preencha os campos</strong></li>
        <p>
          Quando você abre o componente, você verá campos para preencher as informações necessárias, incluindo "Host", "User", "Password" e "Database". Preencha os campos com as configurações corretas.
        </p>
        <li><strong>Salve as configurações</strong></li>
        <p>
          Depois de preencher os campos com as informações corretas, você pode clicar em um botão (que você pode adicionar) para salvar as configurações. Essas informações podem ser usadas para estabelecer uma conexão com o banco de dados.
        </p>
      </ol>
      <hr />
      <p>
        Agora você está pronto para usar o componente Conexão Com Banco de Dados para configurar informações de conexão com o banco de dados de forma fácil e eficaz.
      </p>
      <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />
    </div>
  );
};
export default ConexaoDBInfo;
import { Divider, Image } from 'antd';
import { TitleL3 } from '@components';
export const DocumentosSoftwareAnaliseFacialLaudoGerado = () => {
  const imagesB = [
    'a1a9e487-b2e0-402f-aaa0-8f4eea47b719_page-0001.jpg',
    'a1a9e487-b2e0-402f-aaa0-8f4eea47b719_page-0002.jpg',
    'a1a9e487-b2e0-402f-aaa0-8f4eea47b719_page-0003.jpg',
    'a1a9e487-b2e0-402f-aaa0-8f4eea47b719_page-0004.jpg',
    'a1a9e487-b2e0-402f-aaa0-8f4eea47b719_page-0005.jpg',
    'a1a9e487-b2e0-402f-aaa0-8f4eea47b719_page-0006.jpg',
    'a1a9e487-b2e0-402f-aaa0-8f4eea47b719_page-0007.jpg',
    'a1a9e487-b2e0-402f-aaa0-8f4eea47b719_page-0008.jpg',
    'a1a9e487-b2e0-402f-aaa0-8f4eea47b719_page-0009.jpg',
    'a1a9e487-b2e0-402f-aaa0-8f4eea47b719_page-0010.jpg',
    'a1a9e487-b2e0-402f-aaa0-8f4eea47b719_page-0011.jpg',
    'a1a9e487-b2e0-402f-aaa0-8f4eea47b719_page-0012.jpg',
    'a1a9e487-b2e0-402f-aaa0-8f4eea47b719_page-0013.jpg',
    'a1a9e487-b2e0-402f-aaa0-8f4eea47b719_page-0014.jpg',
    'a1a9e487-b2e0-402f-aaa0-8f4eea47b719_page-0015.jpg',
    'a1a9e487-b2e0-402f-aaa0-8f4eea47b719_page-0016.jpg',
    'a1a9e487-b2e0-402f-aaa0-8f4eea47b719_page-0017.jpg',
    'a1a9e487-b2e0-402f-aaa0-8f4eea47b719_page-0018.jpg',
  ];
  return (
    <section style={{ marginTop: '20px' }}>
      <TitleL3 id="DocumentosSoftwareAnaliseFacialLaudoGerado">Laudo Gerado</TitleL3>
      <Divider style={{ margin: '0px', borderColor: 'black', borderWidth: '3px', marginBottom: '10px' }} />
      <div>
        <div className='row text-center'>
          <div className='col-md-1 border py-1 align-items-center d-flex justify-content-center' style={{ padding: '0px' }}>
            <small>Versão 1.0.0</small>
          </div>
          <div className='col-md-1 border py-1' style={{ padding: '0px' }}>
            <Image src={require('../assets/ex1.jpg')} alt="Exemplo 2" width={100} height={100} /><br />
            <small>1</small>
          </div>
          <div className='col-md-1 border py-1' style={{ padding: '0px' }}>
            <Image src={require('../assets/ex1.jpg')} alt="Exemplo 2" width={100} height={100} /><br />
            <small>1</small>
          </div>
          <div className='col-md-1 border py-1' style={{ padding: '0px' }}>
            <Image src={require('../assets/ex2.jpg')} alt="Exemplo 2" width={100} height={100} /><br />
            <small>2</small>
          </div>
          <div className='col-md-1 border py-1' style={{ padding: '0px' }}>
            <Image src={require('../assets/ex3.jpg')} alt="Exemplo 2" width={100} height={100} /><br />
            <small>3</small>
          </div>
          <div className='col-md-1 border py-1' style={{ padding: '0px' }}>
            <Image src={require('../assets/ex4.jpg')} alt="Exemplo 2" width={100} height={100} /><br />
            <small>4</small>
          </div>
          <div className='col-md-1 border py-1' style={{ padding: '0px' }}>
            <Image src={require('../assets/ex5.jpg')} alt="Exemplo 2" width={100} height={100} /><br />
            <small>5</small>
          </div>
          <div className='col-md-1 border py-1' style={{ padding: '0px' }}>
            <Image src={require('../assets/ex6.jpg')} alt="Exemplo 2" width={100} height={100} /><br />
            <small>6</small>
          </div>
        </div>
      </div>
      <div>
        <div className='row text-center'>
          <div className='col-md-1 border py-1 align-items-center d-flex justify-content-center' style={{ padding: '0px' }}>
            <small>Versão 1.0.1</small>
          </div>
          {imagesB.map((image, index) => (
            <div key={index} className="col-md-1 border py-1" style={{ padding: '0px' }}>
              <Image
                src={require(`../assets/${image}`)}
                alt={`Exemplo ${index + 1}`}
                width={100}
                height={100}
              />
              <br />
              <small>{index + 1}</small>
            </div>
          ))}
        </div>
      </div >
    </section >
  );
};
export default DocumentosSoftwareAnaliseFacialLaudoGerado;
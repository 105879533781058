import React, { useState, useEffect } from 'react';
import { baseURL } from '@services';
import { marked } from 'marked';
import { FooterWiki } from '../Footer/Footer'
import { Header } from '../Header/Header';
import '../Info/info.css';
export const WikiInfo = () => { 
  const [folders, setFolders] = useState({});
  const [readmeContent, setReadmeContent] = useState('');
  const [openFolders, setOpenFolders] = useState({});
  const [selectedReadmePath, setSelectedReadmePath] = useState(null);
  useEffect(() => {
    const directoryPath = "C:\\Users\\ronne\\OneDrive\\Documentos\\GitHub\\plataforma-fe";
    const fetchDirectory = async () => {
      try {
        const response = await fetch(`${baseURL}analyze-directoryForJSFiles`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ directoryPath })
        });
        const data = await response.json();
        setFolders(data);
      } catch (error) {
        console.error('Error analyzing directory:', error);
      }
    };
    fetchDirectory();
  }, []);
  const fetchReadme = async (readmePath) => {
    try {
      const response = await fetch(`${baseURL}api/readme/${readmePath}`);
      const content = await response.text();
      setReadmeContent(content);
    } catch (error) {
      console.error('Error fetching README:', error);
    }
  };
  const toggleFolder = (folderPath) => {
    setOpenFolders(prevOpenFolders => ({
      ...prevOpenFolders,
      [folderPath]: !prevOpenFolders[folderPath]
    }));
  };
  const renderFolders = (data, path = [], level = 0) => {
    return Object.keys(data).map(key => {
      const newPath = [...path, key];
      const folderPath = newPath.join('/');
      const folder = data[key];
      const isOpen = openFolders[folderPath];
      const marginLeft = `${level * 20}px`;
      const hasReadme = folder && folder['Readme.md'];
      return (
        <div key={folderPath} className="Folder" style={{ marginLeft }}>
          <div onClick={() => {
            toggleFolder(folderPath);
            if (hasReadme) {
              setSelectedReadmePath(folderPath + '/Readme.md');
              fetchReadme(folderPath + '/Readme.md');
            }
          }}>
            {isOpen ? '[-] ' : '[+] '}
            {key}
          </div>
          {isOpen && typeof folder === 'object' && renderFolders(folder, newPath, level + 1)}
        </div>
      );
    });
  };
  return (
    <>
      <Header pagina="sprints" />
      <div className="col-md-12 wiki-info">
                <div className="d-flex flex-wrap justify-content-center">
      <div className="Info bg-light col-md-3 py-3">
        <div className="Folders">
          {renderFolders(folders)}
          {selectedReadmePath}
        </div>
      </div>
      <div className="col-md-9 justify-content-center text-center mt-3">
        <div dangerouslySetInnerHTML={{ __html: marked(readmeContent) }} />
      </div>
      </div> </div>
      <FooterWiki/>
    </>
  );
}
export default WikiInfo;
import React, { forwardRef } from 'react';
import IdentificacaoEmitente from './components/IdentificacaoEmtitente';
import './styles/ReceitaControleEspecial.css';
import IdentificacaoComprador from './components/IdentificacaoComprador';
import IdentificacaoFornecedor from './components/IdentificacaoFornecedor';
import InfoIntercambialidade from './components/InfoSobreIntercambialidade';
import  DadosDosProdutos  from './components/DadosDosProdutos';
import DispensacaoManual  from './components/DispensacaoManual';
const ReceitaControleEspecial = forwardRef(
  (
    {
      assinatura,
      emitente,
      crm,
      crmUf,
      enderecoEmitente,
      cidade,
      uf,
      telefone,
      nomePaciente,
      enderecoPaciente,
      medicamentos,
      data,
      assinaturaMedico,
      crf,
      crfUf,
    },
    ref
  ) => {
    return (
      <div className="container" ref={ref}>
        <h2>RECEITUÁRIO DE CONTROLE ESPECIAL</h2>
        <IdentificacaoEmitente
          emitente={emitente}
          crm={crm}
          crmUf={crmUf}
          enderecoEmitente={enderecoEmitente}
          cidade={cidade}
          uf={uf}
          telefone={telefone}
          data={data}
          assinaturaMedico={assinaturaMedico}
        />
        <div>
          <p>NOME PACIENTE: {nomePaciente}</p>
          <p>ENDEREÇO COMPLETO: {enderecoPaciente}</p>
        </div>
        <p>PRESCRIÇÃO:</p>
        {medicamentos.map((medicamento, index) => (
          <div key={index} className="dadosDoPaciente">
            <p className="medicamentoNome">{index + 1}. Medicamento: {medicamento.nomeMedicamento}</p>
            <p className="dosagemReceita">Dosagem: {medicamento.dosagem}</p>
            <p className="formulaReceita">Fórmula Farmacêutica: {medicamento.formulaFarmaceutica}</p>
            <p className="quantidadeReceita">Quantidade: {medicamento.quantidade}</p>
            <p className="posologiaReceita">Posologia: {medicamento.posologia}</p>
          </div>
        ))}
        <div className="containersCompradorFornecedor">
          <IdentificacaoComprador
            nome={nomePaciente}
            rg={''}
            orgaoEmissor={''}
            endereco={enderecoPaciente}
            cidade={cidade}
            uf={uf}
            telefone={telefone}
          />
          <IdentificacaoFornecedor
            nome={emitente}
            crf={crf}
            crfuf={crfUf}
            farmacia={''}
            endereco={enderecoEmitente}
            cidade={cidade}
            uf={uf}
            telefone={telefone}
            cnpj={''}
            assinatura={assinatura}
          />
        </div>
        <div className="verso-folha">
        <div className="div-title3">
            <h2 className="title1">DADOS DO(S) PRODUTO(S) DISPENSADOS</h2>
        </div>
        {medicamentos.map((index) => (
          <div key={index}>
          <DadosDosProdutos />
          </div>
          ))}
          <div className="div-title3">
              <h2 className="title1">INFORMAÇÕES SOBRE INTERCAMBIALIDADE</h2>
          </div>
          {medicamentos.map((index) => (
          <div key={index}>
          <InfoIntercambialidade />
           </div>
          ))}
          <DispensacaoManual />
        </div>
      </div>
    );
  }
);
export default ReceitaControleEspecial;
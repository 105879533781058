import React, { useState } from 'react';
import { Card, List, Typography, Tag, Divider } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
const { Title, Text } = Typography;
const renderField = (label, value) => {
  return value !== undefined && value !== null ? (
    <>
      <Text strong>{label}:</Text> {value}<br />
    </>
  ) : null;
};
const PaymentDetails = ({ data }) => {
  const [showDetails, setShowDetails] = useState(false);
  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };
  return (
    <Card
      title="Detalhes do Pagamento"
      extra={<InfoCircleOutlined />}
      onClick={toggleDetails}
      style={{
        body: { display: showDetails ? 'block' : 'none',  width: 600, margin: 'auto' } // Use `style.body` for body-specific styles
      }}
    >
      {showDetails ? (
        <>
          <Title level={4}>Informações Gerais</Title>
          {renderField('ID', data?.id)}
          {renderField('Status', data?.status ? <Tag color={data.status === 'succeeded' ? 'green' : 'red'}>{data.status}</Tag> : null)}
          {renderField('Método', data?.method)}
          {renderField('Valor', data?.amount)}
          {renderField('Total', data?.totalAmount)}
          {renderField('Parcelas', data?.installments)}
          {renderField('Referência', data?.reference)}
          {renderField('ID da Transação', data?.transactionId)}
          <Divider />
          <Title level={4}>Histórico</Title>
          <List
            itemLayout="horizontal"
            dataSource={data?.history}
            renderItem={item => (
              <List.Item>
                <List.Item.Meta
                  title={
                    <>
                      {renderField('ID', item?.id)}
                      {renderField('Operação', item?.operation)}
                    </>
                  }
                  description={
                    <>
                      {renderField('Valor', item?.amount)}
                      {renderField('ID do Autorizador', item?.authorizerId)}
                      {renderField('Código de Autorização', item?.authorizationCode)}
                      {renderField('NSU de Autorização', item?.authorizationNSU)}
                      {renderField('Criado em', item?.createdAt)}
                      {renderField('Atualizado em', item?.updatedAt)}
                    </>
                  }
                />
              </List.Item>
            )}
          />
          <Divider />
          <Title level={4}>Método de Pagamento</Title>
          {renderField('ID', data?.paymentMethod?.id)}
          {renderField('Recurso', data?.paymentMethod?.resource)}
          {renderField('Marca do Cartão', data?.paymentMethod?.cardBrand)}
          {renderField('4 Dígitos Iniciais', data?.paymentMethod?.first4Digits)}
          {renderField('4 Dígitos Finais', data?.paymentMethod?.last4Digits)}
          {renderField('Nome do Titular', data?.paymentMethod?.holderName)}
          {renderField('Mês de Validade', data?.paymentMethod?.expirationMonth)}
          {renderField('Ano de Validade', data?.paymentMethod?.expirationYear)}
          {renderField('Barcode', data?.paymentMethod?.barcode)}
          {renderField('Data de Vencimento', data?.paymentMethod?.dueDate)}
          {renderField('Data Limite de Pagamento', data?.paymentMethod?.paymentLimitDate)}
          {renderField('Destinatário', data?.paymentMethod?.recipient)}
          {renderField('Número de Referência', data?.paymentMethod?.referenceNumber)}
          {renderField('Número do Documento', data?.paymentMethod?.documentNumber)}
          {renderField('QR Code', data?.paymentMethod?.qrCode)}
          {data?.paymentMethod?.boleto && renderField('Path do Boleto', data.paymentMethod.boleto.path)}
          {data?.paymentMethod?.boleto?.url && (
            <>
              <Text strong>URL do Boleto:</Text> <a href={data.paymentMethod.boleto.url} target="_blank" rel="noopener noreferrer">{data.paymentMethod.boleto.url}</a><br />
            </>
          )}
          {renderField('Criado em', data?.paymentMethod?.createdAt)}
          {renderField('Atualizado em', data?.paymentMethod?.updatedAt)}
          <Divider />
          <Title level={4}>QR Code</Title>
          {renderField('Código', data?.qrCode?.code)}
          {data?.qrCode?.image && <><Text strong>Imagem:</Text> <img src={data.qrCode.image} alt="QR Code" style={{ maxWidth: '100px' }} /><br /></>}
          <Divider />
          <Title level={4}>Código de Barras</Title>
          {renderField('Código de Barras', data?.barcode)}
          <Divider />
          <Title level={4}>URL</Title>
          {data?.url && <><Text strong>URL:</Text> <a href={data.url} target="_blank" rel="noopener noreferrer">{data.url}</a><br /></>}
          {renderField('Criado em', data?.createdAt)}
          {renderField('Atualizado em', data?.updatedAt)}
        </>
      ) : null}
    </Card>
  );
};
export default PaymentDetails;
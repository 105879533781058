export function landmarks_botox_color(doc, landmark, index, imgWidth, imgHeight, pontoDestacado) {
    // Se o ponto estiver selecionado ou se não houver destaque, exibe o ponto
    if (landmark && (pontoDestacado === null || pontoDestacado === index)) {
        const x = 15 + landmark.x * imgWidth;
        const y = 70 + landmark.y * imgHeight;
        // Define as cores baseadas no valor de UN
        let fillColor, textColor;
        switch (landmark.UN) {
            case 1:
                fillColor = [255, 0, 0]; // Vermelho
                textColor = [255, 0, 0]; // Vermelho
                break;
            case 1.5:
                fillColor = [0, 255, 0]; // Verde
                textColor = [0, 255, 0]; // Verde
                break;
            case 2:
                fillColor = [0, 0, 255]; // Azul
                textColor = [0, 0, 255]; // Azul
                break;
            default:
                fillColor = [0, 0, 0]; // Preto (padrão)
                textColor = [0, 0, 0]; // Preto (padrão)
        }
        // Desenha o círculo no PDF com a cor de preenchimento dinâmica
        doc.setDrawColor(...fillColor);  // Cor de contorno do círculo
        doc.setFillColor(...fillColor);  // Cor de preenchimento do círculo
        doc.circle(x, y, 1, 'F');  // Desenha o círculo com raio 1
        doc.setFontSize(8);  // Define o tamanho da fonte
        doc.setTextColor(...textColor);  // Define a cor do texto
        // Exibe o valor de UN ao lado do ponto
        doc.text(`UN: ${landmark.UN}`, x - 4, y + 4);  // Exibe o valor de UN ao lado do ponto
    }
}
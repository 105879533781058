import React, { useCallback, useContext, useEffect, useState } from 'react';
import { baseURL, apiToken, AuthContext } from '../../../../guard';
import { useLocation, useNavigate } from 'react-router-dom';
import { NavBreadcrumb, CardDirecionamento, InputPadraoSearch } from '../../../../components';
export const Index = () => {
    const { getProfessionalId, getUserId } = useContext(AuthContext);
    const profissional_id = getProfessionalId();
    const user_id = getUserId();
    const navigate = useNavigate();
    const location = useLocation();
    const { search } = location;
    const [searchTerm, setSearchTerm] = useState('');
    const [cart, setCart] = useState(JSON.parse(localStorage.getItem('cart')) || []);
    const [loading, setLoading] = useState(true); // Adicione um estado de loading
    const cards = [
        { text: 'Profissional', icon: 'mt-1 fa fa-suitcase fa-3x text-white', href: '../profissionais' },
        { text: 'Social', icon: 'mt-1 fa fa-comments fa-3x text-white', href: './social' },
        { text: 'Serviços', icon: 'mt-1 fa fa-concierge-bell fa-3x text-white', href: './servicos' },
        { text: 'Produtos', icon: 'mt-1 fa fa-cubes fa-3x text-white', href: './produtos' },
        { text: 'Consultas', icon: 'mt-1 fa fa-hand-paper-o fa-3x text-white', href: './consultas' },
        { text: 'Exames', icon: 'mt-1 fa fa-flask fa-3x text-white', href: './exames' },
        { text: 'Medicamentos', icon: 'mt-1 fa fa-capsules fa-3x text-white', href: './medicamentos' },
        { text: 'Equipamentos', icon: 'mt-1 fa fa-microchip fa-3x text-white', href: './equipamentos' },
        { text: 'Prontuário', icon: 'mt-1 fa fa-book fa-3x text-white', href: './prontuario' },
        { text: 'Atestados', icon: 'mt-1 fa fa-bed fa-3x text-white', href: './atestados' },
        { text: 'Carrinho', icon: 'mt-1 fa fa-cart-plus fa-3x text-white', href: './carrinho' },
        { text: 'NFe', icon: 'mt-1 fa fa-barcode fa-3x text-white', href: './nfe' },
        { text: 'Cadastro', icon: 'mt-1 fa fa-id-card fa-3x text-white', href: './cadastro' },
        { text: 'Preferências', icon: 'mt-1 fa fa-cogs fa-3x text-white', href: './preferencias' },
    ];
    const fetchCartData = useCallback(async () => {
        try {
            if (!loading) return; // Evitar chamadas múltiplas
            const response = await apiToken().post(`${baseURL}post_cart/${user_id}`, { cart });
            console.log('Resposta ao adicionar ao carrinho:', response.data);
            setCart(response.data.cartItems);
            localStorage.setItem('cart', JSON.stringify(response.data.cartItems));
            setLoading(false); // Desativar o loading após o carregamento
        } catch (error) {
            console.error('Erro ao adicionar ao carrinho:', error);
        }
    }, [user_id, cart, loading]);
    useEffect(() => {
        if (loading) {
            fetchCartData(); // Somente chamar se estiver em estado de loading
        }
        const cadastroProfissional = localStorage.getItem('cadastroProfissional');
        if (cadastroProfissional === 'SIM') {
            navigate('/usuario/cadastro/profissional');
        }
        const chavesParaRemover = ["Google", "Facebook", "Github"];
        chavesParaRemover.forEach(chave => {
            localStorage.removeItem(chave);
        });
    }, [navigate, profissional_id, user_id, fetchCartData, loading]);
    const filteredCards = cards.filter(card => card.text.toLowerCase().includes(searchTerm.toLowerCase()));
    return (
        <div className='w-100'>
            <NavBreadcrumb icon={'user fa'} text={'Início Usuário'} />
            <div className="text-center">
                <InputPadraoSearch
                    type="text"
                    placeholder="Filtre páginas"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                <div>
                    <div className="row d-inline-flex justify-content-center w-100">
                        {profissional_id && filteredCards.filter(card => card.text === 'Profissional').map(card => (
                            <CardDirecionamento
                                key={card.text}
                                text={card.text}
                                icon={card.icon}
                                href={`${card.href}${search}`}
                            />
                        ))}
                        {filteredCards.filter(card => card.text !== 'Profissional').map(card => (
                            <CardDirecionamento
                                key={card.text}
                                text={card.text}
                                icon={card.icon}
                                href={`${card.href}${search}`}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Index;
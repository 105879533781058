import React from "react";
import '../styles/DadosDosProdutos.css'
const DadosDosProdutos = ({ nomeMedicamento, laboratorio, lote, qtdcaixas, numeroDeRegistro}) => {
    return (
        <div className="principalProdutosDispen">
            <div className="containerDadosMedDiv">
                <p>NOME DO MEDICAMENTO: {nomeMedicamento}</p>
                <p>LABORATÓRIO: {laboratorio}</p>
                <p>NUMERO DO LOTE: {lote}</p>
                <p>QUANTIDADE DE CAIXAS {qtdcaixas}</p>
                <p>
                    <span>NUMERO DE REGISTRO DA RECEITA NO LIVRO DE RECEITUÁRIO: {numeroDeRegistro}</span>
                    <span>(QUANDO MEDICAMENTO MANIPULADO)</span>
                </p>
            </div>
        </div>
    );
    }
    export default DadosDosProdutos;
import { Carousel3D, TitleL1 } from '@components';
import "./Styles/PreLancamento.css"
import {
    imagesINF1, cellCountINF1
} from './functions/DataImagens';
import { Divider } from 'antd';
export const InfinityLifeParcerias = () => {
    return (<>
        <div  style={{ overflowX: 'hidden' }} >
            <TitleL1>Termografia Médica</TitleL1>
            <h5 className="text-justify mx-auto mt-3">
                As pessoas continuam entrando e saindo doentes dos estabelecimentos
                de saúde, muitas vezes por falta de um equipamento como o Infinity Life Healthcare,
                que realiza o escaneamento termográfico automático ainda na entrada do estabelecimento.
                Equipamento capaz de identificar mais de 5.000 doenças com uma simples passagem à sua frente. Qualidade, agilidade e segurança nos atendimentos.
                Equipamento não invasivo, livre de emissões de radiações. Faz a indicação dos locais com prováveis distúrbios
            </h5>
            <h5 className="text-dark text-justify mt-3">
                O equipamento propõe o diagnóstico de doenças já no primeiro processo de triagem, permitindo que o paciente seja encaminhado para a
                realização de outros exames mais adequados às suas necessidades. Ele realiza o mapeamento termográfico do paciente, indicando o local do provável distúrbio,
                promovendo um aumento no fluxo de uso do estabelecimento e acelerando o processo de tratamento.
                O Infinity Life Healthcare economiza tempo e dinheiro ao antecipar o diagnóstico de doenças que normalmente exigiriam um grande
                investimento em identificação. Isso promove rapidez, tratamento adequado e até mesmo prevenção, reduzindo custos desnecessários.
                Um exemplo prático disso é o câncer, em que um diagnóstico precoce pode resultar em custos até 6 vezes menores</h5>
            <div className=' border py-2 mt-5 rounded mb-5'>
                <div className='d-flex mx-auto col-md-12  justify-content-center align-items-center ' >
                    <Carousel3D images={imagesINF1} cellCount={cellCountINF1} />
                </div>
                <Divider />
                <h5 className=' ml-2 mr-2 text-justify mb-2'>
                    Funções rotineiras de segurança  esperadas do equipamento quando treinado com Inteligência Artificial.
                    Esse equipamento é gerenciado nessa mesma plataforma e em condições de pesquisa espera que o equipamento possa <br />
                    - Analisar a homogenização dos grupos<br />
                    - Analisar diferencial termografico dos grupos<br />
                    - Compara pesquisas para saber se deu certo ou não
                </h5>
            </div>
        </div>
    </>);
}
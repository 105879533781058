import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ButtonAdicionarCampo, ButtonEditarCampo, ButtonRemoverCampo, MaisInfoMenosInfo } from '@components';
import ProntuarioCirurgiasInfo from '../info/ProntuarioCirurgiasInfo';
export const ProntuarioCirurgias = () => {
  const [fezCirurgia, setFezCirurgia] = useState(false);
  const [cirurgias, setCirurgias] = useState([]);
  const [novaCirurgia, setNovaCirurgia] = useState('');
  const [indiceEditando, setIndiceEditando] = useState(-1);
  const handleFezCirurgiaChange = (e) => {
    setFezCirurgia(e.target.checked);
  };
  const handleNovaCirurgiaChange = (e) => {
    setNovaCirurgia(e.target.value);
  };
  const adicionarCirurgia = () => {
    if (novaCirurgia.trim() !== '') {
      if (indiceEditando === -1) {
        setCirurgias([...cirurgias, novaCirurgia]);
      } else {
        const novaLista = [...cirurgias];
        novaLista[indiceEditando] = novaCirurgia;
        setCirurgias(novaLista);
        setIndiceEditando(-1); // Resetar o índice de edição
      }
      setNovaCirurgia('');
    }
  };
  const removerCirurgia = (index) => {
    const novasCirurgias = [...cirurgias];
    novasCirurgias.splice(index, 1);
    setCirurgias(novasCirurgias);
  };
  const handleEditarCirurgia = (index) => {
    setNovaCirurgia(cirurgias[index]);
    setIndiceEditando(index);
  };
  return (
    <>
      <h5 style={{ fontWeight: "bold" }} className="mt-3">
        Já fez alguma cirurgia?
        </h5>
        <label>
          <input type="checkbox" onChange={handleFezCirurgiaChange} /> Sim
        </label>
        <br/>
        <label>
          <input type="checkbox" onChange={() => setFezCirurgia(false)} /> Não
        </label>
      {fezCirurgia && (
        <div>
          <h6>Se Sim, quais cirurgias?</h6>
          <div>
            <input
              type="text"
              value={novaCirurgia}
              onChange={handleNovaCirurgiaChange}
              placeholder="Informe a cirurgia"
            />
            <ButtonAdicionarCampo type={'button'}  onClick={adicionarCirurgia}/>
          </div>
          <ul>
            {cirurgias.map((cirurgia, index) => (
              <li key={index}>
                {cirurgia}
                <ButtonEditarCampo onClick={() => handleEditarCirurgia(index)} />
                <ButtonRemoverCampo onClick={() => removerCirurgia(index)}/>
              </li>
            ))}
          </ul>
        </div>
      )}
      <div className="col-md-12 mt-2 mb-5 d-flex">
        <Link className="btn btn-primary mx-auto col-md-6" href="#">
        Enviar Informações
        </Link>
      </div>
    <MaisInfoMenosInfo text={<ProntuarioCirurgiasInfo />}></MaisInfoMenosInfo> 
    </>
  );
};
export default ProntuarioCirurgias;
import { Divider } from "antd"
import { ButtonCadastrar, TitleL1 } from "@components"
export const Ferramentas = () => {
    return (<>
        <section className="mr-3 ml-3" style={{ minHeight: '95dvh' }}>
            <TitleL1 icon={<i className='fa fa-cogs mx-auto'style={{scale:'1.5'}}></i>}>Ferramentas</TitleL1>
            <h5 className="text-justify">
                Como forma de propagação da informação tentamos deixar de forma
                evidente grande maioria das ferramentas de 3º com o respectivo link utilizadas na criação dessa rede. Isso também dentre tantos outros
                é um forma de respeito com o time de desenvolvedores das outras plataformas, é o mínimo de reconhecimento e prestigío
            </h5>
            <div className="">
                <h5 className="text-left mt-4 font-weight-bold rounded"><b>
                    &nbsp;Gerenciamento ágil de projetos    </b>  </h5>
                <Divider />
                <h5 className="text-left mt-3">
                    <b>
                        &emsp;<a className='text-primary' href="https://mirro.io/" target={'_blank'} rel={'noopener noreferrer'} >Github</a><br></br>
                        &emsp;<a className='text-primary' href="https://mirro.io/" target={'_blank'} rel={'noopener noreferrer'} >Mirro</a><br></br>
                        &emsp;<a className='text-primary' href="https://www.atlassian.com/br/software/jira" target={'_blank'} rel={'noopener noreferrer'} >Jira</a>
                        <br></br><br></br>
                    </b>
                </h5>
            </div>
            <div className="row rounded py-3">
                <div className="col-md-12">
                    <h5 className="font-weight-bold"><b>Ferramentas de Programação</b>
                    </h5>
                    <Divider />
                </div>
                <div className="container-fluid align-items-center justify-content-center">
                    <div className="row container-fluid  justify-content-center">
                        <div className="col-md-4">
                            <h5><b>
                                &nbsp;IDE<br></br>
                                &emsp;<a className='text-primary' href="https://code.visualstudio.com/" target={'_blank'} rel={'noopener noreferrer'} >Visual Studio</a><br></br>
                            </b>
                            </h5>
                        </div>
                        <div className="col-md-4">
                            <h5><b>
                                &nbsp;Front-end<br></br>
                                &emsp;<a className='text-primary' href="https://pt-br.reactjs.org/" target={'_blank'} rel={'noopener noreferrer'} >React</a><br></br>
                                &emsp;<a className='text-primary' href="https://html5.org/" target={'_blank'} rel={'noopener noreferrer'} >Html</a><br></br>
                                &emsp;<a className='text-primary' href="https://www.w3.org/Style/CSS/" target={'_blank'} rel={'noopener noreferrer'} >Css</a><br></br>
                                &emsp;<a className='text-primary' href="https://getbootstrap.com/" target={'_blank'} rel={'noopener noreferrer'} >Bootstrap</a><br></br>
                                &emsp;<a className='text-primary' href="https://threejs.org/" target={'_blank'} rel={'noopener noreferrer'} >Three.js</a><br></br>
                                &nbsp;Ícones<br></br>
                                &emsp;<a className='text-primary' href="https://ctan.org/pkg/academicons" target={'_blank'} rel={'noopener noreferrer'} >Academicons</a><br></br>
                                &emsp;<a className='text-primary' href="https://fontawesome.com/v4/" target={'_blank'} rel={'noopener noreferrer'} >Font-awesome</a><br></br>
                                &emsp;<a className='text-primary' href="https://www.svgrepo.com/">Svgrepo</a><br></br >
                                &emsp;<a className='text-primary' href="https://www.flaticon.com/br/" target={'_blank'} rel={'noopener noreferrer'} >Flaticon</a><br></br ><br></br >
                            </b>
                            </h5>
                        </div>
                        <div className="col-md-4">
                            <h5>
                                <b>
                                    &nbsp;Back-end<br></br>
                                    &emsp;<a className='text-primary' href="https://nodejs.org/en/" target={'_blank'} rel={'noopener noreferrer'} >Node</a><br></br>
                                    &emsp;<a className='text-primary' href="https://tensorflow-object-detection-api-tutorial.readthedocs.io/en/latest/install.html#tensorflow-installation" target={'_blank'} rel={'noopener noreferrer'} >TensorFlow</a><br></br>
                                    &emsp;<a className='text-primary' href="https://www.docker.com/" target={'_blank'} rel={'noopener noreferrer'} >Docker</a><br></br>
                                    &emsp;<a className='text-primary' href="https://kubernetes.io/pt-br/" target={'_blank'} rel={'noopener noreferrer'} >Kubernetes</a><br></br >
                                    &emsp;<a className='text-primary' href="https://www.whatsapp.com/?lang=pt_br" target={'_blank'} rel={'noopener noreferrer'} >WhatsApp</a><br></br >
                                </b>
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row ">
                <div className="col-md-12">
                    <h5 className="text-left font-weight-bold"><b>
                        Imagens</b>
                    </h5>
                    <Divider />
                </div>
                <div className="col-md-4">
                    <h5>
                        &nbsp;<b>3D<br></br>
                            &emsp;<a className='text-primary' href="https://www.blender.org/" target={'_blank'} rel={'noopener noreferrer'} >Blender</a><br></br>
                            &emsp;<a className='text-primary' href="https://solidedge.siemens.com/pt-br/" target={'_blank'} rel={'noopener noreferrer'} >SolidEdge</a><br></br>
                            &emsp;<a className='text-primary' href="https://www.autodesk.com/products/artcam/overview" target={'_blank'} rel={'noopener noreferrer'} >Artcam</a><br></br>
                            &emsp;<a className='text-primary' href="https://www.machsuporte.com.br/download-mach3.php" target={'_blank'} rel={'noopener noreferrer'} >Mach3</a><br></br>
                            &emsp;<a className='text-primary' href="https://longer-3d-slicer.updatestar.com/pt" target={'_blank'} rel={'noopener noreferrer'} >Longer Slicer</a><br></br>
                        </b>
                    </h5>
                </div>
                <div className="col-md-4">
                    <h5 className="text-left"><b>
                        <h5>
                            &nbsp;<b>2D<br></br>
                                &emsp;<a className='text-primary' href="https://www.gimp.org/" target={'_blank'} rel={'noopener noreferrer'} >Gimp</a><br></br>
                                &emsp;<a className='text-primary' href="https://www.canva.com/" target={'_blank'} rel={'noopener noreferrer'} >Canvas</a><br></br>
                                &emsp;<a className='text-primary' href="https://www.blender.org/" target={'_blank'} rel={'noopener noreferrer'} >Voot</a><br></br>
                            </b>
                        </h5>
                    </b>
                    </h5>
                </div>
                <div className="col-md-4">
                    <h5 className="text-left"><b>
                        <h5>
                            &nbsp;<b>Video<br></br>
                                &emsp;<a className='text-primary' href="https://www.blender.org/" target={'_blank'} rel={'noopener noreferrer'} >OBS</a><br></br>
                                &emsp;<a className='text-primary' href="https://www.blender.org/" target={'_blank'} rel={'noopener noreferrer'} >Voot</a><br></br>
                            </b>
                        </h5>
                    </b>
                    </h5>
                </div>
            </div>
            <ButtonCadastrar/>
        </section >
    </>)
}
import { InputPadraoSearch } from '@components'
import { MaisOpcoesBusca } from './MaisOpcoesBusca'
import { ResultadoBuscaArtigos } from './ResultadoBuscaArtigos'
export const Busca = () => {
    return (<>
        <section style={{ minHeight: '95dvh' }}>
            <InputPadraoSearch
                id="inlineFormInputGroup"
                placeholder="Busca Artigos no banco de dados" />
            <MaisOpcoesBusca />
            <ResultadoBuscaArtigos />
        </section>
    </>)
}
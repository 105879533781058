import React, { useEffect } from 'react';
import { Image, Select } from 'antd';
import {
    MaisInfoMenosInfo,
    TitleL1,
    JSONViewerAnt,
    renderImagesApi,
    TitleL3
} from 'components';
import { FacePointTable, AnaliseFacialInfo } from '../../partials';
import { MdOutlineCircle } from 'react-icons/md';
export const FaceRenderResults = ({
    currentType,
    setLandmarks,
    landmarks,
    setModifiedLandmarks,
    modifiedLandmarks,
    photos,
    imageWidth,
    imageHeight,
    setPontoDestacado,
    pontoDestacado,
    setMostrarInformacoes,
    mostrarInformacoes,
    apiImages,
    apiResults,
    others,
    handleImageLoad,
    modifiedLandmarksType,
    setModifiedLandmarksType
}) => {
    const handlePointMouseDown = (e, index, isNested = false) => {
        console.log( modifiedLandmarks?.[currentType])
        e.preventDefault();
        const { clientX, clientY } = e;
        const imageRect = e.target.getBoundingClientRect();
        const offsetX = clientX - imageRect.left;
        const offsetY = clientY - imageRect.top;
        let landmarkArray = modifiedLandmarks?.[currentType]?.[modifiedLandmarksType];
        if (!landmarkArray) {
            console.error("Tipo de landmark não encontrado:", currentType, modifiedLandmarksType);
            return;
        }
        if (isNested && landmarkArray.landmarks) {
            landmarkArray = landmarkArray.landmarks;
        }
        if (!Array.isArray(landmarkArray) || landmarkArray.length <= index) {
            console.error("Landmark não encontrado ou índice inválido:", index);
            return;
        }
        const initialLandmark = landmarkArray[index];
        const initialX = initialLandmark.x * imageWidth;
        const initialY = initialLandmark.y * imageHeight;
        const deltaX = offsetX - initialX;
        const deltaY = offsetY - initialY;
        const updatePointCoordinates = (x, y) => {
            setModifiedLandmarks(prevLandmarks => {
                const newLandmarks = { ...prevLandmarks };
                let updatedArray = newLandmarks[currentType]?.[modifiedLandmarksType];
                if (!updatedArray) {
                    console.error("Erro ao acessar os landmarks");
                    return prevLandmarks;
                }
                if (isNested && updatedArray.landmarks) {
                    updatedArray = updatedArray.landmarks;
                }
                const updatedLandmarks = [...updatedArray];
                updatedLandmarks[index] = {
                    x: (x - deltaX) / imageWidth,
                    y: (y - deltaY) / imageHeight,
                    z: updatedLandmarks[index]?.z || -0.01,
                };
                if (isNested) {
                    newLandmarks[currentType] = {
                        ...newLandmarks[currentType],
                        [modifiedLandmarksType]: {
                            ...newLandmarks[currentType][modifiedLandmarksType],
                            landmarks: updatedLandmarks,
                        }
                    };
                } else {
                    newLandmarks[currentType] = {
                        ...newLandmarks[currentType],
                        [modifiedLandmarksType]: updatedLandmarks,
                    };
                }
                return newLandmarks;
            });
        };
        const handleMouseMove = (e) => {
            const { clientX, clientY } = e;
            const offsetX = clientX - imageRect.left;
            const offsetY = clientY - imageRect.top;
            updatePointCoordinates(offsetX, offsetY);
        };
        const handleMouseUp = () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };
        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('mouseup', handleMouseUp);
    };
    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'Escape' && pontoDestacado !== null) {
                setPontoDestacado(null);
            }
        };
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [pontoDestacado]);
    const handleMostrarInformacoes = () => {
        setMostrarInformacoes(!mostrarInformacoes);
        setPontoDestacado(null);
    };
    return (<>
        <div className='text-center'>
            <TitleL1>Analise Orofacial</TitleL1>
        </div>
        <MaisInfoMenosInfo text={<AnaliseFacialInfo />} />
        <div className='d-flex justify-content-center align-items-center col-md-12'>
            <div style={{ position: 'relative' }}>
                {photos[currentType] && (
                    <Image
                        id="651614552525"
                        className="d-block w-100 mx-auto"
                        src={typeof photos[currentType] === "string" ? photos[currentType] : photos[currentType] instanceof Blob ? URL.createObjectURL(photos[currentType]) : ''}
                        alt="Uploaded"
                        style={{ maxHeight: "1024px" }}
                        onLoad={handleImageLoad}
                        preview={false}
                    />
                )}
                {['', 'landmarks'].flatMap((nestedKey) => {
                    const landmarksArray = modifiedLandmarks?.[currentType]?.[modifiedLandmarksType]?.[nestedKey] ||
                        (nestedKey === '' ? modifiedLandmarks?.[currentType]?.[modifiedLandmarksType] : []);
                    return Array.isArray(landmarksArray)
                        ? landmarksArray.map((landmark, index) =>
                            (pontoDestacado === null || pontoDestacado === index) && landmark ? (
                                <div
                                    key={`${modifiedLandmarksType}-${nestedKey || 'main'}-${index}`}
                                    style={{
                                        position: 'absolute',
                                        left: landmark.x !== undefined ? landmark.x * imageWidth : 0,
                                        top: landmark.y !== undefined ? landmark.y * imageHeight : 0,
                                        width: '5px',
                                        height: '5px',
                                        backgroundColor: nestedKey ? 'blue' : 'red', // Azul para aninhados, vermelho para principais
                                        cursor: 'pointer',
                                    }}
                                    onMouseDown={(e) => handlePointMouseDown(e, index, !!nestedKey)} // true para aninhados
                                />
                            ) : null
                        )
                        : [];
                })}
            </div>
        </div >
        {modifiedLandmarks[currentType] &&
            <div className='text-center d-flex align-items-center justify-content-center'>
                <small>Pontos</small> <MdOutlineCircle color='red' />
                <Select
                    style={{ width: 200 }}
                    value={modifiedLandmarksType}
                    onChange={(value) => setModifiedLandmarksType(value)}
                >
                    <Select.Option key="empty" value="">
                        Selecione...
                    </Select.Option>
                    {modifiedLandmarks?.[currentType] ? (
                        Object.keys(modifiedLandmarks[currentType]).length > 0 ? (
                            Object.keys(modifiedLandmarks[currentType]).map((key) => (
                                <Select.Option key={key} value={key}>
                                    {key.replace(/landmarks_/g, '').replace(/_/g, ' ')}
                                </Select.Option>
                            ))
                        ) : (
                            <Select.Option disabled>Nenhum ponto disponível</Select.Option>
                        )
                    ) : (
                        <Select.Option disabled>Tipo inválido</Select.Option>
                    )}
                </Select>
            </div>
        }
        {Object.keys(apiImages).length > 0 && (<>
            <button
                id='bntAnaliseFacialTradePontos'
                className={mostrarInformacoes ? "btn btn-success  w-100 mt-3" : 'btn btn-warning  w-100 mt-3'}
                onClick={handleMostrarInformacoes}
            >
                {mostrarInformacoes ? `Ocultar Informações de ${currentType}` : `Mostrar Alterar Informações de ${currentType} ⚙️`}
            </button>
            &nbsp;
            {mostrarInformacoes && (
                <FacePointTable
                    landmarks={landmarks}
                    setLandmarks={setLandmarks}
                    pontoDestacado={pontoDestacado}
                    setPontoDestacado={setPontoDestacado}
                    currentType={currentType}
                    modifiedLandmarks={modifiedLandmarks}
                    modifiedLandmarksType={modifiedLandmarksType}
                    setModifiedLandmarks={setModifiedLandmarks}
                />
            )}
        </>)}
        {currentType && others && (
            <div className='text-center mt-2 bg-success py-3 bg-secondary trl-container rounded'>
                <TitleL3 className="text-white">Insights</TitleL3>
                <div className='d-flex align-items-center justify-content-center'>
                    {renderImagesApi(apiImages, currentType)}
                </div>
                <MaisInfoMenosInfo text={<><JSONViewerAnt data={apiResults[currentType]} /></>} />
            </div>
        )}
    </>);
};
export default FaceRenderResults;
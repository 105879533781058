import React from 'react';
const ProspeccaoClientesEmailInfo = () => {
  return (
    <div className="prospeccao-clientes-email-info">
      <h5>Instruções para Envio de E-mails para Clientes</h5>
      <p>Preencha o formulário abaixo para enviar e-mails para os clientes como parte do processo de prospecção.</p>
      <ol className='ml-3'>
        <li>Preencha o campo "E-mail que enviará msg" com o seu endereço de e-mail.</li>
        <li>Insira a senha do seu e-mail no campo "Senha do e-mail que enviará msg".</li>
        <li>No campo "Destinatários", insira os endereços de e-mail dos destinatários separados por vírgula.</li>
        <li>No campo "Assunto", digite o assunto do e-mail.</li>
        <li>Na caixa de texto "Mensagem", escreva o conteúdo do e-mail que você deseja enviar.</li>
        <li>Clique no botão "Enviar" para enviar os e-mails.</li>
      </ol>
      <p>Após o envio, você receberá uma mensagem de confirmação.</p>
    </div>
  );
};
export default ProspeccaoClientesEmailInfo;
import { CheckCircleOutlined } from "@ant-design/icons";
import { Tag } from "antd";
import { TitleL2 } from "@components";
export const VendasIAImagem = (props) => {
  return (
    <Tag className='mt-3 mb-3 d-flex ' style={{
      width: '100%',
      padding: '60px',
      whiteSpace: 'pre-line'
    }} icon={<CheckCircleOutlined style={{ fontSize: '48px', paddingInlineEnd: '50px' }} />} color="green">
      <div className='col-md-12'>
        <TitleL2 style={{ color: "green", textAlign: "left" }}>Como vender</TitleL2>
        <li className='text-success'> Siga para página de serviços e cadastre um serviço do tipo I.A. sub-tipo classificação de imagens</li>
        <li className='text-dark font-weight-bold mt-3'> Termos da ferramenta de I.A. de classificação de imagens no code como serviço da THOT I.A. CORP.</li>
        <li className='text-dark '> Ao ofertar esse serviço você assumiu compartilhamento total de dados com a THOT I.A. CORP.</li>
        <li className='text-dark '>Você também assume total responsabilidade pelo uso dos sistemas e está pronto para desfrustar dos beneficíos desse</li>
        <li className='text-dark '>Lembre-se a THOT I.A. CORP. não se responsabiliza pelo uso da ferramenta ou venda dessa ferramenta para 3º ou serviços de 3º de qualquer natureza</li>
        <li className='text-dark'> A THOT I.A. CORP. é uma gerenciadora digital administrativa qualquer ato irregular detectado deve ser manifestado de imediato ao jurídico do país competente para notificar o 3º servidor e eventualmente a THOT I.A. CORP para esta fornecer toda e qualquer documentação solicitada judicialmente</li>
      </div>
    </Tag>
  );
};
export default VendasIAImagem;
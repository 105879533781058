import { useLocation } from 'react-router-dom';
import { NavUserMain, NavBreadcrumb, CardDirecionamento, FooterUser, Nav } from '@components';
import Agenda from '../../Agenda/Agenda';
function RedirecionarParaBuscarPaciente() {
  window.location.href = "/usuario/servicos";
}
export const MarcarConsultas = () => {
  const location = useLocation();
  return (
    <>
    <Nav/>
      <div className="d-flex mt-3">
        <NavUserMain />
        <div className="w-100">
          <NavBreadcrumb
            text={` ${
              location.pathname === "/usuario/consultas/marcar"
                ? "Marcar Consultas"
                : "Marcar Exames"
            }`}
            icon={"calendar fa"}
          />
          <div className="py-3 ">
            <div className="text-center">
              <div className="container-fluid">
                <div className="row d-inline-flex justify-content-center w-100">
                  <CardDirecionamento
                    text={"PARTICULAR"}
                    onClick={() => RedirecionarParaBuscarPaciente()}
                    icon={"mt-3 fa fa-money fa-3x text-white"}
                  />
                  <CardDirecionamento
                    text={"CONVÊNIO"}
                    onClick={() => RedirecionarParaBuscarPaciente()}
                    icon={"mt-3 fa fa-address-card fa-3x text-white"}
                  />
                  <CardDirecionamento
                    text={"PÚBLICO"}
                    onClick={() => RedirecionarParaBuscarPaciente()}
                    icon={"mt-3 fa fa-institution fa-3x text-white"}
                  />
                  <div
                    id="ServicosProcedimentosList"
                    className="collapse col-md-12"
                  >
                    <div className="col-md-12 mt-5 py-3 mx-auto">
                      <Agenda />
                    </div>
                    <div className="col-md-12 mt-5 py-3 mx-auto">
                      {/*   <ServicosProcedimentosList />*/}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <FooterUser />
        </div>
      </div>
    </>
  );
};
export default MarcarConsultas;
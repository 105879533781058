import React from "react";
import { Table } from "../../Table/Table";
import { Cards } from "../../Cards/Cards";
import { Link } from "react-router-dom";
import "./SprintContent.css";
import { List } from "../../List/List";
import { MenuItens } from "../../MenuItens/MenuItens";
export function SprintContent() {
  const pessoas = [
    {
      img: "https://avatars.githubusercontent.com/u/109116643?v=4",
      nome: "Cleyson Oliveira",
      funcao: "Desenvolvedor",
      link: "https://github.com/CleysonOlvr",
    },
    {
      img: "https://avatars.githubusercontent.com/u/123272360?v=4",
      nome: "Anabelly Passos",
      funcao: "Desenvolvedora",
      link: "https://github.com/anabellypassos",
    },
    {
      img: "https://avatars.githubusercontent.com/u/128336634?v=4",
      nome: "Felipe Martines",
      funcao: "Desenvolvedor",
      link: "https://github.com/Kurjata",
    },
    {
      img: "https://avatars.githubusercontent.com/u/74729853?v=4",
      nome: "Leonardo Santos",
      funcao: "Gerente De Projetos",
      link: "https://github.com/LeonardoRSantos",
    },
  ];
  return (
    <div className="container-conteudo">
      <h1 className="titulo-sprint">Planejamento</h1>
      <MenuItens.Container>
        <List.Container>
          {" "}
          {/* Adicione a classe center-content */}
          <div className="titulo-visao-geral">
            <MenuItens.TableContent
              title="1. Visão Geral"
              dark={true}
              id="visaogeral"
            />
          </div>
          <List.ContentList>
            <Table.Container>
              <Table.Head>
                <Table.Title title="Data de Início" />
                <Table.Title title="07/04/2021" />
              </Table.Head>
              <Table.Body>
                <Table.Row>
                  <Table.Item text="Data de Término" />
                  <Table.Item text="18/03/2021" />
                </Table.Row>
                <Table.Row>
                  <Table.Item text="Duração" />
                  <Table.Item text="7 dias" />
                </Table.Row>
              </Table.Body>
            </Table.Container>
          </List.ContentList>
          <List.ContentList title="Papéis">
          <Cards.Root estilo={"container-card-sprintContent"}>
            <div className="d-flex">              
              {pessoas.map((pessoa, index) => (
                <div className="md-typeset__scrollwrap">
                  <div className="md-typeset__table">
                    <table className="md-typset__table">
                      <tbody>
                        <tr>
                          <td align="center">
                            <Cards.Card
                              key={index}
                              img={pessoa.img}
                              nome={pessoa.nome}
                              funcao={pessoa.funcao}
                              link={pessoa.link}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              ))}
            </div>
          </Cards.Root>
          </List.ContentList>
          <MenuItens.TableContent
            title="2. Objetivo da Sprint"
            dark={true}
            id="objetivosprint"
          />
          <List.ContentList>
            <p>Esta Sprint tem como objetivo:</p>
            <ul className="container-objetivos">
              <li>Criar canais de comunicação para a equipe.</li>
              <li>Debater sobre o nível de conhecimento individual.</li>
              <li>Debater temas que mais parecem interessantes e viáveis.</li>
            </ul>
          </List.ContentList>
          <MenuItens.TableContent
            title="3. Sprint Backlog"
            dark={true}
            id="sprintbacklog"
          />
          <List.ContentList>
            <p>
              A Milestone da Sprint 0 encontra-se neste <Link to="#">Link</Link>
            </p>
          </List.ContentList>
          <MenuItens.TableContent
            title="4. Débitos Acumulados"
            dark={true}
            id="debitosacumulados"
          />
          <List.ContentList>
            <code className="code-debito">
              Por ser a primeira Sprint do projeto, não houveram débitos
              adicionais.
            </code>
          </List.ContentList>
        </List.Container>
      </MenuItens.Container>
    </div>
  );
}
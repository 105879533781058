import React, { useState, useEffect } from "react";
import "./css/style.css";
import VideoStream from "../../pages/Private/Profissionais/Equipamentos/components/VideoStream/VideoStream";
import { baseURL } from "../../services";
export const FullScreenImage = ({ folder, setSelectedAnimal, isVisible, setIsVisible }) => {
  const [isImageVisible, setIsImageVisible] = useState(false);
  useEffect(() => {
    setIsImageVisible(true);
    const timeout = setTimeout(() => {
      setIsImageVisible(false);
    }, 5000);
    return () => clearTimeout(timeout);
  }, [folder]);
  const handleClick = () => {
    setSelectedAnimal(null);
    setIsVisible(false);
  }
  return (<>
    {isVisible && (
      <div className="fullscreen CardTHOT" style={{ height: "100vh", width: "100vw" }}>
        <button
          className=" btn a-89 fixed-bottom mb-2"
          onClick={handleClick}
          style={{ position: 'fixed', maxWidth: '20%' }}>Cancelar
          <i className="fas fa-window-close ml-2"></i>
        </button>
        <button className=" btn a-87 text-right fixed-bottom mb-2 "
          onClick={handleClick}
          style={{ position: 'fixed', maxWidth: '20%', marginLeft: '80%' }}>
          <span>
            {isImageVisible ? (<>
              Carregando <i className="fas fa-hourglass-half ml-2"></i>
            </>) : (<>
              Analisar <i className="fas fa-hourglass-half ml-2"></i>
            </>)}
          </span>
        </button>
        {isImageVisible && (
          <img
            className="fullscreen__image w-100 h-100"
            src={`${baseURL}images/BioterioForte/${encodeURIComponent(
              folder
            )}.jpeg`}
            alt={folder}
            style={{ maxWidth: "auto", maxHeight: "auto" }}
          />
        )}
        <h5 className="fixed-top font-weight-bold text-uppercase text-white fixed">{folder}</h5>
        <VideoStream />
      </div>
    )}
  </>
  );
};
export default FullScreenImage;
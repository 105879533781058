import React from 'react';
import {  ButtonCadastrar, TitleL2 } from '@components';
export function DashboardServiceAdvanced() {
  return (<>
    <section style={{ minHeight: '95dvh' }}>
      <TitleL2>Recursos Avançados</TitleL2>
      <h5 className='text-justify mt-3'>
        Estamos comprometidos em fornecer uma plataforma poderosa e intuitiva
        que o ajude a transformar dados em conhecimento acionável. Junte-se a nós
        nessa jornada de análise de dados e descubra as oportunidades que seus
        dados podem revelar.
        Conecte-se , analise, compartilhe  e impulsione  o sucesso da sua
        organização com o Dashboard Dinâmico de Análise de Dados. Comece agora
        mesmo e aproveite o poder da análise de dados para tomar decisões mais
        inteligentes e acertivas
      </h5>
      <ul>
        <li className='mt-3'>
          <i className=' fa fa-check-square text-success'></i>&nbsp;
          <strong>Conecte Seu Banco de Dados:</strong> Integre facilmente seus
          dados de várias fontes, seja bancos de dados, planilhas ou APIs. Nossa
          plataforma suporta uma ampla gama de fontes de dados para atender às
          suas necessidades
        </li>
        <ul className='ml-3'>
          <li className='mt-3'>🆗 MySQL </li>
          <li> 🆗 MySQL2</li>
          <li>🆗 PostgreSQL</li>
        </ul>
        <li className='mt-3'><i className=' fa fa-check-square text-success'></i>&nbsp;
          <strong>Analise Qualquer Coisa:</strong> Não importa qual seja a sua
          área de interesse - vendas, marketing, finanças, recursos humanos ou
          qualquer outro campo - você pode realizar análises profundas e
          personalizadas para obter insights valiosos
        </li>
        <li className='mt-3'><i className=' fa fa-check-square text-success'></i>&nbsp;
          <strong>Tomada de Decisões Informadas:</strong> Capacite sua equipe a
          tomar decisões mais embasadas e estratégicas. Utilize dados para
          impulsionar o sucesso da sua organização
        </li>
        <li className='mt-3'><i className=' fa fa-check-square text-success'></i>&nbsp;
          <strong>Business Intelligence (BI):</strong> Utilize recursos
          avançados de Business Intelligence para criar painéis interativos,
          gráficos dinâmicos e relatórios personalizados. Obtenha uma visão clara
          do desempenho da sua organização em tempo real
        </li>
        <li className='mt-3'><i className=' fa fa-check-square text-success'></i>&nbsp;
          <strong>Transmita Seu Frame:</strong> Compartilhe suas análises de
          maneira fácil e eficaz. Transmita suas descobertas por meio de
          relatórios interativos, dashboards e apresentações envolventes
        </li>
        <li className='mt-3'><i className=' fa fa-check-square text-success'></i>&nbsp;
          <strong>Migre Seus Dados:</strong> Comunique a equipe da THOT I.A. CORP. para migrar dados de outros ERP CMR ou parecidos
        </li>
      </ul>
      <ButtonCadastrar/>
    </section >
  </>);
}
export default DashboardServiceAdvanced;
// SprintNavData.js
export const sprintMainData = [
  // ... Seus outros dados de navegação ...
  {
    id: 'navSprints',
    title: 'Sprints 0',
    icon: 'fa fa-tasks',
    topDist: 'mt-5',
    subData: [
      {
        id: 'navPlanejamento0',
        title: 'Planejamento',
        icon: 'fa fa-circle',
      },
      {
        id: 'navRevisao0',
        title: 'Revisao',
        icon: 'fa fa-circle',
      }
    ],
  },
  {    
    id: 'navSprints1',
    title: 'Sprints 1',
    icon: 'fa fa-tasks',    
    subData: [
      {
        id: 'navPlanejamento1',
        title: 'Planejamento',
        icon: 'fa fa-circle',
      },
      {
        id: 'navRevisao1',
        title: 'Revisao',
        icon: 'fa fa-circle',
      }
    ],
  },
];
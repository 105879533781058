import React from 'react';
const MonitoringTrainIATextInfo = ({startSimulation, simulationProgress}) => {
  return (
    <div>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>
      <h5><strong>Monitoramento de Treinamento</strong></h5>
      <p>Este componente React simula o monitoramento de treinamento de uma I.A., exibindo informações importantes sobre o progresso do treinamento. Ele oferece insights sobre a época, etapa, progresso, perda (loss) e o número de sentenças processadas durante o treinamento.</p>
      <hr />
                <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />
      <h5><strong>Utilização</strong></h5>
      <p>Para usar este componente, siga os seguintes passos:</p>
      <ol className='ml-3'>
        <li>Certifique de que sua I.A. esteja em treinamento para acompanhar o progresso real.</li>
        <li>Certifique-se de ver a lista de treinamentos.</li>
        <li>Certifique-se de que o comportamento simulado do componente. Ver se este não está gerando erros.</li>
      </ol>
      <hr />
                <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />
      <h5><strong>Funcionalidades</strong></h5>
      <p>O componente exibe as seguintes informações:</p>
      <ol className='ml-3'>
        <li><strong>Status:</strong> Indica o status atual do treinamento, que pode ser "Waiting..." (Aguardando) ou "Training Completed" (Treinamento Concluído).</li>
        <li><strong>Época (Epoch):</strong> Representa o número da época atual em que o treinamento se encontra.</li>
        <li><strong>Etapa (Step):</strong> Indica o número da etapa dentro de cada época. Após cada série de etapas, o componente avançará para a próxima época.</li>
        <li><strong>Progresso:</strong> Exibe a barra de progresso do treinamento, calculada com base no número de etapas concluídas em relação ao total de etapas em uma época.</li>
        <li><strong>Perda (Loss):</strong> Apresenta o valor da perda (loss) calculado de forma aleatória para simular a evolução do treinamento.</li>
        <li><strong>Sentenças Processadas:</strong> Mostra o número de sentenças processadas durante todo o treinamento.</li>
      </ol>
      <hr />
                <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />
      <h5><strong>Simulação</strong></h5>
      <p>O componente tambem pode simular o processo de treinamento em intervalos regulares. Ele avança pelas etapas e épocas, gerando valores aleatórios para a perda e atualizando as informações conforme o treinamento avança. Simulação criada para caso esteja na fila.</p>
      <button className="btn btn-primary"  disabled={simulationProgress} onClick={startSimulation}>
        {simulationProgress ? 'Em simulação':'Simular Progresso' }
      </button>
      <hr />
                <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  </div>
  );
};
export default MonitoringTrainIATextInfo;
export const projecaoMalarEsq = (points, imageX, imageY, imgWidthHalf, imgHeightHalf) => [
    { x: imageX + points.point117.x * imgWidthHalf, y: imageY + points.point117.y * imgHeightHalf },
    { x: imageX + points.point208.x * imgWidthHalf, y: imageY + points.point208.y * imgHeightHalf },
    { x: imageX + points.point101.x * imgWidthHalf, y: imageY + points.point101.y * imgHeightHalf },
    { x: imageX + points.point117.x * imgWidthHalf, y: imageY + points.point117.y * imgHeightHalf },
];
export const projecaoMalarDir = (points, imageX, imageY, imgWidthHalf, imgHeightHalf) => [
    { x: imageX + points.point330.x * imgWidthHalf, y: imageY + points.point330.y * imgHeightHalf },
    { x: imageX + points.point428.x * imgWidthHalf, y: imageY + points.point428.y * imgHeightHalf },
    { x: imageX + points.point341.x * imgWidthHalf, y: imageY + points.point341.y * imgHeightHalf },
    { x: imageX + points.point330.x * imgWidthHalf, y: imageY + points.point330.y * imgHeightHalf },
];
export const labioInferior = (points, imageX, imageY, imgWidthHalf, imgHeightHalf) => [
    // Linha inferior do labio inferior
    { name: '62', x: imageX + points.point62.x * imgWidthHalf, y: imageY + points.point62.y * imgHeightHalf },
    { name: '147', x: imageX + points.point147.x * imgWidthHalf, y: imageY + points.point147.y * imgHeightHalf },
    { name: '181', x: imageX + points.point181.x * imgWidthHalf, y: imageY + points.point181.y * imgHeightHalf },
    { name: '86', x: imageX + points.point86.x * imgWidthHalf, y: imageY + points.point86.y * imgHeightHalf },
    { name: '17', x: imageX + points.point17.x * imgWidthHalf, y: imageY + points.point17.y * imgHeightHalf },
    { name: '316', x: imageX + points.point316.x * imgWidthHalf, y: imageY + points.point316.y * imgHeightHalf },
    // Linha superior do labio inferior
    { name: '322', x: imageX + points.point322.x * imgWidthHalf, y: imageY + points.point322.y * imgHeightHalf },
    { name: '376', x: imageX + points.point376.x * imgWidthHalf, y: imageY + points.point376.y * imgHeightHalf },
    { name: '292', x: imageX + points.point292.x * imgWidthHalf, y: imageY + points.point292.y * imgHeightHalf },
    { name: '325', x: imageX + points.point325.x * imgWidthHalf, y: imageY + points.point325.y * imgHeightHalf },
    { name: '318', x: imageX + points.point318.x * imgWidthHalf, y: imageY + points.point318.y * imgHeightHalf },
    { name: '88', x: imageX + points.point88.x * imgWidthHalf, y: imageY + points.point88.y * imgHeightHalf },
    { name: '179', x: imageX + points.point179.x * imgWidthHalf, y: imageY + points.point179.y * imgHeightHalf },
    { name: '62', x: imageX + points.point62.x * imgWidthHalf, y: imageY + points.point62.y * imgHeightHalf },
];
export const labioSuperior = (points, imageX, imageY, imgWidthHalf, imgHeightHalf) => [
    { name: '62', x: imageX + points.point62.x * imgWidthHalf, y: imageY + points.point62.y * imgHeightHalf },
    { name: '186', x: imageX + points.point186.x * imgWidthHalf, y: imageY + points.point186.y * imgHeightHalf },
    { name: '41', x: imageX + points.point41.x * imgWidthHalf, y: imageY + points.point41.y * imgHeightHalf },
    { name: '40', x: imageX + points.point40.x * imgWidthHalf, y: imageY + points.point40.y * imgHeightHalf },
    { name: '38', x: imageX + points.point38.x * imgWidthHalf, y: imageY + points.point38.y * imgHeightHalf },
    { name: '1', x: imageX + points.point1.x * imgWidthHalf, y: imageY + points.point1.y * imgHeightHalf },
    { name: '268', x: imageX + points.point268.x * imgWidthHalf, y: imageY + points.point268.y * imgHeightHalf },
    { name: '8', x: imageX + points.point270.x * imgWidthHalf, y: imageY + points.point270.y * imgHeightHalf },
    { name: '9', x: imageX + points.point271.x * imgWidthHalf, y: imageY + points.point271.y * imgHeightHalf },
    { name: '12', x: imageX + points.point410.x * imgWidthHalf, y: imageY + points.point410.y * imgHeightHalf },
    { name: '13', x: imageX + points.point273.x * imgWidthHalf, y: imageY + points.point273.y * imgHeightHalf },
    { name: '14', x: imageX + points.point272.x * imgWidthHalf, y: imageY + points.point272.y * imgHeightHalf },
    { name: '69', x: imageX + points.point269.x * imgWidthHalf, y: imageY + points.point269.y * imgHeightHalf },
    { name: '14', x: imageX + points.point14.x * imgWidthHalf, y: imageY + points.point14.y * imgHeightHalf },
    { name: '39', x: imageX + points.point39.x * imgWidthHalf, y: imageY + points.point39.y * imgHeightHalf },
    { name: '42', x: imageX + points.point42.x * imgWidthHalf, y: imageY + points.point42.y * imgHeightHalf },
    { name: '43', x: imageX + points.point43.x * imgWidthHalf, y: imageY + points.point43.y * imgHeightHalf },
    { name: '79', x: imageX + points.point79.x * imgWidthHalf, y: imageY + points.point79.y * imgHeightHalf },
    { name: '62', x: imageX + points.point62.x * imgWidthHalf, y: imageY + points.point62.y * imgHeightHalf },
];
import { TitleL2 } from '@components'
import { InfinityLifeParcerias } from '../../../Produtos/partials/layout/vender/PreLancamento/InfinityLifeParcerias'
import video from '../assets/videos/DesenvolvimentoInfinityLifeHealthcare.mp4'
export const EquipamentosMedicos = () => {
  return (<>
    <section className='mr-3 ml-3' style={{ minHeight: '95dvh' }}>
      <div className=" mx-auto rounded d-flex">
        <video className="w-100 rounded" controls >
          <source src={video} type="video/mp4" alt='Parceria para treinamento de software de visão computacional' />
        </video>
      </div>
      <InfinityLifeParcerias />
      <TitleL2>
        Aquisições do Protótipo
      </TitleL2>
      <h5 className='font-weight-bold'>
        <strong>Valor de Custo</strong>
      </h5>
      <h5 className='text-justify'>  O protótipo funcional proposto é um hardware composto por uma câmera unidade termográfica.
        O protótipo funcional não inclui um software com soluções em visão computacional, capaz de identificar mais de 5 mil doenças.
        Estamos atualmente treinando essa IA, o que justifica a comercialização do protótipo funcional a valor de custo.
        Dessa forma, empresas ou profissionais que adquirirem o protótipo nos ajudarão a aprimorar nossa IA.
        Além disso, já realizamos testes em ambiente laboratorial e dominamos a tecnologia envolvida no protótipo funcional
      </h5>
      <div className='d-flex mx-auto justify-content-center'>
        <div className="text-center rounded col-md-6 mt-5 py-3 ">
          <a className=" align-itens-center mx-auto mb-5"
            target={'_blank'} rel={'noopener noreferrer'}
            href={'https://api.whatsapp.com/send?phone=5566996675486&text=Estou%20entrando%20em%20contato%20para%20adiquirir%20o%20protótipo%20funcional%20do%20equipamento%20infinity%20Life%20Healthcare%20'}><i
              className="ion-social-whatsapp  text-success fa-3x"></i> </a><br />
          Compra uso e treinamento - entre em contato com a empresa agora mesmo através deste botão
        </div>
      </div>
    </section >
  </>)
}
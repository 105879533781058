export const pdf_download_14 = (
    doc,
    pdf_download_footer,
    qrCode,
    professionalInfo,
    imgHeight,
    modifiedLandmarks,
    landmarks_botox_soma_un,
    generateTablePDF
) => {
    if (
        !doc ||
        !pdf_download_footer ||
        !qrCode ||
        !professionalInfo ||
        !imgHeight ||
        !modifiedLandmarks.FaceFrontal
    ) {
        console.warn("Parâmetros ausentes, página não adicionada.");
        return;
    }
    doc.addPage();
    doc.addImage(require('../assets/fundoPDF.png'), 'PNG', 0, 0, doc.internal.pageSize.width, doc.internal.pageSize.height);
    doc.setFont('times', 'bold');
    doc.setFontSize(25);
    doc.text('Material: Botox', 100, 15, { align: 'center' });
    doc.setFont('times', 'normal');
    doc.setFontSize(8);
    // Função para gerar o PDF
    const somaUNValor = landmarks_botox_soma_un(modifiedLandmarks.FaceFrontal.landmarks_botox_frontal.landmarks);
    const somaUNValorGlabela = landmarks_botox_soma_un(modifiedLandmarks.FaceFrontal.landmarks_botox_glabela.landmarks);
    const somaUNValorPeriorbital = landmarks_botox_soma_un(modifiedLandmarks.FaceFrontal.landmarks_botox_periorbital.landmarks);
    const somaUNValorNasal = landmarks_botox_soma_un(modifiedLandmarks.FaceFrontal.landmarks_botox_periorbital.landmarks);
    const somaUNValorOralMento = landmarks_botox_soma_un(modifiedLandmarks.FaceFrontal.landmarks_botox_periorbicular.landmarks);
    const somaUNValorPeriorbicular = landmarks_botox_soma_un(modifiedLandmarks.FaceFrontal.landmarks_botox_oralemento.landmarks);
    const somaUNValorPlatisma = landmarks_botox_soma_un(modifiedLandmarks.FaceFrontal.landmarks_botox_platisma.landmarks);
    const somaUNTotal = somaUNValor + somaUNValorGlabela + somaUNValorPeriorbital + somaUNValorNasal + somaUNValorPeriorbicular + somaUNValorOralMento + somaUNValorPlatisma
    // Exemplo de uso
    const materials = [
        { name: 'Luvas', quantity: 6, unit: 'un', desc: 'EPI' },
        { name: 'Mascara descartável', quantity: 2, unit: 'un', desc: 'EPI' },
        { name: 'Touca descartável', quantity: 3, unit: 'un', desc: 'EPI' },
        { name: '', quantity: '', unit: '', desc: '' },
        { name: 'Compressa de gaze estéril envelope com 5 unidade', quantity: 2, unit: 'un', desc: 'Antissepsia' },
        { name: 'Clorexidine', quantity: 1, unit: 'ml', desc: 'Antissepsia' },
        { name: 'Soro fisiológico (SF) a 0,9% estéril 300ml', quantity: 1, unit: 'ml', desc: 'Antissepsia' },
        { name: '', quantity: '', unit: '', desc: '' },
        { name: 'Botox', quantity: somaUNTotal, unit: 'un', desc: 'Aplicação' },
        { name: 'Pomada anestésica BLT 1,2g', quantity: 1, unit: 'un', desc: 'Aplicação' },
        { name: 'Soro fisiológico (SF) a 0,9% estéril 10ml', quantity: 8, unit: 'ml', desc: 'Aplicação' },
        { name: 'Seringa 10ml Agulha 20 a 22G', quantity: 1, unit: 'un', desc: 'Aplicação' },
        { name: 'Seringa 0,01ml ou 0,02ml Agulha 30G curta ou longa', quantity: 6, unit: 'un', desc: 'Aplicação' },
    ];
    generateTablePDF(doc, materials);
    doc.setFontSize(5);
    doc.text('Ayres, E. L., & Sandoval, M. H. L. (2016). Toxina botulínica na dermatologia: guia prático de técnicas e produtos. 1. ed. Rio de Janeiro: Guanabara Koogan.', 15, 75 + imgHeight);
    doc.text('Arora, G., & Arora, S. (2024). Where and how to use botulinum toxin on the face and neck – Indications and techniques. Journal of Dermatological Applications, 12(3), 45-50. https://cosmoderma.org/content/130/2021/1/1/pdf/CSDM-1-17.pdf', 15, 77 + imgHeight);
    doc.setFontSize(8);
    doc.setTextColor(0, 0, 0);  // Define a cor do texto
    pdf_download_footer(doc, qrCode, professionalInfo);
};
import { FotoFace } from './layout/FotoFace';
import { Cadastrar } from "../../../../../Public/Cadastrar/Cadastrar";
import { FooterUser, Nav, NavUserMain, ReferenciaLocal, NavBreadcrumb } from '@components';
export const CadastroPaciente = () => {
    return (<>
        <Nav />
        <div className="d-flex mt-3">
            <NavUserMain />
            <div className="w-100">
                <NavBreadcrumb icon={'user fa'} text={'Cadastro'} />
                <ReferenciaLocal text={'Foto Face'} />
                <FotoFace />
                <ReferenciaLocal text={'Informações Pessoais'} />
                <Cadastrar />
                <FooterUser />
            </div>
        </div>
    </>)
}
import api from "./api";
export const apiToken = () => {
  const token = localStorage.getItem('token');
  const instance = api.create({
    headers: {
      Authorization: `Bearer ${token}`,
    },
    timeout: 60000, // Timeout de 60 segundos
  });
  return instance;
};
export default apiToken;
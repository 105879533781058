import React, { useState, useEffect, useRef, useCallback, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { InputPadraoSearch } from '@components';
import { baseURL } from '@services';
import ServicesCardList from './ServicesCardList';
import { AutoComplete, Pagination } from 'antd';
import { AuthContext } from '../../../../../../guard';
import "../../../../Produtos/partials/styles/promotion.css";
import { post_cart } from '../../../../Carrinho/partials/services/post_cart';
const { Option } = AutoComplete;
export const ServicosList = () => {
  const [servicos, setservicos] = useState([]);
  const [quantidadeExibida] = useState(10);
  const [totalServices, setTotalServices] = useState(0);
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [filterName, setFilterName] = useState('');
  const [filterType, setFilterType] = useState('');
  const [filterPriceMin, setFilterPriceMin] = useState('');
  const [filterPriceMax, setFilterPriceMax] = useState('');
  const [filterCompany, setFilterCompany] = useState('');
  const [filterCompanyUnit, setFilterCompanyUnit] = useState('');
  const [filterProfessional, setFilterProfessional] = useState('');
  const [filtersActive, setFiltersActive] = useState(false);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const buttonRef = useRef(null);
  const [screenY, setScreenY] = useState(window.innerWidth); // Inicialize com a altura atual da tela
  const { getUserId } = useContext(AuthContext);
  const user_id = getUserId();
  const [cart, setCart] = useState(JSON.parse(localStorage.getItem('cart')) || []);
  useEffect(() => {
    const handleResize = () => {
      setScreenY(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const handlePageChange = (pageNumber) => {
    setPaginaAtual(pageNumber);
  };
  async function fetchCity() {
    let displayCity = null;
    while (!displayCity) {
      if (navigator.geolocation) {
        const position = await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        });
        const { latitude, longitude } = position.coords;
        setLatitude(latitude);
        setLongitude(longitude);
        const response = await fetch(`https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`);
        const data = await response.json();
        displayCity = data.display_name;
      } else {
        console.error("Geolocation is not supported by this browser.");
        break;
      }
    }
    return displayCity;
  }
  const fetchservicos = useCallback(async () => {
    try {
      const url = new URL(`${baseURL}get_services`);
      url.searchParams.append('latitude', latitude);
      url.searchParams.append('longitude', longitude);
      if (filterName) url.searchParams.append('filterName', filterName);
      if (filterType) url.searchParams.append('filterType', filterType);
      if (filterCompany) url.searchParams.append('filterCompany', filterCompany);
      if (filterCompanyUnit) url.searchParams.append('filterCompanyUnit', filterCompanyUnit);
      if (filterProfessional) url.searchParams.append('filterProfessional', filterProfessional);
      if (filterPriceMin) url.searchParams.append('filterPriceMin', filterPriceMin);
      if (filterPriceMax) url.searchParams.append('filterPriceMax', filterPriceMax);
      url.searchParams.append('pagina', paginaAtual);
      url.searchParams.append('resultadosPorPagina', quantidadeExibida);
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error('Network response was not ok.');
      }
      const data = await response.json();
      if (data && Array.isArray(data.servicos)) {
        setservicos(data.servicos);
        setTotalServices(data.totalServicos);
      } else {
        setservicos([]);
        console.error("Formato de dados inválido na resposta da API");
      }
    } catch (error) {
      console.error("Error fetching servicos:", error);
    }
  }, [latitude, longitude, filterName, filterType, filterCompany, filterCompanyUnit, filterProfessional, filterPriceMin, filterPriceMax, paginaAtual, quantidadeExibida]);
  useEffect(() => {
    fetchservicos();
  }, [fetchservicos]);
  useEffect(() => {
    fetchCity();
  }, []);
  useEffect(() => {
    if (!filtersActive) {
      setFilterName('');
      setFilterType('');
      setFilterPriceMin('');
      setFilterPriceMax('');
      setFilterCompany('');
      setFilterCompanyUnit('');
      setFilterProfessional('');
      setPaginaAtual(1);
    }
  }, [filtersActive]);
  useEffect(() => {
    if (!filterName && !filterType && !filterCompany && !filterCompanyUnit && !filterProfessional && !filterPriceMin && !filterPriceMax) {
      setFiltersActive(false);
    } else {
      setFiltersActive(true);
    }
  }, [filterName, filterType, filterPriceMin, filterCompany, filterCompanyUnit, filterProfessional, filterPriceMax]);
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  const filteredServices = servicos.filter((servico) => {
    const nameMatch = filterName === '' || (servico.products_name || '').toLowerCase().includes(filterName.toLowerCase());
    const typeMatch = filterType === '' || servico.servicos_type === filterType;
    const priceMinMatch = filterPriceMin === '' || parseFloat(servico.value) >= parseFloat(filterPriceMin);
    const priceMaxMatch = filterPriceMax === '' || parseFloat(servico.value) <= parseFloat(filterPriceMax);
    const companyMatch = filterCompany === '' || servico.company_id === parseInt(filterCompany);
    const companyUnitMatch = filterCompanyUnit === '' || servico.company_unit_id === parseInt(filterCompanyUnit);
    const professionalMatch = filterProfessional === '' || servico.professional_id === parseInt(filterProfessional);
    return nameMatch && typeMatch && priceMinMatch && priceMaxMatch && companyMatch && companyUnitMatch && professionalMatch;
  });
  const location = useLocation();
  const isServicoPage = location.pathname === "/servicos";
  const handleAddToCart = (product) => {
    post_cart(product, cart, setCart, user_id, baseURL);
  };
  return (<>
    <section style={{ minHeight: '95dvh' }}>
      <InputPadraoSearch
        type="text"
        placeholder='Buscar serviços por nome...'
        id="filtro"
        value={filterName}
        onChange={(e) => {
          setFilterName(e.target.value);
        }}
      />
      <div id="mostraMaisOpcoesBusca" className={`collapse  align-items-center justify-content-center ${isExpanded ? 'show' : ''}`}>
        <div className="d-flex flex-wrap justify-content-center">
          <div className='flex-wapper col-md-3 mt-2'>
            <small>Localização cidade</small><br />
            <AutoComplete
              className="w-100 text-dark text-center"
              value={filterType}
              onChange={(value) => setFilterType(value)}
              placeholder="Selecione o Tipo"
            >
              {Array.from(new Set(servicos.map((servicos) => servicos.servicos_type))).map((servicos_type) => (
                <Option key={servicos_type} value={servicos_type}>
                  {servicos_type}
                </Option>
              ))}
            </AutoComplete>
          </div>
          <div className='flex-wapper col-md-3 mt-2'>
            <small>Tipo de profissional</small><br />
            <AutoComplete
              className="w-100 text-dark text-center"
              value={filterType}
              onChange={(value) => setFilterType(value)}
              placeholder="Selecione o Tipo"
            >
              {Array.from(new Set(servicos.map((servicos) => servicos.professional_id))).map((professional_id) => (
                <Option key={professional_id} value={professional_id}>
                  {professional_id}
                </Option>
              ))}
            </AutoComplete>
          </div>
          <div className='flex-wapper col-md-3 mt-2'>
            <small>Tipo de atendimento</small><br />
            <AutoComplete
              className="w-100 text-dark text-center"
              value={filterType}
              onChange={(value) => setFilterType(value)}
              placeholder="Selecione o Tipo"
            >
              {Array.from(new Set(servicos.map((servicos) => servicos.servicos_type))).map((servicos_type) => (
                <Option key={servicos_type} value={servicos_type}>
                  {servicos_type}
                </Option>
              ))}
            </AutoComplete>
          </div>
          <div className='flex-wapper col-md-3 mt-2'>
            <small>Tipo de serviço</small><br />
            <AutoComplete
              className="w-100 text-dark text-center"
              value={filterType}
              onChange={(value) => setFilterType(value)}
              placeholder="Selecione o Tipo"
            >
              {Array.from(new Set(servicos.map((servicos) => servicos.servicos_type))).map((servicos_type) => (
                <Option key={servicos_type} value={servicos_type}>
                  {servicos_type}
                </Option>
              ))}
            </AutoComplete>
          </div>
          <div className='flex-wapper col-md-3 mt-2'>
            <small>Empresa</small><br />
            <AutoComplete
              className="w-100 text-dark text-center"
              value={filterCompany}
              onChange={(value) => setFilterCompany(value)}
              placeholder="Selecione a Empresa"
            >
              {Array.from(new Set(servicos.map((servicos) => servicos.company_id))).map((company_id, index) => (
                <Option key={`${company_id}_company_id_${index}`} value={company_id}>
                  {company_id}
                </Option>
              ))}
            </AutoComplete>
          </div>
          <div className='flex-wapper col-md-3 mt-2'>
            <small>Empresa unidade</small><br />
            <AutoComplete
              className="w-100 text-dark text-center"
              value={filterCompanyUnit}
              onChange={(value) => setFilterCompanyUnit(value)}
              placeholder="Selecione a Empresa Unidade"
            >
              {Array.from(new Set(servicos.map((servicos) => servicos.company_unit_id))).map((company_unit_id, index) => (
                <Option key={`${company_unit_id}_company_unit_id_${index}`} value={company_unit_id}>
                  {company_unit_id}
                </Option>
              ))}
            </AutoComplete>
          </div>
          <div className='flex-wapper col-md-3 mt-2'>
            <small>Profissional</small><br />
            <AutoComplete
              className="w-100 text-dark text-center"
              value={filterProfessional}
              onChange={(value) => setFilterProfessional(value)}
              placeholder="Selecione o Profissional"
            >
              {Array.from(new Set(servicos.map((servicos) => servicos.professional_id))).map((professional_id) => (
                <Option key={professional_id} value={professional_id}>
                  {professional_id}
                </Option>
              ))}
            </AutoComplete></div>
          <div className='flex-wapper col-md-3 mt-2'>
            <small>Valor mínimo</small><br />
            <AutoComplete
              className="w-100 text-dark text-center"
              type="number"
              placeholder="a partir"
              value={filterPriceMin}
              onChange={(value) => setFilterPriceMin(value)}
            >
              {Array.from(new Set(servicos.map((servicos) => servicos.value))).map((value) => (
                <Option key={value} value={value}>
                  {value}
                </Option>
              ))}
            </AutoComplete>
          </div>
          <div className='flex-wapper col-md-3 mt-2'>
            <small>Valor máximo</small><br />
            <AutoComplete
              className="w-100 text-dark text-center"
              type="number"
              placeholder="até"
              value={filterPriceMax}
              onChange={(value) => setFilterPriceMax(value)}
            >
              {Array.from(new Set(servicos.map((servicos) => servicos.value))).map((value) => (
                <Option key={value} value={value}>
                  {value}
                </Option>
              ))}
            </AutoComplete>
          </div>
          <div className="col-md-6 text-center collapse">
            <div className="input-group-append col-md-12 mt-3 mx-auto">
              <input
                type="text"
                className="form-control mt-1 col-md-11 form-control-lg text-dark"
                placeholder='Buscar local'
                style={{ borderRadius: '20px 0px 0px 20px' }}
                id="inputEscolherLocal"
                value={""}
                onChange={''}
              />
              <div className="input-group-append"><button disabled className=" mt-1 btn btn-success" type="button" style={{ borderRadius: '0px 20px 20px 0px' }}><i
                className="fa fa-search text-dark mt-1"></i></button></div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-12" style={{ padding: '10px' }}>
        <div className='d-flex flex-wrap '>
          <div className={`input-group-append h-100 w-100 col-md-6`} style={{ padding: '0px', alignItems: 'center' }}>
            <h5 className=' text-left'>
              <ul className={`${isExpanded ? "text-danger " : 'text-primary'}`}
                ref={buttonRef}
                onClick={toggleExpand}
                style={{ cursor: "pointer", border: 'none', background: 'none', padding: 0, margin: 0, fontFamily: ' Arial, Helvetica' }}                             >
                {`${isExpanded ? 'Menos opções de filtros' : 'Mais opções de filtros'}`} ...<i className="fa fa-search flip-horizontal mr-2"></i>
              </ul>
            </h5>
          </div>
          <div className={`col-md-6     font-weight-bold `}
            style={{ padding: '0px' }}>
            <h5 className={` ${screenY > 1058 ? 'text-right' : ''}`}
              style={{ fontFamily: ' Arial, Helvetica' }}>Serviços encontrados:
              <b style={{ fontFamily: ' Arial, Helvetica' }}>{totalServices} </b> </h5> </div>
        </div>
      </div>    <ServicesCardList servicesData={servicos} onAddToCart={handleAddToCart} />
      {totalServices > quantidadeExibida && <><div className="col-md-12 font-weight-bold text-center mt-5 mb-3" style={{ fontFamily: ' Arial, Helvetica' }}>Total de páginas: {Math.ceil(filtersActive
        ? filteredServices.length / quantidadeExibida
        : totalServices / quantidadeExibida)}</div>
        <div className='d-flex text-center justify-content-center mb-5 '>
          <Pagination
            current={paginaAtual}
            total={totalServices}
            pageSize={quantidadeExibida}
            onChange={handlePageChange}
          />
        </div>
      </>}
      {isServicoPage && <>
        {/* Alerta */}
        <div className='py-2 mt-5 bg-light rounded align-items-center  col-md-10  mx-auto d-flex' >
          <i className="fa fa-heartbeat "></i>
          <h5 className=" col-md-12 text-justify text-dark"> <b >Podemos auxiliar com a indicação e verificação dos serviços mais adequados ao seu perfil dentro da plataforma; faça seu cadastro ou login para ter atendimento personalizado  </b></h5>
        </div>
      </>}
    </section>
  </>);
};
export default ServicosList;
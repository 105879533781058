export  function calcularVolumeLandmarks(landmarks) {
        landmarks.sort((a, b) => a.x - b.x);
        const slices = landmarks.length - 1;
        let volume = 0;
        for (let i = 0; i < slices; i++) {
            const width = landmarks[i + 1].x - landmarks[i].x;
            const height = landmarks[i + 1].y;
            volume += Math.PI * Math.pow(width / 2, 2) * height;
        }
        return volume;
    }
export const pdf_download_footer = (doc, qrCode, professionalInfo) => {
    doc.setFont('times', 'bold');
    doc.setFontSize(8);
    doc.text('CONTROLADORIA', 100, 275, { align: 'center' });
    doc.text('THOT I.A. CORP. - Inteligência Artifical Ilimitada', 100, 280, { align: 'center' });
    doc.text('Alto Araguaia - Mato Grosso - Brasil', 100, 285, { align: 'center' });
    doc.setTextColor(0, 0, 255);
    doc.text('https://thotiacorp.com.br', 100, 290, { align: 'center' });
    doc.setTextColor(0, 0, 0);
    doc.text('+55 66 996675486', 100, 295, { align: 'center' });
    doc.setFont('times', 'normal');
    doc.setFont('times', 'bold');
    doc.setFontSize(8);
    doc.text('EXAME (V-1.0.0)', 29, 275, { align: 'center' });
    const qrCodeData = 'https://thotiacorp.com.br';
    qrCode.toDataURL(qrCodeData, { errorCorrectionLevel: 'H' }, (err, qrCodeImage) => {
        if (err) {
            console.error(err);
            return;
        }
        doc.addImage(qrCodeImage, 'PNG', 19, 276, 20, 20);
    });
    doc.setTextColor(0, 0, 0);
    doc.setFont('times', 'normal');
    doc.setFont('times', 'bold');
    doc.setFontSize(8);
    doc.text('VALIDADOR', 170, 275, { align: 'center' });
    doc.text(professionalInfo.empresa, 170, 280, { align: 'center' });
    doc.text(professionalInfo.profissional, 170, 285, { align: 'center' });
    doc.setTextColor(0, 0, 255);
    doc.text(professionalInfo.enderecoEletronico, 170, 290, { align: 'center' });
    doc.setTextColor(0, 0, 0);
    doc.text(professionalInfo.telefone, 170, 295, { align: 'center' });
    doc.setFont('times', 'normal');
};
// ProductList.jsx
import React from 'react';
import ProductItem from './ProductItem';
export const ProductList = ({
  xmlData,
  setXmlData,
  productImages,
  handleSetProductImages,
  editedProductIndexes,
  handleEditProduct,
  handleSaveProduct,
  handleCancelEdit,
}) => {
  return (<>
    <ul>
      {xmlData.map((item, index) => (
        <li className=" mt-5" key={index}>
          {item.det && item.det.length > 0 && (
            <div>
              <h4 className='font-weight-bold text-center'>Detalhes dos Produtos da NFe</h4>
              {item.det.map((detItem, detIndex) => (
                <div key={detIndex}>
                  {detItem.prod && detItem.prod.length > 0 && (
                    <div>
                      <ul>
                        {detItem.prod.map((prodItem, prodIndex) => (
                          <li key={prodIndex}>
                            <div>
                            </div>
                            <ProductItem
                              productIndex={index}
                              subIndex={detIndex}
                              product={prodItem}
                              xmlData={xmlData}
                              setXmlData={setXmlData}
                              updateXmlData={(updatedXmlData) => {
                                setXmlData(updatedXmlData);
                              }}
                              productImages={productImages[index]?.[detIndex] || []}
                              handleSetProductImages={(images) => handleSetProductImages(index, detIndex, images)}
                              handleEditProduct={() => handleEditProduct(index, detIndex)}
                              handleSaveProduct={(editedProduct) => handleSaveProduct(editedProduct, setXmlData)}
                              handleCancelEdit={() => handleCancelEdit()}
                              inEditMode={editedProductIndexes && editedProductIndexes.productIndex === index && editedProductIndexes.subIndex === detIndex}
                            />
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </li>
      ))}
    </ul>
  </>);
};
export default ProductList;
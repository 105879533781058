import React from 'react';
const ProspeccaoClienteSelecionaInfo = () => {
  return (
    <div className="info">
      <h5><strong>Instruções para Prospecção de Clientes</strong></h5>
      <p>Utilize o formulário abaixo para realizar a prospecção de clientes com base nas suas preferências de venda.</p>
      <hr />  <br />  <br />  <br />  <br />
      <ol className='ml-3'>
        <li>Escolha a opção desejada em "Quero Vender" (Produtos, Procedimentos ou Ambos).</li>
        <li>Selecione o público-alvo em "Vender Para" (Público, Privado, Plano de Saúde ou Todos).</li>
        <li>No campo "Número de clientes", insira a quantidade de clientes que você deseja prospectar.</li>
        <li>No campo "Recorrência", insira a quantidade de dias de intervalo que você deseja prospectar novamente.</li>
        <li>Clique no botão "Procurar" para iniciar a prospecção com as suas escolhas. Certifique-se de que as informações estejam corretas antes de prosseguir.</li>
      </ol>
      <hr />  <br />  <br />  <br />  <br />
      <div className="results-info">
        <h5><strong>Resultados da Busca</strong></h5>
        <p>Após a busca, você verá a lista de clientes correspondentes às suas preferências de venda. Também serão exibidos os canais aos quais você pode se comunicar com esses clientes.</p>
      </div>
      <hr />  <br />  <br />  <br />  <br />
      <div className="info">
        <h5><strong>Inserção de Dados Básicos</strong></h5>
        <p>Antes de iniciar a prospecção, certifique-se de que os seguintes dados básicos estão atualizados e completos componentes abaixo:</p>
        <hr />  <br />  <br />  <br />  <br />
        <ol className='ml-3'>
          <li>Prospecção via e-mail: Certifique-se de que seu endereço de e-mail esteja correto e acessível, pois é um canal importante de comunicação.</li>
          <li>Prospecção via telefone: Verifique se o seu número de telefone de contato está atualizado para que os clientes possam entrar em contato com você.</li>
        </ol>
        <hr />  <br />  <br />  <br />  <br />
        <p>Ter esses dados atualizados ajudará a estabelecer uma comunicação eficaz com os clientes durante o processo de prospecção.</p>
      </div>
    </div>
  );
};
export default ProspeccaoClienteSelecionaInfo;
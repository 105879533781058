export const pdf_download_3 = (
    doc,
    imageData,
    points,
    pdf_download_footer,
    qrCode,
    professionalInfo,
    calcularVolumeLandmarks,
    imgWidthHalf,
    imgHeightHalf,
    imageX,
    imageY,
    imageXDir,
    imageYDir,
    linhaMediaPag,
    yEndVerticalLineMediaPag,
    textY,
    landmarks_position,
    modifiedLandmarks,
    projecaoMalarEsq,
    projecaoMalarDir
) => {
    doc.addPage();
    doc.addImage(require('../assets/fundoPDF.png'), 'PNG', 0, 0, doc.internal.pageSize.width, doc.internal.pageSize.height);
    // Página - 3 imagem 1 lado esquerdo
    doc.addImage(imageData, 'JPEG', imageX, imageY, imgWidthHalf, imgHeightHalf);
    doc.setLineWidth(0.2);
    doc.setDrawColor(255, 0, 0);
    const classes = ['class_8', 'class_9'];
    classes.forEach((className) => {
        if (modifiedLandmarks.FaceFrontal?.landmarks_face_parsing?.[className]) {
            const landmark = modifiedLandmarks.FaceFrontal.landmarks_face_parsing[className];
            if (landmark) {
                modifiedLandmarks.FaceFrontal.landmarks_face_parsing[className].forEach((landmark, index) => {
                    const x = 5 + landmark.x  * imgWidthHalf; // Calcula a coordenada X
                    const y = 30 + landmark.y  * imgHeightHalf; // Calcula a coordenada Y
                    doc.setDrawColor(255, 0, 0); // Cor da borda do círculo
                    doc.setFillColor(255, 0, 0); // Cor de preenchimento
                    doc.circle(x, y, 0.5, 'F'); // Círculo preenchido
                    doc.setFontSize(5);
                    doc.setTextColor(0, 0, 255);
                    // doc.text(`${index + 1}`, x, y);
                });
            }
        }
    })
    // console.log(doc.getFontList());
    doc.setLineWidth(0.2);
    doc.addImage(imageData, 'JPEG', imageXDir, imageYDir, imgWidthHalf, imgHeightHalf);
    doc.setTextColor(0, 0, 0);
    doc.setFontSize(12);
    doc.setFont('times', 'bold');
    doc.text(`Análises das orelhas: Implantação e Protusão`, 5, textY - 1);
    doc.setTextColor(0, 0, 0);
    doc.setFont('times', 'normal');
    doc.setFontSize(8);
    doc.text(`Orelhas podem apresentar falta de definição de anti-hélice e escafa;`, 5, textY + 8);
    doc.text(`Hipertrofia de conchas; Protrusão do lóbulo; Macrotia `, 5, textY + 11);
    doc.setFontSize(5);
    doc.text(`Ref: Proffit, W. R. Ortodontia Contemporânea (8ª ed.). Elsevier, 2018.`, 5, textY + 14);
    // Página - 3 imagem 1 lado esquerdo
    doc.addImage(imageData, 'JPEG', imageX, imageY + 115, imgWidthHalf, imgHeightHalf);
    doc.setLineWidth(0.2);
    doc.setDrawColor(255, 0, 0);
    doc.line(imageX, landmarks_position.y375 + 115, imageX + imgWidthHalf, landmarks_position.y375 + 115); // Iris inferior direita
    doc.line(imageX, landmarks_position.y9 + 115, imageX + imgWidthHalf, landmarks_position.y9 + 115); //Glabela
    doc.setLineWidth(0.2);
    // Página - 3 imagem 2 lado esquerdo
    doc.addImage(imageData, 'JPEG', imageXDir, imageYDir, imgWidthHalf, imgHeightHalf);
    doc.setTextColor(0, 0, 0);
    doc.setFontSize(12);
    doc.setFont('times', 'bold');
    doc.text(`Análises dos Terços Horizontais Médio - Olhos`, 5, textY + 115);
    doc.setFontSize(8);
    doc.setFont('times', 'normal');
    doc.text(`Os olhos devem estar implantados entre as linhas `, 5, textY + 119);
    doc.setTextColor(0, 0, 0);
    doc.setFont('times', 'normal');
    doc.setFontSize(12);
    // Página - 3 imagem 1 lado direito
    doc.setLineWidth(0.5);
    doc.setDrawColor(0, 0, 0);
    doc.line(linhaMediaPag, imageYDir, linhaMediaPag, yEndVerticalLineMediaPag + 5);
    doc.addImage(imageData, 'JPEG', imageXDir, imageYDir, imgWidthHalf, imgHeightHalf);
    doc.setLineWidth(0.2);
    doc.setDrawColor(255, 0, 0);
    doc.line(landmarks_position.x5, imageYDir, landmarks_position.x5, yEndVerticalLineMediaPag - 140); // Linha média
    doc.setLineWidth(0.2);
    doc.setDrawColor(255, 0, 0);
    const tamanhoNarizEsq = landmarks_position.x5 - landmarks_position.x49
    const tamanhoNarizDir = landmarks_position.x279 - landmarks_position.x5
    const distanciaIntercantalEsq = landmarks_position.x5 - landmarks_position.x134
    const distanciaIntercantalDir = landmarks_position.x363 - landmarks_position.x5
    const distanciaIntercantalExternaEsq = landmarks_position.x5 - landmarks_position.x248
    const distanciaIntercantalExternaDir = landmarks_position.x360 - landmarks_position.x5
    doc.line(landmarks_position.x49, landmarks_position.y49, landmarks_position.x279, landmarks_position.y279); // Base do nariz
    doc.line(landmarks_position.x134, landmarks_position.y134, landmarks_position.x363, landmarks_position.y363); // Intercantal
    doc.line(landmarks_position.x248, landmarks_position.y248 - 8, landmarks_position.x360, landmarks_position.y360 - 9); // Intercantal externo
    doc.line(landmarks_position.x248, landmarks_position.y248, landmarks_position.x248, landmarks_position.y248 - 8); // Intercantal externo
    doc.line(landmarks_position.x360, landmarks_position.y360, landmarks_position.x360, landmarks_position.y360 - 9); // Intercantal externo
    doc.setFont('times', 'bold');
    doc.setFontSize(8);
    doc.text(tamanhoNarizEsq.toString().substring(0, 3), landmarks_position.x49, landmarks_position.y49);
    doc.text(tamanhoNarizDir.toString().substring(0, 3), landmarks_position.x279 - 3, landmarks_position.y279);
    doc.text(distanciaIntercantalEsq.toString().substring(0, 3), landmarks_position.x134, landmarks_position.y134);
    doc.text(distanciaIntercantalDir.toString().substring(0, 3), landmarks_position.x363 - 3, landmarks_position.y363);
    doc.text(distanciaIntercantalExternaEsq.toString().substring(0, 4), landmarks_position.x248, landmarks_position.y248 - 8);
    doc.text(distanciaIntercantalExternaDir.toString().substring(0, 4), landmarks_position.x360 - 3, landmarks_position.y360 - 9);
    doc.setTextColor(0, 0, 0);
    doc.setLineWidth(0.1);
    doc.setDrawColor(255, 255, 255);
    doc.line(landmarks_position.x330, landmarks_position.y330, landmarks_position.x428, landmarks_position.y428); //Projeção malar dir.
    doc.line(landmarks_position.x341, landmarks_position.y341, landmarks_position.x428, landmarks_position.y428); //Projeção malar dir.          
    doc.line(landmarks_position.x330, landmarks_position.y330, landmarks_position.x341, landmarks_position.y341,); //Projeção malar dir.
    doc.line(landmarks_position.x117, landmarks_position.y117, landmarks_position.x208, landmarks_position.y208); //Projeção malar esq.
    doc.line(landmarks_position.x208, landmarks_position.y208, landmarks_position.x101, landmarks_position.y101); //Projeção malar esq.          
    doc.line(landmarks_position.x117, landmarks_position.y117, landmarks_position.x101, landmarks_position.y101,); //Projeção malar esq.
    doc.setFontSize(12);
    doc.setFont('times', 'bold');
    doc.text('Desvios da Linha Média - Olhos, Nariz e Projeção', imageXDir, textY - 1);
    doc.text('Malar com Volume', imageXDir, textY + 3);
    doc.setFont('times', 'normal');
    doc.setTextColor(255, 0, 0);
    doc.setFont('times', 'bold');
    doc.setFontSize(12);
    const volumeMalarEsq = calcularVolumeLandmarks(projecaoMalarEsq(points, imageX, imageY, imgWidthHalf, imgHeightHalf));
    const volumeMalarDir = calcularVolumeLandmarks(projecaoMalarDir(points, imageX, imageY, imgWidthHalf, imgHeightHalf));
    let textoEsq = volumeMalarEsq > volumeMalarDir ? "G" : "P";
    let textoDir = volumeMalarDir > volumeMalarEsq ? "G" : "P";
    doc.text(textoEsq, landmarks_position.x208 - 2, landmarks_position.y208 + 4);
    doc.text(textoDir, landmarks_position.x428 - 1, landmarks_position.y428 + 4);
    doc.setTextColor(0, 0, 0);
    doc.setFont('times', 'normal');
    doc.setFontSize(8);
    doc.text(`Para mimetizar um dos lados repare na cor das projeções malares. Em menor`, imageXDir, textY + 7);
    doc.text(`grau homogenize a cor da pele para espalhar a luz `, imageXDir, textY + 10);
    doc.setFontSize(5);
    doc.text(`Ref: Proffit, W. R. Ortodontia Contemporânea (8ª ed.). Elsevier, 2018. pag. 166 `, imageXDir, textY + 14);
    doc.setFontSize(8);
    // Página - 3 imagem 2 lado direito
    doc.addImage(imageData, 'JPEG', imageXDir, imageY + 115, imgWidthHalf, imgHeightHalf);
    doc.setLineWidth(0.5);
    doc.setDrawColor(0, 0, 0);
    doc.line(linhaMediaPag, imageYDir + 115, linhaMediaPag, yEndVerticalLineMediaPag - 25);
    doc.setLineWidth(0.2);
    doc.setDrawColor(255, 0, 0);
    doc.line(landmarks_position.x5, imageYDir + 115, landmarks_position.x5, yEndVerticalLineMediaPag - 25); // Linha média
    doc.setLineWidth(0.2);
    doc.setDrawColor(255, 0, 0);
    const tamanhoMentoEsq = landmarks_position.x5 - landmarks_position.x177 - imageXDir + 5
    const tamanhoMentoDir = landmarks_position.x401 - landmarks_position.x5
    const tamanhoBocaEsq = landmarks_position.x5 - landmarks_position.x44
    const tamanhoBocaDir = landmarks_position.x274 - landmarks_position.x5
    doc.line(landmarks_position.x274, imageYDir + 115, landmarks_position.x274, imageYDir + 85 + landmarks_position.y274); //boca dir. para cima
    doc.line(landmarks_position.x44, imageYDir + 115, landmarks_position.x44, imageYDir + 85 + landmarks_position.y44); //boca esq. para cima
    doc.line(landmarks_position.x274, imageYDir + 85 + landmarks_position.y274, landmarks_position.x44, imageYDir + 85 + landmarks_position.y44); //boca
    doc.line(landmarks_position.x401, imageYDir + 85 + landmarks_position.y401, landmarks_position.x177 + imageXDir - 5, imageYDir + 85 + landmarks_position.y177); //mento
    doc.text(tamanhoMentoDir.toString().substring(0, 3), landmarks_position.x401 - 3, landmarks_position.y401 + 115);
    doc.text(tamanhoMentoEsq.toString().substring(0, 3), landmarks_position.x177 + imageXDir - 5, landmarks_position.y177 + 115);
    doc.text(tamanhoBocaEsq.toString().substring(0, 3), landmarks_position.x44, landmarks_position.y44 + 115);
    doc.text(tamanhoBocaDir.toString().substring(0, 3), landmarks_position.x274 - 3, landmarks_position.y274 + 115);
    doc.setTextColor(0, 0, 0);
    doc.setFontSize(12);
    doc.setFont('times', 'bold');
    doc.text(`Desvio da Linha Média - Boca e Mento `, imageXDir, textY + 115);
    doc.setFontSize(8);
    doc.setFont('times', 'normal');
    doc.text('Em termos de harmonia facial, existe uma regra clássica de proporção que afir-', imageXDir, textY + 119);
    doc.text('ma que os cantos dos lábios (ou comissuras) devem estar alinhados com a dis-', imageXDir, textY + 122);
    doc.text('tância interna das íris dos olhos ou pupilas', imageXDir, textY + 125);
    doc.setFontSize(5);
    doc.text('Refs: PALUD Alice, et al. Management of otitis media with effusion in cleft palate children. Acta Otorhinolaryngologica Ita-', imageXDir, textY + 129);
    doc.text('lica, [S.l.], v. 29, n. 1, p. 38-42, 2009. Disponível em https://www.actaitalica.it/article/view/38', imageXDir, textY + 132);
    doc.text('Proffit, W. R. Ortodontia Contemporânea (8ª ed.). Elsevier, 2018. pag. 167', imageXDir, textY + 135);
    pdf_download_footer(doc, qrCode, professionalInfo);
};
import React, { useEffect, useState } from 'react';
import Footer from '../footer/Footer';
import { useLocation, useNavigate } from 'react-router-dom';
export const NavLateralComponents = ({ items }) => {
  const [currentItem, setCurrentItem] = useState(items[0]?.id || null);
  const [currentSubItem, setCurrentSubItem] = useState(null);
  const [currentNavigation, setCurrentNavigation] = useState(null);
  const refMap = {};
  const location = useLocation();
  const navigate = useNavigate();
  const handleItemClick = (itemId) => {
    if (currentItem !== itemId) {
      setCurrentItem(itemId);
      setCurrentSubItem(null);
      setCurrentNavigation(itemId);
      if (refMap[itemId]) {
        refMap[itemId].scrollIntoView({ behavior: 'smooth' });
      }
      // Construa a nova URL incluindo o parâmetro desejado
      const newUrl = `${location.pathname}#${itemId}${location.search}`;
      window.history.replaceState(null, '', newUrl);
      
      navigate(`${location.pathname}#${itemId}${location.search}`);
    }
  };
  const handleSubItemClick = (subItemId) => {
    setCurrentSubItem(subItemId);
    const subItem = items.find((item) => item.subItems.some((sub) => sub.id === subItemId));
    setCurrentNavigation(subItem?.title);
    if (screenWidth <= 569) {
      if (refMap[subItemId]) {
        refMap[subItemId].scrollIntoView({ behavior: 'smooth' });
      }
    }
    // Construa a nova URL incluindo o parâmetro desejado
    const newUrl = `${location.pathname}#${currentItem}#${subItemId}${location.search}`;
    window.history.replaceState(null, '', newUrl);
  };
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleWindowResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  return (
    <>
      {currentNavigation && null}
      <div className='col-md-12 mt-3' style={{ minHeight: "85vh", overflow: "hidden", padding: '0px' }}>
        <div className={`${screenWidth <= 569 ? "" : "row"}`}>
          <div className={`${screenWidth <= 1058 || location.pathname.includes('usuario')? "collapse" : 'col-md-2 '}`} style={{ padding: '0px' }}>
            <div className="nav flex-column nav-pills py-1 h-100" id="v-pills-tab" role="tablist" aria-orientation="vertical" style={{ backgroundColor: "#f0f0f0", borderRadius: '0.25em' }}>
              {items.map((item) => (
                <div className='ml-2 mr-1' key={item.id + item.title}>
                  <button
                    id={item.id}
                    className={`nav-link text-left w-100 ${currentItem === item.id ? 'active' : ''}`}
                    onClick={() => handleItemClick(item.id)}
                    role="tab"
                    style={{ border: '0px', padding: '5px', paddingRight: '5px', paddingLeft: '15px' }}
                  >
                    {item.title}
                  </button>
                  {currentItem && (
                    <p className='text-right mr-1' style={{ marginTop: '-33px' }}>
                      <i
                        className={`fas text-dark mt-1 ${item.subItems.length > 0 ? (currentItem === item.id ? 'fa-chevron-down ' : 'fa-chevron-right') : 'fa-circle'}`}
                        style={{ transform: 'scale(40%)', fontWeight: 0, marginRight: currentItem === item.id && item.subItems.length > 0 ? '1px' : '0px' }}></i>
                    </p>
                  )}
                  {currentItem === item.id && (
                    <p className='text-right mr-1' style={{ marginTop: '-40px' }}>
                      <i className={`fas text-white ${item.subItems.length > 0 ? (currentItem === item.id ? 'fa-chevron-down ' : 'fa-chevron-right') : 'fa-circle'}`} style={{ transform: 'scale(40%)' }}></i>
                    </p>
                  )}
                  {currentItem === item.id && item.subItems.length > 0 && (
                    <div className="ml-3 mt-2">
                      {item.subItems.map((subItem) => (
                        <button
                          key={subItem.id}
                          id={subItem.id}
                          ref={(el) => (refMap[subItem.id] = el)}
                          className={`nav-link text-left mt-1 mb-1 w-100 ${currentSubItem === subItem.id ? 'active' : ''}`}
                          onClick={() => handleSubItemClick(subItem.id)}
                          role="tab"
                          style={{ border: '0px', padding: '5px' }}
                        >
                          {subItem.title}
                          {currentSubItem === subItem.id ? (
                            <p className='text-right' style={{ marginTop: '-28px', marginBottom: '1px' }}>
                              <i className="fa fa-circle text-white mt-1 mr-1" style={{ scale: '30%' }}></i>
                            </p>
                          ) : (
                            <p className='text-right' style={{ marginTop: '-25px', marginBottom: '1px' }}>
                              <i className="fas fa-circle mt-1 text-dark mr-1" style={{ scale: '30%', marginRight: '-2px' }}></i>
                            </p>
                          )}
                        </button>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className={`${screenWidth <= 569 ? "" : location.pathname.includes('usuario') ? 'col-md-12' : 'col-md-10'}`}>
            <section>
              <p className={`${screenWidth <= 1058 ||  location.pathname.includes('usuario') ? "collapse" : ''}`} style={{ marginBottom: '10px' }}>
                {currentItem && items.find(item => item.id === currentItem)?.title}
                {currentSubItem && "/ "}
                {currentSubItem &&
                  items
                    .map(item => item.subItems)
                    .flat()
                    .find(subItem => subItem.id === currentSubItem)?.title
                }
              </p>
              {screenWidth >= 1058 && <hr style={{ marginTop: '-12px', marginBottom: '8px' }} />}
              {items.map((item) => (
                <div key={item.id} ref={(el) => (refMap[item.id] = el)} className={`tab-pane ${currentItem === item.id ? 'show' : 'collapse'} ${screenWidth <= 1058 ? "mt-3" : ''}`} role="tabpanel">
                  {currentSubItem === null && item.component && item.component}
                  {currentSubItem !== null && item.subItems.find((subItem) => subItem.id === currentSubItem)?.component}
                  {!location.pathname.includes('usuario') &&  <Footer />}
                </div>
              ))}
            </section>
          </div>
        </div>
      </div>
    </>
  );
};
export default NavLateralComponents;
import React, { useState } from 'react';
const CalculadoraTaxas = ({ productType, productValue }) => {
    const [taxRates] = useState({
        normal: 0.12,
        ecommerce: {
            singlePayment: 0.05,
            installment2to6: 0.0499,
            installment7to12: 0.0599,
        },
        boleto: 0.08,
        pix: 0.0099,
        debitCard: 0.026,
        creditCard: 0.03, // Base rate for credit card
        creditCardInstallments: {
            '2to6': 0.0499,
            '7to12': 0.0599,
        },
    });
    const calculateReducedAmount = (type, value) => {
        let taxRate = 0;
        switch (type) {
            case 'boleto':
                taxRate = taxRates.boleto;
                break;
            case 'pix':
                taxRate = taxRates.pix;
                break;
            case 'debitCard':
                taxRate = taxRates.debitCard;
                break;
            case 'creditCard':
                taxRate = taxRates.creditCard; // Base rate
                break;
            case 'creditCardInstallment2to6':
                taxRate = taxRates.creditCardInstallments['2to6'];
                break;
            case 'creditCardInstallment7to12':
                taxRate = taxRates.creditCardInstallments['7to12'];
                break;
            case 'ecommerceSinglePayment':
                taxRate = taxRates.ecommerce.singlePayment;
                break;
            case 'ecommerceInstallment2to6':
                taxRate = taxRates.ecommerce.installment2to6;
                break;
            case 'ecommerceInstallment7to12':
                taxRate = taxRates.ecommerce.installment7to12;
                break;
            default:
                taxRate = 0;
        }
        const reducedValue = (value - value * taxRates.normal) - (value * taxRate);
        return reducedValue.toFixed(2);
    };
    return (
        <div className='mt-2'>
            <small className='font-weight-bold text-dark'>Taxa de Transação: <br /></small>
            <small>As taxas são repassadas para os clientes de modo que amorteça as despesas do comércio</small>
            <br /> <br />
            <small className='font-weight-bold text-dark'>Taxas Nativas</small>  <br />
            <small className='text-dark'>E-commerce: {taxRates.ecommerce.singlePayment * 100}% à vista</small>
            <br /><br />
            <small className='font-weight-bold text-dark'>Taxas de 3º</small>  <br />
            <small className='text-dark'><strong>Boleto Pago:</strong> R$2,40</small><br />
            <small className='text-dark'><strong>PIX:</strong> {taxRates.pix * 100}%</small><br />
            <small className='text-dark'><strong>Cartão:</strong>
                <br />Débito {taxRates.debitCard * 100}%
                <br />De 2 a  6X – {taxRates.creditCardInstallments['2to6'] * 100}%
                <br />De 7 a 12X – {taxRates.creditCardInstallments['7to12'] * 100}%
                <br />+ R$0,60 (antifraude)</small> <br /><br />
            <small className='font-weight-bold text-dark'>Lucro total</small><br />
            <small>{calculateReducedAmount(productType, productValue)}</small><br />
        </div>
    );
};
export default CalculadoraTaxas;
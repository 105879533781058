import React, { useState } from 'react';
import { Tooltip, Button } from 'antd';
import { ReactComponent as RobotIcon } from './assets/help.svg'; // Substitua pelo caminho do seu arquivo SVG
export const ButtonHelp = (props) => {
  const [open, setOpen] = useState(false); // Renomeando 'visible' para 'open'
  const [iconColor, setIconColor] = useState('#fff'); // Cor padrão do ícone
  return (
    <div style={{ position: 'fixed', bottom: '10px', right: '-35px', zIndex: '1000' }}> {/* Mudando para a direita */}
      <Tooltip
        title="Precisa de ajuda? Clique"
        open={open}
        onMouseEnter={() => { setOpen(true); setIconColor('#000'); }} // Abre o Tooltip e muda a cor ao passar o mouse
        onMouseLeave={() => { setOpen(false); setIconColor('#fff'); }} // Fecha o Tooltip e restaura a cor ao remover o mouse
      >
        <Button
          shape="circle"
          className='btn-success'
          style={{
            color: { iconColor },
            border: 'none',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2), inset 0 -4px 5px rgba(255, 255, 255, 0.4)', // Sombra externa e interna
            transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
            width: '80px', // Ajuste o tamanho do botão aqui
            height: '80px', // Ajuste o tamanho do botão aqui
          }}
          onClick={props.onClick}
        >
          <RobotIcon width="30" height="30" style={{ marginRight: '15px' }} /> {/* Controle do tamanho do SVG */}
        </Button>
      </Tooltip>
    </div>
  );
};
export default ButtonHelp;
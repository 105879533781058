export const fetchProfessionalImage = async (professional_id, setImageLogoAtiva) => {
  const imageUrls = JSON.parse(localStorage.getItem('drive'));
  try {
    if (professional_id) {
      const imageUrl = imageUrls.imageUrls[0]; // Assumindo que você está interessado apenas na primeira URL da lista
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const imageUrlBlob = URL.createObjectURL(blob);
      setImageLogoAtiva(imageUrlBlob);
      return;
    }
  } catch (error) {
    console.error(error);
    return null;
  }
};
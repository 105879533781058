import React from "react";
import '../styles/DispercaoManual.css'
const DadosDosProdutos = () => {
    return (
        <div className="principalDispencao">
            <div className="div-title1">
                <h2 className="title1">PARA DISPENSAÇÃO MANUAL</h2>
            </div>
            <div className="containerDispDiv">
                <p>DATA: </p>
                <p>ASSINATURA DO COMPRADOR: </p>
                <p>ASSINATURA DO FARMACÊUTICO: </p>
            </div>
        </div>
    );
    }
    export default DadosDosProdutos;
// utils/validateForm.js
export const validateForm = (formValues, setError, IsValidEmail) => {
  const { name, cpf, validationResponse, dataNascimento, email, phone, password, confirmPassword, aceitouTermo } = formValues;
  if (!name.trim()) {
    return { message: 'Por favor, insira um nome!', field: 'name' };
  }
  if (!cpf.trim() || validationResponse !== 'VÁLIDO') {
    return { message: 'Por favor, insira um CPF válido!', field: 'cpf' };
  }
  if (!dataNascimento.trim()) {
    return { message: 'Por favor, insira a data de nascimento!', field: 'dataNascimento' };
  }
  if (!email.trim() || !IsValidEmail(email)) {
    return { message: 'Por favor, insira um e-mail válido!', field: 'email' };
  }
  if (!phone.trim()) {
    return { message: 'Por favor, insira um telefone!', field: 'phone' };
  }
  if (!password.trim()) {
    return { message: 'Por favor, insira uma senha!', field: 'password' };
  }
  if (password !== confirmPassword) {
    return { message: 'As senhas não são iguais!', field: 'confirmPassword' };
  }
  if (!aceitouTermo) {
    return { message: 'Você deve aceitar os termos de responsabilidade!', field: 'aceitouTermo' };
  }
  return null;
};
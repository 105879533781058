export function generateTablePDF(doc, materials) {
                const headers = ['#', 'Nome', 'Quantidade', 'Unidade', 'Descrição'];
                const rowHeight = 6; // Altura das linhas
                const margin = 2; // Margem entre o texto e a linha
                let y = 20//                   ela começa
                // Definindo larguras das colunas
                const columnWidths = [5, 65, 30, 30, 50];
                // Cabeçalho da Tabela com fundo colorido
                const headerBgColor = [0, 123, 255]; // Cor de fundo do cabeçalho (azul)
                doc.setTextColor(255, 255, 255); // Cor do texto (branco)
                headers.forEach((header, i) => {
                    const xPos = 15 + columnWidths.slice(0, i).reduce((a, b) => a + b, 0);
                    doc.setFillColor(...headerBgColor); // Define a cor de fundo do cabeçalho
                    doc.rect(xPos, y, columnWidths[i], rowHeight, 'F'); // Desenha o fundo do cabeçalho
                    doc.text(header, xPos + margin, y + rowHeight / 2 + margin); // Texto do cabeçalho
                });
                // Dados da Tabela
                materials.forEach((material, index) => {
                    const row = [index + 1, material.name, material.quantity, material.unit, material.desc];
                    const rowY = y + rowHeight; // Posição Y de cada linha de dados
                    // Alternando cores de fundo para as linhas
                    const isEvenRow = index % 2 === 0;
                    const rowBgColor = isEvenRow ? [240, 240, 240] : [255, 255, 255]; // Linhas alternadas com fundo claro
                    // Desenha as células
                    row.forEach((text, i) => {
                        const xPos = 15 + columnWidths.slice(0, i).reduce((a, b) => a + b, 0);
                        doc.setFillColor(...rowBgColor); // Cor de fundo da célula
                        doc.rect(xPos, rowY, columnWidths[i], rowHeight, 'F'); // Fundo da célula
                        doc.setTextColor(0, 0, 0); // Cor do texto (branco)
                        doc.text(String(text), xPos + margin, rowY + rowHeight / 2 + margin); // Texto na célula
                    });
                    y += rowHeight; // Atualiza a posição Y para a próxima linha
                });
            }
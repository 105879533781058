import { Image } from "antd";
export const renderImagesApi = (apiImages, type) => {
  const images = apiImages[type];
  if (!images) return <p>Nenhuma imagem disponível para "{type}"</p>;
  if (typeof images === 'object' && !Array.isArray(images)) {
    return (
      <div>
        <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap', justifyContent:'center', alignItems: 'center' }}>
          {Object.entries(images).map(([key, value], index) => (
            <Image
              key={index}
              src={value}
              alt={`${key} do tipo ${type}`}
              style={{
                width: '100px',
                height: '100px',
                objectFit: 'cover',
                border: '1px solid #ccc',
              }}
            />
          ))}
        </div>
      </div>
    );
  }
  // Caso seja um array
  if (Array.isArray(images)) {
    return (
      <div>
        <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap',justifyContent:'center',  alignItems: 'center' }}>
          {images.map((img, index) => (
            <Image
              key={index}
              src={img}
              alt={`Imagem ${index + 1} do tipo ${type}`}
              style={{
                width: '100px',
                height: '100px',
                objectFit: 'cover',
                border: '1px solid #ccc',
              }}
            />
          ))}
        </div>
      </div>
    );
  }
  // Caso seja uma string (uma única imagem)
  return (
    <div>
      <Image
        src={images}
        alt={`Imagem do tipo ${type}`}
        style={{
          width: '150px',
          height: '150px',
          objectFit: 'cover',
          border: '1px solid #ccc',
        }}
      />
    </div>
  );
};
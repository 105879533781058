import React from 'react'
import { FooterProfissional, Nav, NavProfissionalMain } from '@components';
export const ProdutosVendidos = () => {
    return (<>
        <Nav />
        <div className="d-flex mt-3">
            <NavProfissionalMain />
            <div className="w-100" >
                <FooterProfissional />
            </div>
        </div>
    </>)
}
export default ProdutosVendidos;
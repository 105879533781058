import { NavUserMain, NavBreadcrumb, FooterUser, InputPadraoSearch, Nav } from '@components';
export const ConsultasMarcadas = () => {
    return (<>
        <Nav />
        <div className="d-flex mt-3">
            <NavUserMain />
            <div className='w-100'>
                <NavBreadcrumb text={'Consultas Marcadas'} icon={'location-arrow fa'} />
                <div className="py-3">
                    <InputPadraoSearch />
                    <div className="text-center">
                        <div className="container-fluid">
                            <div className="row d-inline-flex justify-content-center w-100">
                            </div>
                        </div>
                    </div>
                </div>
                <FooterUser />
            </div>
        </div>
    </>)
}
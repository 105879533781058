import React from 'react';
const ProntuarioAlergiasInfo = () => {
  return (
    <div>
      <h5>Usando o Componente <strong> Prontuario Alergias</strong></h5>
      <p>
        O componente <strong> Prontuario Alergias</strong> é projetado para ajudá-lo a gerenciar informações sobre alergias. Ele fornece uma interface fácil de usar para adicionar, editar e remover alergias. Abaixo, você encontrará um guia passo a passo sobre como usar este componente.
      </p>
      <hr />
      <ol className='ml-3'>
        <li><strong> Marcar se tem alergia</strong></li>
        <p>
          Quando você abre o componente, você verá a pergunta "Tem algum tipo de alergia?" e três opções: "Sim," "Não" e "Não Sei." Marque a opção correspondente para indicar se você tem alergia.
        </p>
        <li><strong> Adicionar alergias</strong></li>
        <p>
          Se você marcar "Sim," uma seção adicional aparecerá onde você pode adicionar alergias. Digite o nome da alergia no campo de texto abaixo de "Se Sim, quais?" e clique no botão "Adicionar Alérgia" para inserir a alergia na lista.
        </p>
        <li><strong> Editar alergias</strong></li>
        <p>
          Se você quiser editar uma alergia existente na lista, clique no botão "Editar" ao lado dela. Isso preencherá o campo de texto com o nome da alergia para edição. Faça as alterações necessárias e clique no botão "Adicionar Alérgia" para salvar as alterações.
        </p>
        <li><strong> Remover alergias</strong></li>
        <p>
          Se você deseja remover uma alergia da lista, clique no botão "Remover" ao lado dela. A alergia será excluída da lista.
        </p>
        <li><strong> Finalização</strong></li>
        <p>
          Depois de adicionar ou editar alergias, você pode continuar usando o componente conforme necessário. Quando terminar, você pode clicar no botão "ADICIONAR ALERGIA" no final do componente para concluir o processo.
        </p>
      </ol>
      <hr />
      <p>
        Agora você está pronto para usar o componente <strong> Prontuario Alergias</strong> para gerenciar suas alergias de forma fácil e conveniente. Certifique-se de marcar as alergias corretamente e de adicionar/editar/remover alergias conforme necessário.
      </p>
      <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />
    </div>
  );
};
export default ProntuarioAlergiasInfo;
import React from 'react';
export const InvitationCard = ({
  photo,
  user_image,
  name,
  invitation_date,
  location,
  status,
  id,
  owner_user_id,
  owner_professional_id,
  owner_professional_unit_id,
  owner_company_id,
  owner_company_unit_id,
  state,
  user_id,
  professional_id,
  company_unit_id,
  token,
  expiry_date,
  deleteInvitation,
  blockedInvitation,
  acceptInvitation,
  invitation,
  message
}) => {
  const handleAccept = async () => {
    const shouldAccept = window.confirm("Tem certeza de que deseja aceitar este convite?");
    if (shouldAccept) {
      await acceptInvitation(id); // Chama a função acceptInvitation com o ID do convite   
    }
  };
  const handleDelete = async () => {
    const shouldDelete = window.confirm("Tem certeza de que deseja deletar este convite?");
    if (shouldDelete) {
      await deleteInvitation(id);
    }
  };
  const handleBlock = async () => {
    const shouldAccept = window.confirm("Tem certeza de que deseja bloquear este convite e toda cadeia dele?");
    if (shouldAccept) {
      await blockedInvitation(id); // Chama a função acceptInvitation com o ID do convite   
    }
  };
  return (
// Restante do código mantido para foco na parte específica da div
<div className={`mt-1 rounded ${state === "deleted" ? 'bg-warning' : state === "blocked" ? 'bg-danger' : state === "active" ? 'bg-success' : 'bg-light'}`}>
      <div className="col-md-12 d-flex flex-wrap justify-content-center">
        <div className="col-md-2 mt-2 mb-2 d-flex justify-content-center align-items-center mx-auto">
          <img className='rounded'
            src={invitation}
            alt="Imagem do Convite"
            style={{ maxWidth: '100%', height: 'auto', alignItems: 'center' }} />
        </div>
        <div className='col-md-8 text-left'>
          <h5 className="card-title">{name}</h5>
          <small>Date: {invitation_date}</small>
          <br />
          <>
            {location && <li>Location: {location}</li>}
            {status && <li>Status: {status}</li>}
            <ul>De:
              {owner_user_id && <li>Owner User ID: {owner_user_id}</li>}
              {owner_professional_id && <li>Owner Professional ID: {owner_professional_id}</li>}
              {state && <li>State: {state}</li>}
              {owner_professional_unit_id && <li>Owner Professional Unit ID: {owner_professional_unit_id}</li>}
              {owner_company_id && <li>Owner Company ID: {owner_company_id}</li>}
              {owner_company_unit_id && <li>Owner Company Unit ID: {owner_company_unit_id}</li>}
            </ul>
            Para:
            {user_id && <li>User ID: {user_id}</li>}
            {professional_id && <li>Professional ID: {professional_id}</li>}
            {company_unit_id && <li>Ingresso na Company Unit ID: {company_unit_id}</li>}
            {expiry_date && <li>Expiry Date: {expiry_date}</li>}
            {message && <li>Mensagem: {message}</li>}
          </>
        </div>
        <div className='col-md-2'>
          <button className="w-100 mt-2 btn btn-primary" onClick={handleAccept}>Aceitar</button>
          <button className="w-100 mt-2 btn btn-warning" onClick={handleDelete}>Recusar</button>
          <button className='w-100 mt-2 mb-2 btn btn-danger' onClick={handleBlock}> Bloquear usuário </button>
        </div>
      </div>
    </div>
  );
};
export default InvitationCard;
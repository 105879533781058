import React, { useState, useEffect } from 'react';
import { InputPadraoSearch, NavBreadcrumb, TitleL2 } from '@components';
import { api } from '@services';
import { Input } from 'antd';
import GetUsers from '../../Usuarios/Search/GetUsers';
const fetchProdutos = async () => {
    try {
        const response = await api.get(`get_products`);
        const produtosComRatio = response.data.produtos.map(produto => ({
            ...produto,
            recorrencia: false,
        }));
        return produtosComRatio;
    } catch (error) {
        console.error('Erro ao buscar produtos:', error);
        return [];
    }
};
export const Index = () => {
    const [carrinho, setCarrinho] = useState([]);
    const [quantidades, setQuantidades] = useState({});
    const [buscaProduto, setBuscaProduto] = useState('');
    const [produtos, setProdutos] = useState([]);
    const [produtosFiltrados, setProdutosFiltrados] = useState([]);
    const [vendasRecurrentes, setVendasRecurrentes] = useState(false);
    useEffect(() => {
        const getProdutos = async () => {
            const produtos = await fetchProdutos();
            setProdutos(produtos);
            setProdutosFiltrados(produtos);
        };
        getProdutos();
    }, []);
    const adicionarAoCarrinho = (produto) => {
        const novoCarrinho = [...carrinho];
        const index = novoCarrinho.findIndex((item) => item.id === produto.id);
        if (index !== -1) {
            novoCarrinho[index] = {
                ...novoCarrinho[index],
                quantidade: novoCarrinho[index].quantidade + 1,
            };
        } else {
            novoCarrinho.push({ ...produto, quantidade: 1, recorrencia: vendasRecurrentes });
        }
        setCarrinho(novoCarrinho);
        atualizarQuantidade(produto.id, 1);
    };
    const removerDoCarrinho = (produtoId) => {
        const novoCarrinho = carrinho.filter((item) => item.id !== produtoId);
        const quantidadeRemovida = quantidades[produtoId];
        setCarrinho(novoCarrinho);
        atualizarQuantidade(produtoId, -quantidadeRemovida);
    };
    const atualizarQuantidade = (produtoId, quantidade) => {
        const novaQuantidade = {
            ...quantidades,
            [produtoId]: (quantidades[produtoId] || 0) + quantidade,
        };
        setQuantidades(novaQuantidade);
    };
    const handleVendaRecorrente = (produtoId, isRecorrente) => {
        const novoCarrinho = carrinho.map((item) =>
            item.id === produtoId ? { ...item, recorrencia: isRecorrente } : item
        );
        setCarrinho(novoCarrinho);
    };
    const marcarTodosComoRecorrentes = (e) => {
        const isChecked = e.target.checked;
        const novoCarrinho = carrinho.map((item) => ({
            ...item,
            recorrencia: isChecked,
        }));
        setCarrinho(novoCarrinho);
        setVendasRecurrentes(isChecked);
    };
    const calcularTotal = () => {
        return carrinho.reduce((total, produto) => {
            return total + produto.value * produto.quantidade;
        }, 0).toFixed(2);
    };
    const finalizarCompra = () => {
        alert(`Compra finalizada por Total: R$ ${calcularTotal()}`);
        setCarrinho([]);
        setQuantidades({});
    };
    const handleBuscaProduto = (e) => {
        setBuscaProduto(e.target.value);
        const termo = e.target.value.toLowerCase();
        const filtrados = produtos.filter((produto) =>
            produto.products_name.toLowerCase().includes(termo) ||
            produto.id.toString().includes(termo)
        );
        setProdutosFiltrados(filtrados);
    };
    const handleQuantidadeChange = (e, produtoId) => {
        const novaQuantidade = parseInt(e.target.value) || 0;
        const diferenca = novaQuantidade - (quantidades[produtoId] || 0);
        const novoCarrinho = [...carrinho];
        const index = novoCarrinho.findIndex((item) => item.id === produtoId);
        if (index !== -1) {
            novoCarrinho[index] = {
                ...novoCarrinho[index],
                quantidade: novaQuantidade,
            };
        }
        setCarrinho(novoCarrinho);
        atualizarQuantidade(produtoId, diferenca);
    };
    return (
        <>
            <div className="w-100">
                <NavBreadcrumb icon={'suitcase fa'} text={'Serviços'} />
                <GetUsers
                    title="Selecionar cliente"
                />
                <div className="row d-inline-flex justify-content-center w-100">
                    <div className="col-md-6 mt-3">
                        <TitleL2>Selecionar itens <i className='fa fa-concierge-bell '></i> <i className='fa fa-cubes'></i> </TitleL2>
                        <InputPadraoSearch
                            type="text"
                            placeholder="Buscar por nome ou código"
                            value={buscaProduto}
                            onChange={handleBuscaProduto}
                        />
                        <label htmlFor="vendasRecorrentes">
                            <Input
                                id="vendasRecorrentes"
                                type="checkbox"
                                checked={vendasRecurrentes}
                                onChange={marcarTodosComoRecorrentes}
                            />
                            <small> - Ativar vendas recorrentes</small>
                        </label>
                        <ul className="list-group">
                            {Array.isArray(produtosFiltrados) &&
                                produtosFiltrados.map((produto) => (
                                    <li key={produto.id} className="d-flex justify-content-between align-items-center">
                                        <ul className='col-md-4' style={{ padding: '0px' }}>{produto.products_name} -</ul>
                                        <u>R$ {parseFloat(produto.value).toFixed(2)}</u>
                                        <Input
                                            type="number"
                                            placeholder="Quantidade"
                                            style={{ width: '100px' }}
                                            value={quantidades[produto.id] || 0}
                                            onChange={(e) => handleQuantidadeChange(e, produto.id)}
                                        />
                                        <Input
                                            type="radio"
                                            checked={produto.recorrencia}
                                            onChange={() => handleVendaRecorrente(produto.id, !produto.recorrencia)}
                                        />
                                        <button
                                            className="btn btn-outline-secondary btn-sm ml-2"
                                            onClick={() => adicionarAoCarrinho(produto)}
                                        >
                                            Adicionar ao carrinho
                                        </button>
                                    </li>
                                ))}
                        </ul>
                    </div>
                    <div className="col-md-6 mt-3">
                        <div className="row justify-content-center ">
                            <TitleL2>Itens selecionados <i className='fa fa-cart-plus ml-2'></i></TitleL2>
                            <ul className="list-group">
                                {carrinho.map((produto) => (
                                    <li key={produto.id} className="d-flex justify-content-between align-items-center">
                                        {produto.products_name} (x{produto.quantidade}) - R$ {(produto.value * produto.quantidade).toFixed(2)} -
                                        {produto.recorrencia ? 'Recorrente' : 'Não Recorrente'}
                                        <button
                                            className="btn btn-danger btn-sm"
                                            onClick={() => removerDoCarrinho(produto.id)}
                                        >
                                            Remover
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <p>Total: R$ {calcularTotal()}</p>
                        <p className='text-center'>
                            <button
                                className="btn btn-success"
                                onClick={finalizarCompra}
                            >
                                Finalizar Venda
                            </button>
                        </p>
                    </div>
                </div>
            </div>
        </>);
};
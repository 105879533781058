export const pdf_download_15 = (
    doc,
    pdf_download_footer,
    qrCode,
    professionalInfo,
) => {
    if (
        !doc ||
        !pdf_download_footer ||
        !qrCode ||
        !professionalInfo
    ) {
        console.warn("Parâmetros ausentes, página não adicionada.");
        return;
    }
    doc.addPage();
    doc.addImage(require('../assets/fundoPDF.png'), 'PNG', 0, 0, doc.internal.pageSize.width, doc.internal.pageSize.height);
    doc.setFont('times', 'bold');
    doc.setFontSize(25);
    doc.text('Recomendações ao Paciente: Botox', 100, 15, { align: 'center' });
    let yInic = 30; // Posição inicial para o texto
    const instructions = [
        "Evite massagear ou manipular com firmeza a área tratada, pois isso pode facilitar a disseminação do produto para outro músculo.",
        "Não deite na posição horizontal por um período de 2 a 4 horas, pois isso também pode facilitar a disseminação do produto para outro músculo.",
        "Evite compressas de gelo no local, pois elas podem interferir na migração da toxina.",
        "Evite exercício físico e atividades estressantes pelo menos até o final do dia. Já a corrida ou o exercício em academia podem ser realizados após 24 horas.",
        "Faça mímicas, franza o rosto e faça as caretas costumeiras, principalmente nas primeiras horas, nos locais em que a toxina está mais atuante, a fim de melhorar o efeito da mesma.",
        "Após a aplicação de TBA nas axilas, deve-se evitar sauna, ambientes muito quentes ou exercícios físicos mais intensos por um período de 24 a 48 horas.",
        "Tenha clareza de que os efeitos só começarão a aparecer com 3 a 7 dias após a aplicação, com o melhor resultado entre 15 e 21 dias.",
        "Retorne ao consultório 15 dias após a aplicação para verificação do efeito e/ou possível correção de algum efeito adverso. Antes dessa data, não é indicada qualquer correção.",
        "Tenha clareza de que, para continuidade do efeito, a aplicação deve ser realizada 2 ou até 3 vezes por ano se a musculatura tratada for muito hipercinética.",
        "Evite viagem aérea no mesmo dia da aplicação, pois a flutuação na pressão dentro da aeronave pode promover a difusão da toxina.",
        "Evite fazer uso de medicamentos que interfiram na junção neuromuscular pelos diferentes mecanismos e que possam interagir com a TBA, como bloqueadores do canal de cálcio, antibióticos do grupo dos aminoglicosídios, ciclosporina, cloroquina, hidroxicloroquina, D-penicilamina. Evite também o uso de anti-inflamatórios não esteroides.",
        "Evite reaplicação da TBA em um período igual ou menor que 3 meses para prevenir a possibilidade de formação de anticorpos neutralizantes.",
        "A análise do antes e depois deve sempre ser feita para confirmar a melhora e possibilitar tirar qualquer dúvida que se possa vir a ter."
    ];
    instructions.forEach((instruction, index) => {
        doc.setFont('times', 'bold');
        doc.setFontSize(12);
        doc.text(`${index + 1}.`, 15, yInic);
        doc.setFont('times', 'normal');
        const splitText = doc.splitTextToSize(instruction, 170);
        splitText.forEach(line => {
            doc.text(line, 25, yInic);
            yInic += 5; // Ajuste a distância conforme necessário
        });
        yInic += 3.5; // Espaço extra de 5 unidades entre as instruções
    });
    doc.setFontSize(5);
    doc.text('Ayres, E. L., & Sandoval, M. H. L. (2016). Toxina botulínica na dermatologia: guia prático de técnicas e produtos. 1. ed. Rio de Janeiro: Guanabara Koogan.', 100, 265, { align: 'center' });
    pdf_download_footer(doc, qrCode, professionalInfo);
};
import React, { useState } from 'react';
export const TermoIAText = () => {
  const [aceitouTermo, setAceitouTermo] = useState(false);
  const handleAceitarTermo = () => {
    setAceitouTermo(true);
  };
  return (
    <div>
      <h5 className='font-weight-bold'>Termo de Responsabilidade</h5>
      <p>
        Este termo de responsabilidade estabelece os termos e condições para a utilização dos serviços da THOT I.A. CORP. e a responsabilidade do cliente ao criar e utilizar I.As (Inteligências Artificiais) através de nossos serviços. Ao aceitar este termo, o cliente concorda com os seguintes pontos:
      </p>
      <ol >
        <li>
          O cliente é o único responsável pela criação, treinamento e uso das I.As, respeitando as leis vigentes do Brasil, como o Marco Civil da Internet (Lei nº 12.965/2014), e as leis dos Estados Unidos, como o Digital Millennium Copyright Act (DMCA).
        </li>
        <li>
          A THOT I.A. CORP. não exerce controle sobre as decisões tomadas pelas I.As criadas pelo cliente, e não será responsável por quaisquer consequências decorrentes de suas ações.
        </li>
        <li>
          O cliente compromete-se a utilizar as I.As de forma ética e legal, evitando violações de privacidade, difamação, assédio ou qualquer conduta prejudicial.
        </li>
        <li>
          Em caso de disputas judiciais relacionadas ao uso das I.As, o cliente concorda em isentar a THOT I.A. CORP. de qualquer responsabilidade legal e indenizar a empresa por quaisquer danos ou custos incorridos.
        </li>
      </ol>
      <hr/>
      <h5 className='font-weight-bold'>Posições da THOT I.A. CORP. sobre Uso Ético e Legal</h5>
      <p>A THOT I.A. CORP. está empenhada em fornecer tecnologias avançadas de inteligência artificial para melhorar diversos aspectos da vida humana e empres Arial, Helvetica. No entanto, essa inovação traz consigo uma grande responsabilidade ética e legal.</p>
      <ol >
        <li><strong>Responsabilidade do Usuário:</strong> A THOT I.A. CORP. enfatiza que seus produtos e serviços devem ser usados em conformidade com as leis e regulamentos aplicáveis.</li>
        <li><strong>Ética e Uso Legal:</strong> Os usuários são incentivados a evitar o uso abusivo das tecnologias da THOT I.A. CORP., incluindo a disseminação de conteúdo prejudicial ou ilegal.</li>
        <li><strong>Transparência e Identificação:</strong> A transparência é valorizada, e os usuários devem identificar quando estão interagindo com uma I.A. e não com um ser humano.</li>
        <li><strong>Colaboração com Autoridades Jurídicas:</strong> A empresa colaborará com autoridades legais em casos de uso abusivo de suas tecnologias, fornecendo informações e registros conforme necessário.</li>
      </ol>
      <hr/>
      {/* Embasamento Legal */}
      <h5 className='font-weight-bold'>Embasamento Legal e Jurisprudência</h5>
      <p>As posições da THOT I.A. CORP. são embasadas por leis e jurisprudências de diversos países. Alguns exemplos incluem:</p>
      <ol >
        <li>Lei Geral de Proteção de Dados (LGPD) - Brasil</li>
        <li>California Consumer Privacy Act (CCPA) - Estados Unidos</li>
        <li>Directive on Copyright in the Digital Single Market - União Europeia</li>
      </ol>     
      <div className='text-center'>
        <label>
          <input
            type="checkbox"
            defaultChecked={aceitouTermo}
            onChange={handleAceitarTermo}
          />
          Eu aceito os termos de responsabilidade.
        </label>
      </div>
      {aceitouTermo && (
        <div className="alert alert-success mt-2">
          Termo aceito. O cliente assume total responsabilidade pelas I.As criadas e utilizadas.
        </div>
      )}
    </div>
  );
};
export default TermoIAText;
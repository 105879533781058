import React, { useEffect, useRef } from "react";
import { Chart } from "chart.js/auto";
import { MaisInfoMenosInfo } from "@components";
import MontarGraficosDinamicosInfo from "../info/MontarGraficosDinamicosInfo";
export function MontarGraficosDinamicos(props) {
  const chartRefs = useRef([]);
  useEffect(() => {
    if (chartRefs.current.length === 0 && props.responses) {
      props.questions.forEach((question, index) => {
        const response = props.responses[index].response;
        const columnName = question.coluna;
        const dataByColumn = {};
        response.forEach((item) => {
          const value = item[columnName];
          if (value) {
            if (!dataByColumn[columnName]) {
              dataByColumn[columnName] = {};
            }
            if (!dataByColumn[columnName][value]) {
              dataByColumn[columnName][value] = 0;
            }
            dataByColumn[columnName][value]++;
          }
        });
        if (
          dataByColumn[columnName] &&
          Object.keys(dataByColumn[columnName]).length > 0
        ) {
          const chartContainer = document.getElementById("chartContainer");
          const chartRef = document.createElement("canvas");
          const chartDiv = document.createElement("div");
          chartDiv.classList.add("col-md-6");
          chartDiv.appendChild(chartRef);
          chartContainer.appendChild(chartDiv);
          const ctx = chartRef.getContext("2d");
          const sortedData = Object.entries(dataByColumn[columnName]).sort(
            (a, b) => b[1] - a[1]
          );
          const labels = sortedData.map(([label]) => label);
          const values = sortedData.map(([_, value]) => value);
          const colorMap = {};
          values.forEach((count) => {
            if (!colorMap[count]) {
              const ratio = count / Math.max(...values);
              let red, green, blue;
              if (ratio <= 0.5) {
                red = Math.round(0);
                green = Math.round(255 - ratio * 2 * 255);
                blue = Math.round(255);
              } else {
                red = Math.round(ratio * 2 * 255);
                green = Math.round(255 - (ratio - 0.5) * 2 * 255);
                blue = Math.round(255 - ratio * 2 * 255);
              }
              const color = `rgba(${red}, ${green}, ${blue}, 0.6)`;
              colorMap[count] = color;
            }
          });
          const backgroundColors = values.map((count) => colorMap[count]);
          const data = {
            labels: labels,
            datasets: [
              {
                label: "Valores",
                data: values,
                backgroundColor: backgroundColors,
                borderColor: "rgba(75, 192, 192, 1)",
                borderWidth: 1,
              },
            ],
          };
          new Chart(ctx, {
            type: question.tipo_grafico.toLowerCase(),
            data: data,
            options: {
              responsive: true,
              plugins: {
                legend: {
                  display: false,
                },
              },
              scales: {
                x: {
                  beginAtZero: true,
                },
              },
            },
          });
          chartRefs.current.push(chartRef);
        }
      });
    }
  }, [props.responses, props.questions]);
  return (
    <>
      <div className='d-flex row' id="chartContainer"></div>
      <div className="py-4">
        <MaisInfoMenosInfo
          text={<MontarGraficosDinamicosInfo />}
        ></MaisInfoMenosInfo>
      </div>
    </>
  );
}
export default MontarGraficosDinamicos;
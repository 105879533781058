import React, { useState } from 'react';
const ProcurarEquipamento = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedEquipment, setSelectedEquipment] = useState([]);
  // Função para realizar a busca no banco de dados
  const searchEquipment = async () => {
    try {
      // Simulando uma chamada assíncrona usando setTimeout
      const response = await new Promise((resolve) =>
        setTimeout(() => {
          const results = [
            { id: 1, name: 'Equipment 1' },
            { id: 2, name: 'Equipment 2' },
            { id: 3, name: 'Equipment 3' },
          ];
          resolve(results);
        }, 1000)
      );
      setSearchResults(response);
      setSelectedEquipment([]);
    } catch (error) {
      console.error('Error searching equipment:', error);
    }
  };
  // Função para atualizar o termo de busca
  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };
  // Função para lidar com o envio do formulário de busca
  const handleSearchSubmit = (event) => {
    event.preventDefault();
    searchEquipment();
  };
  // Função para lidar com a seleção de um equipamento
  const handleEquipmentSelect = (equipment) => {
    const isSelected = selectedEquipment.includes(equipment.id);
    if (isSelected) {
      // Se o equipamento já estiver selecionado, remove-o da lista de selecionados
      setSelectedEquipment(selectedEquipment.filter((id) => id !== equipment.id));
    } else {
      // Se o equipamento ainda não estiver selecionado, adiciona-o à lista de selecionados
      setSelectedEquipment([...selectedEquipment, equipment.id]);
    }
  };
  return (
    <>
      <div className='col-md-12 d-flex'>
        <div className='col-md-6 bg-light '>
          <h5 className='font-weight-bold mt-3'><strong>Procurar equipamento
          </strong></h5>
          <form onSubmit={handleSearchSubmit}>
            <div className="generate-container">
              <div className="input-container mt-3">
                <input
                  className={`input-field form-control mt-1 form-control-lg text-dark text-center`}
                  type="text"
                  placeholder="Digite o nome da I.A."
                  value={searchTerm}
                  onChange={handleSearchTermChange}
                />
                <button className={` ${searchTerm && 'success'
                  } generate-button`} onClick={handleSearchSubmit}>
                  <i className={` ${searchTerm && 'input-filled'
                    } fa fa-rocket mt-2`}></i>
                </button>
              </div>
            </div>
          </form>
          {searchResults.length > 0 ? (
            <ul>
              {searchResults.map((equipment) => (
                <li key={equipment.id}>
                  <label>
                    <input
                      type="checkbox"
                      value={equipment.id}
                      defaultChecked={selectedEquipment.includes(equipment.id)}
                      onChange={() => handleEquipmentSelect(equipment)}
                    />
                    {equipment.name}
                  </label>
                </li>
              ))}
            </ul>
          ) : (
            <p>No results found.</p>
          )}
        </div>
        {selectedEquipment.length > 0 && (
          <div className='col-md-6'>
            <h5 className='font-weight-bold mt-3'><strong>Equipamento Selecionado
            </strong></h5>
            <ul>
              {selectedEquipment.map((id) => {
                const equipment = searchResults.find((result) => result.id === id);
                return <li key={id}>{equipment.name}</li>;
              })}
            </ul>
          </div>
        )}
      </div>
    </>);
};
export default ProcurarEquipamento;
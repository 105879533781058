import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
const useLocalStorageListener = (key) => {
  const [storageValue, setStorageValue] = useState(localStorage.getItem(key));
  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === key) {
        setStorageValue(event.newValue);
      }
    };
    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [key]);
  return storageValue;
};
export const AuthProfessional = ({ children }) => {
  const userFromLocalStorage = JSON.parse(useLocalStorageListener('user'));
  const tokenFromLocalStorage = useLocalStorageListener('token');
  // Redirect if the user is not authenticated
  if (!userFromLocalStorage || userFromLocalStorage.user_type !== 'profissional' || !tokenFromLocalStorage) {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    return <Navigate to="/login" />;
  }
  return children;
};
export default AuthProfessional;
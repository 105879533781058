import React from 'react';
const ShareAITextInfo = () => {
      return (
            <div>  <br />  <br />  <br />  <br />  <br />  <br />  <br />
                  <h5><strong>Utilização - Compartilhamento I.A.</strong></h5>
                  <p>Utilizando o componente <strong>Compartilhamento I.A.</strong>, você pode compartilhar suas I.A.s generativas de texto de maneira segura e controlada, garantindo que seus resultados sejam acessados apenas por pessoas autorizadas, mantendo assim a integridade dos seus dados.</p>
                  <hr />
                  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />
                  <h5><strong>Compreensão Profunda - Compartilhamento de I.A.</strong></h5>
                  <p>Este documento visa esclarecer o funcionamento do componente de compartilhamento de I.A., que permite compartilhar sua I.A. generativa de texto com outros usuários. O componente fornece uma interface para inserir o nome da I.A., adicionar contatos e iniciar o processo de compartilhamento.</p>
                  <hr />
                  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />
                  <h5><strong>Compartilhar I.A. Generativa de Texto</strong></h5>
                  <p>O componente <strong>Compartilhamento</strong> permite que você compartilhe sua I.A. generativa de texto com outras pessoas. Você pode inserir o nome da I.A. e adicionar contatos que receberão acesso à I.A. e seus treinamentos.</p>
                  <hr />
                  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />
                  <h5><strong>Adicionar Contatos</strong></h5>
                  <p>O componente permite adicionar contatos através de seleção de tipo (e-mail ou WhatsApp) e inserção do valor correspondente (endereço de e-mail ou número de WhatsApp). Isso permite que você controle quem terá acesso à I.A. e seus resultados.</p>
                  <hr />
                  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />
                  <h5><strong>Chaves de Acesso e Segurança</strong></h5>
                  <p>Para garantir a segurança das I.A.s compartilhadas, foi utilizado chaves de acesso exclusivas para cada usuário. Essas chaves de acesso funcionam como senhas, permitindo o acesso controlado às funcionalidades da I.A., como treinamento, versões e documentos relacionados.</p>
                  <hr />
                  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />
                  <h5><strong>Responsabilidade e Sensibilidade dos Dados</strong></h5>
                  <p>Lembre-se de que o compartilhamento de I.A.s também envolve a responsabilidade de proteger dados sensíveis. Certifique-se de compartilhar apenas com indivíduos confiáveis e implemente medidas de segurança adequadas para proteger sua criação e dados sensíveis.</p>
                  <hr />
                  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br /> </div>
      );
};
export default ShareAITextInfo;
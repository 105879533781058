import React, { useState } from 'react';
import { ButtonAdicionarCampo, ButtonEditarCampo, ButtonRemoverCampo } from '@components';
const ProntuarioDentistaGengivaSangra = () => {
  const [gengivaSangra, setGengivaSangra] = useState(false);
  const [gengivaSangraOpcoes, setGengivaSangraOpcoes] = useState([]);
  const [novaOpcao, setNovaOpcao] = useState('');
  const [indiceEditando, setIndiceEditando] = useState(-1);
  const handleCheckboxChange = () => {
    setGengivaSangra(!gengivaSangra);
  };
  const handleNovaOpcaoChange = (e) => {
    setNovaOpcao(e.target.value);
  };
  const handleAdicionarOpcao = () => {
    if (novaOpcao.trim() !== '') {
      if (indiceEditando === -1) {
        setGengivaSangraOpcoes([...gengivaSangraOpcoes, novaOpcao]);
      } else {
        const novaLista = [...gengivaSangraOpcoes];
        novaLista[indiceEditando] = novaOpcao;
        setGengivaSangraOpcoes(novaLista);
        setIndiceEditando(-1);
      }
      setNovaOpcao('');
    }
  };
  const handleRemoverOpcao = (index) => {
    const novaLista = [...gengivaSangraOpcoes];
    novaLista.splice(index, 1);
    setGengivaSangraOpcoes(novaLista);
  };
  const handleEditarOpcao = (index) => {
    setNovaOpcao(gengivaSangraOpcoes[index]);
    setIndiceEditando(index);
  };
  return (
    <>
      <h5 style={{ fontWeight: "bold" }}>Sua gengiva sangra?</h5>
      <label>
        <input type="checkbox" defaultChecked={gengivaSangra} onChange={handleCheckboxChange} /> Sim
      </label>
      <br />
      <label>
        <input type="checkbox" defaultChecked={!gengivaSangra} onChange={() => setGengivaSangra(false)} /> Não
      </label>
      {gengivaSangra && (
        <div>
          <h5 style={{ fontWeight: "bold" }}>Quando, como:</h5>
          <div>
            <input
              type="text"
              value={novaOpcao}
              onChange={handleNovaOpcaoChange}
              placeholder="Nova Opção"
            />
            <ButtonAdicionarCampo onClick={handleAdicionarOpcao} />
          </div>
          <ul>
            {gengivaSangraOpcoes.map((opcao, index) => (
              <li key={index}>
                {opcao}
                <ButtonEditarCampo onClick={() => handleEditarOpcao(index)} />
                <ButtonRemoverCampo onClick={() => handleRemoverOpcao(index)} />
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};
export default ProntuarioDentistaGengivaSangra;
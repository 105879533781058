import React from 'react';
import { Collapse, Button, Card } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
const { Panel } = Collapse;
// Função recursiva para renderizar JSON com temas e subtemas
const renderJSONRecursively = (data) => {
  if (typeof data !== 'object' || data === null) {
    return <span>{String(data)}</span>;
  }
  return (
    <Collapse>
      {Object.entries(data).map(([key, value]) => (
        <Panel header={key} key={key}>
          {typeof value === 'object' ? renderJSONRecursively(value) : <span>{String(value)}</span>}
        </Panel>
      ))}
    </Collapse>
  );
};
// Componente principal
export const JSONViewerAnt = ({ data, title }) => {
  // Formata o JSON para download
  const handleDownloadJSON = () => {
    const blob = new Blob([JSON.stringify(data, null, 2)], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${title || 'data'}.json`;
    link.click();
    URL.revokeObjectURL(url);
  };
  return (
    <Card
      title={
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
          <span>{title || 'Resposta completa JSON'}</span>
          <Button
            icon={<DownloadOutlined />}
            onClick={handleDownloadJSON}
            type="primary"
            size="small"
          >
            Download JSON
          </Button>
        </div>
      }
    >
      <div style={{ background: '#f0f0f0', padding: '10px', borderRadius: '5px' }}>
        {renderJSONRecursively(data)}
      </div>
    </Card>
  );
};
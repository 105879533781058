import { NavBreadcrumb, CardDirecionamento } from '@components'
export const Index = () => {
    return (<>
        <div className=' w-100' >
            <NavBreadcrumb icon={'suitcase fa'} text={'Início Profissional'} />
            <div className="py-3">
                <div className="text-center">
                    <div className="container-fluid">
                        <div className="row d-inline-flex justify-content-center w-100">
                            <CardDirecionamento text={'Novo'} icon={'mt-3 fa fa-bed fa-3x text-white'} href={'../profissionais/atestados/novo'} />
                            <CardDirecionamento text={'Ativo'} icon={'mt-3 fa fa-ambulance fa-3x text-white'} href={'../profissionais/atestados/ativo'} />
                            <CardDirecionamento text={'Armazenados'} icon={'mt-3 fa fa-flask fa-3x text-white'} href={'../profissionais/atestados/armazenado'} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}
import React from "react";
import { Card } from "antd";
import { useNavigate } from "react-router-dom";
import TitleL2 from "../text/TitleL2";
const { Meta } = Card;
export const CardDirecionamento = (props) => {
  const navigate = useNavigate();
  const handleCardClick = () => {
    if (props.onClick) {
      props.onClick();
    }
    navigate(props.href);
  };
  return (
    <div
      className={`col-md-3 mt-2 ${props.className}`}
      onClick={handleCardClick}
      style={{ cursor: "pointer" }}
    >
      <Card
        className="rounded border no-drag bg-primary"
        bodyStyle={{ padding: '0' }} // Remove o padding do corpo do card
        cover={
          <div className="bg-light py-1" style={{ padding: '0', borderRadius:'20px 20px 20px 20px' }}>
            <div className="text-center" style={{ padding: '0' }}>
              <i className={`fa text-dark ${props.icon}`} style={{ padding: '0' }}></i>
              {props.iconReact}
            </div>
            <TitleL2
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                minHeight: "24px",
                WebkitLineClamp: 1,
                WebkitBoxOrient: 'vertical',
                padding: '0'
              }}
            >
              {props.text}
            </TitleL2>
          </div>
        }
      >
        <Meta
          title={
            <h4 className="text-white mt-1" style={{ padding: '0' }}>
            </h4>
          }
          style={{ padding: '0' }} // Remove o padding do Meta
        />
      </Card>
    </div>
  );
};
export default CardDirecionamento;
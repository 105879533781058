import React from 'react';
import { Image } from 'antd';
export const RenderImageGrid = ({ images }) => {
  return (<>
    {images.length > 0 && <>
      <div className="d-flex flex-column ">
        <div className="row ">
          <Image
            className=" rounded d-flex"
            style={{ minWidth: '200px', maxWidth: '200px', minHeight: '200px', maxHeight: '200px', objectFit: 'cover', }}
            src={images[0]?.url || ""}
            alt="Produto Imagem Grande"
          />
          <div className="d-flex flex-wrap" style={{ maxWidth: '50px' }}>
            {images.slice(1, 4).map((img, index) => (
              <Image
                key={index}
                className="m-1"
                style={{ minWidth: '54px', maxWidth: '54px', minHeight: '54px', maxHeight: '54px', padding: '0px', margin: '0px', objectFit: 'cover', }}
                src={img?.url || ""}
                alt={`Produto Imagem ${index + 1}`}
              />
            ))}
          </div>
        </div>
        <div className="row justify-content-left">
        {images.slice(5, 10).reverse().map((img, index) => (
            <Image
              key={index}
              className="m-1"
              style={{ minWidth: '54.7px', maxWidth: '54.7px', minHeight: '54.7px', maxHeight: '54.7px', padding: '0px', margin: '0px', }}
              src={img?.url || ""}
              alt={`Produto Imagem ${index + 6}`}
            />
          ))}
        </div>
      </div>
    </>}
  </>);
};
export default RenderImageGrid;
import React from 'react';
import { Link } from 'react-router-dom';
export function ButtonAdicionarCampo(props) {
  return (
    <>
      &nbsp;
      <Link className={`btn btn-success ml-3 mx-auto text-center text-white ${props.className}`}
        type={props.type}
        href={props.href}
        style={{ borderRadius: '50%', ...props.style }}
        onClick={props.onClick}
        aria-label="Adicionar Campo"
      >
        {props.children}   <i className='fa fa-plus'></i>
      </Link>
    </>
  );
}
export default ButtonAdicionarCampo;
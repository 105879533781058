import React, { useState } from 'react';
import { QRCode } from 'react-qrcode-logo';
import { toPng } from 'html-to-image';
const QRCodeWithLogo = () => {
    const [value, setValue] = useState('');
    const [logo, setLogo] = useState('');
    const handleUrlChange = (event) => {
        setValue(event.target.value);
    };
    const handleLogoChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            setLogo(reader.result);
        };
        if (file) {
            reader.readAsDataURL(file);
        }
    };
    const downloadQRCode = () => {
        const qrCodeElement = document.getElementById('qr-code');
        toPng(qrCodeElement)
            .then((dataUrl) => {
                const link = document.createElement('a');
                link.download = 'qr-code.png';
                link.href = dataUrl;
                link.click();
            })
            .catch((error) => {
                console.error('Erro ao baixar o QR Code', error);
            });
    };
    return (
        <div style={{ textAlign: 'center' }}>
            <h1>QR Code with Logo</h1>
            <input
                type="text"
                placeholder="Enter URL"
                value={value}
                onChange={handleUrlChange}
                style={{ marginBottom: '10px', padding: '5px', width: '300px' }}
            />
            <br />
            <input
                type="file"
                accept="image/*"
                onChange={handleLogoChange}
                style={{ marginBottom: '20px' }}
            />
            <br />
            {value && logo && (
                <div>
                    <div id="qr-code" style={{ display: 'inline-block', background: '#fff', padding: '10px', borderRadius: '15px' }}>
                        <QRCode
                            value={value}
                            logoImage={logo}
                            size={256}
                            logoWidth={64}
                            logoHeight={64}
                            qrStyle='squares'
                            eyeRadius={[
                                [10, 10, 0, 10],
                                [10, 10, 10, 0],
                                [10, 0, 10, 10],
                            ]}
                            logoPadding={10}
                            logoPaddingStyle={{ fill: '#ffffff' }} // fundo branco em volta da logo
                        />
                    </div>
                    <br />
                    <button onClick={downloadQRCode} style={{ marginTop: '20px', padding: '10px 20px', fontSize: '16px' }}>
                        Download QR Code
                    </button>
                </div>
            )}
        </div>
    );
};
export default QRCodeWithLogo;
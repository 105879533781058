import { useState, useEffect, useContext } from 'react';
import { InputPadrao } from '@components';
import { apiToken, baseURL } from '@services';
import { Button } from 'antd';
import { MdDateRange } from 'react-icons/md';
import { AuthContext } from '../../../../../../../guard';
import GetUsers from '../../../../../Usuarios/Search/GetUsers';
export const Index = () => {
    const [procedimentos, setProcedimentos] = useState([]);
    const [novoProcedimento, setNovoProcedimento] = useState('');
    const [procedimentosFiltrados, setProcedimentosFiltrados] = useState([]);
    const { getUserId, getProfessionalId } = useContext(AuthContext);
    const professional_id = getProfessionalId();
    const user_id = getUserId()
    useEffect(() => {
        fetch(`${baseURL}procedimentos/${professional_id}`)
            .then(response => response.json())
            .then(data => {
                setProcedimentos(data);
            });
    }, [professional_id]);
    const handleAdicionarProcedimento = () => {
        setProcedimentos([...procedimentos, novoProcedimento]);
        setNovoProcedimento('');
    };
    const handleNovoProcedimentoChange = (event) => {
        const valor = event.target.value;
        setNovoProcedimento(valor);
        const filtrados = procedimentos.filter((procedimento) =>
            procedimento.toLowerCase().includes(valor.toLowerCase())
        );
        setProcedimentosFiltrados(filtrados);
    };
    const handleProcedimentoClick = (event) => {
        const valor = event.target.innerText;
        setNovoProcedimento(valor);
        setProcedimentosFiltrados([]);
    };
    const carregarProcedimentos = async () => {
        const resposta = await fetch(`${baseURL}procedimentos`);
        const dados = await resposta.json();
        setProcedimentos(dados);
    };
    useEffect(() => {
        carregarProcedimentos();
    }, []);
    const [selectedOption, setSelectedOption] = useState({
        value: "Nome",
        label: "Nome"
    });
    const updateSelectedOption = (newSelectedOption) => {
        setSelectedOption(newSelectedOption);
    };
    const customColumns = [
        {
            title: "Agendar",
            dataIndex: "Consulta",
            key: "consultas",
            render: () => (
                <Button
                    // type="primary"
                    icon={<MdDateRange style={{ fontSize: '17px' }} />}
                    onClick={() => handleAgendarConsulta()}
                    style={{ marginLeft: '8px' }}
                >
                </Button>
            ),
        },
    ];
    const [data, setData] = useState([]);
    const [dataTableVisible, setDataTableVisible] = useState(false);
    const onSearch = async (value, currentPage = 1) => {
        if (value) {
            try {
                const response = await apiToken().post(`${baseURL}buscarUsuarios/${user_id}`, {
                    nome: selectedOption?.value === "Nome" ? value : undefined,
                    cpf: selectedOption?.value === "CPF" ? value : undefined,
                    identificador: selectedOption?.value === "Identificador" ? value : undefined,
                    pagina: currentPage,
                    resultadosPorPagina: pagination.pageSize,
                });
                const { pacientes, totalPacientes, totalPages } = response.data;
                setDataTableVisible(true);
                setData(pacientes);
                setPagination({
                    ...pagination,
                    current: currentPage,
                    total: totalPacientes,
                    totalPages: totalPages
                });
            } catch (error) {
                console.error("Erro ao buscar pacientes:", error);
                setData([]);
                setPagination({
                    ...pagination,
                    current: 1,
                    total: 0,
                });
            }
        } else {
            console.log("Nome inválido");
        }
    };
    const updateSearchValue = (newValue) => {
        setSearchValue(newValue);
    };
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
    });
    const [searchValue, setSearchValue] = useState("");
    const handleAgendarConsulta = () => {
        alert('Usuário selecionado ')
        setDataTableVisible(false)
    };
    return (<>
        <GetUsers
            props={{
                onSearch,
                customColumns,
                data,
                dataTableVisible,
            }}
            selectedOption={selectedOption}
            updateSelectedOption={updateSelectedOption}
            updateSearchValue={updateSearchValue} // Passa a função para atualizar o valor de pesquisa
            searchValue={searchValue} // Passa o valor de pesquisa
            pagination={pagination}
            setPagination={setPagination}
            title="Buscar usuário"
        />
        <div className={`mt-3 col-md-12 mx-auto `} >
            <div className="container">
                <div className="row">
                    <div className="col-md-6 text-center">
                        <div className="form-group">
                            <InputPadrao
                                title={'Serviço'}
                                type="text"
                                value={novoProcedimento}
                                onChange={handleNovoProcedimentoChange}
                            />
                            {procedimentosFiltrados.length > 0 && (
                                <ul className="list-group">
                                    {procedimentosFiltrados.map((procedimento, index) => (
                                        <li
                                            key={index}
                                            className="list-group-item"
                                            onClick={handleProcedimentoClick}
                                        >
                                            {procedimento}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    </div>
                    <div className="col-md-12 d-flex justify-content-center mx-auto align-items-center"> <button
                        className="btn btn-primary btn-block w-50 mb-2 mt-3 mx-auto a-86 text-center" style={{ boxShadow: "inset 0px -6px 3px 5px rgba(0, 0, 0, 0.5)" }} onClick={handleAdicionarProcedimento}>Adicionar Procedimento</button></div>
                    <div className='col-md-12 mx-auto d-flex  justify-content-center'>
                        <small className=" text-white text-center mb-3 mt-3 "> Confira a tabela abaixo junto com valor total e envie para o paciente o orçamento</small>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Procedimento</th>
                            </tr>
                        </thead>
                        <tbody>
                            {procedimentos.map((procedimento) => (
                                <tr key={procedimento}>
                                    <td>{procedimento}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </>)
}
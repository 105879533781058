import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import InputMask from 'react-input-mask';
import creditCardType from 'credit-card-type';
const PaymentFormCard = ({ state, setState }) => {
  const handleInputFocus = (e) => {
    setState(prevState => ({ ...prevState, focus: e.target.name }));
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState(prevState => {
      const newState = { ...prevState, [name]: value };
      if (name === 'number') {
        const cardType = creditCardType(value);
        newState.issuer = cardType.length > 0 ? cardType[0].niceType : '';
      }
      return newState;
    });
  };
  return (
    <div className='col-md-12' id="PaymentForm">
      <Cards
        cvc={state.cvc}
        expiry={state.expiry}
        focused={state.focus}
        name={state.name}
        number={state.number}
        issuer={state.issuer.toLowerCase()} // Adicionado para identificar a bandeira
      />
      <form className='text-dark' >
        <InputMask
          mask="9999 9999 9999 9999"
          maskChar=""
          className='mt-2 col-md-5 mr-1 ml-1 text-dark'
          type="tel"
          name="number"
          placeholder="Card Number"
          value={state.number}
          onChange={handleInputChange}
          onFocus={handleInputFocus}
        />
        <input
          type="text"
          className='mt-2 col-md-5 mr-1 ml-1 text-dark'
          name="name"
          placeholder="Name"
          value={state.name}
          onChange={handleInputChange}
          onFocus={handleInputFocus}
        />
        <InputMask
          mask="99/99"
          maskChar=""
          type="tel"
          className='mt-2 col-md-5 mr-1 ml-1 text-dark'
          name="expiry"
          placeholder="MM/YY Expiry"
          value={state.expiry}
          onChange={handleInputChange}
          onFocus={handleInputFocus}
        />
        <input
          type="tel"
          name="cvc"
          placeholder="CVC"
          className='mt-2 col-md-5 mr-1 ml-1 text-dark'
          value={state.cvc}
          onChange={handleInputChange}
          onFocus={handleInputFocus}
        />
      </form>
    </div>
  );
};
export default PaymentFormCard;
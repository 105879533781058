import { Modal, Spin } from 'antd';
export const LoadingModal = ({ visible, message = 'Carregando...', children, onClose }) => {
  return (
    <Modal
      open={visible}
      footer={null}
      closable={true} // Permite o fechamento manual
      centered
      onCancel={onClose} // Apenas fecha o modal
    >
      <div style={{ textAlign: 'center' }}>
        <Spin size="large" />
        <p>{message}</p>
      </div>
      {children}
    </Modal>
  );
};
export default LoadingModal;
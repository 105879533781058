import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { MaisInfoMenosInfo, ButtonAdicionarCampo, ButtonRemoverCampo, ButtonEditarCampo } from '@components';
import ProntuarioChasFitoterapiaSuplementosInfo from '../info/ProntuarioChasFitoterapiaSuplementosInfo';
export const ProntuarioChasFitoterapiaSuplementos = () => {
  const [usaChaFitoterapiaSuplementos, setUsaChaFitoterapiaSuplementos] = useState(false);
  const [chaFitoterapiaSuplemento, setChaFitoterapiaSuplemento] = useState({
    finalidade: '',
    nome: '',
    dose: '',
    posologia: '',
  });
  const [chaFitoterapiaSuplementosLista, setChaFitoterapiaSuplementosLista] = useState([]);
  const [indiceEditando, setIndiceEditando] = useState(-1);
  const handleCheckboxChange = (e) => {
    setUsaChaFitoterapiaSuplementos(e.target.checked);
  };
  const handleChaFitoterapiaSuplementoChange = (e) => {
    const { name, value } = e.target;
    setChaFitoterapiaSuplemento((prevMedicamento) => ({
      ...prevMedicamento,
      [name]: value,
    }));
  };
  const handleAdicionarChaFitoterapiaSuplemento = () => {
    if (
      chaFitoterapiaSuplemento.finalidade.trim() !== '' &&
      chaFitoterapiaSuplemento.nome.trim() !== '' &&
      chaFitoterapiaSuplemento.dose.trim() !== '' &&
      chaFitoterapiaSuplemento.posologia.trim() !== ''
    ) {
      if (indiceEditando === -1) {
        setChaFitoterapiaSuplementosLista([...chaFitoterapiaSuplementosLista, chaFitoterapiaSuplemento]);
      } else {
        const novaLista = [...chaFitoterapiaSuplementosLista];
        novaLista[indiceEditando] = chaFitoterapiaSuplemento;
        setChaFitoterapiaSuplementosLista(novaLista);
        setIndiceEditando(-1);
      }
      setChaFitoterapiaSuplemento({ finalidade: '', nome: '', dose: '', posologia: '' });
    }
  };
  const handleRemoverChaFitoterapiaSuplemento = (index) => {
    const novaLista = [...chaFitoterapiaSuplementosLista];
    novaLista.splice(index, 1);
    setChaFitoterapiaSuplementosLista(novaLista);
  };
  const handleEditarChaFitoterapiaSuplemento = (index) => {
    const itemEditando = chaFitoterapiaSuplementosLista[index];
    setChaFitoterapiaSuplemento({ ...itemEditando });
    setIndiceEditando(index);
  };
  return (
    <>
      <h5 style={{ fontWeight: "bold" }} className="mt-3">
        - Faz uso de chás / fitoterapia/ suplementos?
      </h5>
      <label>
        <input
          type="checkbox"
          defaultChecked={usaChaFitoterapiaSuplementos}
          onChange={handleCheckboxChange} /> Sim
      </label>
      <br />
      <label>
        <input
          type="checkbox"
          defaultChecked={!usaChaFitoterapiaSuplementos}
          onChange={() => setUsaChaFitoterapiaSuplementos(false)} /> Não
      </label>
      {usaChaFitoterapiaSuplementos && (
        <div>  *Se Sim, quais (finalidade/nome/dose/posologia):<br /><br />
          <input
            className=''
            type="text"
            name="finalidade"
            value={chaFitoterapiaSuplemento.finalidade}
            onChange={handleChaFitoterapiaSuplementoChange}
            placeholder="Finalidade"
          />
          <input
            type="text"
            name="nome"
            value={chaFitoterapiaSuplemento.nome}
            onChange={handleChaFitoterapiaSuplementoChange}
            placeholder="Nome"
          />
          <input
            type="text"
            name="dose"
            value={chaFitoterapiaSuplemento.dose}
            onChange={handleChaFitoterapiaSuplementoChange}
            placeholder="Dose"
          />
          <input
            type="text"
            name="posologia"
            value={chaFitoterapiaSuplemento.posologia}
            onChange={handleChaFitoterapiaSuplementoChange}
            placeholder="Posologia"
          />
          <ButtonAdicionarCampo type={'button'} onClick={handleAdicionarChaFitoterapiaSuplemento}></ButtonAdicionarCampo>
        </div>
      )}
      {chaFitoterapiaSuplementosLista.length > 0 && (
        <div>
          <h6>Chás/Fitoterapia/Suplementos Adicionados:</h6>
          <ul>
            {chaFitoterapiaSuplementosLista.map((chaFitoterapiaSuplemento, index) => (
              <li key={index}>
                <strong>Finalidade:</strong> {chaFitoterapiaSuplemento.finalidade},
                <strong> Nome: </strong>{chaFitoterapiaSuplemento.nome},
                <strong> Dose: </strong>{chaFitoterapiaSuplemento.dose},
                <strong> Posologia: </strong>{chaFitoterapiaSuplemento.posologia}
                <ButtonEditarCampo onClick={() => handleEditarChaFitoterapiaSuplemento(index)} />
                <ButtonRemoverCampo onClick={() => handleRemoverChaFitoterapiaSuplemento(index)} />
              </li>
            ))}
          </ul>
        </div>
      )}
      <div className="col-md-12 mt-2 mb-5 d-flex">
        <Link className="btn btn-primary mx-auto col-md-6" href="#">
        Enviar Informações
        </Link>
      </div>
       <MaisInfoMenosInfo text={<ProntuarioChasFitoterapiaSuplementosInfo />}></MaisInfoMenosInfo> 
    </>
  );
};
export default ProntuarioChasFitoterapiaSuplementos;
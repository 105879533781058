import React from 'react';
export const ProntuarioInfoPaciente = () => {
    return (
        <>
            <h5 style={{ fontWeight: "bold" }} className=" text-center mt-2"> Nome:
            </h5>
            <h5 style={{ fontWeight: "bold" }} className=" text-center mt-2"> Genótipo: 
            </h5>
            <h5 style={{ fontWeight: "bold" }} className=" text-center mt-2"> Idade: 
            </h5>
        </>
    );
};
export default ProntuarioInfoPaciente;
import React, { useContext, useState } from 'react';
import { MaisInfoMenosInfo } from '@components';
import '../style/Style.css';
import axios from 'axios';
import { baseURL } from '@services';
import { AuthContext } from '../../../../../../../guard/auth';
import SearchAITextInfo from '../info/SearchAITextInfo';
function SearchAIText({ nameIAText, setNameIAText }) {
  const [isLoading, setIsLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const { getUserId } = useContext(AuthContext);
  const user_id = getUserId();
  const handleSearchAIText = async () => {
    setIsLoading(true);
    try {
      const ia_name = nameIAText;
      //alert(user_id, ia_name)
      const response = await axios.get(`${baseURL}get_ia`, {
        params: {
          user_id: user_id,
          ia_name: ia_name,
        },
      });
      const data = response.data.iaInfo;
      //alert(data)
      setSearchResults(data);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };
  const handleInputChange = (e) => {
    const iaName = e.target.value;
    setNameIAText(iaName);
    handleSearchAIText(iaName);
  };
  const handleResultClick = (result) => {
    setNameIAText(result.name);
    localStorage.setItem('driveIA', JSON.stringify(result));
  };
  return (
    <div className="col-md-5 mt-2 rounded bg-light">
      <h5 className='font-weight-bold mt-3'>Procurar I.A</h5>
      <div className="generate-container">
        <div className="input-container mt-3">
          <input
            className={`input-field form-control mt-1 form-control-lg text-dark text-center`}
            type="text"
            placeholder="Nome da I.A. ?"
            value={nameIAText}
            onChange={handleInputChange}
          />
          <button
            className={`${isLoading ? 'success' : ''} generate-button`}
            onClick={handleSearchAIText}
          >
            <i className={`${'input-filled'} fa fa-rocket mt-2`}></i>
          </button>
        </div>
      </div>
      <div className="search-results text-left mt-2">
        {searchResults.map((result, index) => (
          <div
            key={index}
            className="search-result"
            onClick={() => handleResultClick(result)}
          >
            <p><span className="bullet-point">&#8226;</span><small>{result.name}</small> </p>
            {result.versoes && result.versoes.length > 0 && (
              <div>
                <ul>
                  {result.versoes.map((versao, versaoIndex) => (
                    <li className='ml-2' key={versaoIndex}>
                      <small>
                        <strong>Versões número: </strong>{versao.numero_versao}
                      </small>
                    </li>
                  ))}
                </ul>
                <hr />
              </div>
            )}
          </div>
        ))}
      </div>
   <MaisInfoMenosInfo text={<SearchAITextInfo />}></MaisInfoMenosInfo>
    </div>
  );
}
export default SearchAIText;
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import ConsoleMessage from './info/ConsoleMessage';
import SearchBar from '../../pages/Public/Search/partials/layout/SearchBar';
import IconThot from '../icons/IconThot';
export const Nav = () => {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [isFixed, setIsFixed] = useState(false);
    const [navHeight, setNavHeight] = useState(0);
    const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
    const [isNavbarCollapsed, setIsNavbarCollapsed] = useState(true);
    const location = useLocation();
    const { search } = location;
    const [imageUrl, setImageUrl] = useState(null);
    const userStr = localStorage.getItem('user');
    const user = JSON.parse(userStr);
    const cart = JSON.parse(localStorage.getItem('cart')) || [];
    let total = 0;
    cart.forEach((item) => {
        total += parseInt(item.quantity);
    });
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);
    useEffect(() => {
        const handleScroll = () => {
            if (window.pageYOffset > 0) {
                setIsFixed(true);
            } else {
                setIsFixed(false);
            }
        };
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);
    useEffect(() => {
        const handleWindowResize = () => {
            setScreenWidth(window.innerWidth);
            setIsFixed(false);
        };
        window.addEventListener("resize", handleWindowResize);
        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    }, []);
    useEffect(() => {
        const nav = document.querySelector('.navbar');
        const height = nav ? nav.getBoundingClientRect().height : 0;
        setNavHeight(height);
    }, [screenWidth]);
    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset;
            const isScrollingUp = prevScrollPos > currentScrollPos;
            setIsFixed(isScrollingUp);
            setPrevScrollPos(currentScrollPos);
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [prevScrollPos]);
    const navClasses = `navbar navbar-expand-md navbar-dark bg-primary justify-content-center align-items-center text-white w-100 ${isFixed && !location.pathname.includes('profissionais/ecommerce') ? 'fixed-top' : ''}`;
    const collapseItens = () => {
        setIsNavbarCollapsed((prevIsCollapsed) => !prevIsCollapsed);
    };
    useEffect(() => {
        if (user !== null && user.imageUrlsUser !== null) {
            setImageUrl(user.imageUrlsUser);
        }
    }, [user]);
    const clicarNoId = () => {
        const elementoClicado = document.getElementById('collapseNavBar');
        if (elementoClicado) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            elementoClicado.click();
        }
    };
    return (<>
        <div id='evitaPulo' style={{ height: isFixed ? `${navHeight}px` : 0, }}></div>
        <nav className={navClasses} style={{ minHeight: "5dvh" }}>
            <div
                className={`justify-content-between   ${screenWidth <= 1200 ? `  ${isNavbarCollapsed ? 'd-flex' : 'row'}` : 'col-md-12 '}   justify-content-center align-items-center`}>
                <button
                    className="navbar-toggler navbar-toggler-right  mr-1"
                    id='collapseNavBar' type="button"
                    style={{ padding: '10px', border: 'none' }}
                    data-toggle="collapse"
                    aria-label="Abrir menu de navegação"
                    data-target="#navbar18"
                    onClick={collapseItens}
                >
                    <span className="navbar-toggler-icon text-white" style={{ border: 'none' }}></span>
                </button>
                <div className="collapse navbar-collapse  col-md-12" id="navbar18" style={{ padding: '0px' }} >
                    <Link className="navbar-brand d-none d-md-block   text-white" aria-label="Home" to={`../${search} `} style={{ cursor: 'pointer' }}>
                        <i className='text-white py-1 bg-white rounded' style={{ boxShadow: location.pathname === '/' && " 0px 0px 4px 2px rgb(255, 255, 255, 0.3)" }} ><IconThot /> </i>
                    </Link>
                    <div className={` ${screenWidth <= 1200 ? 'collapse' : '  col-md-4 '}`} style={{ opacity: '1' }}>
                        <div className="container justify-content-center align-items-center d-flex mx-auto" style={{ padding: '0px' }}>
                            <div className=" w-100 text-center">
                                <form className=" ">
                                    <SearchBar />
                                </form>
                            </div>
                        </div>
                    </div>
                    <ul className="navbar-nav " style={{ padding: '0px', marginBottom: 0, marginTop: -20 }}>
                        <li className="nav-item mt-4">
                            <Link className={`nav-link ${screenWidth <= 1200 ? (location.pathname === '/' ? 'font-weight-bold text-white' : '') : 'collapse'}`}
                                to={'../' + search}
                            >
                                Início
                            </Link>
                        </li>
                        <li className="nav-item dropdown">
                            <button className={` nav-link dropdown-toggle ${location.pathname.includes('servicos') ? ' font-weight-bold  text-white' : ''}`}
                                style={{ background: 'none', border: 'none', textShadow: location.pathname.includes('servicos') ? ' 0.5px 0 0 currentColor, -0.5px 0 0 currentColor' : '' }}
                                id="navbarDropdownServicos"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                Serviços
                            </button>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdownServicos">
                                <a
                                    className={`dropdown-item text-dark ${(location.pathname.includes('/servicos') && location.hash === '#servicos') || (location.pathname === '/servicos' && location.hash === '') ? ' font-weight-bold ' : ''}`}
                                    style={{ background: 'none' }}
                                    href={`${user ? '../../usuario/servicos#servicos' + search : '../servicos#servicos' + search}`}
                                    onClick={clicarNoId}
                                >Comprar</a>
                                <a
                                    className={`dropdown-item text-dark ${(location.pathname.includes('/servicos') && location.hash === '#apresentacaoServicos') || (location.pathname === '/servicos' && location.hash === '') ? ' font-weight-bold ' : ''}`}
                                    style={{ background: 'none' }}
                                    href={`${user ? '../../usuario/servicos#apresentacaoServicos' + search : '../servicos#apresentacaoServicos' + search}`}
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                    onClick={clicarNoId}
                                >
                                    Vender
                                </a>
                            </div>
                        </li>
                        <li className="nav-item dropdown">
                            <button
                                className={`nav-link dropdown-toggle ${location.pathname.includes('produtos') ? ' font-weight-bold  text-white' : ''}`}
                                id="navbarDropdownProdutos"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                style={{ background: 'none', border: 'none', fontWeight: 'initial', textShadow: location.pathname.includes('produtos') ? ' 0.5px 0 0 currentColor, -0.5px 0 0 currentColor' : '' }}>
                                Produtos
                            </button>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdownProdutos">
                                <a
                                    className={`dropdown-item text-dark ${(location.pathname.includes('/produtos') && location.hash === '#produtos') || (location.pathname === '/produtos' && location.hash === '') ? ' font-weight-bold ' : ''}`}
                                    style={{ background: 'none' }}
                                    href={`${user ? '../../usuario/produtos#produtos' + search : '../produtos#produtos' + search}`}
                                    onClick={clicarNoId}

                                >Comprar</a>
                                <a
                                   className={`dropdown-item text-dark ${(location.pathname.includes('/produtos') && location.hash === '#apresentacao') || (location.pathname === '/produtos' && location.hash === '') ? ' font-weight-bold ' : ''}`}
                                   style={{ background: 'none' }}
                                    href={`${user ? '../../usuario/produtos#apresentacao' + search : '../produtos#apresentacao' + search}`}
                                    onClick={clicarNoId}
                                >
                                    Vender
                                </a>
                            </div>
                        </li>
                        <li className="nav-item dropdown">
                            <Link
                                className={`nav-link dropdown-toggle ${location.pathname.includes('artigos') ? ' font-weight-bold  text-white' : ''}`}
                                style={{ textShadow: location.pathname.includes('artigos') ? ' 0.5px 0 0 currentColor, -0.5px 0 0 currentColor' : '' }}
                                id="navbarDropdownArtigos"
                                role="button"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Artigos
                            </Link>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdownArtigos">
                                <a
                                    className={`dropdown-item text-dark ${(location.pathname === '/artigos' && location.hash === '#publicArtigos') || (location.pathname === '/artigos' && location.hash === '') ? ' font-weight-bold ' : ''}`}
                                    style={{ background: 'none' }}
                                    href={`${user ? '../../artigos#publicArtigos' + search : '../artigos#publicArtigos' + search}`}
                                    onClick={clicarNoId}>Comprar</a>

                                <a
                                    className={`dropdown-item text-dark ${(location.pathname === '/artigos' && location.hash === '#SobrePublicacoesInfo') ? ' font-weight-bold ' : ''}`}
                                    href={`${user ? '../../usuario/artigos#SobrePublicacoesInfo' + search : '../artigos#SobrePublicacoesInfo' + search}`}
                                    onClick={clicarNoId}
                                >
                                    Politica de Publicação </a>
                                <a
                                    className={`dropdown-item text-dark ${(location.pathname === '/artigos' && location.hash === '#ArticleSubmissionRequirements') ? ' font-weight-bold ' : ''}`}
                                    style={{ background: 'none' }} href={`${user ? '../../usuario/artigos#ArticleSubmissionRequirements' + search : '../artigos#ArticleSubmissionRequirements' + search}`}
                                    onClick={clicarNoId}
                                >
                                    Como Publicar</a>
                            </div>
                        </li>
                        <li className="nav-item dropdown">
                            <Link
                                className={`nav-link dropdown-toggle ${location.pathname.includes('parcerias') ? ' font-weight-bold  text-white' : ''}`}
                                style={{ textShadow: location.pathname.includes('parcerias') ? ' 0.5px 0 0 currentColor, -0.5px 0 0 currentColor' : '' }}
                                id="navbarDropdownparcerias"
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                Parcerias
                            </Link>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdownparcerias">
                                <a onClick={clicarNoId}
                                    className={`dropdown-item text-dark ${(location.pathname === '/parcerias' && location.hash === '#apresentacaoParcerias') || (location.pathname === '/parcerias' && location.hash === '') ? ' font-weight-bold ' : ''}`}
                                    style={{ background: 'none' }}
                                    href={`${user ? '../../parcerias#apresentacaoParcerias' + search : '../parcerias#apresentacaoParcerias' + search}`}>
                                    Apresentação
                                </a>
                                <a onClick={clicarNoId} className={`dropdown-item text-dark ${(location.pathname === '/parcerias' && location.hash === '#representacao') ? ' font-weight-bold ' : ''}`} style={{ background: 'none' }} href={`${user ? '../../parcerias#representacao' + search : '../parcerias#representacao' + search}`}>
                                    Representação Comercial </a>
                                <a onClick={clicarNoId} className={`dropdown-item text-dark ${(location.pathname === '/parcerias' && location.hash === '#SoftwareHouse') ? ' font-weight-bold ' : ''}`} style={{ background: 'none' }} href={`${user ? '../../parcerias#SoftwareHouse' + search : '../parcerias#SoftwareHouse' + search}`}>
                                    Software House</a>
                                <a onClick={clicarNoId} className={`dropdown-item text-dark ${(location.pathname === '/parcerias' && location.hash === '#Pesquisadores') ? ' font-weight-bold ' : ''}`} style={{ background: 'none' }} href={`${user ? '../../parcerias#Pesquisadores' + search : '../parcerias#Pesquisadores' + search}`}>
                                    Pesquisadores</a>
                                <a onClick={clicarNoId} className={`dropdown-item text-dark ${(location.pathname === '/parcerias' && location.hash === '#fornecedores') ? ' font-weight-bold ' : ''}`} style={{ background: 'none' }} href={`${user ? '../../parcerias#fornecedores' + search : '../parcerias#fornecedores' + search}`}>
                                    Fornecedores</a>
                                <a onClick={clicarNoId} className={`dropdown-item text-dark ${(location.pathname === '/parcerias' && location.hash === '#ColaboreConosco') ? ' font-weight-bold ' : ''}`} style={{ background: 'none' }} href={`${user ? '../../parcerias#ColaboreConosco' + search : '../parcerias#ColaboreConosco' + search}`}>
                                    Colabore Conosco</a>
                            </div>
                        </li>
                        <li className="nav-item">
                            <Link
                                className={`nav-link ${location.pathname === '/sobre' ? 'nav-link font-weight-bold text-white' : 'nav-link'}`}
                                style={{ textShadow: location.pathname.includes('sobre') ? ' 0.5px 0 0 currentColor, -0.5px 0 0 currentColor' : '' }}
                                to={'../sobre' + search}
                            >Sobre</Link>
                        </li>
                    </ul>
                    <ul className="navbar-nav ml-auto" style={{ marginBottom: 0, marginTop: -20 }} >
                        <li className="nav-item ">
                            <Link
                                className={`nav-link ${location.pathname === '/carrinho' || location.pathname === '/usuario/carrinho' ? 'nav-link font-weight-bold text-white' : 'nav-link'} ${screenWidth <= 1200 && 'mt-3'}`}
                                to={user ? `../usuario/carrinho${search}` : `../carrinho${search}`}
                                style={{ textShadow: location.pathname.includes('carrinho') ? ' 0.5px 0 0 currentColor, -0.5px 0 0 currentColor' : '' }}
                            >
                                {total > 0 && <span className="text-white" style={{ background: 'green', padding: '5px', borderRadius: '50%' }} id="QntItensCarrinho"> {total}
                                </span>}<i className="fa  fa-shopping-cart"></i>
                                Carrinho                                </Link>
                        </li>
                        <li className="nav-item d-flex">
                            <Link
                                className={`nav-link ${!imageUrl ? ' ' : 'd-flex'} ${location.pathname === '/login' ? 'nav-link font-weight-bold text-white' : 'nav-link'}`}
                                to={user ? `../usuario${search}` : `../login${search}`}
                                style={{ textShadow: location.pathname.includes('login') ? ' 0.5px 0 0 currentColor, -0.5px 0 0 currentColor' : '' }}
                            >
                                {imageUrl &&
                                    <img className="d-block rounded-circle mr-1"
                                        href={imageUrl ? `../usuario${search}` : `../login${search}`}
                                        style={{
                                            maxHeight: '24px',
                                            minHeight: '24px',
                                            maxWidth: '24px',
                                            minWidth: '24px',
                                        }}
                                        src={imageUrl}
                                        id="FaceFrontalLogin"
                                        alt="Imagem do Usuário"
                                    />
                                }
                                {!user &&
                                    <i
                                        className="fa fa-user-circle mr-1"
                                        style={{ scale: '1.4' }}
                                    />}
                                <span
                                    style={{ textShadow: location.pathname === '/usuario' ? ' 0.5px 0 0 currentColor, -0.5px 0 0 currentColor' : '' }}
                                    className={` ${location.pathname === `/usuario` ? ' font-weight-bold text-white' : ''}`}>
                                    {imageUrl ? "Usuario" : 'Entrar'}
                                </span>{screenWidth <= 1200 ? <>&emsp;</> : null}
                            </Link>
                            <Link
                                className={`nav-link ${location.pathname === `/cadastrar` || location.pathname.includes('/usuario/cadastro') ? ' font-weight-bold text-white' : 'nav-link'}`}
                                to={user ? `../usuario/cadastro${search}` : `../cadastrar${search}`}
                                style={{ textShadow: location.pathname.includes('cadastrar') || location.pathname.includes('/usuario/cadastro') ? ' 0.5px 0 0 currentColor, -0.5px 0 0 currentColor' : '' }}
                            >
                                {!user ? (<i className="fa fa-user-plus mr-1" ></i>) :
                                    (<i className="fa fa-id-badge mr-1" ></i>)
                                }
                                {user ? "Cadastro" : 'Cadastrar'}
                            </Link>
                        </li>
                    </ul>
                    <form className={` ${screenWidth >= 1200 ? 'collapse' : ' py-1 w-100 collapse show'}`} style={{ opacity: '1' }}>
                        <SearchBar />
                    </form>
                </div>
                {isNavbarCollapsed ? (<>
                    {screenWidth <= 1200 && <SearchBar />}
                    <Link className="navbar-toggler navbar-togglerl-left border-0 collapse show" to={'../carrinho' + search} >
                        <i className="fa text-white fa-shopping-cart"></i>
                    </Link>
                </>) : null}
                {isNavbarCollapsed ? (<>
                    {screenWidth <= 1200 && <>
                        {!user &&
                            <Link className="navbar-toggler  navbar-togglerl-left border-0 collapse show"
                                to={user ? `../usuario${search}` : `../login${search}`} >
                                <i className="fa fa-user-circle text-white "></i>
                            </Link>}
                        {user && imageUrl && (<>
                            <Link to={imageUrl ? `../usuario${search}` : `../login${search}`} >
                                <img className="d-block rounded-circle "
                                    style={{
                                        maxHeight: '25px',
                                        minHeight: '25px',
                                        maxWidth: '25px',
                                        minWidth: '25px',
                                        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.5), 0 5px 15px rgba(0, 0, 0, 0.2)"
                                    }}
                                    src={imageUrl}
                                    id="FaceFrontalLogin"
                                    alt="Imagem do Usuário"
                                />
                            </Link>
                        </>)}
                    </>}</>) : null}
            </div>
        </nav>
        <ConsoleMessage />
    </>)
}
export default Nav;
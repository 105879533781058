import React from 'react';
import SoftwareHouseInfo from '../info/SoftwareHouseInfo';
import { ButtonCadastrar, MaisInfoMenosInfo, TitleL1, TitleL2 } from "@components";
import { Divider, Image } from 'antd';
export const SoftwareHouse = () => {
    const clicarNoCadastroDeParcerias = () => {
        const elementoCadastroParcerias = document.getElementById('cadastroParcerias');
        if (elementoCadastroParcerias) {
            elementoCadastroParcerias.click();
        }
    };
    const clicarNodadosDeParcerias = () => {
        const elementoCadastroParcerias = document.getElementById('dadosDeParcerias');
        if (elementoCadastroParcerias) {
            elementoCadastroParcerias.click();
        }
    };
    return (<>
        <section className='mr-3 ml-3' style={{ minHeight: '95dvh' }}>
            <p className='text-center'>
                <img className="d-block image-fluid mx-auto col-md-12"
                    style={{ maxHeight: '450px', maxWidth: '450px' }}
                    src={require('../assets/SoftwareHouse.png')} alt='Imagem de lâmpada moderna para representar a ideia e o desenvolvimento' />
            </p>
            <TitleL1>Desenvolvimento de Projetos</TitleL1>
            <h5 className=" mb-5 text-dark text-justify ">
                Tem uma boa ideia para o desenvolvimento de softwares e/ou equipamentos.
                Desenvolvemos ou auxiliamos o projeto, veja temos experiência com
                desenvolvimento de
            </h5>
            <div className='mt-3'>
                <h5>✅ IA (Inteligência Artificial) </h5>
                <h5>✅ RM (Realidade Mista)</h5>
                <h5>✅ IoT (Internet das Coisas)</h5>
                <h5>✅ Big Data </h5>
                <h5>✅ Web Dev.</h5>
                <h5>✅ E afins </h5>
            </div>
            <div className='d-flex flex-wrap justify-content-center border mt-5 py-3 rounded align-items-center mx-auto'>
                <div className='col-md-12'><TitleL2>Automatize Trabalhos</TitleL2></div>
                <Divider />
                <div className='col-md-6 '>
                    <h5 className=' text-justify '>
                        Conecte-se e tenha acesso à equipe especializada em desenvolvimento de tecnologias computacionais avançadas.
                        Estamos aqui para ajudar você a transformar suas ideias em realidade.
                        Seja qual for o seu projeto ou desafio tecnológico nossa experiência
                        e expertise estão prontas para impulsioná-lo rumo à automação e ao sucesso</h5>
                </div>
                <div className='col-md-6 text-center mb-5'>
                    <Image className="d-block image-fluid mx-auto "
                        preview={false}
                        style={{ maxHeight: '450px', maxWidth: '450px', minHeight: '280px' }} x
                        src={require('../../../../../pages/Public/Home/partials/assets/images/parceriasRobo.png')}
                        alt='Imagem de robôs sentados em frente a computadores representando a equipe e o atendimento às parcerias.'
                    />
                </div>
                <Divider />
                <div className='col-md-12  align-items-center rounded mx-auto mt-2 mb-2'>
                    <h5
                        className='text-justify d-flex  text-dark mt-2'>
                        Conosco o seu desenvolvimento tecnológico a vai à um novo patamar.
                        Entre em contato agora mesmo e descubra como podemos fazer a diferença para o seu negócio
                    </h5>
                </div>
            </div>
            <br />
            <h3 className="text-dark text-left ml-2 py-5  mt-5" style={{ fontWeight: '600', fontSize: '1.5em' }}>
                <b>Benefícios de ter uma equipe especializada:</b>  </h3>
            <div className=" mt-3 text-justify">
                <h5 className="ml-3 mt-2">✅ Maior eficiência no desenvolvimento de equipamentos e softwares</h5>
                <h5 className="ml-3 mt-2">✅ Qualidade superior nos produtos finais</h5>
                <h5 className="ml-3 mt-2">✅ Capacidade de enfrentar desafios técnicos complexos</h5>
                <h5 className="ml-3 mt-2">✅ Inovação constante e acompanhamento das últimas tendências</h5>
                <h5 className="ml-3 mt-2">✅ Rápida resolução de problemas e suporte técnico especializado</h5>
                <h5 className="ml-3 mt-2">✅ Entrega dentro do prazo e orçamento</h5>
                <h5 className="ml-3 mt-2">✅ Melhorias contínuas e atualizações do produto</h5>
                <h5 className="ml-3 mt-2">✅ Integração perfeita com outras tecnologias e sistemas</h5>
                <h5 className="ml-3 mt-2">✅ Segurança e conformidade com regulamentos</h5>
                <h5 className="ml-3 mt-2">✅ Maior vantagem competitiva no mercado</h5>
            </div>
            <div className='py-5 d-flex flex-wrap justify-content-center '>
                <div className="col-md-3 mt-5 border rounded bg-light text-center  py-4 no-drag"
                    style={{
                        padding: '5px',
                        marginLeft: '5px',
                        marginRight: "5px",
                    }}>
                    <a onClick={clicarNoCadastroDeParcerias} href='#cadastroParcerias'>
                        <i className="fa-3x">🗳️ </i>
                        <h5 className="mt-3 font-weight-bold">Cadastrar Projetos </h5>
                        <h3 className="font-weight-bold py-3">👆</h3>
                    </a>
                </div>
                <div className="col-md-3 mt-5 border rounded bg-light text-center  py-4 no-drag"
                    style={{
                        padding: '5px',
                        marginLeft: '5px',
                        marginRight: "5px",
                    }}>
                    <a onClick={clicarNodadosDeParcerias} href='#dadosDeParcerias'>  <i className="fa-3x ">📊</i>
                        <h5 className="mt-3 font-weight-bold">Dados de Projetos</h5>
                        <h3 className="font-weight-bold py-3 ">👆</h3>
                    </a>
                </div>
            </div>
            <div className='py-4 text-center'>
                <MaisInfoMenosInfo text={<><SoftwareHouseInfo /></>} />
            </div>
            <ButtonCadastrar />
        </section>
    </>)
}
export default SoftwareHouse;
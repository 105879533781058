import React from 'react';
const ProntuarioChasFitoterapiaSuplementosInfo = () => {
  return (
    <div>
      <h5>Usando o Componente <strong>Chas Fitoterapia Suplementos</strong></h5>
      <p>
        O componente <strong>Chas Fitoterapia Suplementos</strong> permite que você registre informações sobre o uso de chás, fitoterapia e suplementos. Ele oferece uma maneira conveniente de adicionar, editar e remover esses itens. Abaixo, você encontrará um guia de como usar este componente.
      </p>
<hr/>
<ol className='ml-3'>
      <li><strong>Indicar o uso de chás, fitoterapia e suplementos</strong></li>
      <p>
        Quando você abre o componente, você verá a pergunta "- Faz uso de chás / fitoterapia/ suplementos?" e duas opções: "Sim" e "Não". Marque a opção correspondente para indicar se você faz uso desses itens.
      </p>
      <li><strong>Adicionar chás, fitoterapia ou suplementos</strong></li>
      <p>
        Se você marcar "Sim," uma seção adicional aparecerá onde você pode adicionar informações sobre esses itens, incluindo finalidade, nome, dose e posologia. Preencha os campos apropriados e clique no botão "Adicionar Chá / Fitoterapia / Suplementos" para inserir as informações na lista.
      </p>
      <li><strong>Editar chás, fitoterapia ou suplementos</strong></li>
      <p>
        Se você quiser editar um item existente na lista, clique no botão "Editar" ao lado dele. Isso preencherá os campos com as informações desse item para edição. Faça as alterações necessárias e clique no botão "Adicionar Chá / Fitoterapia / Suplementos" para salvar as alterações.
      </p>
      <li><strong>Remover chás, fitoterapia ou suplementos</strong></li>
      <p>
        Se você deseja remover um item da lista, clique no botão "Remover" ao lado dele. O item será excluído da lista.
      </p>
      <li><strong>Finalização</strong></li>
      <p>
        Depois de adicionar, editar ou remover itens, você pode continuar usando o componente conforme necessário. Quando terminar, você pode clicar no botão "ADICIONAR CHÁ / FITOTERAPIA / SUPLEMENTOS" no final do componente para concluir o processo.
      </p>
      </ol>
      <hr/>
      <p>
        Agora você está pronto para usar o componente <strong>Chas Fitoterapia Suplementos</strong> para gerenciar informações sobre chás, fitoterapia e suplementos de forma fácil e eficaz. Certifique-se de marcar corretamente se faz uso desses itens e de adicionar/editar/remover as informações conforme necessário.
      </p>
      <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br /> 
    </div>
  );
};
export default ProntuarioChasFitoterapiaSuplementosInfo;
import React, { useState } from 'react';
import { Select } from 'antd';
const { Option } = Select;
const ProfissionalVinculadoCard = ({ companyUnit, onDelete, onAdicionar, typo, cboOptions, selectedCBO, setSelectedCBO, setType, type, allowedOptions }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const handleDeleteClick = () => {
    onDelete(companyUnit.company_id);
  };
  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const handleAdicionarClick = () => {
    onAdicionar(companyUnit.company_id, type, selectedCBO);
  };
  const handleCBOChange = value => {
    setSelectedCBO(value);
  };
  const isSubscribed = false;
  return (<>
    <div className="col-md-3 mt-2 rounded"
      style={{
        backgroundColor: '#fff', /* Cor do background */
        padding: '5px', /* Espaçamento interno em todos os lados */
        marginLeft: '5px', // Add margin of 5 pixels to the left
        marginRight: "5px", // Add margin of 5 pixels to the right
      }}>
      <div className="card-body">
        <h5 className="card-title">{companyUnit.unit_type}</h5>
        <p className="card-text">Nome:<b> {companyUnit.company_name}</b></p>
        <p className="card-text">Company ID: <b>{companyUnit.company_id}</b></p>
        <p className="card-text">Address ID:<b> {companyUnit.address_id}</b></p>
        <p className="card-text">State: {companyUnit.state}</p>
        <Select
          defaultValue=""
          style={{ width: 200, marginBottom: 10 }}
          onChange={setType}
        >
          <Option value="">Selecione o tipo</Option>
          {allowedOptions.map(option => (
            <Option key={option} value={option}>{option}</Option>
          ))}
        </Select>
        <Select
          defaultValue=""
          style={{ width: 200, marginBottom: 10 }}
          onChange={handleCBOChange}
        >
          <Option value="">Selecione o CBO</Option>
          {Object.entries(cboOptions).map(([key, value]) => (
            <Option key={key} value={key}>
              {value}
            </Option>
          ))}
        </Select>
        <div className="dropdown">
          <button className="btn btn-secondary dropdown-toggle" onClick={handleDropdownToggle}>
            Ações
          </button>
          {isDropdownOpen && (
            <div className="dropdown-menu" style={{ display: "block" }}>
              {!isSubscribed ? (<>
                <button className="btn btn-secondary" onClick={handleAdicionarClick}>
                  Adicionar {typo}
                </button>
              </>) : (<>
                <p className="dropdown-item">Você está inscrito nesta empresa</p>
                <button className="dropdown-item" onClick={handleDeleteClick}>
                  Deletar Cadastro
                </button>
              </>)}
            </div>
          )}
        </div>
      </div>
    </div>
  </>);
};
export default ProfissionalVinculadoCard;
import { Divider, Image } from "antd";
import {
  imgIntraOralFrontal,
  imgIntraOralLateralDireita,
  imgIntraOralLateralEsquerda,
  imgIntraOralOclusalInferior,
  imgIntraOralOclusalSuperior
} from "../assets"
import { TitleL4, TitleL3 } from "@components";
export const SuperiorPhoto = () => (<>
  <TitleL3 id="SuperiorPhoto" level={3}>Análise Intraoral Superior</TitleL3>
  <Divider style={{ margin: '0px', borderColor: 'black', borderWidth: '3px', marginBottom: '10px' }} />
  <h4> Entrada de imagem para análise intraoral</h4>
  <TitleL4>Imagens exemplo:</TitleL4>
  <div className='row text-center'>
    <div className='col-md-1 border py-1' style={{ padding: '0px' }}>
      <Image src={imgIntraOralOclusalSuperior} alt="Exemplo 2" width={100} height={100} /><br />
      <small>Intraoral Superior</small>
    </div>
  </div>
  <p><strong>Descrição:</strong> Captura a vista oclusal da arcada superior, permitindo avaliar a disposição dos dentes superiores e a relação entre eles.</p>
  <p><strong>Importância clínica:</strong> Ajuda na análise da mordida e no planejamento de tratamentos ortodônticos.</p>
  <p><strong>Melhores práticas para captura:</strong> Usar um afastador bucal e uma iluminação adequada para melhor visibilidade.</p>
  <p><strong>Erros comuns:</strong> Inclinação incorreta da câmera ou sombras excessivas podem dificultar a análise.</p>
</>
); export const InferiorPhoto = () => (<>
  <TitleL3 id="InferiorPhoto" level={3}>Análise Intraoral Inferior</TitleL3>
  <Divider style={{ margin: '0px', borderColor: 'black', borderWidth: '3px', marginBottom: '10px' }} />
  <h4> Entrada de imagem para análise intraoral</h4>
  <TitleL4>Imagens exemplo:</TitleL4>
  <div className='row text-center'>
    <div className='col-md-1 border py-1' style={{ padding: '0px' }}>
      <Image src={imgIntraOralOclusalInferior} alt="Exemplo 2" width={100} height={100} /><br />
      <small>Intraoral Inferior</small>
    </div>
  </div>
  <p><strong>Descrição:</strong> Captura a vista oclusal da arcada inferior, permitindo avaliar a disposição dos dentes inferiores e sua relação com a mordida.</p>
  <p><strong>Importância clínica:</strong> Essencial para avaliar desgaste dentário e alinhamento mandibular.</p>
  <p><strong>Melhores práticas para captura:</strong> Pedir ao paciente para inclinar levemente a cabeça para trás para um melhor ângulo.</p>
  <p><strong>Erros comuns:</strong> Posicionamento muito próximo pode distorcer a imagem.</p>
</>);
export const FrontalFechadaPhoto = () => (<>
  <TitleL3 id="FrontalFechadaPhoto" level={3}>Análise Intraoral Frontal</TitleL3>
  <Divider style={{ margin: '0px', borderColor: 'black', borderWidth: '3px', marginBottom: '10px' }} />
  <h4> Entrada de imagem para análise intraoral primária</h4>
  <TitleL4>Imagens exemplo:</TitleL4>
  <div className='row text-center'>
    <div className='col-md-1 border py-1' style={{ padding: '0px' }}>
      <Image src={imgIntraOralFrontal} alt="Exemplo 2" width={100} height={100} /><br />
      <small>Intraoral frontal</small>
    </div>
  </div>
  <p><strong>Descrição:</strong> Imagem frontal com a boca fechada para avaliar o alinhamento e a simetria dos dentes frontais.</p>
  <p><strong>Importância clínica:</strong> Usada para diagnóstico de desvio da linha média e assimetrias faciais.</p>
  <p><strong>Melhores práticas para captura:</strong> Garantir que o paciente esteja olhando diretamente para a câmera.</p>
  <p><strong>Erros comuns:</strong> Desalinhamento da câmera pode afetar a percepção da simetria.</p>
</>);
export const LateralEsquerdaPhoto = () => (<>
  <TitleL3 id="LateralEsquerdaPhoto" level={3}>Análise Intraoral Esquerda</TitleL3>
  <Divider style={{ margin: '0px', borderColor: 'black', borderWidth: '3px', marginBottom: '10px' }} />
  <h4> Entrada de imagem para análise intraoral</h4>
  <TitleL4>Imagens exemplo:</TitleL4>
  <div className='row text-center'>
    <div className='col-md-1 border py-1' style={{ padding: '0px' }}>
      <Image src={imgIntraOralLateralEsquerda} alt="Exemplo 2" width={100} height={100} /><br />
      <small>Intraoral esquerda</small>
    </div>
  </div>
  <p><strong>Descrição:</strong> Captura da vista lateral esquerda com a boca fechada, útil para avaliar a mordida e relação entre os dentes laterais esquerdos.</p>
  <p><strong>Importância clínica:</strong> Ajuda a visualizar o relacionamento oclusal posterior e discrepâncias de mordida.</p>
  <p><strong>Melhores práticas para captura:</strong> Manter a câmera paralela à linha do perfil do paciente.</p>
  <p><strong>Erros comuns:</strong> Inclinar a cabeça do paciente pode resultar em uma visão distorcida.</p>
</>);
export const LateralDireitaPhoto = () => (<>
  <TitleL3 id="LateralDireitaPhoto" level={3}>Análise Intraoral Direita</TitleL3>
  <Divider style={{ margin: '0px', borderColor: 'black', borderWidth: '3px', marginBottom: '10px' }} />
  <h4> Entrada de imagem para análise intraoral</h4>
  <TitleL4>Imagens exemplo:</TitleL4>
  <div className='row text-center'>
    <div className='col-md-1 border py-1' style={{ padding: '0px' }}>
      <Image src={imgIntraOralLateralDireita} alt="Exemplo 2" width={100} height={100} /><br />
      <small>Intraoral direita</small>
    </div>
  </div>
  <p><strong>Descrição:</strong> Captura da vista lateral direita com a boca fechada, permitindo verificar o alinhamento e a relação dos dentes do lado direito.</p>
  <p><strong>Importância clínica:</strong> Fundamental para avaliação de discrepâncias na mordida lateral.</p>
  <p><strong>Melhores práticas para captura:</strong> Usar um suporte estável para evitar tremores na imagem.</p>
  <p><strong>Erros comuns:</strong> Zoom excessivo pode cortar partes importantes da imagem.</p>
</>);
export const DocumentosSoftwareAnaliseIntraOral = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
      <FrontalFechadaPhoto />
      <SuperiorPhoto />
      <InferiorPhoto />
      <LateralEsquerdaPhoto />
      <LateralDireitaPhoto />
    </div>
  );
};
export default DocumentosSoftwareAnaliseIntraOral;
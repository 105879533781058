import { Nav, Footer } from '../../../components';
import { LoginMain } from './partials/layout/LoginMain';
export const Login = () => {
  return (<>
    <Nav />
    <section style={{ minHeight: "85dvh",alignContent:'center', alignItems:'center' }} >
      <LoginMain /> 
      </section>
    <Footer />
  </>)
};
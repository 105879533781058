import React, { useState } from 'react';
import { Tooltip, Button } from 'antd';
import { MdDownload } from 'react-icons/md';
export const ButtonDownload = (props) => {
  const [open, setOpen] = useState(false); // Renomeando 'visible' para 'open'
  const [iconColor, setIconColor] = useState('#fff'); // Cor padrão do ícone
  return (
    <div style={{ position: 'fixed', bottom: '100px', right: '-35px', zIndex: '1000' }}>
      <Tooltip
        title="Baixar o laudo? Clique"
        open={open}
       onMouseEnter={() => { setOpen(true); setIconColor('#000'); }} // Abre o Tooltip e muda a cor ao passar o mouse
        onMouseLeave={() => { setOpen(false); setIconColor('#fff'); }} // Fecha o Tooltip e restaura a cor ao remover o mouse
    >
        <Button
          shape="circle"
          className='btn-primary'
          id={props.id}
          style={{
            color: '#fff',
            border: 'none',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2), inset 0 -4px 5px rgba(255, 255, 255, 0.4)',
            transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
            width: '80px',
            height: '80px',
          }}
          onClick={props.onClick}
        >
          <MdDownload size={30} color={iconColor} style={{marginRight:'15px'}} /> {/* Controle da cor do ícone via estado */}
        </Button>
      </Tooltip>
    </div>
  );
};
export default ButtonDownload;
export const landmarks_media_pipe_position_xy = (points, imgWidthHalf, imgHeightHalf, imageXDir, imageYDir, imageX, imageY) => {
    const getPointValue = (point, coord) =>
        point && point[coord] !== undefined && !isNaN(point[coord]) ? point[coord] : 0;
    return {
        x3: imageX + getPointValue(points.point3, 'x') * imgWidthHalf,
        y3: imageY + getPointValue(points.point3, 'y') * imgHeightHalf,
        y375: imageY + getPointValue(points.point375, 'y') * imgHeightHalf,
        x9: imageX + getPointValue(points.point9, 'x') * imgWidthHalf,
        y9: imageY + getPointValue(points.point9, 'y') * imgHeightHalf,
        y469: imageY + getPointValue(points.point469, 'y') * imgHeightHalf,
        x469: imageX + getPointValue(points.point469, 'x') * imgWidthHalf,
        x177: imageX + getPointValue(points.point177, 'x') * imgWidthHalf,
        y177: imageY + getPointValue(points.point177, 'y') * imgHeightHalf,
        y15: imageY + getPointValue(points.point15, 'y') * imgHeightHalf,
        x153: imageX + getPointValue(points.point153, 'x') * imgWidthHalf,
        y153: imageY + getPointValue(points.point153, 'y') * imgHeightHalf,
        x5: imageXDir + getPointValue(points.point5, 'x') * imgWidthHalf,
        x297: imageXDir + getPointValue(points.point297, 'x') * imgWidthHalf,
        x44: imageXDir + getPointValue(points.point44, 'x') * imgWidthHalf,
        y44: imageYDir + getPointValue(points.point44, 'y') * imgHeightHalf,
        x49: imageXDir + getPointValue(points.point49, 'x') * imgWidthHalf,
        y49: imageYDir + getPointValue(points.point49, 'y') * imgHeightHalf,
        x56: imageXDir + getPointValue(points.point56, 'x') * imgWidthHalf,
        x133: imageXDir + getPointValue(points.point133, 'x') * imgWidthHalf,
        y133: imageYDir + getPointValue(points.point133, 'y') * imgHeightHalf,
        x134: imageXDir + getPointValue(points.point134, 'x') * imgWidthHalf,
        y134: imageYDir + getPointValue(points.point134, 'y') * imgHeightHalf,
        x136: imageXDir + getPointValue(points.point136, 'x') * imgWidthHalf,
        y136: imageYDir + getPointValue(points.point136, 'y') * imgHeightHalf,
        x128: imageXDir + getPointValue(points.point128, 'x') * imgWidthHalf,
        x67: imageXDir + getPointValue(points.point67, 'x') * imgWidthHalf,
        x71: imageXDir + getPointValue(points.point71, 'x') * imgWidthHalf,
        x106: imageXDir + getPointValue(points.point105, 'x') * imgWidthHalf,
        x131: imageXDir + getPointValue(points.point131, 'x') * imgWidthHalf,
        x161: imageXDir + getPointValue(points.point161, 'x') * imgWidthHalf,
        y161: imageYDir + getPointValue(points.point161, 'y') * imgHeightHalf,
        x248: imageXDir + getPointValue(points.point248, 'x') * imgWidthHalf,
        y248: imageYDir + getPointValue(points.point248, 'y') * imgHeightHalf,
        x227: imageXDir + getPointValue(points.point227, 'x') * imgWidthHalf,
        x264: imageXDir + getPointValue(points.point264, 'x') * imgWidthHalf,
        x274: imageXDir + getPointValue(points.point274, 'x') * imgWidthHalf,
        y274: imageYDir + getPointValue(points.point274, 'y') * imgHeightHalf,
        x279: imageXDir + getPointValue(points.point279, 'x') * imgWidthHalf,
        y279: imageYDir + getPointValue(points.point279, 'y') * imgHeightHalf,
        x286: imageXDir + getPointValue(points.point286, 'x') * imgWidthHalf,
        x301: imageXDir + getPointValue(points.point301, 'x') * imgWidthHalf,
        x330: imageXDir + getPointValue(points.point330, 'x') * imgWidthHalf,
        y330: imageYDir + getPointValue(points.point330, 'y') * imgHeightHalf,
        x360: imageXDir + getPointValue(points.point360, 'x') * imgWidthHalf,
        y360: imageYDir + getPointValue(points.point360, 'y') * imgHeightHalf,
        x363: imageXDir + getPointValue(points.point363, 'x') * imgWidthHalf,
        y363: imageYDir + getPointValue(points.point363, 'y') * imgHeightHalf,
        x388: imageXDir + getPointValue(points.point388, 'x') * imgWidthHalf,
        y388: imageYDir + getPointValue(points.point388, 'y') * imgHeightHalf,
        x398: imageXDir + getPointValue(points.point398, 'x') * imgWidthHalf,
        y398: imageYDir + getPointValue(points.point398, 'y') * imgHeightHalf,
        x117: imageXDir + getPointValue(points.point117, 'x') * imgWidthHalf,
        y117: imageYDir + getPointValue(points.point117, 'y') * imgHeightHalf,
        x208: imageXDir + getPointValue(points.point208, 'x') * imgWidthHalf,
        y208: imageYDir + getPointValue(points.point208, 'y') * imgHeightHalf,
        x101: imageXDir + getPointValue(points.point101, 'x') * imgWidthHalf,
        y101: imageYDir + getPointValue(points.point101, 'y') * imgHeightHalf,
        x335: imageXDir + getPointValue(points.point335, 'x') * imgWidthHalf,
        x341: imageXDir + getPointValue(points.point341, 'x') * imgWidthHalf,
        y341: imageYDir + getPointValue(points.point341, 'y') * imgHeightHalf,
        x401: imageXDir + getPointValue(points.point401, 'x') * imgWidthHalf,
        y401: imageYDir + getPointValue(points.point401, 'y') * imgHeightHalf,
        x402: imageXDir + getPointValue(points.point402, 'x') * imgWidthHalf,
        y402: imageYDir + getPointValue(points.point402, 'y') * imgHeightHalf,
        x428: imageXDir + getPointValue(points.point428, 'x') * imgWidthHalf,
        y428: imageYDir + getPointValue(points.point428, 'y') * imgHeightHalf,
        x447: imageXDir + getPointValue(points.point447, 'x') * imgWidthHalf,
        x448: imageXDir + getPointValue(points.point448, 'x') * imgWidthHalf,
    };
};
import { useState } from 'react';
import axios from 'axios';
import { baseURL } from '@services';
import { ReferenciaLocal, ButtonAPI, InputPadraoTitle, MaisInfoMenosInfo } from '@components';
import ProspeccaoClientesTelefoneInfo from '../info/ProspeccaoClientesTelefoneInfo';
export const ProspeccaoClientesTelefone = () => {
    const [numbers, setNumbers] = useState('');
    const [message, setMessage] = useState('');
    const [tipoComunicacaoTelefone, setTipoComunicacaoTelefone] = useState('');
    const handleSubmit = (event) => {
        event.preventDefault();
        axios.post(`${baseURL}enviar-mensagem`, { numbers, message })
            .then((response) => {
                alert(response);
            })
            .catch((error) => {
                alert(error);
            });
    };
    const handleNumbersChange = (event) => {
        setNumbers(event.target.value);
    };
    const handleMessageChange = (event) => {
        setMessage(event.target.value);
    };
    const handleTipoComunicacaoTelefoneChange = (event) => {
        setTipoComunicacaoTelefone(event.target.value);
    };
    return (
        <>
            <ReferenciaLocal text={'Prospecção via Telefone'} />
            <div>
                <form onSubmit={handleSubmit}>
                    <InputPadraoTitle
                        htmlFortitle={'Tipo de comunicação'}
                        title={'Tipo de comunicação'}
                    />
                    <select className="form-control text-center col-md-12 border border-dark mb-3" value={tipoComunicacaoTelefone} onChange={handleTipoComunicacaoTelefoneChange}>
                        <option value="">Selecione uma sub-opção</option>
                        <option value="WatsApp">WatsApp</option>
                        <option value="Msg">Msg</option>
                        <option value="Ambos">Ambos</option>
                    </select>
                    <InputPadraoTitle
                        htmlFortitle={'Números de telefone (separados por vírgula):'}
                        title={'Números de telefone (separados por vírgula):'}
                    />
                    <input
                        className="form-control text-center col-md-12 border border-dark mb-3"
                        type="text"
                        value={numbers}
                        onChange={handleNumbersChange}
                    />
                    <InputPadraoTitle
                        htmlFortitle={'Mensagem:'}
                        title={'Mensagem:'}
                    />
                    <textarea
                        className="form-control text-center col-md-12 border border-dark mb-3"
                        value={message}
                        onChange={handleMessageChange}
                    />
                    <ButtonAPI type="submit">Enviar</ButtonAPI>
                </form>
                <div className='py-4 mt-3'>
                   <MaisInfoMenosInfo text={<ProspeccaoClientesTelefoneInfo />}></MaisInfoMenosInfo> 
                </div>
            </div>
        </>
    );
}
export default ProspeccaoClientesTelefone;
import React from 'react';
import { NavBreadcrumb, CardDirecionamento } from '../../../../components';
export const ControllerMedicamentos = () => {
    return (<>
        <div className=' w-100' >
            <NavBreadcrumb icon={'user fa'} text={'Meus Medicamentos'} />
            <div className="py-3">
                <div className="text-center">
                    <div className="container-fluid">
                        <div className="row d-inline-flex justify-content-center w-100">
                            <CardDirecionamento text={'Contínuo'} icon={'mt-3 fa fa-history fa-3x text-white'} href={'../usuario/consultas'} />
                            <CardDirecionamento text={'Ocasiões'}  icon={'mt-3 fa fa-wave-square fa-3x text-white'} href={'../usuario/exames'} />
                            <CardDirecionamento  text={'Estoque'} icon={'mt-3 fa fa-database fa-3x text-white'} href={'./usuario/medicacao'} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    )
}
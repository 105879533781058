import React from 'react';
const MontarGraficosDinamicosInfo = () => {
  return (
    <div>
      <h5>Usando o Componente <strong>Montar Gráficos Dinâmicos</strong></h5>
      <p>
        O componente <strong>Montar Gráficos Dinâmicos</strong> é projetado para ajudá-lo a exibir gráficos dinamicamente com base em dados do componente <strong>Criação de Gráficos</strong>. Ele fornece uma interface para criar vários gráficos com base nos dados fornecidos.
      </p>
      <hr />
      <ol className='ml-3'>
        <li><strong>Visualização de Gráficos</strong></li>
        <p>Quando você utiliza o componente, ele irá gerar automaticamente gráficos com base nos dados de perguntas e respostas fornecidos no componente <strong>Criação de Gráficos</strong>. Cada gráfico é gerado de acordo com as configurações da pergunta, como tipo de gráfico, coluna e dados de resposta.
        </p>
        <li><strong>Configuração de Perguntas e Respostas</strong></li>
        <p>Para usar este componente, você precisa fornecer um conjunto de perguntas e respostas no componente <strong>Criação de Gráficos</strong>. Cada pergunta deve incluir informações como o tipo de gráfico desejado, a coluna de dados a ser usada e os dados de resposta correspondentes.
        </p>
        <li><strong>Exibição de Gráficos</strong></li>
        <p>Os gráficos gerados serão exibidos na área primeira área superior. Cada gráfico é gerado com base nas configurações fornecidas e nos dados correspondentes.
        </p>
        <li><strong>Edição e Remoção de Gráficos</strong></li>
        <p>Você pode editar ou remover gráficos existentes com base nas configurações do componente <strong>Criação de Gráficos</strong> perguntas e respostas. Para fazer isso, basta fornecer novos dados de perguntas e respostas e o componente irá atualizar automaticamente os gráficos.
        </p>
        <li><strong>Finalização</strong></li>
        <p>Após configurar suas perguntas e respostas e visualizar os gráficos gerados, você pode continuar usando o componente conforme necessário. Certifique-se de atualizar os dados de perguntas e respostas sempre que desejar gerar novos gráficos ou editar os existentes.
        </p>
      </ol>
      <hr />
      <p>
        Agora você está pronto para usar o componente <strong>Montar Gráficos Dinâmicos</strong> para exibir gráficos com facilidade e flexibilidade com base nos dados fornecidos. Certifique-se de fornecer as perguntas e respostas adequadas para gerar os gráficos desejados.
      </p>
      <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />
    </div>
  );
};
export default MontarGraficosDinamicosInfo;
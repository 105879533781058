import { apiToken } from "../../../../../contexts";
export const handleSendImage = async (id, image, setIsLoading, setImageUpdated, fetchUserPhoto, setImage, onPhotoUpdated) => {
  setIsLoading(true);
  try {
    const formData = new FormData();
    formData.append('id', `${id}`);
    formData.append('file', dataURItoBlob(image));
    await apiToken().post(`fotoFace/${id}`, formData);
    setImageUpdated(true);
    fetchUserPhoto(id, setImage);
    onPhotoUpdated();
    alert('Imagem enviada com sucesso!');
  } catch (error) {
    console.error(error);
    alert('Erro ao enviar a imagem.');
  } finally {
    setIsLoading(false);
  }
};
const dataURItoBlob = (dataURI) => {
  const byteString = atob(dataURI.split(',')[1]);
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([ab], { type: mimeString });
  return blob;
};
import React, { useState } from 'react';
import { ButtonAdicionarCampo, ButtonEditarCampo, ButtonRemoverCampo } from '@components';
const ProntuarioDentistaEscovarDentes = () => {
  const [escovarDentes, setEscovarDentes] = useState(false);
  const [escovarDentesHistorico, setEscovarDentesHistorico] = useState([]);
  const [novaQuantidade, setNovaQuantidade] = useState('');
  const [indiceEditando, setIndiceEditando] = useState(-1);
  const handleEscovarDentesChange = (e) => {
    setEscovarDentes(e.target.checked);
  };
  const handleQuantidadeEscovaChange = (e) => {
    setNovaQuantidade(e.target.value);
  };
  const handleAdicionarQuantidade = () => {
    if (novaQuantidade.trim() !== '') {
      if (indiceEditando === -1) {
        console.log("Adicionando nova quantidade:", novaQuantidade);
        setEscovarDentesHistorico([...escovarDentesHistorico, novaQuantidade]);
      } else {
        const novaLista = [...escovarDentesHistorico];
        novaLista[indiceEditando] = novaQuantidade;
        console.log("Editando quantidade existente:", novaQuantidade);
        setEscovarDentesHistorico(novaLista);
        setIndiceEditando(-1);
      }
      setNovaQuantidade('');
    }
  };
  const handleRemoverQuantidade = (index) => {
    const novaLista = [...escovarDentesHistorico];
    novaLista.splice(index, 1);
    console.log("Removendo quantidade no índice", index);
    setEscovarDentesHistorico(novaLista);
  };
  const handleEditarQuantidade = (index) => {
    setNovaQuantidade(escovarDentesHistorico[index]);
    setIndiceEditando(index);
  };
  console.log("EscovarDentes:", escovarDentes);
  console.log("EscovarDentesHistorico:", escovarDentesHistorico);
  console.log("Nova Quantidade:", novaQuantidade);
  return (
    <>
      <h5 style={{ fontWeight: "bold" }}>
        Escova os dentes
      </h5>
      <label>
        <input
          type="checkbox"
          defaultChecked={escovarDentes}
          onChange={handleEscovarDentesChange}
        />{' '}Sim<br />
        <input
          type="checkbox"
          defaultChecked={!escovarDentes}
          onChange={handleEscovarDentesChange}
        />{' '}
        Não
      </label>
      <br />
      {escovarDentes && <>
        <label>
          Quantas vezes escova os dentes por dia? vezes/dia <br/>
          <input
            type="text"
            value={novaQuantidade}
            onChange={handleQuantidadeEscovaChange}
            placeholder="Informe a quantidade"
          />
        </label>
        <ButtonAdicionarCampo onClick={handleAdicionarQuantidade} />
        <ul>
          {escovarDentesHistorico.map((quantidade, index) => (
            <li key={index}>
              {quantidade}
              <ButtonEditarCampo onClick={() => handleEditarQuantidade(index)} />
              <ButtonRemoverCampo onClick={() => handleRemoverQuantidade(index)} />
            </li>
          ))}
        </ul>
      </>}
    </>
  );
};
export default ProntuarioDentistaEscovarDentes;
import React from 'react';
import { ButtonAPI, TitleL2 } from '@components';
export const FinalizarCompra = ({
    finalizarCompra,
    hasItemsToRemove,
    handleDeleteItemZero,
    setCart
}) => {
    return (<>
        <section >
            <div className=' col-md-12  mb-3 mt-5  mx-auto mr-3 ml-3 rounded py-2 justify-content-center  align-items-center text-center' style={{ borderRadius: '20px', }}>
                <TitleL2>Ações centrais</TitleL2>
                <ButtonAPI
                    className="mt-5 mb-5 btn btn-primary  a-87 mx-auto "
                    onClick={finalizarCompra}
                >
                    <b>
                        Finalizar compra  <i className='fa fa-gavel  ml-3 mr-3' style={{ rotate: "-90deg" }} ></i>
                    </b>
                </ButtonAPI>
                {hasItemsToRemove() &&
                    <>
                        <button
                            text="Clear Cart Itens Zerados"
                            className="btn btn-primary mt-5 mb-5 a-88  mx-auto col-md-10"
                            onClick={handleDeleteItemZero}
                        ><i className='fa fa-eraser align-left ml-3 mr-3'></i> LIMPAR ITENS SEM PREÇO OU quantity</button> </>}
                <ButtonAPI
                    text="Clear Cart"
                    className="btn btn-danger mt-5 mb-5 a-89  mx-auto"
                    style={{ background: 'red' }}
                    onClick={() => {
                        setCart([]);
                        localStorage.removeItem('cart');
                    }}
                >Limpar carrinho <i className='fa align-left ml-3 mr-3' >🗑️</i> </ButtonAPI>
            </div>
        </section>
    </>);
};
export default FinalizarCompra;
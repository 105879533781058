import React from 'react';
import { Link } from 'react-router-dom';
export const MainProntuarioPacienteInfo = () => {
  return (<>
    <small><li className="mt-5 text-justify">Prontuário de responsabilidade dividida, sendo
      seu        maior controlador o próprio        usuário !</li>
      <li className=" text-justify"> Fique atento atualize ou verifique
        atualizações/informações sempre após
        uma consulta/procedimento para que possamos tentar manter sua segurança !
      </li>
      <li className=" text-justify"> Compartilhe seu prontuário apenas com quem for seus
        profissionais de saúde co-responsáveis - <Link className="text-primary" >Verifique aqui</Link> !
      </li>
      <li className=" text-justify"><b className="bg-warning">Marcações em amarelo são
        informações de alta
        importância para
        notificação dos profissionais toda vez no qual necessitar de atendimento !</b>
      </li>
      <li className=" text-justify"><b className="bg-danger">Marcações em vermelho são
        informações que precisam ser atualizadas e/ou informadas !</b>
      </li>
      <li className=" text-justify"> Essas informações do prontuário único são um
        compilado mais atual da sua saúde geral !
      </li></small>
    <div className='text-center font-weight-bold mt-3'>
      <small> <strong> Ultima atualização:</strong>
      </small> </div>
  </>);
};
export default MainProntuarioPacienteInfo;
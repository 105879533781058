import React from 'react';
export const InnovationalConducts = () => {
  return (
    <div style={{ padding: '20px', textAlign: 'justify' }}>
      <p>
        Nos comprometemos a adotar práticas que nos tornem financeiramente indispensáveis no mercado.
        Focamos na identificação de oportunidades de inovação e eficiência, antecipando as demandas futuras do setor.
        Nosso propósito é gerar um impacto positivo em todas as nossas ações, promovendo o bem-estar social e ambiental,
        cultivando relacionamentos de confiança com nossos colaboradores, clientes e parceiros.
        Estamos comprometidos com a melhoria constante de nossos processos e produtos,
        promovendo uma cultura de adaptabilidade e criatividade para assegurar que nossas práticas
        estejam sempre alinhadas com as melhores tendências do mercado
      </p>
    </div>
  );
};
export default InnovationalConducts;
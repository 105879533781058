import './style/InputCabecalho.css'; // Certifique-se de importar seu arquivo de estilo
export const InputCabecalho = (props) => {
  return (
    <div className="input-container mt-3">
      <input
        className="input-field form-control form-control-lg text-dark text-center"
        type={props.type}
        id={props.id}
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        onKeyDown={props.onKeyDown}
        placeholder={props.placeholder}
        disabled={props.disabled}
      />  
      <label className='border-top border-left' htmlFor="myInput">{props.children}</label>
    </div>
  );
};
export default InputCabecalho;
import React, { useState } from "react";
import { Tooltip } from "antd";
import { InfoCircleOutlined, CloseCircleOutlined } from "@ant-design/icons"; // Importando ícones do Ant Design
export function IconInfo(props) {
  const [open, setOpen] = useState(false);
  const toggle = (e) => {
    e.preventDefault(); // Evita o comportamento padrão do link
    setOpen(!open); // Alterna entre mostrar e ocultar o texto
  };
  return (<>
    <Tooltip title={open ? "Clique para menos info" : "Clique para mais info 🔎"} placement="top">
      <a href={props.centralC} onClick={toggle} style={props.style}>
        {/* Mudando o ícone e cor baseado no estado "open" */}
        {open ? (
          <>
            <CloseCircleOutlined className="ml-2 text-danger" />
            <span style={{ marginLeft: "5px", color: "red" }}>{props.titleDown}</span> {/* Título ao lado do ícone */}
          </>
        ) : (
          <>
            <InfoCircleOutlined className="ml-2 text-primary" />
            <span style={{ marginLeft: "5px", color: "blue" }}>{props.titleOpen}</span> {/* Título ao lado do ícone */}
          </>
        )}
      </a>
    </Tooltip>
    {open && (
      <div style={{ marginTop: '5px', color: 'black' }}>
        {props.text} {/* Exibe o texto explicativo */}
      </div>
    )}
  </>);
}
export default IconInfo;
import React from "react";
import { data, dataBackEnd } from "./GraficoLinguagem";
import { ButtonCadastrar, ChartBarData, TitleL1, TitleL2 } from "@components";
export const Linguagem = () => {
    return (<>
        <section className="mr-3 ml-3" style={{ minHeight: '95dvh' }}>
            <TitleL1 icon={ <i className="fa fa-language " style={{scale:'2'}}></i>}>Linguagem</TitleL1>
            <h5 className="text-justify mb-3">As linguagens de programação são ferramentas essenciais no desenvolvimento de
                software e sistemas computacionais. Elas permitem que os desenvolvedores comuniquem instruções às máquinas de forma estruturada e lógica
                . A escolha da linguagem de programação adequada depende das necessidades específicas do projeto,
                das preferências da equipe de desenvolvimento e das características técnicas desejadas. Além disso, as linguagens
                de programação estão em constante evolução, com novas versões e frameworks sendo lançados regularmente para melhorar a
                eficiência, a segurança e a facilidade de uso, refletindo a natureza dinâmica e inovadora do campo da tecnologia da informação<br /><br />
                O score de linguaguem da <strong>THOT I.A. CORP. </strong>hoje gira em torno desses gráficos a seguir</h5>
            <div className="row">
                <div className="col-md-6">
                    <TitleL2>Front-end</TitleL2>
                    <ChartBarData data={data} />
                    <p className="text-center"> <small>estimativa atualizada dia 17/12/2023</small></p>
                </div>
                <div className="col-md-6">
                    <TitleL2>Back-end</TitleL2>
                    <ChartBarData data={dataBackEnd} />
                    <p className="text-center"> <small>estimativa atualizada dia 17/12/2023</small></p>
                </div>
            </div>
            <ButtonCadastrar/>
        </section>
    </>)
}
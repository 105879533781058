import React, { useEffect, useState, useCallback } from 'react';
import { Modal, Button } from 'antd';
const DuplicateKeysMonitor = () => {
  const [modalContent, setModalContent] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  // Memorize getElementsWithAttribute
  const getElementsWithAttribute = useCallback((attribute) => {
    const elements = document.querySelectorAll(`[${attribute}]`);
    const values = Array.from(elements).map(el => el.getAttribute(attribute));
    return values;
  }, []);
  // Memorize findDuplicateValues
  const findDuplicateValues = useCallback((values) => {
    const duplicates = values.filter((item, index) => values.indexOf(item) !== index);
    return [...new Set(duplicates)];
  }, []);
  // Memorize updateModalContent
  const updateModalContent = useCallback((title, content) => {
    setModalContent({
      title,
      content: (
        <>
          <div style={{ fontSize: '18px' }}>{content}</div>
          <div id="additional-info" style={{ color: 'black', fontSize: '16px', marginTop: '10px' }} />
          <Button type="primary" onClick={() => {
            const additionalInfo = document.getElementById('additional-info');
            if (additionalInfo) {
              additionalInfo.innerHTML = `
                <div style="color: black; font-size: 16px; margin-top: 10px;">
                  Mais detalhes: Revise os itens listados acima para garantir que não haja duplicatas.
                </div>
              `;
            }
          }}>
            Instruções Adicionais
          </Button>
        </>
      ),
      width: 600,
      maskClosable: false,
      closable: false,
    });
    setModalVisible(true);
  }, []);
  // Memorize checkAttributesInDOM
  const checkAttributesInDOM = useCallback(() => {
    console.log("Verificando atributos no DOM...");
    const ids = getElementsWithAttribute('id');
    const duplicateIds = findDuplicateValues(ids);
    const dataKeys = getElementsWithAttribute('data-key');
    const duplicateDataKeys = findDuplicateValues(dataKeys);
    if (duplicateIds.length > 0) {
      console.warn("IDs Duplicados Encontrados:", duplicateIds);
      updateModalContent(
        'IDs Duplicados Encontrados',
        `IDs duplicados: ${duplicateIds.join(', ')}`
      );
    } else {
      console.info("Nenhum ID Duplicado");
    }
    if (duplicateDataKeys.length > 0) {
      console.warn("Data-keys Duplicados Encontrados:", duplicateDataKeys);
      updateModalContent(
        'Data-keys Duplicados Encontrados',
        `Data-keys duplicados: ${duplicateDataKeys.join(', ')}`
      );
    } else {
      console.info("Nenhum Data-key Duplicado");
    }
  }, [getElementsWithAttribute, findDuplicateValues, updateModalContent]);
  useEffect(() => {
    const handleLoad = () => {
      console.log("Página carregada, iniciando verificação...");
      checkAttributesInDOM();
    };
    window.addEventListener('load', handleLoad);
    // Limpeza do event listener
    return () => {
      window.removeEventListener('load', handleLoad);
    };
  }, [checkAttributesInDOM]);
  useEffect(() => {
    if (modalVisible) {
      Modal.info(modalContent);
      setModalVisible(false);
    }
  }, [modalContent, modalVisible]);
  return null; // Não renderiza nada na UI
};
export default DuplicateKeysMonitor;
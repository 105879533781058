import React from 'react';
import { MaisInfoMenosInfo, TitleL1, TitleL2, ButtonCadastrar } from '@components';
import PlanosPrecosServicosInfo from '../info/PlanosPrecosServicosInfo';
import RepasseAPIPagamento from './RepasseAPIPagamento';
export const PlanosPrecosServicos = () => {
    return (<>
        <section className='mr-3 ml-3' style={{ minHeight: '95dvh' }}>
            <p className='text-center'>
                <img
                    src={require('../assets/PlanosPrecos.png')}
                    alt="Liberdade Financeira"
                    style={{ maxHeight: '280px', maxWidth: '480px' }}
                    className='col-md-12'
                />
            </p>
            <TitleL1>Planos e Preços</TitleL1>
            <h5 className=' text-justify'>
                <strong>Só pague pelo que usar!</strong> Máxima automação sempre! 
                Nosso objetivo é manter você com um alto grau de proficiência no centro de seus negócios, com o mínimo esforço possível.
                Para isso, criamos planos autoescaláveis de forma dinâmica, veja:
            </h5>
            <div className="row d-flex flex-wrap justify-content-center text-white mt-4" style={{ padding: '0px', border: '2px solid', borderRadius: '27px' }}>
                <div className="col-md-4 " style={{
                    padding: '5px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    flexShrink: '0',
                    borderRadius: '33px',
                }}
                >
                    <div className='trl-container rounded'>
                        <TitleL2 style={{ color: 'white' }}>Profissional Único</TitleL2>
                        <div className='text-justify mr-2 ml-2'>
                            <ul className='font-weight-bold'>Benefícios</ul>
                            <ul>
                                <li><i className=' fa fa-check-square text-success'></i>&nbsp;Usuários Ilimitados</li>
                                <li><i className=' fa fa-check-square text-success'></i>&nbsp;E-commerce como sub-domínio</li>
                                <li className='font-weight-bold'><strong><i className=' fa fa-check-square text-success'></i>&nbsp;Comissão de 30% em vendas de exames digitais da plataforma</strong></li>
                                <br />
                            </ul>
                            <ul>
                                <ul className='font-weight-bold mt-3'>Valor</ul>
                                <li className='font-weight-bold'>💳 Taxa de mov. bruta: 1.5%</li>
                                <li className='font-weight-bold '>💳 Mais repasse de API de 3ª: NFe, etc..</li><br />
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 " style={{
                    padding: '5px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    flexShrink: '0',
                    borderRadius: '33px',
                }}
                >
                    <div className='trl-container rounded'>
                        <TitleL2 style={{ color: 'white' }} >Até 5 profissionais</TitleL2>
                        <div className='text-justify mr-2 ml-2'>
                            <ul className='font-weight-bold'>Benefícios</ul>
                            <ul>
                                <li><i className=' fa fa-check-square text-success'></i>&nbsp;Usuários Ilimitados</li>
                                <li><i className=' fa fa-check-square text-success'></i> E-commerce como sub-domínio</li>
                                <li className='font-weight-bold'><strong><i className=' fa fa-check-square text-success'></i> Comissão de 50% em vendas de exames digitais da plataforma</strong></li>
                                <br />
                            </ul>
                            <ul>
                                <ul className='font-weight-bold mt-3'>Valor</ul>
                                <li className='font-weight-bold '>💳 Taxa de mov. bruta: 2.5%</li>
                                <li className='font-weight-bold '>💳 Mais repasse de API de 3ª: NFe, etc..</li>
                                <br />
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex" style={{
                    padding: '5px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    flexShrink: '0',
                    borderRadius: '33px',
                }}
                >
                    <div className='trl-container rounded'>
                        <TitleL2 style={{ color: 'white' }}>Profissionais Ilimitados</TitleL2>
                        <div className='text-justify mr-2 ml-2'>
                            <ul className='font-weight-bold'>Benefícios</ul>
                            <ul>
                                <li><i className=' fa fa-check-square text-success'></i>&nbsp;Usuários Ilimitados</li>
                                <li><i className=' fa fa-check-square text-success'></i>&nbsp;E-commerce com sub ou domínio</li>
                                <li className='font-weight-bold'><strong><i className=' fa fa-check-square text-success'></i>&nbsp;Comissão de 70% em vendas de exames digitais da plataforma</strong></li>
                                <li className='font-weight-bold'><i className=' fa fa-check-square text-success'></i>&nbsp; Adição de alunos</li>
                            </ul>
                            <ul>
                                <ul className='font-weight-bold mt-3'>Valor</ul>
                                <li className='font-weight-bold '>💳 Taxa de mov. bruta: 5%</li>
                                <li className='font-weight-bold '>💳 Mais repasse de API de 3ª: NFe, etc..</li>
                                <li className='font-weight-bold '>💳 Hospedagem, domínio e similares</li></ul>
                        </div>
                    </div>
                </div>
                <br /><br />
                <small className="text-dark mt-3">O justo é justo! O uso do sistema é open-source [ gratuito ] até o momento da movimentação real.
Isso significa que você pode criar sua conta e configurar seu sistema sem custos. Quando o sistema detectar vendas ou transações, o valor do plano será automaticamente deduzido da transação, caso ela seja processada pelo nosso sistema de pagamentos.
Se a transação for realizada por um meio externo, o valor será acumulado para dedução em uma futura transação conosco. Caso todas as transações sejam externas, a cobrança será gerada mensalmente com base nas transações registradas na plataforma.</small>
            </div>
            <PlanosPrecosServicosInfo />
            <div className='py-4 text-center'>
                <MaisInfoMenosInfo text={<>  <RepasseAPIPagamento /> </>} />
            </div>
            <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />
            <ButtonCadastrar />
            <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />
        </section>
    </>);
};
export default PlanosPrecosServicos;
import { BodyTable } from "./BodyTable";
import { HeadTable } from "./HeadTable";
import {ItemTable} from "./ItemTable";
import { TitleTable } from "./TitleTable";
import { TableContainer } from "./TableContainer.jsx";
import { RowTable } from "./RowTable";
import './table.css' 
export const Table = {
  Container : TableContainer,
  Body : BodyTable,
  Head : HeadTable,
  Item : ItemTable,
  Title : TitleTable,
  Row : RowTable
}
import { apiToken } from "@services";
export const fetchOrders = async (user_id) => {
  try {
    const response = await apiToken().get(`get_cart_payments_res/${user_id}`);
    const data = response.data; // Acesso direto à propriedade data
    return data;
  } catch (error) {
    console.error('Erro ao buscar pedidos:', error);
    return [];
  }
};
export const pdf_download_1 = (
    doc,
    imageData,
    imgWidth,
    imgHeight,
    photos,
    modifiedLandmarks,
    pontoDestacado,
    pdf_download_footer,
    qrCode,
    professionalInfo,
    drawAttentionIcon
) => {
    doc.addImage(imageData, 'JPEG', 15, 70, imgWidth, imgHeight);
    if (photos.FaceFrontalAR) {
        if (photos.FaceFrontalAR instanceof File) {
            const FaceFrontalAR = URL.createObjectURL(photos.FaceFrontalAR);
            doc.addImage(FaceFrontalAR, 'JPEG', 155, 69.5, 40, 40);
            URL.revokeObjectURL(FaceFrontalAR);
        } else if (typeof photos.FaceFrontalAR === 'string') {
            doc.addImage(photos.FaceFrontalAR, 'JPEG', 155, 69.5, 40, 40);
        } else {
            console.error('Formato inválido para FaceFrontalAR:', photos.FaceFrontalAR);
        }
    } else {
        doc.setFillColor(255, 255, 255);
        doc.rect(155.5, 69.5, 40, 40, 'F'); // Ajuste as coordenadas e dimensões conforme necessário
        drawAttentionIcon(doc, 175, 87, 10); // Coordenadas (30, 25), tamanho do ícone 100
        doc.setFont('times', 'bold');
        doc.setFontSize(8);
        doc.text('FALTA FOTO', 165, 100);
        doc.setFont('times', 'normal');
        doc.text('[ Realidade Virtual ]', 163, 105);
    }
    console.log("modifiedLandmarksFrontal", modifiedLandmarks.FaceFrontal.landmarks_media_pipe)
    const modifiedLandmarksFrontal = modifiedLandmarks.FaceFrontal.landmarks_media_pipe
    modifiedLandmarksFrontal.forEach((landmark, index) => {
        if (landmark && (pontoDestacado === null || pontoDestacado === index)) {
            const x = 15 + landmark.x * imgWidth;
            const y = 70 + landmark.y * imgHeight;
            doc.setDrawColor(255, 0, 0);
            doc.setFillColor(255, 0, 0);
            doc.circle(x, y, 0.5, 'F');
            doc.setFontSize(5);
            doc.setTextColor(0, 0, 255);
            doc.text(`${index + 1}`, x + 0, y + 0);
        }
    });
    const classes = ['class_8', 'class_9'];
    classes.forEach((className) => {
        if (modifiedLandmarks.FaceFrontal?.landmarks_face_parsing?.[className]) {
            const landmark = modifiedLandmarks.FaceFrontal.landmarks_face_parsing[className];
            if (landmark) {
                modifiedLandmarks.FaceFrontal.landmarks_face_parsing[className].forEach((landmark, index) => {
                    const x = 15 + landmark.x  * imgWidth; // Calcula a coordenada X
                    const y = 70 + landmark.y  * imgHeight; // Calcula a coordenada Y
                    doc.setDrawColor(255, 0, 0); // Cor da borda do círculo
                    doc.setFillColor(255, 0, 0); // Cor de preenchimento
                    doc.circle(x, y, 0.5, 'F'); // Círculo preenchido
                    doc.setFontSize(5);
                    doc.setTextColor(0, 0, 255);
                    // doc.text(`${index + 1}`, x, y);
                });
            }
        }
    })
    doc.setTextColor(0, 0, 0);
    pdf_download_footer(doc, qrCode, professionalInfo);
};
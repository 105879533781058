import React, { useState } from 'react';
import { areasConhecimentoJSON } from '../assets/areas_do_conhecimento';
import { ButtonAPI, InputPadraoTitle, MaisInfoMenosInfo } from '@components';
import AreasConhecimentoCNPQInfo from '../info/AreasConhecimentoCNPQInfo';
const AreasConhecimentoCNPQ = ({ onAddArea }) => {
    const [selectedGrandeArea, setSelectedGrandeArea] = useState('');
    const [selectedArea, setSelectedArea] = useState('');
    const [selectedSubArea, setSelectedSubArea] = useState('');
    const handleGrandeAreaChange = (event) => {
        setSelectedGrandeArea(event.target.value);
        setSelectedArea('');
        setSelectedSubArea('');
    };
    const handleAreaChange = (event) => {
        setSelectedArea(event.target.value);
        setSelectedSubArea('');
    };
    const handleSubAreaChange = (event) => {
        setSelectedSubArea(event.target.value);
    };
    const getAreasByGrandeArea = () => {
        const grandeArea = areasConhecimentoJSON['grandes-areas'].find((area) => area.codigo === selectedGrandeArea);
        return grandeArea && Array.isArray(grandeArea.areas) ? grandeArea.areas : [];
    };
    const getSubAreasByArea = () => {
        const areas = getAreasByGrandeArea();
        const area = areas.find((area) => area.codigo === selectedArea);
        return area && Array.isArray(area['sub-areas']) ? area['sub-areas'] : [];
    };
    const getEspecialidadesBySubArea = () => {
        const subAreas = getSubAreasByArea();
        const subArea = subAreas.find((subArea) => subArea.codigo === selectedSubArea);
        return subArea && Array.isArray(subArea.especialidades) ? subArea.especialidades : [];
    };
    const handleAddArea = () => {
        const isGrandeAreaSelected = selectedGrandeArea !== '';
        const isAreaSelected = selectedArea !== '';
        const isSubAreaSelected = selectedSubArea !== '';
        if (isGrandeAreaSelected) {
            if (isAreaSelected && isSubAreaSelected) {
                onAddArea({
                    grande_area: selectedGrandeArea,
                    area: selectedArea,
                    sub_area: selectedSubArea,
                    especialidade: selectedSubArea,
                });
            } else {
                onAddArea({
                    grande_area: selectedGrandeArea,
                });
            }
        }
        setSelectedGrandeArea('');
        setSelectedArea('');
        setSelectedSubArea('');
    };
    return (<>
        <div className='py-5 mt-3'>
            <InputPadraoTitle title={'GRANDE AREA'} />
            <select className="custom-select text-center " value={selectedGrandeArea} onChange={handleGrandeAreaChange}>
                <option value="">Selecione uma grande área</option>
                {areasConhecimentoJSON['grandes-areas']?.map((grandeArea) => (
                    <option key={grandeArea.codigo} value={grandeArea.codigo}>
                        {grandeArea.nome}
                    </option>
                ))}
            </select>
            <div className='text-dark text-center col-md-12'>
                <small >Adicione sua área de conhecimento CNQP
                </small>
            </div>
            {selectedGrandeArea && (<>
                <InputPadraoTitle title={'AREA'} />
                <select className="custom-select text-center mb-3" value={selectedArea} onChange={handleAreaChange}>
                    <option value="">Selecione uma área</option>
                    {getAreasByGrandeArea()?.map((area) => (
                        <option key={area.codigo} value={area.codigo}>
                            {area.nome}
                        </option>
                    ))}
                </select>
            </>)}
            {selectedArea && (<>
                <InputPadraoTitle title={'SUB-AREA'} />
                <select className="custom-select text-center mb-3" value={selectedSubArea} onChange={handleSubAreaChange}>
                    <option value="">Selecione uma sub-área</option>
                    {getSubAreasByArea()?.map((subArea) => (
                        <option key={subArea.codigo} value={subArea.codigo}>
                            {subArea.nome}
                        </option>
                    ))}
                </select>
            </>)}
            {selectedSubArea && (<>
                <InputPadraoTitle title={'ESPECIALIDADE'} />
                <select className="custom-select text-center mb-3">
                    <option value="">Selecione uma especialidade</option>
                    {getEspecialidadesBySubArea()?.map((especialidade) => (
                        <option key={especialidade.codigo} value={especialidade.codigo}>
                            {especialidade.nome}
                        </option>
                    ))}
                </select>
            </>)}
            {selectedGrandeArea && (
                <ButtonAPI onClick={handleAddArea}>
                    Adicionar Área
                </ButtonAPI>)}
            <div className='col-md-12 text-center'>
                <MaisInfoMenosInfo text={<AreasConhecimentoCNPQInfo />}></MaisInfoMenosInfo>
            </div>
        </div>
    </>);
};
export default AreasConhecimentoCNPQ;
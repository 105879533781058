import React, { useState } from 'react';
export const ProntuarioEducadorFisico = () => {
  const [queixaPrincipal, setQueixaPrincipal] = useState('');
  const [objetivoTratamento, setObjetivoTratamento] = useState('');
  const [exercicioAntes, setExercicioAntes] = useState(false);
  const [tempoExercicio, setTempoExercicio] = useState('');
  const [dorPeitoTontura, setDorPeitoTontura] = useState('');
  const [disfuncaoColuna, setDisfuncaoColuna] = useState(false);
  const [limitacaoMovimentos, setLimitacaoMovimentos] = useState('');
  const [limitacaoOsteoArticular, setLimitacaoOsteoArticular] = useState('');
  const [limitacaoMusculoArticular, setLimitacaoMusculoArticular] = useState('');
  return (
    <div>
      <h5 style={{ fontWeight: "bold" }} className="mt-5 text-center">Educador Físico</h5>
      <h5 style={{ fontWeight: "bold" }} className="mt-5">Queixa principal:</h5>
      <textarea
        required
        id="AnamnesesEducadorFisicoQueixaPrincipal"
        type="text"
        rows="2"
        data-ls-module="charCounter"
        value={queixaPrincipal}
        onChange={(e) => setQueixaPrincipal(e.target.value)}
        name=""
        maxlength="100"
        className="form-control text-center form-control-lg border-dark"
      />
      <h5 className="text-justify">Insira o motivo principal de ir ao Educador Físico.</h5>
      <h5 style={{ fontWeight: "bold" }} className="mt-5">Objetivo do tratamento:</h5>
      <input type="checkbox" /> Emagrecimento
      <input type="checkbox" /> Hipertrofia
      <input type="checkbox" /> Condicionamento Físico
      <input type="checkbox" /> Outros. Quais
      <textarea
        required
        id="AnamnesesEducadorFisicoObjetivoDoTratamento"
        type="text"
        rows="2"
        data-ls-module="charCounter"
        value={objetivoTratamento}
        onChange={(e) => setObjetivoTratamento(e.target.value)}
        name=""
        maxlength="100"
        className="form-control text-center form-control-lg border-dark"
      />
      <h5 className="text-justify">Insira o objetivo do tratamento - O que espera com o tratamento do Educador Físico.</h5>
      <h5 style={{ fontWeight: "bold" }} className="mt-5">Você já praticou exercício físico antes?</h5>
      <input type="checkbox" onChange={() => setExercicioAntes(!exercicioAntes)} /> SIM
      <input type="checkbox" onChange={() => setExercicioAntes(false)} /> NÃO
      {exercicioAntes && (
        <div>
          <h5 className="mt-3">Se sim, quanto tempo praticou e o que praticou?</h5>
          <textarea
            required
            type="text"
            rows="2"
            value={tempoExercicio}
            onChange={(e) => setTempoExercicio(e.target.value)}
            name=""
            className="form-control text-center form-control-lg border-dark"
          />
        </div>
      )}
      <h5 style={{ fontWeight: "bold" }} className="mt-5">Quanto tempo está sem fazer exercícios?</h5>
      <input
        type="text"
        value={tempoExercicio}
        onChange={(e) => setTempoExercicio(e.target.value)}
        name=""
        className="form-control text-center form-control-lg border-dark"
      />
      <h5 style={{ fontWeight: "bold" }} className="mt-5">
        Sente dor no peito, tontura ou falta de ar durante o esforço?
        Você já perdeu o equilíbrio por causa de tontura ou alguma vez perdeu a consciência?
      </h5>
      <textarea
        type="text"
        rows="2"
        value={dorPeitoTontura}
        onChange={(e) => setDorPeitoTontura(e.target.value)}
        name=""
        className="form-control text-center form-control-lg border-dark"
      />
      <h5 style={{ fontWeight: "bold" }} className="mt-5">Possui alguma disfunção ortopédica na coluna?</h5>
      <input type="checkbox" onChange={() => setDisfuncaoColuna(!disfuncaoColuna)} /> SIM
      <input type="checkbox" onChange={() => setDisfuncaoColuna(false)} /> NÃO
      {disfuncaoColuna && (
        <div>
          <h5 className="mt-3">Se sim, explique:</h5>
          <textarea
            required
            type="text"
            rows="2"
            value={limitacaoMovimentos}
            onChange={(e) => setLimitacaoMovimentos(e.target.value)}
            name=""
            className="form-control text-center form-control-lg border-dark"
          />
        </div>
      )}
      <h5 style={{ fontWeight: "bold" }} className="mt-5">Limitações ósteo-articulares? (Lesões ou fraturas prévias)</h5>
      <textarea
        type="text"
        rows="2"
        value={limitacaoOsteoArticular}
        onChange={(e) => setLimitacaoOsteoArticular(e.target.value)}
        name=""
        className="form-control text-center form-control-lg border-dark"
      />
      <h5 style={{ fontWeight: "bold" }} className="mt-5">Limitações músculo-articulares? (Lesões prévias)</h5>
      <textarea
        type="text"
        rows="2"
        value={limitacaoMusculoArticular}
        onChange={(e) => setLimitacaoMusculoArticular(e.target.value)}
        name=""
        className="form-control text-center form-control-lg border-dark"
      />
    </div>
  );
};
export default ProntuarioEducadorFisico;
import React from 'react';
export const BuscarEquipamentoInfo = () => {
    return (
        <div>
            <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />
            <h5><strong>Informações sobre Equipamentos</strong></h5>
            <p>Aqui você pode encontrar informações detalhadas sobre os equipamentos cadastrados no sistema. Esta seção permite visualizar, editar e gerenciar os equipamentos associados à sua conta.</p>
            <hr />
            <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />
            <h5><strong>Visualização de Equipamentos</strong></h5>
            <p>A lista exibe os equipamentos registrados no sistema. Você pode ver os detalhes de cada equipamento, como nome, tipo, marca e número de série. Para editar um equipamento, clique no botão "Editar" correspondente.</p>
            <hr />
            <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />
            <h5><strong>Edição de Equipamentos</strong></h5>
            <p>Para editar um equipamento, clique no botão "Editar" ao lado do equipamento desejado. Isso abrirá um formulário de edição onde você pode modificar os campos editáveis, como nome, tipo, marca e número de série. Depois de fazer as alterações desejadas, clique em "Salvar" para confirmar as alterações.</p>
            <hr />
            <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />
            <h5><strong>Exclusão de Equipamentos</strong></h5>
            <p>Para excluir um equipamento, clique no botão "Excluir" ao lado do equipamento desejado. Isso removerá permanentemente o equipamento do sistema. Certifique-se de confirmar a exclusão antes de prosseguir, pois essa ação não pode ser desfeita.</p>
            <hr />
            <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />
            <h5><strong>Responsabilidade e Segurança</strong></h5>
            <p>É importante usar esta seção com responsabilidade e garantir que você tenha as permissões necessárias para editar ou excluir equipamentos. Certifique-se de que suas ações sejam apropriadas e não causem danos ao sistema ou aos dados associados.</p>
            <hr />
            <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />
        </div>
    );
};
export default BuscarEquipamentoInfo;
export const pdf_download_header = ({ doc, cliente, professionalInfo }) => {
    doc.setFontSize(8);
    if (cliente && cliente.name) {
        doc.text('Paciente: ' + cliente.name, 10, 40, { align: 'left' });
    } else {
        doc.text('Paciente: Não informado', 10, 40, { align: 'left' });
    }
    let ageText = 'Idade: Não informada';
    if (cliente && cliente.birthdate) {
        const birthdate = new Date(cliente.birthdate);
        if (!isNaN(birthdate.getTime())) { // Verifica se a data é válida
            const currentDate = new Date();
            let age = currentDate.getFullYear() - birthdate.getFullYear();
            const monthDiff = currentDate.getMonth() - birthdate.getMonth();
            if (monthDiff < 0 || (monthDiff === 0 && currentDate.getDate() < birthdate.getDate())) {
                age--;
            }
            ageText = 'Idade: ' + age;
        }
    }
    doc.text(ageText, 10, 45, { align: 'left' });
    doc.text('Empresa: ' + (professionalInfo?.empresa || 'Não informada'), 10, 50, { align: 'left' });
    doc.text('Responsável: ' + (professionalInfo?.profissional || 'Não informado'), 10, 55, { align: 'left' });
    const currentDate = new Date();
    const formattedDate = `Dia da Análise: ${currentDate.getDate().toString().padStart(2, '0')}/${(currentDate.getMonth() + 1).toString().padStart(2, '0')}/${currentDate.getFullYear()}`;
    doc.text(formattedDate, 10, 60, { align: 'left' });
};
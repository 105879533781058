import React, { useState } from 'react';
import { Tooltip, Button, Modal } from 'antd';
import { MdOutlinePersonSearch } from 'react-icons/md';
import './styles/modal.css'
import GetUsers from '../../pages/Private/Usuarios/Search/GetUsers';
export const ButtonCliente = (props) => {
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [iconColor, setIconColor] = useState('#fff');
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  return (<>
    <div style={{ position: 'fixed', bottom: '200px', right: '-35px', zIndex: '1000' }}>
      <Tooltip
        title="Identificar cliente(s)? Clique aqui"
        open={open}
        onMouseEnter={() => { setOpen(true); setIconColor('#000'); }}
        onMouseLeave={() => { setOpen(false); setIconColor('#fff'); }}
      >
        <Button
          shape="circle"
          className='btn-primary'
          style={{
            color: '#fff',
            border: 'none',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2), inset 0 -4px 5px rgba(255, 255, 255, 0.4)',
            transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
            width: '80px',
            height: '80px',
          }}
          onClick={(e) => {
            e.preventDefault();
            handleOpenModal();  // Abrir o modal
          }}
        >
          <MdOutlinePersonSearch size={30} color={iconColor} style={{ marginRight: '24px' }} />
        </Button>
      </Tooltip>
    </div>
    <Modal
      open={openModal}
      onCancel={() => setOpenModal(false)}
      footer={null}
      centered
      width="90vw"
      className="custom-modal"
      style={{ paddingTop: '4%' }}
    >
      <GetUsers
        title="Selecionar cliente"
      />
    </Modal>
  </>);
};
export default ButtonCliente;
import React, { useState, useEffect } from 'react';
import ChartSelection from './ChartSelection';
import MainCanvas from './MainCanvas';
import { chartTypes } from './ChartSelection';
import { ReferenciaLocal, TitleL2 } from '@components';
const GraficosDinamicos = () => {
  const [createdCharts, setCreatedCharts] = useState([]);
  const storedData = JSON.parse(localStorage.getItem('drive'));
  const professionalId = storedData.id;
  useEffect(() => {
    const savedCharts = JSON.parse(localStorage.getItem('createdCharts')) || [];
    setCreatedCharts(savedCharts);
  }, []);
  const saveChartsToLocalStorage = (charts) => {
    localStorage.setItem('createdCharts', JSON.stringify(charts));
  };
  const handleChartCreate = (chartType) => {
    const localStorageKey = `professional_${professionalId}_chartConfigs`;
    const savedConfigs = JSON.parse(localStorage.getItem(localStorageKey)) || {};
    if (chartType in savedConfigs) {
      const newChart = { type: chartType, config: {} };
      setCreatedCharts((prevCharts) => [...prevCharts, newChart]);
      saveChartsToLocalStorage([...createdCharts, newChart]);
    } else {
      const newChart = { type: chartType, config: {} };
      setCreatedCharts((prevCharts) => [...prevCharts, newChart]);
    }
  };
  const handleChartConfigure = (chartIndex, config) => {
    setCreatedCharts((prevCharts) => {
      const updatedCharts = [...prevCharts];
      updatedCharts[chartIndex] = { ...updatedCharts[chartIndex], config };
      return updatedCharts;
    });
    saveChartsToLocalStorage([...createdCharts]);
  };
  const handleChartDelete = (chartIndex) => {
    const localStorageKey = `professional_${professionalId}_chartConfigs`;
    const savedConfigs = JSON.parse(localStorage.getItem(localStorageKey)) || {};
    if (chartIndex in savedConfigs) {
      setCreatedCharts((prevCharts) => {
        const updatedCharts = [...prevCharts];
        updatedCharts.splice(chartIndex, 1);
        return updatedCharts;
      });
      saveChartsToLocalStorage([...createdCharts]);
    } else {
      setCreatedCharts((prevCharts) => {
        const updatedCharts = [...prevCharts];
        updatedCharts.splice(chartIndex, 1);
        return updatedCharts;
      });
    }
  };
  return (<>
    <ReferenciaLocal text="Gráficos Personalizados"
      children={<>
        <div className='col-md-12 flex-wrap d-flex'>
          <div className='col-md-6 text-center mt-3' >
            <TitleL2>Selecione o Gráfico</TitleL2>
            <ChartSelection onChartCreate={handleChartCreate} chartTypes={chartTypes} />
          </div>
          <div className='col-md-6 text-center mt-3' >
            <TitleL2>Gráficos Selecionados</TitleL2>
            <MainCanvas
              createdCharts={createdCharts}
              onChartConfigure={handleChartConfigure}
              onChartDelete={handleChartDelete}
            />
          </div>
        </div>
      </>}
    />
  </>  );
};
export default GraficosDinamicos;
export const generateHeatmapPDF = (doc, heatmapWrinklesData, imgWidthHalf, imgHeightHalf, imageWidth, imageHeight) => {
    let heatmapWrinkles;
    try {
        heatmapWrinkles = JSON.parse(heatmapWrinklesData);
    } catch (error) {
        console.error("Erro ao converter heatmapWrinkles para JSON:", error);
        return;
    }
    if (!Array.isArray(heatmapWrinkles)) {
        console.error("O dado do heatmapWrinkles não é um array:", heatmapWrinkles);
        return;
    }
    const originalSquareSize = 32; // Tamanho do quadrado na imagem original
    doc.setGState(new doc.GState({ opacity: 0.2 }));
    // Definir deslocamentos (espaço superior e lateral)
    const offsetX = 10;
    const offsetY = 30;
    // Normalizar o tamanho do quadrado para a escala do PDF
    const squareSizeX = (originalSquareSize / imageWidth) * imgWidthHalf;
    const squareSizeY = (originalSquareSize / imageHeight) * imgHeightHalf;
    heatmapWrinkles.forEach(({ x, y, color }) => {
        const xScaled = x * imgWidthHalf + offsetX;
        const yScaled = y * imgHeightHalf + offsetY;
        const [r, g, b] = color; // Extrai valores RGB
        doc.setFillColor(r, g, b);
        doc.rect(xScaled, yScaled, squareSizeX, squareSizeY, "F");
    });
    doc.setGState(new doc.GState({ opacity: 1 })); // Restaurar opacidade normal
};
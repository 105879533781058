import React, { Component } from "react";
import { Link, animateScroll as scroll } from "react-scroll";
export default class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSticky: false,
    };
  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  handleScroll = () => {
    if (window.scrollY > 0) {
      this.setState({ isSticky: true });
    } else {
      this.setState({ isSticky: false });
    }
  };
  scrollToTop = () => {
    scroll.scrollToTop();
  };
  render() {
    const { isSticky } = this.state;
    return (
      <nav
        className={`nav-sprints${isSticky ? " sticky" : ""}`}
        id="navbar"
      >
        <div className="wiki-nav-content">
          <h5 className="nav-title">
            <b>Table of Contents</b>
          </h5>
          <ul className="nav-items"></ul>
          <Link
                activeClass="active"
                to="visaogeral"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                1. Visão Geral
              </Link>
            <li className="nav-item">
              <Link
                activeClass="active"
                to="objetivosprint"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                2. Objetivo da Sprint
              </Link>
            </li>
            <li className="nav-item">
              <Link
                activeClass="active"
                to="sprintbacklog"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                3. Sprint Backlog
              </Link>
            </li>
            <li className="nav-item">
              <Link
                activeClass="active"
                to="debitosacumulados"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                4. Débitos Acumulados
              </Link>
            </li>
        </div>
      </nav>
    );
  }
}
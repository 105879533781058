import React from "react";
import { Helmet } from "react-helmet";
export const CustomHelmet = ({ nome, descricao, imagemUrl, id }) => {
  return (
    <Helmet>
      <title>{`Produto ${nome} - Rede Social Profissional`}</title>
      <meta name="description" content={descricao} />
      <meta property="og:title" content={nome} />
      <meta property="og:description" content={descricao} />
      <meta property="og:image" content={imagemUrl} />
      <meta property="og:url" content={`https://www.thotiacorp.com.br/produtos/${id}`} />
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:title" content={nome} />
      <meta property="twitter:description" content={descricao} />
      <meta property="twitter:image" content={imagemUrl} />
    </Helmet>
  );
};
export default CustomHelmet;
import { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { api, baseURL, createSession, register, apiToken } from "../services";
export const AuthContext = createContext();
export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const recoveredUser = localStorage.getItem('user');
    const token = localStorage.getItem('token');
    if (recoveredUser && token) {
      const userData = JSON.parse(recoveredUser);
      setUser(userData);
      api.defaults.headers.Authorization = `Bearer ${token}`;
      setAuthenticated(true);
    }
    setLoading(false);
  }, []);
  const login = async (email, password) => {
    try {
      const response = await createSession(email, password);
      const { user, imageUrlsUser, token, userId, user_type, professionalData, professionalUnitData, user_cbo, professionalOwnerCompany } = response.data;
      localStorage.setItem("user", JSON.stringify({ ...user, id: userId, user_type, imageUrlsUser }));
      localStorage.setItem("token", token);
      api.defaults.headers.Authorization = `Bearer ${token}`;
      setUser(user);
      setAuthenticated(true);
      // alert(JSON.stringify(professionalOwnerCompany))
      if (user_type === 'profissional') {
        localStorage.setItem("professionalData", JSON.stringify(professionalData));
        const driveData = JSON.parse(localStorage.getItem('drive'));
        if (driveData === null) {
          localStorage.setItem('drive', JSON.stringify(professionalData[0]));
        }
        if (professionalUnitData === '') {
          localStorage.setItem("professionalUnitData", JSON.stringify(professionalUnitData));
        }
        localStorage.setItem("professionalCBO", JSON.stringify(user_cbo));
        localStorage.setItem("professionalOwnerCompany", JSON.stringify(professionalOwnerCompany));
        if (professionalData && professionalData.cbo_ocupacao_names) {
          // alert("CBO Ocupacao Names:", professionalData.cbo_ocupacao_names);
        }
      } else {
        localStorage.removeItem("professionalData");
        localStorage.removeItem("professionalUnitData");
      }
      navigate("/usuario");
    } catch (error) {
      alert('Login error')
      //console.error(error);
      if (error.response) {
        // alert(JSON.stringify(error.response.data));
      } else if (error.request) {
        //  alert(JSON.stringify(error.request));
      } else {
        // alert(JSON.stringify(error.message));
      }
    }
  };
  const getUserId = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    return user && user.id ? user.id : null;
  };
  const getProfessionalId = () => {
    const professionalData = JSON.parse(localStorage.getItem("professionalData"));
    const firstProfessional = professionalData && professionalData.length > 0 ? professionalData[0] : null;
    if (firstProfessional) {
      //alert(firstProfessional.id)
      return firstProfessional.id;
    } else {
      return null;
    }
  };
  const logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    logoutProfessional();
    api.defaults.headers.Authorization = null;
    setUser(null);
    setAuthenticated(false);
    navigate("/login");
  };
  const logoutProfessional = () => {
    localStorage.removeItem("professionalData");
    localStorage.removeItem("professionalUnitData");
    localStorage.removeItem("drive");
    localStorage.removeItem("driveIA");
    const updatedUser = { ...user, user_type: "paciente" };
    localStorage.setItem("user", JSON.stringify(updatedUser));
    renewToken()
    navigate('./usuario')
  };
  const cadastrar = async (
    email,
    name,
    password,
    cpf,
    address,
    phone,
    dataNascimento,
    user_type,
    convite_code
  ) => {
    const response = await register(
      email,
      name,
      password,
      cpf,
      address,
      phone,
      dataNascimento,
      user_type,
      convite_code
    );
    if (response.status === 200) {
      await login(email, password);
      navigate("/usuario");
    } else {
      console.error('Erro no registro:', response.statusText);
    }
  };
  const renewToken = async () => {
    const id = getUserId()
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        return;
      }
      const response = await apiToken().post(`${baseURL}post_user_login_renew/${id}`, {
        token: token,
      });
      const { user, imageUrlsUser, newToken, userId, user_type, professionalData, professionalUnitData, user_cbo, professionalOwnerCompany } = response.data;
      localStorage.setItem("user", JSON.stringify({ ...user, id: userId, user_type, imageUrlsUser }));
      localStorage.setItem("token", newToken);
      api.defaults.headers.Authorization = `Bearer ${token}`;
      setUser(user);
      setAuthenticated(true);
      if (user_type === 'profissional') {
        localStorage.setItem("professionalData", JSON.stringify(professionalData));
        const driveData = JSON.parse(localStorage.getItem('drive'));
        if (driveData === null) {
          localStorage.setItem('drive', JSON.stringify(professionalData[0]));
        }
        if (professionalUnitData === '') {
          localStorage.setItem("professionalUnitData", JSON.stringify(professionalUnitData));
        }
        localStorage.setItem("professionalCBO", JSON.stringify(user_cbo));
        localStorage.setItem("professionalOwnerCompany", JSON.stringify(professionalOwnerCompany));
        if (professionalData && professionalData.cbo_ocupacao_names) {
        }
      } else {
        localStorage.removeItem("professionalData");
        localStorage.removeItem("professionalUnitData");
      }
    } catch (error) {
      console.error('Error renewing token:', error);
    }
  };
  return (
    <AuthContext.Provider
      value={{
        authenticated,
        user,
        loading,
        login,
        logout,
        cadastrar,
        getUserId,
        renewToken,
        getProfessionalId,
        logoutProfessional
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
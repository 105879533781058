import React from 'react';
export const ProntuarioQueixasPrincipaisInfo = () => {
  return (
    <div>
      <h5>Usando o Componente <strong>Queixas Principais</strong></h5>
      <p>
        O componente <strong>Queixas Principais</strong> permite que você registre suas queixas principais de saúde. Você pode adicionar, editar e remover queixas conforme necessário. Abaixo, você encontrará um guia de como usar este componente.
      </p>
      <hr/>
      <ol className='ml-3'>
        <li> <strong>Registrar Queixas Principais</strong></li>
        <p>
          Quando você abrir o componente, você verá um campo de entrada de texto onde pode registrar suas queixas principais. Digite uma queixa no campo apropriado e clique no botão "Adicionar" para inserir a queixa na lista.
        </p>
        <li> <strong>Editar Queixas</strong></li>
        <p>
          Se você desejar editar uma queixa existente na lista, clique no botão "Editar" ao lado dela. Isso permitirá que você faça as alterações necessárias na queixa. Após as edições, clique no botão "Adicionar" para salvar as alterações.
        </p>
        <li> <strong>Remover Queixas</strong></li>
        <p>
          Para remover uma queixa da lista, clique no botão "Excluir" ao lado dela. A queixa será excluída da lista.
        </p>
        <li> <strong>Conclusão</strong></li>
        <p>
          Depois de adicionar, editar ou remover queixas, você pode continuar usando o componente conforme necessário. Certifique-se de registrar suas queixas principais com precisão.
        </p>
      </ol>
      <hr/>
      <p>
        Agora você está pronto para usar o componente <strong>Queixas Principais</strong> para registrar suas queixas principais de saúde. Certifique-se de adicionar/editar/remover as queixas conforme necessário.
      </p>
      <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br /> 
    </div>
  );
};
export default ProntuarioQueixasPrincipaisInfo;
import React from 'react';
const AreasConhecimentoCNPQInfo = () => {
  return (
    <div>
      <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />
      <h5 className='font-weight-bold'>Áreas de Conhecimento CNPQ</h5>
      <p>Este documento tem como objetivo explicar o funcionamento e a utilização do componente "AreasConhecimentoCNPQ", que permite aos usuários selecionar e adicionar áreas de conhecimento conforme o padrão CNPQ. O componente oferece uma interface para escolher áreas, sub-áreas e especialidades de conhecimento.</p>
      <hr/>
      <h5 className='font-weight-bold'>Seleção de Áreas, Sub-Áreas e Especialidades</h5>
      <p>O componente <strong>AreasConhecimentoCNPQ</strong> facilita a escolha de áreas de conhecimento de acordo com a classificação CNPQ. Você pode selecionar a grande área, área, sub-área e especialidade correspondentes para associar ao seu perfil.</p>
      <hr/>
      <h5 className='font-weight-bold'>Grande Área de Conhecimento</h5>
      <p>Primeiro, escolha a grande área de conhecimento que melhor representa a sua expertise. Isso permite uma categorização mais ampla da sua área de atuação.</p>
      <hr/>
      <h5 className='font-weight-bold'>Área e Sub-Área de Conhecimento</h5>
      <p>Após selecionar a grande área, escolha a área e sub-área de conhecimento que correspondem à sua especialização. Isso refinará ainda mais a categorização da sua expertise.</p>
      <hr/>
      <h5 className='font-weight-bold'>Especialidade de Conhecimento</h5>
      <p>Por fim, você pode escolher uma especialidade de conhecimento dentro da sub-área selecionada. Isso proporciona uma definição ainda mais precisa da sua atuação.</p>
      <hr/>
      <h5 className='font-weight-bold'>Adicionar e Remover Áreas</h5>
      <p>O componente permite que você adicione áreas de conhecimento ao seu perfil, incluindo todos os níveis de categorização. Além disso, você pode remover áreas adicionadas previamente, se necessário.</p>
      <hr/>
      <p>Utilizando o componente <strong>AreasConhecimentoCNPQ</strong>, você pode indicar suas áreas de conhecimento de forma precisa e alinhada com o padrão CNPQ, contribuindo para uma categorização clara e eficiente das expertises dos usuários.</p>
        </div>
  );
};
export default AreasConhecimentoCNPQInfo;
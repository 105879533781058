import { IconInfo } from '@components';
import DocumentosSoftwareAnaliseFacial from "./DocumentosSoftwareAnaliseFacial";
export const AnaliseFacialInfo = () => {
  return (<>
    <div className="mx-auto rounded d-flex" style={{ position: "relative", paddingBottom: "56.25%", height: 0 }}>
      <iframe
        className="w-100 h-100 rounded"
        style={{ position: "absolute", top: 0, left: 0 }}
        src="https://www.youtube.com/embed/_2hNMPk9FmI?si=GijEZog-PYd9sqFq"
        title="Parceria para treinamento de software de visão computacional"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
    <p className="text-justify">
      Análise Orofacial com dois cliques. Tenha laudos com indicações de forma automática com precisão e velocidades impressionantes.
      Para demais informações técnicas consulte documentos de software.
    </p>
    <div className="text-center mt-3">
      <IconInfo
        style={{ cursor: "pointer" }}
        titleOpen="Mostrar documentos de software"
        titleDown="Esconder documentos de software"
        text={<DocumentosSoftwareAnaliseFacial />}
      />
    </div>
  </>);
};
export default AnaliseFacialInfo;
import { NavBreadcrumb, CardDirecionamento } from '@components'
export const Index = () => {
    return (<>
        <div className='w-100'>
            <NavBreadcrumb text={'Meus Exames'} icon={'flask fa'} />
            <div className="py-3">
                <div className="text-center">
                    <div className="container-fluid">
                        <div className="row d-inline-flex justify-content-center w-100">
                            <CardDirecionamento text={'Marcar'} icon={'mt-3 fa fa-3x fa-calendar text-white'} href={'../../../usuario/servicos'} />
                            <CardDirecionamento text={'Marcados'} icon={'mt-3 fa fa-3x fa-location-arrow text-white'} href={'../../../usuario/exames/marcados'} />
                            <CardDirecionamento text={'Finalizados'} icon={'mt-3 fa fa-database fa-3x text-white'} href={'../../../usuario/exames/finalizados'} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}
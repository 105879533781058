import React, { useState } from 'react';
import axios from 'axios';
import './EVAComponent.css';
import { MaisInfoMenosInfo } from '../../../../components';
import EVAComponentInfo from './EVAComponentInfo';
const EVAComponent = ({ onRegister }) => {
  const [painValue, setPainValue] = useState(0);
  const [emotion, setEmotion] = useState('');
  const [painClassification, setPainClassification] = useState('');
  const handleChange = (e) => {
    const value = parseFloat(e.target.value);
    setPainValue(value);
    // Classifique a dor com base nos valores da escala
    if (value <= 3) {
      setPainClassification('Leve');
      setEmotion('Feliz'); // Defina a emoção com base no nível de dor
    } else if (value <= 5) {
      setPainClassification('Moderada');
      setEmotion('Neutro');
    } else if (value <= 8) {
      setPainClassification('Moderada');
      setEmotion('Triste');
    } else {
      setPainClassification('Intensa');
      setEmotion('Triste');
    }
  };
  const handleSubmit = () => {
    axios.post('/api/registar-dor', { value: painValue })
      .then(() => {
        onRegister();
        setPainValue(0);
        setPainClassification('');
        setEmotion('');
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const getColor = () => {
    if (painValue <= 3) {
      return 'blue';
    } else if (painValue <= 5) {
      return 'green';
    } else if (painValue <= 8) {
      return 'yellow';
    } else {
      return 'red';
    }
  };
  return (<>
    <div className="col-md-12 py-4 bg-light mt-5 rounded">
      <h2>Escala Visual Analógica para Dor</h2>
      <div className="eva-slider">
        <input
          type="range"
          min="0"
          max="10"
          step="0.1"
          value={painValue}
          onChange={handleChange}
        />
      </div>
      <div className="eva-value">
        <p style={{ backgroundColor: getColor() }}>Nível de dor: {painValue.toFixed(1)}</p>
        <p>Classificação: {painClassification}</p>
        <p>Emoção: {emotion}</p>
      </div>
      <button onClick={handleSubmit}>Registrar Dor</button>
      <div className='py-4'>
      <MaisInfoMenosInfo text={<EVAComponentInfo />}/>
    </div>
    </div>
  </>);
};
export default EVAComponent;
import React from "react";
import { Nav, NavProfissionalMain, NavBreadcrumb, FooterProfissional } from "@components";
import { EscolheIAImagem } from "./partials/layout/EscolheIAImagem";
export const ImagemIA = () => {
  return (<>
    <Nav />
    <div className="d-flex mt-3" style={{ overflowX: 'hidden' }}>
      <NavProfissionalMain />
      <section className='w-100'>
        <NavBreadcrumb text={'I.A Imagem'} icon={'crosshairs fa'} />
        <div >
          <EscolheIAImagem />
        </div>
        <FooterProfissional />
      </section>
    </div>
  </>);
};
export default ImagemIA;
export const pdf_download_5 = (
    doc,
    imageData,
    pdf_download_footer,
    qrCode,
    professionalInfo,
    imgWidthHalf,
    imgHeightHalf,
    imageX,
    imageY,
    yEndVerticalLineMediaPag,
    drawSquareWithCheckmark,
    drawBlueSquareWithArrow,
    drawBlueSquareWithArrowDown,
    apiImages,
    others
) => {
    doc.addPage();
    doc.setLineWidth(0.5);
    doc.setFont('times', 'bold');
    doc.setDrawColor(0, 0, 0);
    doc.addImage(require('../assets/fundoPDF.png'), 'PNG', 0, 0, doc.internal.pageSize.width, doc.internal.pageSize.height);
    doc.text('Espelhamento: Composições fotográficas são a melhor maneira de perceber assimetrias faciais normais. Ref: Proffit, W. R. Ortodontia Contemporânea (8ª ed.)', 5, 20,);
    doc.text('Elsevier, 2018. pag. 165', 5, 24,);
    doc.setFont('times', 'normal');
    doc.text('Esquerdo', 27, imageY + 65,);
    doc.text('Normal', 93, imageY + 65,);
    doc.text('Direito', 160, imageY + 65,);
    if (apiImages['FaceFrontal'].combined_image_right) {
        doc.addImage(apiImages['FaceFrontal'].combined_image_right, 'JPEG', imageX, imageY - 5, imgWidthHalf - 30, imgHeightHalf - 30);
    }
    if (apiImages['FaceFrontal'].combined_image_left) {
        doc.addImage(apiImages['FaceFrontal'].combined_image_left, 'JPEG', imageX + 130, imageY - 5, imgWidthHalf - 30, imgHeightHalf - 30);
    }
    doc.addImage(imageData, 'JPEG', imageX + 65, imageY - 5, imgWidthHalf - 30, imgHeightHalf - 30);
    doc.setFont('times', 'bold');
    doc.setFontSize(12);
    doc.text('[ Indicações de Acordo com Formato Atual da Face ]', 50, imageY + 70);
    doc.setFontSize(8);
    doc.text('Formato do rosto:', 5, imageY + 75);
    doc.text(others['FaceFrontal'].face_shape, 29, imageY + 75);
    doc.setFont('times', 'normal')
    doc.text(others['FaceFrontal'].sugestao.descricao, 5, imageY + 80);
    others['FaceFrontal'].sugestao.recomendacoes.forEach((item, index) => {
        doc.text(`- ${item}`, 5, imageY + 85 + (index * 5));
    });
    doc.setFont('times', 'bold');
    doc.text('Técnicas de Harmonização Facial:', 5, 115 + (others['FaceFrontal'].sugestao.recomendacoes.length * 5));
    doc.setFont('times', 'normal');
    others['FaceFrontal'].sugestao.tecnicas.forEach((item, index) => {
        doc.text(`- ${item}`, 5, 120 + (others['FaceFrontal'].sugestao.recomendacoes.length * 5) + (index * 5));
    });
    doc.setFont('times', 'bold');
    doc.text('Óculos a Usar:', 5, 130 + (others['FaceFrontal'].sugestao.tecnicas.length * 5));
    doc.text(others['FaceFrontal'].sugestao.oculos_usar, 25, 130 + (others['FaceFrontal'].sugestao.tecnicas.length * 5));
    doc.setFillColor(255, 255, 255);
    doc.rect(5, 145, 80, 15, 'F'); // Ajuste as coordenadas e dimensões conforme necessário
    if (others['FaceFrontal'].sugestao.oculos_usar_img && Array.isArray(others['FaceFrontal'].sugestao.oculos_usar_img) && others['FaceFrontal'].sugestao.oculos_usar_img.length > 0) {
        const margin = 10;
        const imageWidth = 10;  // Largura da imagem
        const imageHeight = 10; // Altura da imagem
        const initialX = 10;    // Posição inicial X
        const initialY = 148;   // Posição Y onde as imagens começam
        let xOffset = initialX; // A coordenada X começará na posição inicial e será ajustada
        others['FaceFrontal'].sugestao.oculos_usar_img.forEach((img, index) => {
            if (img && img.url) { // Verifica se a imagem existe e possui a chave 'url'
                const base64Image = img.url; // A imagem já está no formato Base64
                try {
                    // Verifica se a imagem é válida e começa com 'data:image'
                    if (base64Image.startsWith('data:image')) {
                        doc.addImage(base64Image, 'PNG', xOffset, initialY, imageWidth, imageHeight);
                        // Atualiza a coordenada X para a próxima imagem
                        xOffset += imageWidth + margin; // Largura da imagem + margem
                    } else {
                        console.warn(`Imagem na posição ${index} não está no formato Base64 válido.`);
                    }
                } catch (error) {
                    console.error(`Erro ao adicionar imagem na posição ${index}:`, error);
                }
            } else {
                console.warn(`Imagem na posição ${index} está nula ou sem o campo 'url'.`);
            }
        });
    } else {
        console.warn('oculos_usar_img está vazio, nulo ou não é um array válido.');
    }
    doc.text('Óculos a Não Usar:', 5, 125 + (others['FaceFrontal'].sugestao.oculos_usar_img.length * 10));
    doc.text(others['FaceFrontal'].sugestao.oculos_n_usar, 30, 125 + (others['FaceFrontal'].sugestao.oculos_usar_img.length * 10));
    doc.setFillColor(255, 255, 255);
    doc.rect(5, 168, 80, 15, 'F'); // Ajuste as coordenadas e dimensões conforme necessário
    doc.rect(95, 145, 80, 38, 'F'); // Ajuste as coordenadas e dimensões conforme necessário
    if (others['FaceFrontal'].sugestao.oculos_n_usar_img && Array.isArray(others['FaceFrontal'].sugestao.oculos_n_usar_img) && others['FaceFrontal'].sugestao.oculos_n_usar_img.length > 0) {
        const margin = 10;
        const imageWidth = 10;  // Largura da imagem
        const imageHeight = 10; // Altura da imagem
        const initialX = 10;    // Posição inicial X
        const initialY = 170;   // Posição Y onde as imagens começam
        let xOffset = initialX; // A coordenada X começará na posição inicial e será ajustada
        others['FaceFrontal'].sugestao.oculos_n_usar_img.forEach((img, index) => {
            if (img && img.url) { // Verifica se a imagem existe e possui a chave 'url'
                const base64Image = img.url; // A imagem já está no formato Base64
                try {
                    if (base64Image.startsWith('data:image')) {
                        doc.addImage(base64Image, 'PNG', xOffset, initialY, imageWidth, imageHeight);
                        xOffset += imageWidth + margin; // Largura da imagem + margem
                    } else {
                        console.warn(`Imagem na posição ${index} não está no formato Base64 válido.`);
                    }
                } catch (error) {
                    console.error(`Erro ao adicionar imagem na posição ${index}:`, error);
                }
            } else {
                console.warn(`Imagem na posição ${index} está nula ou sem o campo 'url'.`);
            }
        });
    } else {
        console.warn('oculos_usar_img está vazio, nulo ou não é um array válido.');
    }
    doc.text('Sobrancelha:', 5, 170 + (others['FaceFrontal'].sugestao.oculos_n_usar_img.length * 10));
    doc.text(others['FaceFrontal'].sugestao.sobrancelha, 25, 170 + (others['FaceFrontal'].sugestao.oculos_n_usar_img.length * 10));
    doc.text('Maquiagem:', 5, 190 + (others['FaceFrontal'].sugestao.sobrancelha ? 5 : 0));
    Object.keys(others['FaceFrontal'].sugestao.maquiagem).forEach((key, index) => {
        doc.text(`${key.charAt(0).toUpperCase() + key.slice(1)}: ${others['FaceFrontal'].sugestao.maquiagem[key]}`, 25, 190 + (index * 5) + (others['FaceFrontal'].sugestao.sobrancelha ? 5 : 0));
    });
    doc.setFont('times', 'normal');
    const addLegendaCores = () => {
        doc.setFontSize(12);
        doc.setTextColor(0, 0, 0);
        doc.setFont('times', 'bold');
        doc.text('Legenda de Cores e Simbolos:', 5, yEndVerticalLineMediaPag - 30);
        doc.setFont('times', 'normal');
        doc.text('Desvio positivo', 5 + 6, yEndVerticalLineMediaPag - 24);
        doc.text('Desvio negativo', 5 + 6, yEndVerticalLineMediaPag - 18);
        doc.text('Ideal ou muito próximo', 5 + 6, yEndVerticalLineMediaPag - 12);
        drawBlueSquareWithArrow(doc, 5, yEndVerticalLineMediaPag - 28, 5);
        drawBlueSquareWithArrowDown(doc, 5, yEndVerticalLineMediaPag - 22, 5);
        drawSquareWithCheckmark(doc, 5, yEndVerticalLineMediaPag - 16, 5);
    }
    addLegendaCores();
    doc.setTextColor(0, 0, 0);
    if (apiImages['FaceFrontalAR'] && apiImages['FaceFrontalAR'].modified_image_data) {
        doc.addImage(apiImages['FaceFrontalAR'].modified_image_data, 'PNG', imageX + 150, 145, imgWidthHalf - 57, imgHeightHalf - 57);
    } else {
        console.error("apiImages['FaceFrontalAR'] ou 'modified_image_data' está indefinido.");
    }
    if (others['FaceFrontalAR'] && others['FaceFrontalAR'].pd_total_cm) {
        const pdText = String(others['FaceFrontalAR'].pd_total_cm.toFixed(2)); // Conversão para string
        const pdneText = String(others['FaceFrontalAR'].pd_left_cm.toFixed(2)); // Conversão para string 
        const pdndText = String(others['FaceFrontalAR'].pd_right_cm.toFixed(2));
        doc.setFont('times', 'bold');
        doc.setFontSize(8);
        doc.text("Distância Pupilar (DP): " + pdText + "cm", 97, imageY + 125);
        doc.text("Distância Naso Pupilar (DPN)", 97, imageY + 135);
        doc.text("DPN Esquerda: " + pdneText + "cm", 97, imageY + 140);
        doc.text("DPN Direita: " + pdndText + "cm", 97, imageY + 145);
    } else {
        console.error("others['FaceFrontalAR'] ou 'pd_total_cm' está indefinido.");
    }
    pdf_download_footer(doc, qrCode, professionalInfo)
};
import React from 'react';
import { TitleL1, TitleL2 } from '@components';
export const CompatibilidadeAtendimentoServicos = () => {
    return (<>
        <p className='text-center'>  <img
            src={require('../assets/CompatibilidadeGerenciamento.jpg')}
            alt="Liberdade Financeira"
            style={{ maxHeight: '280px', maxWidth: '550px' }}
            className='col-md-12'
        />
        </p>
        <TitleL1>Compatibilidade de Atendimento</TitleL1>
        <h5 className='mt-3 mb-5 text-justify'>Descubra como nossos serviços atendem a uma ampla variedade de contextos
            Gerenciamento único e universal atendimento de A a Z  para que você  esteja sempre eficientemente no centro
            de todos os seus negócios
        </h5>
        <div className="d-flex flex-wrap justify-content-center justify-content-between">
            <div className="col-md-5 mb-5 border" style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                flexShrink: '0',
                borderRadius: '33px',
            }}
            >
                <TitleL2>
                    Gerenciamento Jurídico
                </TitleL2>
                <h5
                    className={` py-2 mt-3 mb-5 text-left  text-dark rounded`}
                >
                    ✅ Profissionais
                    <br />
                    ✅ Empresas
                    <br />
                    ✅ Instituições
                    <br />
                    ✅ Equipamentos
                </h5>
            </div>
            <div className="col-md-6 py-2 mb-5 border" style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                flexShrink: '0',
                borderRadius: '33px',
            }}
            >
                <TitleL2> Suporte aos Jurídicos</TitleL2>
                <ul className=' text-justify'>
                    <li className='mt-2'><strong>Clientes Jurídicos:</strong> Oferecemos suporte para questões legais,
                        garantindo conformidade e segurança nas operações</li>
                    <li className='mt-2'><strong>Profissionais:</strong>
                        Nossos serviços fornecem soluções eficazes para profissionais de diversas
                        áreas, desde médicos a artistas influênciando diretamento no crescimento, escalonamento</li>
                    <li className='mt-2'><strong>Empreas:</strong>
                        Proporcionamos ferramentas que aumentam a eficiência e produtividade empresarial, promovendo o crescimento sustentável</li>
                    <li className='mt-2'><strong>Instituições:</strong> Nossos serviços são adaptáveis
                        para atender às necessidades de instituições educacionais e organizações com ou sem fins lucrativos
                        com mesmos benefícios descritos acima</li>
                    <li className='mt-2'><strong>Equipamentos:</strong> Integramos nossas soluções com equipamentos diversos,
                        garantindo uma experiência única   perfeita e otimizada</li>
                </ul>
            </div>
            <div className="col-md-5 mb-5 border" style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                flexShrink: '0',
                borderRadius: '33px',
            }}
            >
                <TitleL2>Gerenciamento Físico</TitleL2>;
                <h5 className={` py-2 mt-3 mb-5 text-left  text-dark rounded`}
                >
                    ✅ Usuários
                    <br />
                    ✅ Gadgets
                </h5>
            </div>
            <div className='col-md-1'></div>
            <div className="col-md-6 py-2 mb-5 border" style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                flexShrink: '0',
                borderRadius: '33px',
            }}
            >
                <TitleL2>Suporte aos Físicos</TitleL2>
                <ul className=' text-justify'>
                    <li className='text-justify'><strong>Clientes Físicos: </strong>
                        Estendemos nossas funcionalidades para interações com os profissionais,
                        proporcionando uma experiência integrada e abrangente</li>
                    <li className='mt-2'><strong>Usuários:</strong> Nossos serviços melhoram o atendimento, facilita intercomunicação, acompanhamentos e gerenciamento, usuários são pré-profissionais</li>
                    <li className='mt-2'><strong>Gadgets:</strong> Somos compatíveis com uma variedade de gadgets, permitindo acesso conveniente em diferentes dispositivos</li>
                </ul>
            </div>
        </div>
    </>);
}
export default CompatibilidadeAtendimentoServicos;
import { createContext, useContext, useState, useEffect } from 'react';

const ClienteContext = createContext(null);

export const ClienteProvider = ({ children }) => {
  const getStoredCliente = () => {
    try {
      const storedCliente = localStorage.getItem('cliente');
      return storedCliente ? JSON.parse(storedCliente) : null;
    } catch (error) {
      console.error('Erro ao recuperar cliente do localStorage:', error);
      return null;
    }
  };

  const [cliente, setCliente] = useState(getStoredCliente);

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === 'cliente') {
        try {
          setCliente(event.newValue ? JSON.parse(event.newValue) : null);
        } catch (error) {
          console.error('Erro ao atualizar cliente do localStorage:', error);
        }
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);

  const updateCliente = (newCliente) => {
    try {
      localStorage.setItem('cliente', JSON.stringify(newCliente));
      setCliente(newCliente);
    } catch (error) {
      console.error('Erro ao salvar cliente no localStorage:', error);
    }
  };

  const clearCliente = () => {
    localStorage.removeItem('cliente');
    setCliente(null);
  };

  return (
    <ClienteContext.Provider value={{ cliente, updateCliente, clearCliente }}>
      {children}
    </ClienteContext.Provider>
  );
};

export const useCliente = () => {
  const context = useContext(ClienteContext);
  if (!context) {
    throw new Error('useCliente deve ser usado dentro de um ClienteProvider');
  }
  return context;
};

import React, { useState } from 'react';
import { ButtonAdicionarCampo, ButtonEditarCampo, ButtonRemoverCampo } from '@components';
const ProntuarioDentistaFioDental = () => {
  const [usaFioDental, setUsaFioDental] = useState(false);
  const [historicoFioDental, setHistoricoFioDental] = useState([]);
  const [novaResposta, setNovaResposta] = useState('');
  const [indiceEditando, setIndiceEditando] = useState(-1);
  const handleCheckboxChange = () => {
    setUsaFioDental(!usaFioDental);
  };
  const handleAdicionarResposta = () => {
    if (novaResposta.trim() !== '') {
      if (indiceEditando === -1) {
        setHistoricoFioDental([...historicoFioDental, novaResposta]);
      } else {
        const novaLista = [...historicoFioDental];
        novaLista[indiceEditando] = novaResposta;
        setHistoricoFioDental(novaLista);
        setIndiceEditando(-1);
      }
      setNovaResposta('');
    }
  };
  const handleRemoverResposta = (index) => {
    const novaLista = [...historicoFioDental];
    novaLista.splice(index, 1);
    setHistoricoFioDental(novaLista);
  };
  const handleEditarResposta = (index) => {
    setNovaResposta(historicoFioDental[index]);
    setIndiceEditando(index);
  };
  return (
    <>
      <h5 style={{ fontWeight: "bold" }}>Usa fio dental?</h5>
      <label>
        <input type="checkbox" defaultChecked={usaFioDental} onChange={handleCheckboxChange} /> Sim
      </label>
      <br />
      <label>
        <input type="checkbox" defaultChecked={!usaFioDental} onChange={() => setUsaFioDental(false)} /> Não
      </label>
      {usaFioDental && (
        <>
          <h5 >Quantas vezes ao dia:</h5>
          <div>
            <input
              type="text"
              value={novaResposta}
              onChange={(e) => setNovaResposta(e.target.value)}
              placeholder="Nova resposta"
            />
            <ButtonAdicionarCampo onClick={handleAdicionarResposta} />
          </div>
        </>
      )}
      <ul>
        {historicoFioDental.map((resposta, index) => (
          <li key={index}>
            {resposta}
            <ButtonEditarCampo onClick={() => handleEditarResposta(index)} />
            <ButtonRemoverCampo onClick={() => handleRemoverResposta(index)} />
          </li>
        ))}
      </ul>
    </>
  );
};
export default ProntuarioDentistaFioDental;
import "./Footer.css";
export const FooterWiki = () => {
  return (
    <>
      <div className="wiki-bg-primary">
        <div className="py-1 text-left"></div>
        <h5 className="text-white text-center mt-1 ">
          Redes sociais outros contatos{" "}
        </h5>
        <div className="d-flex">
          <div className=" mx-auto text-center">
            <a
              className="mt-1"
              target={"_blank"}
              rel={"noopener noreferrer"}
              href={"https://www.linkedin.com/company/thot-ia-corp/"}
            >
              <i className="icon  text-white mr-3 ion-social-linkedin fa-3x"></i>
            </a>
            <a
              className="mt-1"
              target={"_blank"}
              rel={"noopener noreferrer"}
              href={"https://www.instagram.com/t8corp/"}
            >
              <i className="icon  text-white mr-3 ion-social-instagram fa-3x"></i>
            </a>
            <a
              className="mt-1"
              target={"_blank"}
              rel={"noopener noreferrer"}
              href={"https://github.com/THOTIACORP"}
            >
              <i className="icon  mr-3  text-white fa-3x  ion-social-github"></i>
            </a>
            <a
              className="mt-1"
              target={"_blank"}
              rel={"noopener noreferrer"}
              href={
                "https://api.whatsapp.com/send?phone=5566996675486&text=Estou%20entrando%20em%20contato%20para%20"
              }
            >
              <i className="ion-social-whatsapp  text-white fa-3x"></i>
            </a>
          </div>
        </div>
        <div className="d-flex inlyne flex-row-reverse bg-primary">
          <div className="d-flex align-items-center mx-auto text-center">
            <p className="text-white  mt-1 text-center">
              © THOT I.A. CORP. &nbsp;- Todos direitos reservados
            </p>
          </div>
        </div>
      </div>
    </>
    //  <>
    //    <Footer />
    //  </>
  );
};
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
import {
  //Public  
  NotFound,
  Artigos,
  RenderArtigos,
  Cadastrar,
  Carrinho,
  Home,
  Login,
  Parcerias,
  Produtos,
  ProductsDetailsPage,
  Search,
  Servicos,
  ServicesDetailsPage,
  SobreNos,
  //Private User
  Atestados,
  Cadastro,
  CadastroPaciente,
  CadastroProfissional,
  CarrinhoPaciente,
  Consultas,
  MarcarConsultas,
  ConsultasMarcadas,
  ConsultasFinalizadas,
  EquipamentosPacientes,
  Exames,
  MarcarExames,
  ExamesMarcados,
  ExamesFinalizados,
  Pacientes, //Home Usuário trocar HomeClient
  Medicamentos,
  Notificacoes,
  Preferencias,
  ProntuarioPaciente,
  UniversoImersivo,
  Social,
  //Private Profissionais
  AgendaProfissional,
  AtestadosProfissionais,
  AtestadosProfissionaisNovo,
  AtestadosProfissionaisArmazenado,
  AtestadosProfissionaisAtivo,
  EmitirAtestadoPage,
  DashBoardFinanceiro,
  Ecommerce,
  Equipamentos,
  Profissionais,//Home Profissionais
  IA,
  ProspeccaoClientes,
  ImagemIA,
  TextIA,
  MedicacaoProfissional,
  NotificacoesProfissionais,
  ServicosProfissionais,
  Wiki,
  Sprints,
  Configuracoes,
  Projetos,
  ProdutosProfissionais,
  WikiInfo,
  ProdutosCadastrar,
  ProdutosVendidos,
  ProdutosEstoque,
  ProfissionaisServicosAprovados,
  ProfissionaisServicosOrcamento,
  ProfissionaisCadastrarServicos,
  ProfissionaisEditarServicos,
  UserProdutos,
  UserServices,
  VendasProfissionais,
} from "pages"
import { AuthProvider, AuthUser, AuthProfessional } from "guard";
import { AnalyticsComponent, UrlChecker } from "functions";
import DuplicateKeysMonitor from "../tests/DuplicateKeysMonitor.jsx";
import { ClienteProvider } from "context";
const AppRoutes = () => {
  return (<>
    <HelmetProvider>
      <BrowserRouter>
        <AuthProvider>
          <ClienteProvider>
            <AnalyticsComponent />
            <UrlChecker />
            { /*   <Adsense />*/}
            {/* Bateria de testes localhost */}
            {process.env.NODE_ENV === 'development' && <DuplicateKeysMonitor />}
            <Routes>
              {/* PÚBLICAS colocar na sequencia de nome das pastas facilitar correção*/}
              <Route path="*" element={<NotFound />} />
              <Route exact path="/artigos" element={<Artigos />} />
              <Route exact path="/artigos/:id" element={<RenderArtigos />} />
              <Route exact path="/cadastrar" element={<Cadastrar />} />
              <Route exact path="/carrinho" element={<Carrinho />} />
              <Route exact path="/" element={<Home />} />
              <Route exact path="/login" element={<Login />} />
              <Route exact path="/parcerias" element={<Parcerias />} />
              <Route exact path="/produtos" element={<Produtos />} />
              <Route path="/produtos/:products_id" element={<ProductsDetailsPage />} />
              <Route exact path="/servicos" element={<Servicos />} />
              <Route path="/servicos/:services_id" element={<ServicesDetailsPage />} />
              <Route exact path="/sobre" element={<SobreNos />} />
              <Route exact path="/search" element={<Search />} />
              {/* PRIVADAS Usuários colocar na sequencia de nome das pastas facilitar correção*/}
              <Route exact path="/usuario/atestados" element={<AuthUser><Atestados /></AuthUser>} />
              <Route exact path="/usuario/cadastro" element={<AuthUser><Cadastro /></AuthUser>} />
              <Route exact path="/usuario/cadastro/usuario" element={<AuthUser><CadastroPaciente /></AuthUser>} />
              <Route exact path="/usuario/cadastro/profissional" element={<AuthUser><CadastroProfissional /></AuthUser>} />
              <Route exact path="/usuario/carrinho" element={<AuthUser><CarrinhoPaciente /></AuthUser>} />
              <Route exact path="/usuario/consultas" element={<AuthUser><Consultas /></AuthUser>} />
              <Route exact path="/usuario/consultas/marcar" element={<AuthUser><MarcarConsultas /></AuthUser>} />
              <Route exact path="/usuario/consultas/marcadas" element={<AuthUser><ConsultasMarcadas /></AuthUser>} />
              <Route exact path="/usuario/consultas/finalizadas" element={<AuthUser><ConsultasFinalizadas /></AuthUser>} />
              <Route exact path="/usuario/equipamentos" element={<AuthUser><EquipamentosPacientes /></AuthUser>} />
              <Route exact path="/usuario/exames" element={<AuthUser><Exames /></AuthUser>} />
              <Route exact path="/usuario/exames/marcar" element={<AuthUser><MarcarExames /></AuthUser>} />
              <Route exact path="/usuario/exames/marcados" element={<AuthUser><ExamesMarcados /></AuthUser>} />
              <Route exact path="/usuario/exames/finalizados" element={<AuthUser><ExamesFinalizados /></AuthUser>} />
              <Route exact path="/usuario" element={<AuthUser><Pacientes /></AuthUser>} />
              <Route exact path="/usuario/medicamentos" element={<AuthUser><Medicamentos /></AuthUser>} />
              <Route exact path="/usuario/notificacoes" element={<AuthUser><Notificacoes /></AuthUser>} />
              <Route exact path="/usuario/produtos" element={<AuthUser><UserProdutos /></AuthUser>} />
              <Route exact path="/usuario/preferencias" element={<AuthUser><Preferencias /></AuthUser>} />
              <Route exact path="/usuario/prontuario" element={<AuthUser><ProntuarioPaciente /></AuthUser>} />
              <Route exact path="/usuario/produtos/:products_id" element={<AuthUser><ProductsDetailsPage /></AuthUser>} />
              <Route exact path="/usuario/servicos" element={<AuthUser><UserServices /></AuthUser>} />
              <Route exact path="/usuario/servicos/:services_id" element={<AuthUser><ServicesDetailsPage /></AuthUser>} />
              <Route exact path="/usuario/social" element={<AuthUser><Social /></AuthUser>} />
              <Route exact path="/usuario/universoImersivo" element={<AuthUser><UniversoImersivo /></AuthUser>} />
              {/* PRIVADAS Profissionais colocar na sequencia de nome das pastas facilitar correção*/}
              <Route exact path="/profissionais" element={<AuthProfessional><Profissionais /></AuthProfessional>} />
              <Route exact path="/profissionais/consultas" element={<AuthProfessional><Consultas /></AuthProfessional>} />
              <Route exact path="/profissionais/graficos" element={<AuthProfessional><DashBoardFinanceiro /></AuthProfessional>} />
              <Route exact path="/profissionais/ecommerce" element={<AuthProfessional><Ecommerce /></AuthProfessional>} />
              <Route exact path="/profissionais/IA" element={<AuthProfessional><IA /></AuthProfessional>} />
              <Route exact path="/profissionais/IA/text" element={<AuthProfessional><TextIA /></AuthProfessional>} />
              <Route exact path="/profissionais/IA/imagem" element={<AuthProfessional><ImagemIA /></AuthProfessional>} />
              <Route exact path="/profissionais/prospeccaoIA" element={<AuthProfessional><ProspeccaoClientes /></AuthProfessional>} />
              <Route exact path="/profissionais/servicos" element={<AuthProfessional><ServicosProfissionais /></AuthProfessional>} />
              <Route exact path="/profissionais/servicos/orcamento" element={<AuthProfessional><ProfissionaisServicosOrcamento /></AuthProfessional>} />
              <Route exact path="/profissionais/servicos/aprovados" element={<AuthProfessional><ProfissionaisServicosAprovados /></AuthProfessional>} />
              <Route exact path="/profissionais/servicos/finalizados" element={<AuthProfessional><ServicosProfissionais /></AuthProfessional>} />
              <Route exact path="/profissionais/servicos/cadastrar" element={<AuthProfessional><ProfissionaisCadastrarServicos /></AuthProfessional>} />
              <Route exact path="/profissionais/servicos/editar" element={<AuthProfessional><ProfissionaisEditarServicos /></AuthProfessional>} />
              <Route exact path="/profissionais/vendas" element={<AuthProfessional><VendasProfissionais /></AuthProfessional>} />
              <Route exact path="/profissionais/produtos" element={<AuthProfessional><ProdutosProfissionais /></AuthProfessional>} />
              <Route exact path="/profissionais/produtos/cadastrar" element={<AuthProfessional><ProdutosCadastrar /></AuthProfessional>} />
              <Route exact path="/profissionais/produtos/estoque" element={<AuthProfessional><ProdutosEstoque /></AuthProfessional>} />
              <Route exact path="/profissionais/produtos/vendidos" element={<AuthProfessional><ProdutosVendidos /></AuthProfessional>} />
              <Route exact path="/profissionais/equipamentos" element={<AuthProfessional><Equipamentos /></AuthProfessional>} />
              <Route exact path="/profissionais/agenda" element={<AuthProfessional><AgendaProfissional /></AuthProfessional>} />
              <Route exact path="/profissionais/medicacao" element={<AuthProfessional><MedicacaoProfissional /></AuthProfessional>} />
              <Route exact path="/profissionais/atestados" element={<AuthProfessional><AtestadosProfissionais /></AuthProfessional>} />
              <Route exact path="/profissionais/atestados/novo" element={<AuthProfessional><AtestadosProfissionaisNovo /></AuthProfessional>} />
              <Route exact path="/profissionais/atestados/ativo" element={<AuthProfessional><AtestadosProfissionaisAtivo /></AuthProfessional>} />
              <Route exact path="/profissionais/atestados/emitir-atestado" element={<AuthProfessional><EmitirAtestadoPage /></AuthProfessional>} />
              <Route exact path="/profissionais/atestados/armazenado" element={<AuthProfessional><AtestadosProfissionaisArmazenado /></AuthProfessional>} />
              <Route exact path="/profissionais/notificacoes" element={<AuthProfessional><NotificacoesProfissionais /></AuthProfessional>} />
              {/* PRIVADAS WIKI*/}
              <Route exact path="/wiki" element={<AuthProfessional><Wiki /></AuthProfessional>} />
              <Route exact path="/wiki/info" element={<AuthProfessional><WikiInfo /></AuthProfessional>} />
              <Route exact path="/wiki/sprint" element={<AuthProfessional><Sprints /></AuthProfessional>} />
              <Route exact path="/wiki/projetos" element={<AuthProfessional><Projetos /></AuthProfessional>} />
              <Route exact path="/wiki/configuracoes" element={<AuthProfessional><Configuracoes /></AuthProfessional>} />
            </Routes>
          </ClienteProvider>
        </AuthProvider>
      </BrowserRouter>
    </HelmetProvider >
  </>)
}
export default AppRoutes
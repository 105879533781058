import React from 'react';
import { TitleL2 } from '@components';
export const VisionCards = () => {
  return (<>
    <TitleL2 className='mt-5'>Definição da Visão</TitleL2>
    <div className='row' style={{ padding: '20px', marginTop: '15px', textAlign: 'justify' }}>
      <div className='col-md-4'>
        <p>  <strong>Como nos aprimoramos continuamente !? </strong>
          A THOT I.A. CORP. busca o aprimoramento contínuo por meio da inovação em nossos processos e produtos.
          Estamos constantemente revisando e otimizando nossas práticas para garantir um desempenho de excelência.
          Acreditamos que a criatividade e a adaptação são essenciais para nos mantermos competitivos e alinhados às melhores práticas de mercado.
        </p>
      </div>
      <div className='col-md-4'>
        <p>   <strong>Quando iniciamos o processo de aprimoramento !? </strong>
          O processo de aprimoramento começa desde o primeiro dia de operação da THOT I.A. CORP..
          A melhoria contínua está enraizada em nossa cultura organizacional, sendo um esforço diário.
          Nossos colaboradores são incentivados a aprender e a se desenvolver constantemente, garantindo que nossa evolução ocorra de forma natural e progressiva.
        </p>
      </div>
      <div className='col-md-4'>
        <p>    <strong>Onde focamos nosso aprimoramento !? </strong>
          Nosso foco de aprimoramento está em todas as áreas de atuação, desde os processos internos até o desenvolvimento de novos produtos.
          A THOT I.A. CORP. investe em tecnologia de ponta e nas melhores práticas de mercado, assegurando que a inovação permeie todas as nossas operações, impactando positivamente nossos clientes e parceiros.
        </p>
      </div>
    </div>
  </>);
};
export default VisionCards;
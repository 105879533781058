import React from "react";
import { ButtonAPI } from "@components";
const TesteAutoCadastrar = ({
  setName,
  setEmail,
  setCpf,
  setDataNascimento,
  setPhone,
  setPassword,
  setConfirmPassword,
  setShowPassword,
  setProfissional,
  getLocalizacao,
}) => {
  const testAutoCadastrar = () => {
    setName("Teste automático");
    setEmail("teste@thotiacorp.com");
    setCpf("123.456.789-00");
    const dataNascimento = new Date(1929, 1, 2); // Ano, Mês (0-11), Dia
    const formattedDataNascimento = dataNascimento.toISOString().split("T")[0];
    setDataNascimento(formattedDataNascimento);
    setPhone("+5566996675486");
    setPassword("12345");
    setConfirmPassword("12345");
    setShowPassword(true);
    setProfissional(true);
    getLocalizacao();
    // Preencha outros campos do formulário com seus respectivos setters
  };
  const isCadastrarPage = window.location.pathname === "/cadastrar"; // Verifica o caminho da URL
  if (window.location.hostname === "localhost") {
    return (
      <>
        {isCadastrarPage && (
          <>
            <div className="py-4">
              <ButtonAPI className="btn btn-warning" onClick={testAutoCadastrar}>
                <b>Preencher Auto Teste -<strong> Homolog</strong></b>
              </ButtonAPI>
            </div>
          </>
        )}
      </>
    );
  }
  return null;
};
export default TesteAutoCadastrar;
import React, { useState, useEffect } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Upload, Modal } from 'antd';
export const DragDropPdf = ({ onPdfUpload, initialPdf }) => {
  const [fileList, setFileList] = useState([]);
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);
  useEffect(() => {    
    if (initialPdf) {
      setFileList([{
        uid: '-1',
        name: 'Bula.pdf',
        status: 'done',
        url: String(initialPdf), 
      }]);
    }
  }, [initialPdf]);
  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    if (newFileList.length > 0) {
      onPdfUpload(newFileList[0].originFileObj);
    } else {
      onPdfUpload(null);
    }
  };
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  const handlePreview = () => {
    setIsPreviewVisible(true);
  };
  return (
    <>
      <Upload
        accept=".pdf"
        action={null}
        listType="picture-card"
        fileList={fileList}
        onChange={handleChange}
        beforeUpload={() => false} // Prevent auto upload
        onPreview={handlePreview} // Adiciona função de preview
      >
        {fileList.length >= 1 ? null : uploadButton}
      </Upload>
      <Modal
        title="Pré-visualização do PDF"
        visible={isPreviewVisible}
        footer={null}
        onCancel={() => setIsPreviewVisible(false)}
      >
        {fileList.length > 0 && (
          <iframe
            src={fileList[0].url}
            style={{ width: '100%', height: '400px' }}
            title="PDF Preview"
          />
        )}
        {fileList.length === 0 && <p>Pré-visualização de PDFs não está disponível.</p>}
      </Modal>
    </>
  );
};
export default DragDropPdf;
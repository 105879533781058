import React, { useState } from 'react';
import { Link } from 'react-router-dom';
export const ProntuarioGestante = () => {
  const [estaGestante, setEstaGestante] = useState(false);
  const [semanasGestacao, setSemanasGestacao] = useState('');
  const handleGestanteChange = (e) => {
    setEstaGestante(e.target.checked);
    if (!e.target.checked) {
      setSemanasGestacao('');
    }
  };
  const handleSemanasGestacaoChange = (e) => {
    setSemanasGestacao(e.target.value);
  };
  return (
    <>
      <h5 style={{ fontWeight: "bold" }}>
        Gestante?
      </h5>
      <label>
        <input type="checkbox" onChange={handleGestanteChange} /> Sim
      </label>
      <br />
      <label>
        <input type="checkbox" onChange={() => setEstaGestante(false)} /> Não
      </label>
      <br />
      <label>
        <input
          type="checkbox"
          onChange={() => setEstaGestante(!estaGestante)}
        /> Não Sei
        {estaGestante && <>(Semanas:){' '}
          <input
            type="number"
            value={semanasGestacao}
            onChange={handleSemanasGestacaoChange}
            placeholder="Informe o número de semanas"
          /></>}
      </label>
      <div className="col-md-12 mt-2 mb-5 d-flex">
        <Link className="btn btn-primary mx-auto col-md-6" href="#">
          Enviar Informações
        </Link>
      </div>
    </>
  );
};
export default ProntuarioGestante;
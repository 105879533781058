import React, { useState } from 'react';
import { ButtonAdicionarCampo, ButtonEditarCampo, ButtonRemoverCampo } from '@components';
const PronutarioDentistaGostoRuinBoca = () => {
  const [senteGostoRuimBoca, setSenteGostoRuimBoca] = useState(false);
  const [gostoRuimBocaItens, setGostoRuimBocaItens] = useState([]);
  const [novoItem, setNovoItem] = useState('');
  const [indiceEditando, setIndiceEditando] = useState(-1);
  const handleCheckboxChange = () => {
    setSenteGostoRuimBoca(!senteGostoRuimBoca);
  };
  const handleNovoItemChange = (e) => {
    setNovoItem(e.target.value);
  };
  const handleAdicionarItem = () => {
    if (novoItem.trim() !== '') {
      if (indiceEditando === -1) {
        setGostoRuimBocaItens([...gostoRuimBocaItens, novoItem]);
      } else {
        const novaLista = [...gostoRuimBocaItens];
        novaLista[indiceEditando] = novoItem;
        setGostoRuimBocaItens(novaLista);
        setIndiceEditando(-1);
      }
      setNovoItem('');
    }
  };
  const handleRemoverItem = (index) => {
    const novaLista = [...gostoRuimBocaItens];
    novaLista.splice(index, 1);
    setGostoRuimBocaItens(novaLista);
  };
  const handleEditarItem = (index) => {
    setNovoItem(gostoRuimBocaItens[index]);
    setIndiceEditando(index);
  };
  return (
    <>
      <h5 style={{ fontWeight: "bold" }}>
        Tem sentido gosto ruim na boca ou boca seca?    </h5>
      <label>
        <input type="checkbox" defaultChecked={senteGostoRuimBoca} onChange={handleCheckboxChange} /> Sim
      </label>
      <br />
      <label>
        <input type="checkbox" defaultChecked={!senteGostoRuimBoca} onChange={() => setSenteGostoRuimBoca(false)} /> Não
      </label>
      {senteGostoRuimBoca && (
        <div>
          <div>
            <input
              type="text"
              value={novoItem}
              onChange={handleNovoItemChange}
              placeholder="Quando"
            />
            <ButtonAdicionarCampo onClick={handleAdicionarItem} />
          </div>
          <ul>
            {gostoRuimBocaItens.map((item, index) => (
              <li key={index}>
                {item}
                <ButtonEditarCampo onClick={() => handleEditarItem(index)} />
                <ButtonRemoverCampo onClick={() => handleRemoverItem(index)} />
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};
export default PronutarioDentistaGostoRuinBoca;
import { Button, Modal } from 'antd';
import { ButtonHelp } from '@components';
import {
    tutorial_config,
    tutorial_step_back,
    tutorial_step_next,
    imgFrontal,
    imgFrontalAR,
    imgFaceSorrindo,
} from '../../partials';
export const FaceGuidedTutorial = ({
    setPiscaMaisInfo,
    setHighlightStates,
    setThumbnail,
    setLoading,
    setModalVisible,
    setCurrentType,
    setPhotos,
    setLandmarks,
    setModifiedLandmarks,
    setImageWidth,
    setImageHeight,
    setApiImages,
    setApiResults,
    setOthers,
    setCurrentAction,
    setTutorialStep,
    currentAction,
    modalVisible,
    handleImageLoad,
    tutorialStep
}) => {
    const stepActions = [
        function handleStep1() {
            setPiscaMaisInfo(true)
            setTimeout(() => {
                setPiscaMaisInfo(false);
            }, 8000);
        },
        async function handleStep2() {
            const type = "FaceFrontal";
            destacarCard(type)
            setThumbnail((prev) => ({ ...prev, [type]: imgFrontal }));
            setPhotos((prev) => ({ ...prev, [type]: imgFrontal }));
            const element = document.getElementById("card_id_FaceFrontal");
            if (element) {
                element.scrollIntoView({
                    behavior: "smooth",
                    block: "end",
                });
            }
            try {
                setLoading(true);
                setCurrentType(type)
                const imgElement = document.getElementById('651614552525');
                if (imgElement) {
                    imgElement.onload = handleImageLoad;
                    imgElement.src = imgFrontal;
                }
                const response = await fetch('/assets/json/response_analiseFacial_FaceFrontal.json');
                const simulatedResponse = await response.json();
                setLandmarks((prev) => ({ ...prev, [type]: simulatedResponse.data.res_ia.landmarks }));
                setModifiedLandmarks((prev) => ({ ...prev, [type]: simulatedResponse.data.res_ia.landmarks }));
                setOthers((prev) => ({ ...prev, [type]: simulatedResponse.data.res_ia.others }));
                setApiImages((prev) => ({ ...prev, [type]: simulatedResponse.data.res_ia.images }));
                setApiResults((prev) => ({ ...prev, [type]: simulatedResponse.data.res_ia }));
                await new Promise(resolve => setTimeout(resolve, 1000))
                const element = document.getElementById("bntAnaliseFacialTradePontos");
                if (element) {
                    element.scrollIntoView({
                        behavior: "smooth",
                        block: "end",
                    });
                }
                setLoading(false);
            } catch (error) {
                console.error("Erro ao simular upload da imagem:", error);
                setLoading(false);
            }
        },
        async function handleStep3() {
            console.log("Executando lógica do Passo 3");
            const element = document.getElementById("downloadPDFAnaliseFacial");
            if (element) {
                element.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                });
            }
            await new Promise(resolve => setTimeout(resolve, 1000))
            const button = document.getElementById("downloadPDFAnaliseFacial");
            if (button) {
                button.click();
            }
        },
        async function handleStep4() {
            const type = "FaceFrontalAR";
            destacarCard(type);
            const element = document.getElementById("card_id_FaceFrontalAR");
            if (element) {
                element.scrollIntoView({
                    behavior: "smooth",
                    block: "end",
                });
            }
            setThumbnail((prev) => ({ ...prev, [type]: imgFrontalAR }));
            setPhotos((prev) => ({ ...prev, [type]: imgFrontalAR }));
            setCurrentType(type)
            try {
                const response = await fetch('/assets/json/response_analiseFacial_FaceFrontalAR.json');
                const simulatedResponse = await response.json();
                setApiResults((prev) => ({ ...prev, [type]: simulatedResponse.data.res_ia }));
                setLandmarks((prev) => ({ ...prev, [type]: simulatedResponse.data.res_ia.landmarks }));
                setModifiedLandmarks((prev) => ({ ...prev, [type]: simulatedResponse.data.res_ia.landmarks }));
                setApiImages((prev) => ({ ...prev, [type]: simulatedResponse.data.res_ia.images }));
                setOthers((prev) => ({ ...prev, [type]: simulatedResponse.data.res_ia.others }));
            } catch (error) {
                console.error("Erro ao simular upload da imagem:", error);
                setLoading(false);
            }
        },
        function handleStep5() {
            const element = document.getElementById("card_id_FaceFrontalAR");
            if (element) {
                element.scrollIntoView({
                    behavior: "smooth",
                    block: "end",
                });
            }
        },
        async function handleStep6() {
            const element = document.getElementById("card_id_FaceSorrindo");
            const type = "FaceSorrindo";
            setThumbnail((prev) => ({ ...prev, [type]: imgFaceSorrindo }));
            setPhotos((prev) => ({ ...prev, [type]: imgFaceSorrindo }));
            setCurrentType(type)
            if (element) {
                element.scrollIntoView({
                    behavior: "smooth",
                    block: "end",
                });
            }
            try {
                const response = await fetch('/assets/json/response_analiseFacial_FaceFrontalSurpresa.json');
                const simulatedResponse = await response.json();
                setApiResults((prev) => ({ ...prev, [type]: simulatedResponse.data.res_ia }));
                setApiImages((prev) => ({ ...prev, [type]: simulatedResponse.data.res_ia.images }));
                setOthers((prev) => ({ ...prev, [type]: simulatedResponse.data.res_ia.others }));
            } catch (error) {
                console.error("Erro ao simular upload da imagem:", error);
                setLoading(false);
            }
        }
    ];
    const cancelarTutorial = () => {
        setModalVisible(false);
        setCurrentAction(null);
        setTutorialStep(0);
        setLoading(false); // Exemplo: inicializando como 'false'
        setPhotos({});
        setThumbnail({});
        setApiImages([]); // Exemplo: imagem lateral esquerda inicial
        setLandmarks([]); // Exemplo: array vazio
        setModifiedLandmarks([]); // Exemplo: array vazio para landmarks modificados
        setImageWidth(0); // Exemplo: largura da imagem inicial como 0
        setImageHeight(0); // Exemplo: altura da imagem inicial como 0
    };
    const handleTutorial = () => {
        setModalVisible(false);
        setCurrentAction('tutorial');
        stepActions[0](); // Começa o tutorial no Passo 1
    };
    const showAlert = () => {
        setModalVisible(true);
    };
    const handleWhatsApp = () => {
        window.open("https://wa.me/5566996675486", "_blank");
    };
    const destacarCard = (type) => {
        setHighlightStates((prevStates) => ({
            ...prevStates,
            [type]: true,
        }));
        setTimeout(() => {
            setHighlightStates((prevStates) => ({
                ...prevStates,
                [type]: false,
            }));
        }, 10000);
    };
    return (<>
        <ButtonHelp onClick={showAlert} />
        <Modal
            title="Ajuda"
            visible={modalVisible}
            onCancel={cancelarTutorial}
            footer={null}
        >
            <p>Escolha uma opção:</p>
            <Button type="primary"     style={{ marginRight: '10px', backgroundColor: 'green', borderColor: 'green' }} onClick={handleTutorial} >
                Tutorial Guiado
            </Button>
            <Button type="primary" onClick={handleWhatsApp}>
                Converse com a Equipe Técnica
            </Button>
        </Modal>
        {currentAction === 'tutorial' && (
            <Modal
                title="Tutorial Guiado - Análise Facial"
                visible={currentAction === 'tutorial'}
                onCancel={cancelarTutorial}
                footer={[
                    <Button
                        key="previous"
                        type="default"
                        onClick={() => tutorial_step_back(tutorialStep, setTutorialStep, stepActions)}
                        disabled={tutorialStep === 0}>
                        Passo Anterior
                    </Button>,
                    tutorialStep < tutorial_config.length - 1 ? (
                        <Button
                            key="next"
                            type="primary"
                            onClick={() => tutorial_step_next(tutorialStep, setTutorialStep, stepActions, setCurrentAction)}
                        > Próximo Passo
                        </Button>
                    ) : (
                        <Button key="finish" type="default" 
                        style={{ marginRight: '10px', backgroundColor: 'green', borderColor: 'green' }}
                        onClick={() => {
                            setTutorialStep(0);
                            setCurrentAction(null);
                        }}>
                            Finalizar Tutorial
                        </Button>
                    )
                ]}>
                {tutorial_config[tutorialStep].split('\n').map((line, index) => (
                    <p className='text-justify' key={index}>
                        {line}
                    </p>
                ))}
            </Modal>)}
    </>);
};
export default FaceGuidedTutorial;
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios'; 
import { baseURL } from '@services';
import { MaisInfoMenosInfo } from '@components';
import CommentSectionIATextInfo from '../Text/info/CommentSectionIATextInfo';
export function CommentSectionIA({ onComment, comments, setComments }) {
  const [commentText, setCommentText] = useState('');
  const fetchComments = useCallback(async () => {
    try {
      const existingDriveIAData = JSON.parse(localStorage.getItem('driveIA'));
      const ia_id = existingDriveIAData.id;
      const response = await fetch(`${baseURL}buscarComentariosIAText/${ia_id}`);
      const data = await response.json();
      setComments(data.comentarios);
    } catch (error) {
      console.error(error);
    }
  }, [setComments]);
  useEffect(() => {
    fetchComments();
    const interval = setInterval(() => {
      fetchComments();
    }, 3000);
    return () => {
      clearInterval(interval);
    };
  }, [fetchComments]);
  const handleSubmit = async (event) => {
    event.preventDefault();
    postComment();
  };
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      postComment();
    }
  };
  const postComment = async () => {
    if (commentText.trim() !== '') {
      try {
        const existingDriveIAData = JSON.parse(localStorage.getItem('driveIA'));
        if (existingDriveIAData) {
          const postData = {
            user_id: existingDriveIAData.user_id,
            version_id: existingDriveIAData.versoes[0].numero_versao,
            ia_id: existingDriveIAData.id,
            comentario: commentText,
          };
          await axios.post(`${baseURL}postarComentario`, postData);
          fetchComments();
          setCommentText('');
        }
      } catch (error) {
        console.error(error);
      }
    }
  };
  return (
    <div className="col-md-12 mt-2 rounded bg-light">
      <h5 className="font-weight-bold mt-3 mb-3">Comentários da I.A</h5>
      <div className="text-justify">
        {comments.map((comment, index) => (
          <div key={index} className="mb-2">
            <strong>{comment.user_name}:</strong> {comment.comentario}
          </div>
        ))}
      </div>
      <div className="generate-container">
        <div className="input-container mt-3 mb-3">
          <input
            className="input-field form-control mt-1 form-control-lg text-dark text-center"
            type="text"
            placeholder="..."
            value={commentText}
            onChange={(e) => setCommentText(e.target.value)}
            onKeyPress={handleKeyPress}
          />
          <button className="success generate-button" onClick={handleSubmit}>
            <i className="input-filled fa fa-rocket mt-2"></i>
          </button>
        </div>
      </div>
     <MaisInfoMenosInfo text={<CommentSectionIATextInfo />}></MaisInfoMenosInfo>
    </div>
  );
}
export default CommentSectionIA;
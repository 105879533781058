import React, { useEffect } from 'react';
import { ButtonAPI, TitleL2 } from '@components';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useState, useContext } from "react";
import { AuthContext } from "../../../../../guard";
import TesteAutoLogin from '../testes/TesteAutoLogin';
import CadastroBotoesTentent from '../../../Cadastrar/partials/layout/CadastroAuth';
import { baseURL, fetchUserPhoto } from '@services';
import { Divider, Form, Input } from 'antd';
import axios from 'axios';
export const LoginMain = () => {
  // const  {authenticated, login} = useContext(AuthContext)
  const { login } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [loginNativo, setLoginNativo] = useState(false);
  const location = useLocation();
  const { search } = location;
  const navigate = useNavigate();
  const efetuarLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await login(email, password);
      if (response.status === 200) {
        alert('Logged in successfully');
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };
  const onclickLoginNativo = () => {
    const userStr = localStorage.getItem('user');
    if (userStr) {
      navigate('/usuario' + search);
    }
    setLoginNativo((prevLoginNativo) => !prevLoginNativo);
    if (window.location.hostname === 'localhost') {
      setEmail("teste@thotiacorp.com");
      setPassword("12345");
    }
    setTimeout(() => {
      const emailField = document.getElementById('email');
      if (emailField) {
        emailField.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 400);
  };
  useEffect(() => {
    const userStr = localStorage.getItem('user');
    if (userStr) {
      const user = JSON.parse(userStr);
      const id = user.id;
      fetchUserPhoto(id, setImageUrl);
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');
      if (code) {
        // Remove the code from the URL
        urlParams.delete('code');
        const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
        window.history.replaceState(null, '', newUrl);
        // Redirect to /usuario
        navigate('/usuario' + window.location.search);
      }
    }
  }, [navigate]);
  const onclickRedirecionaParteInterna = () => {
    navigate('/usuario' + search);
  };
  const logout = () => {
    localStorage.clear();
    setImageUrl(null);
  };
  const recuperarSenha = async () => {
    try {
      const response = await axios.post(`${baseURL}post_user_recover_passowrd_email`, { emailOrCpf: email });
      if (response.status === 200) {
        alert('Email de recuperação de senha enviado com sucesso.');
      }
    } catch (error) {
      alert('Erro ao enviar email de recuperação de senha.');
    }
  };
  return (<>
    <div id="login" className=' mt-5'>
      {/* <p>{String(authenticated)}</p> */}
      <div className='text-center'>
        {!imageUrl && (<i className='fa fa-user-circle fa-5x text-white mx-auto mb-3   bg-primary'
          style={{ borderRadius: '50%' }}
        />)}
        {imageUrl && (
          <div style={{ position: "relative", display: "inline-block" }}>
            <img className="d-block rounded-circle w-50 mx-auto image-fluid"
              style={{
                maxHeight: '80.8px',
                minHeight: '80.8px',
                maxWidth: '77.6px',
                minWidth: '77.6px',
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.5), 0 5px 15px rgba(0, 0, 0, 0.2)"
              }}
              src={imageUrl}
              id="FaceFrontalLogin"
              alt="Imagem do Usuário"
              onClick={onclickRedirecionaParteInterna} />
            <i className=" fa fa-power-off mt-2  text-danger position-absolute h-25" onClick={logout} style={{
              zIndex: '1', top: '95%', left: '50%', transform: 'translate(-50%, -50%)',
            }}></i>
          </div>
        )}
      </div>
      <CadastroBotoesTentent onclickLoginNativo={onclickLoginNativo} text={'Login Rápido'} />
      {loginNativo &&
        <> <div className='text-center'>
          <TesteAutoLogin
            efetuarLogin={efetuarLogin}
            setEmail={setEmail}
            setPassword={setPassword}
          />
        </div>
          <Form
            className='border bg-light py-2 rounded'
            initialValues={{ remember: true }}
            layout="vertical"
            onSubmit={efetuarLogin}>
            <div className='col-md-10 mx-auto'>
              <TitleL2>Dados do Usuário</TitleL2>
              <Divider />
              <Form.Item rules={[{ required: true, message: 'Por favor, insira um e-mail!' }]} name="email" label={'E-mail'}>
                <Input
                  type="text"
                  placeholder="E-mail"
                  data-bouncer-message=" ATENÇÃO: Insira um e-mail válido."
                  value={email}
                  onChange={(e) => setEmail(e.target.value)} // Atualiza o estado 'email'
                  id="email" />
              </Form.Item>
              <Form.Item rules={[{ required: true, message: 'Por favor, insira uma senha!' }]} name="password" label={'Senha'}>
                <Input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Senha"
                  id="password" />
              </Form.Item>
            </div>
            <div className="form__field col-md-10 mx-auto  mt-2 text-center  d-flex  justify-content-end align-items-end">
              <small className="form-text text-right text-dark "><Link  onClick={recuperarSenha}><b className='text-dark bg-warning'>Recuperar
                Senha</b></Link></small>
            </div>
            <ButtonAPI isLoading={isLoading} style={{ minWidth: '50%' }} type={'button'} onClick={efetuarLogin} > Entrar</ButtonAPI >
            <h6 className="text-center mt-1">-OU-</h6>
            <Link className="text-white" to={'../cadastrar'} > <ButtonAPI style={{ minWidth: '50%' }} isLoading={isLoading} > Cadastrar</ButtonAPI ></Link>
          </Form>
        </>}
    </div>
  </>)
};
import { NavUserMain, NavBreadcrumb, InputPadraoSearch, FooterUser, } from '@components';
export const ConsultasFinalizadas = () => {
    return (<>
        <div className="d-flex">
            <NavUserMain />
            <div className='w-100'>
                <NavBreadcrumb text={'Consultas Finalizadas'} icon={'database fa'} />
                <div className="py-3">
                    <InputPadraoSearch />
                    <div className="text-center">
                        <div className="container-fluid">
                            <div className="row d-inline-flex justify-content-center w-100">
                            </div>
                        </div>
                    </div>
                </div>
                <FooterUser />
            </div>
        </div>
    </>)
}
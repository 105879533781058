import React, { useState, useContext, useEffect } from 'react';
import { ButtonAPI, MaisInfoMenosInfo } from '@components';
import { AuthContext } from '../../../../../../../../guard/auth';
import AreasConhecimentoCNPQ from './AreasConhecimentoCNPQ';
import { baseURL } from '@services';
import { CBO } from './CBO';
import CadastrarPhotoProfissional from './CadastrarPhotoProfissional';
import NomeProfissionalInfo from '../info/NomeProfissionalInfo';
import { apiToken } from '@services/contexts';
import CadastroAddress from '../../../../../../../Public/Cadastrar/partials/layout/CadastroAddress';
import useLocalizacao from '../../../../../../../Public/Cadastrar/partials/layout/useLocalizacao';
import SearchAreas from './SearchAreas';
export const CadastrarProfissional = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [professionalName, setProfessionalName] = useState('');
  const [localStorageName, setLocalStorageName] = useState('');
  const { latitude, setLatitude, longitude, setLongitude, address, getLocalizacao } = useLocalizacao();
  const [professionalNameMismatch, setProfessionalNameMismatch] = useState('');
  const [selectedAreas, setSelectedAreas] = useState([]);
  const { getUserId, getProfessionalId } = useContext(AuthContext);
  const id = getUserId();
  const professional_id = getProfessionalId();
  const getProfessionalName = () => {
    const professionalData = JSON.parse(localStorage.getItem("professionalData"));
    const firstProfessional = professionalData && professionalData.length > 0 ? professionalData[0] : null;
    if (firstProfessional) {
      // alert(firstProfessional.id);
      setProfessionalName(firstProfessional.professional_name);
    } else {
      //alert("Não foi possível encontrar dados profissionais.");
      return null;
    }
  };
  useEffect(() => {
    getProfessionalName();
    setProfessionalNameMismatch();
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setProfessionalNameMismatch('')
    try {
      const formData = {
        professional_name: professionalName,
        user_id: id,
        areas: selectedAreas,
        address: address
      };
      //alert(professionalName)
      const professional_id = localStorage.getItem('professionalData');
      if (professional_id) {
        await apiToken().put(`${baseURL}put_professional/${id}`, formData);
        setSelectedAreas([]);
        alert('Cadastro atualizado com sucesso.');
      } else {
        //alert('cadastrarProfissional')       
        await apiToken().post(`${baseURL}post_professional/${id}`, formData);
        setSelectedAreas([]);
        alert('Cadastro adicionado com sucesso.');
      }
    } catch (error) {
      // console.error('Erro ao adicionar o cadastro:', error);
      alert('Erro em salvar os dados');
    } finally {
      setIsLoading(false);
    }
  };
  const handleAddArea = (area) => {
    setSelectedAreas((prevAreas) => {
      const isAreaSelected = prevAreas.some(
        (prevArea) =>
          prevArea.grande_area === area.grande_area &&
          prevArea.area === area.area &&
          prevArea.sub_area === area.sub_area &&
          prevArea.especialidade === area.especialidade
      );
      if (!isAreaSelected) {
        return [...prevAreas, area];
      }
      return prevAreas;
    });
  };
  const handleRemoveArea = (area) => {
    setSelectedAreas((prevAreas) => prevAreas.filter((prevArea) => prevArea !== area));
  };
  const handleNameChange = (e) => {
    const inputName = e.target.value;
    setProfessionalName(inputName);
  };
  useEffect(() => {
    const storedProfessionalData = JSON.parse(localStorage.getItem("professionalData"));
    if (storedProfessionalData && storedProfessionalData.length > 0) {
      const firstStoredProfessional = storedProfessionalData[0];
      if (firstStoredProfessional.professional_name !== professionalName) {
        setProfessionalNameMismatch(true);
      } else {
        setProfessionalNameMismatch(false);
      }
    }
    const storedName = JSON.parse(localStorage.getItem('professionalData'));
    if (storedName && storedName.length > 0) {
      setLocalStorageName(storedName[0].professional_name);
    }
  }, [professionalName]);
  const inputClass = professionalNameMismatch
    ? 'bg-warning text-center d-flex justify-content-center col-lg-12 boxFooter '
    : 'text-center d-flex justify-content-center col-lg-12 boxFooter bg-primary ';
  return (<>
    <div className=' mx-auto '>
      <div className="form-group">
        <div className={`text-center d-flex justify-content-center col-lg-12 boxFooter bg-primary TridimencionalSupLat
                     ${professional_id ? 'bg-success' : 'bg-primary'
          }`} >
          <h5 className="text-white mt-2 font-weight-bold"> <b>{props.title}</b></h5>
        </div>
        <div className='d-flex justify-content-center mx-auto bg-light py-4'>
          <div className="col-md-10">
            <div className='mb-3 text-center'>
              <div className={inputClass}>
                <h5 className="text-white mt-2 font-weight-bold" > <b>Nome Profissional</b></h5>
              </div>
              <input
                className="form-control text-center col-md-12 border border-dark"
                id="input_name_profissional_autonomo"
                title={`Nome ${props.title}`}
                type="text"
                value={professionalName}
                onChange={handleNameChange}
                required
              />
              <small>Adicione como você quer que seus pacientes veja seu nome de {props.title}</small>
              <small>{localStorageName}</small>
              <MaisInfoMenosInfo text={<NomeProfissionalInfo />}></MaisInfoMenosInfo>
              {professional_id && professionalNameMismatch && <ButtonAPI type="submit" disabled={isLoading} onClick={handleSubmit}>
                {isLoading ? 'Enviando...' : `${professional_id ? `Salvar edição` : `Cadastrar info.`}`}
              </ButtonAPI>
              }
            </div>
            <div className='mt-3 py-3 text-center'>
              <CBO profissional_id={professional_id} />
            </div>
            <AreasConhecimentoCNPQ onAddArea={handleAddArea} onRemoveArea={handleRemoveArea} selectedAreas={selectedAreas} />
            {selectedAreas.length > 0 ? (
              <div className='col-md-12 bg-light rounded text-center mt-3'>
                <small>Áreas CNPQ Selecionadas:</small>
                <div className='col-md-12'>
                  {selectedAreas.length > 0 ? (<>
                    <div className='py-2 '>
                      {selectedAreas.map((area, index) => (
                        <div className='mt-2  py-2 border border-dark  rounded bg-warning' key={index}>
                          <p>
                            <small>Grande Área:</small> {area.grande_area}
                          </p>
                          {area.area && (
                            <p>
                              <small>Área:</small> {area.area}
                            </p>)}
                          {area.sub_area && (
                            <p>
                              <small>Sub-Área:</small> {area.sub_area}
                            </p>)}
                          {area.especialidade && (
                            <p>
                              <small>Especialidade:</small> {area.especialidade}
                            </p>)}
                          <button style={{ border: '0px', background: "#ffc107 " }} onClick={() => handleRemoveArea(area)}>
                            <i className='fa fa-trash text-danger' > &nbsp; Excluir</i>
                          </button>
                        </div>
                      ))}
                    </div>
                    <ButtonAPI type="submit" disabled={isLoading} onClick={handleSubmit}>
                      {isLoading ? 'Enviando...' : `${professional_id ? `Salvar edição` : `Cadastrar info.`}`}
                    </ButtonAPI>
                  </>) : (
                    <p className='bg-warning'>Nenhuma área selecionada.</p>
                  )}
                </div>
              </div>
            ) : (
              null
            )}
            <SearchAreas id={id} professional_id={professional_id} />
            <CadastroAddress latitude={latitude} setLatitude={setLatitude}
              longitude={longitude} setLongitude={setLongitude} getLocalizacao={getLocalizacao} />
            {latitude && <ButtonAPI type="submit" disabled={isLoading} onClick={handleSubmit}>
              {isLoading ? 'Enviando...' : `${professional_id ? `Salvar edição` : `Cadastrar info.`}`}
            </ButtonAPI>}
            {professional_id && <CadastrarPhotoProfissional />}
            <div className='py-2'>
              {!professional_id && <ButtonAPI type="submit" disabled={isLoading} onClick={handleSubmit}>
                {isLoading ? 'Enviando...' : `${professional_id ? `Editar ${props.title}` : `Cadastrar info. ${props.title}`}`}
              </ButtonAPI>}
            </div>
          </div>
        </div>
        <div className={`boxHeader mb-3 text-center  d-flex  justify-content-center align-items-center  TridimencionalInfLagerLat py-2
                     ${professional_id ? 'bg-success' : 'bg-primary'
          }`} >
          <h5 className="text-white rounded  mt-1 justify-content-center align-items-center d-flex">
            <b>  {professional_id ? <i className="fa fa-check" aria-hidden="true"></i> : '...'
            }   </b>
          </h5>
        </div>
      </div>
    </div>
  </>);
}
export default CadastrarProfissional;
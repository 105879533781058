import React, { useState } from 'react';
import { Link } from 'react-router-dom';
export const ProntuarioProblemasDeSaude = () => {
  const [problemasDeSaude, setProblemasDeSaude] = useState({
    Amigdalite: false,
    Asma: false,
    Bronquite: false,
    Sinusite: false,
    RiniteAlergica: false,
    Cefaleia: false,
    Enxaqueca: false,
    PressaoAlta: false,
    Coracao: false,
    Derrame: false,
    Gastrite: false,
    Refluxo: false,
    Depressao: false,
    DoencaRenal: false,
    Psoriase: false,
    Lupus: false,
    ArtriteReumatoide: false,
    Artrose: false,
    Gota: false,
    Tendinite: false,
    Anemia: false,
    DengueZikaChikungunya: false,
    FebreReumatica: false,
    FebreAmarela: false,
    Hanseniase: false,
    HerpesZoster: false,
    Tireoide: false,
    Parkinson: false,
    Fibromialgia: false,
    Infeccoes: false,
    Cancer: false,
    Outra: false,
  });
  const [temFamiliaComProblemas, setTemFamiliaComProblemas] = useState(false);
  const [familiaComProblemasDetalhes, setFamiliaComProblemasDetalhes] = useState('');
  const handleCheckboxChange = (e, problema) => {
    setProblemasDeSaude({ ...problemasDeSaude, [problema]: e.target.checked });
  };
  const handleTemFamiliaComProblemasChange = (e) => {
    setTemFamiliaComProblemas(e.target.checked);
  };
  const handleFamiliaComProblemasDetalhesChange = (e) => {
    setFamiliaComProblemasDetalhes(e.target.value);
  };
  return (
    <>
      <h5 style={{ fontWeight: "bold" }}>
        Problemas de saúde que tem ou teve:
      </h5>
      <div>
      <div className="row">
        <div className="col-md-6">
          {Object.entries(problemasDeSaude)
            .slice(0, Object.keys(problemasDeSaude).length / 2) // Divide a lista em duas partes
            .map(([problema, valor], index) => (
              <div key={index}>
                <label>
                  <input
                    type="checkbox"
                    onChange={(e) => handleCheckboxChange(e, problema)}
                    defaultChecked={valor}
                  /> {problema}
                </label>
              </div>
            ))}
        </div>
        <div className="col-md-6">
          {Object.entries(problemasDeSaude)
            .slice(Object.keys(problemasDeSaude).length / 2) // Continua da metade até o final
            .map(([problema, valor], index) => (
              <div key={index}>
                <label>
                  <input
                    type="checkbox"
                    onChange={(e) => handleCheckboxChange(e, problema)}
                    defaultChecked={valor}
                  /> {problema}
                </label>
              </div>
            ))}
        </div>
      </div>
        <br /> <label>
          <input
            type="text"
            value={problemasDeSaude.outra ? '' : problemasDeSaude.outraTexto}
            onChange={(e) => setProblemasDeSaude({ ...problemasDeSaude, outraTexto: e.target.value })}
            placeholder="Especifique..."
          />
        </label>
      </div>
      <h5 style={{ fontWeight: "bold" }}>
        Alguém da sua família apresentou ou apresenta algum dos problemas acima ou outros?      </h5>
        <label>
          <input type="checkbox" onChange={handleTemFamiliaComProblemasChange} /> Sim
        </label>
        <br/>
        <label>
          <input type="checkbox" onChange={() => setTemFamiliaComProblemas(false)} /> Não
        </label>
      {temFamiliaComProblemas && (
        <div>
          <h6>Se Sim, quais/Grau de parentesco?</h6>
          <input
            type="text"
            value={familiaComProblemasDetalhes}
            onChange={handleFamiliaComProblemasDetalhesChange}
            placeholder="Informe detalhes sobre a família com problemas de saúde"
          />
        </div>
      )}
      <div className="col-md-12 mt-2 mb-5 d-flex">
        <Link className="btn btn-primary mx-auto col-md-6" href="#">
        Enviar Informações
        </Link>
      </div>
    </>
  );
};
export default ProntuarioProblemasDeSaude;
import React from 'react';
import { TitleL2, TitleL3 } from '@components';
export const ResumoCompra = ({ totaisPorTipo, totalValor }) => {
    return (<>
        <section >
            <div className=' mt-5' >
                <TitleL2 >Resumo da compra</TitleL2>
                <table className="col-md-12 table-dark table-circle text-center mx-auto justify-content-center align-items-center" id="tabela" style={{ borderRadius: '0.25rem' }}>
                    <thead className="col-md-12 " >
                        <tr>
                            <th id="QNT" className="text-white font-weight-bold" >Qnt</th>
                            <th id="ItensCarrinhoDescricao" className="text-white font-weight-bold">Descrição</th>
                            <th id="ItensCarrinhoValor" className="text-white font-weight-bold">Valor</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(totaisPorTipo).map(tipo => (
                            <tr key={tipo}>
                                <td className="text-white font-weight-bold text-center">{totaisPorTipo[tipo].quantity}</td>
                                <td className="text-white ">{tipo}</td>
                                <td className="text-white font-weight-bold">{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(totaisPorTipo[tipo].valorTotal)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <TitleL3 className='text-center'>Valor Total</TitleL3>
                <h3 className='mt-2 font-weight-bold text-center text-dark'>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(totalValor)}</h3>
            </div>
        </section>
    </>);
};
export default ResumoCompra;
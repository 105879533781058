import React from 'react';
const SearchAITextInfo = () => {
  return (
    <div>  <br />  <br />  <br />  <br />  <br />  <br />  <br />
    <h5><strong>Utilização - Procurar I.A.</strong></h5>
      <p>Utilizando o componente <strong>Procurar I.A</strong>, você pode procurar por modelos de I.A. generativos de texto de forma fácil e rápida, permitindo que você encontre as I.A.s que precisa com base nos nomes fornecidos.</p>
      <hr />
      <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />
      <h5><strong>Compreensão Profunda - Procurar I.A.</strong></h5>
      <p>Este documento tem o objetivo de explicar o funcionamento do componente de Procurar I.A., que permite aos usuários procurar por modelos de I.A. generativos de texto. O componente oferece uma interface onde os usuários podem inserir o nome da I.A. que desejam encontrar.</p>
      <hr />
      <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />
      <h5><strong>Buscar por I.A. Generativa de Texto</strong></h5>
      <p>O componente <strong>Procurar I.A</strong> permite que você procure por modelos de I.A. generativos de texto com base no nome da I.A. que você insere. Ele se comunica com o servidor para buscar os modelos correspondentes.</p>
      <hr />
      <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />
      <h5><strong>Funcionamento</strong></h5>
      <p>Quando você insere o nome da I.A. na caixa de pesquisa e clica no botão de busca, o componente realiza uma requisição ao servidor com o nome da I.A. O servidor processa essa requisição, busca as informações correspondentes no banco de dados e retorna os resultados de volta ao componente.</p>
      <hr />
      <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />
      <h5><strong>Resultados da Busca</strong></h5>
      <p>Os resultados da busca são exibidos logo abaixo da caixa de pesquisa. Cada resultado representa um modelo de I.A. correspondente ao nome pesquisado. Você pode clicar em um resultado para selecioná-lo e, se desejar, salvar suas informações em um local de armazenamento local.</p>
      <hr />
      <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />
    </div>
  );
};
export default SearchAITextInfo;
import React from 'react';
const ProntuarioFaltaDeArInfo = () => {
  return (
    <div className='mt-5'>
      <h5>
        Usando o Componente <strong> Falta De Ar</strong>
      </h5>
      <p>
        O componente <strong> Falta De Ar</strong> permite que você registre suas queixas de falta de ar. Você pode adicionar, editar e remover queixas de falta de ar conforme necessário. Abaixo, você encontrará um guia de como usar este componente.
      </p>
      <hr />
      <ol className='ml-3'>
        <li>
          <strong>Responda à pergunta sobre falta de ar</strong>
          <p>
            Ao abrir o componente, você verá a pergunta "Sente falta de ar com frequência?" com duas opções: "Sim" e "Não". Marque a opção correspondente para indicar se você sente falta de ar com frequência.
          </p>
        </li>
        <li>
          <strong>Adicione situações de falta de ar (se aplicável)</strong>
          <p>
            Se você marcar "Sim", uma seção adicional aparecerá onde você pode adicionar situações em que sente falta de ar. Digite a situação no campo de texto abaixo de "Em que situação você sente falta de ar?" e clique no botão "Adicionar Situação de Falta de Ar" para inserir a situação na lista.
          </p>
        </li>
        <li>
          <strong>Edite ou remova situações de falta de ar (se aplicável)</strong>
          <p>
            Se você desejar editar uma situação de falta de ar existente na lista, clique no botão "Editar" ao lado dela. Isso preencherá o campo de texto com a situação de falta de ar para edição. Faça as alterações necessárias e clique no botão "Adicionar Situação de Falta de Ar" para salvar as alterações. Se você desejar remover uma situação, clique no botão "Remover".
          </p>
        </li>
        <li>
          <strong>Conclusão</strong>
          <p>
            Quando terminar de responder às perguntas e adicionar/editar/remover situações de falta de ar, você pode continuar usando o componente conforme necessário. Todas as situações serão mantidas para referência futura.
          </p>
        </li>
      </ol>
      <hr />
      <p>
        Certifique-se de usar este componente para registrar com precisão suas situações de falta de ar, se aplicável, para manter um registro útil de suas informações médicas.
      </p>
      <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />
    </div>
  );
};
export default ProntuarioFaltaDeArInfo;
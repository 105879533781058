import React, { useState } from 'react';
import { ButtonAPI, InputPadrao, ButtonAdicionarCampo, ButtonRemoverCampo } from '../../../../components';
export function FormularioDinamicoMedicacao({ medicamentos, setMedicamentos, onPrescricaoAtualizada }) {
  const [campos, setCampos] = useState([{ id: 1, nomeMedicamento: '', dosagem: '', formulaFarmaceutica: '', quantidade: '', posologia: '', dataEmissao: '' }]);
  const adicionarCampo = () => {
    const novoCampo = { id: Date.now(), nomeMedicamento: '', dosagem: '', formulaFarmaceutica: '', quantidade: '', posologia: '', dataEmissao: '' };
    setCampos([...campos, novoCampo]);
  };
  const removerCampo = () => {
    // Mantenha apenas o campo inicial com o id 1
    const campoInicial = campos.find((campo) => campo.id === 1);
    setCampos([campoInicial]);
  };
  const handleChange = (id, campo, value) => {
    const camposAtualizados = campos.map((campoAtual) => {
      if (campoAtual.id === id) {
        return { ...campoAtual, [campo]: value };
      }
      return campoAtual;
    });
    // Atualize a prescrição quando os campos mudarem
    const novaPrescricao = camposAtualizados
      .map((campo) => `${campo.nomeMedicamento} - ${campo.dosagem} - ${campo.posologia}`)
      .join('\n');
    // Chame a função de callback para atualizar a prescrição no componente pai
    onPrescricaoAtualizada(novaPrescricao);
    setCampos(camposAtualizados);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('Campos:', campos);
    // Atualize a lista de medicamentos no componente pai
    setMedicamentos(campos);
  };
  return (
    <form onSubmit={handleSubmit}>
      {campos.map((campo) => (
        <React.Fragment key={campo.id}>
          <div className={"col-md-12 d-flex flex-wrap"}>
          <InputPadrao
            title={"Nome do Medicamento"}
            value={campo.nomeMedicamento}
            onChange={(e) => handleChange(campo.id, 'nomeMedicamento', e.target.value)}
          />
          <InputPadrao
            title={"Dosagem"}
            value={campo.dosagem}
            onChange={(e) => handleChange(campo.id, 'dosagem', e.target.value)}
          />
          <InputPadrao
            title={"Fórmula Farmacêutica"}
            value={campo.formulaFarmaceutica}
            onChange={(e) => handleChange(campo.id, 'formulaFarmaceutica', e.target.value)}
          />
          <InputPadrao
            title={"Quantidade"}
            value={campo.quantidade}
            onChange={(e) => handleChange(campo.id, 'quantidade', e.target.value)}
          />
          <InputPadrao
            title={"Posologia"}
            value={campo.posologia}
            onChange={(e) => handleChange(campo.id, 'posologia', e.target.value)}
          />
          </div>
        </React.Fragment>
      ))}
      <ButtonAdicionarCampo type="button" onClick={adicionarCampo}>
      </ButtonAdicionarCampo>
      <ButtonRemoverCampo type="button" onClick={removerCampo}>
      </ButtonRemoverCampo>
      <ButtonAPI type="submit">Adicionar</ButtonAPI>
    </form>
  );
}
export default FormularioDinamicoMedicacao;
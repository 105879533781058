import React, { useState, useEffect, useCallback } from 'react';
import { ButtonAPI, InputPadraoTitle, MaisInfoMenosInfo } from '@components';
import ProspeccaoClienteSelecionaInfo from '../info/ProspeccaoClienteSelecionaInfo';
const ProspeccaoClienteSeleciona = () => {
  const [selectedOption, setSelectedOption] = useState(''); 
  const [numClients, setNumClients] = useState(0); 
  const [VenderPara, setVenderPara] = useState('');
  const [clientsFound, setClientsFound] = useState(null); 
  const [recorrencia, setRecorrencia] = useState(null); 
  const handleSubmit = useCallback(() => {
    const dataToSend = {
      selectedOption,
      numClients,
      VenderPara,
    };
    // Exemplo de chamada de API fictícia
    fetch('/sua-rota-de-back-end', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(dataToSend),
    })
      .then((response) => response.json())
      .then((data) => {
        setClientsFound(data);
        alert('Informações resgatadas com sucesso');
      })
      .catch((error) => {
        alert('Erro ao enviar dados para o servidor:');
      });
  }, [selectedOption, numClients, VenderPara]);
  useEffect(() => {
    if (selectedOption && VenderPara && numClients) {
      handleSubmit();
    }
  }, [selectedOption, VenderPara, numClients, handleSubmit]);
  const handleOptionChange = (e) => {
    const value = e.target.value;
    setSelectedOption(value);
    setNumClients(0);
  };
  const handleNumClientsChange = (e) => {
    const value = e.target.value;
    setNumClients(value);
  };
  const handleRecorrenciaChange = (e) => {
    const value = e.target.value;
    setRecorrencia(value);
  };
  const handleVenderParaChange = (e) => {
    const value = e.target.value;
    setVenderPara(value);
  };
  return (
    <>
      <div className="prospeccao-select col-md-12 mt-5">
        <InputPadraoTitle
          htmlFortitle={'Quero Vender'}
          title={'Quero Vender'}
        />
        <select className="form-control text-center col-md-12 border border-dark mb-3" value={selectedOption} onChange={handleOptionChange}>
          <option value="">Selecione uma opção</option>
          <option value="Produtos">Produtos</option>
          <option value="Procedimentos">Procedimentos</option>
          <option value="Ambos">Ambos</option>
        </select>
        <InputPadraoTitle
          htmlFortitle={'Vender Para'}
          title={'Vender Para'}
        />
        <select className="form-control text-center col-md-12 border border-dark mb-3" value={VenderPara} onChange={handleVenderParaChange}>
          <option value="">Selecione uma sub-opção</option>
          <option value="Público">Público</option>
          <option value="Privado">Privado</option>
          <option value="Plano de Saúde">Plano de Saúde</option>
          <option value="Ambos">Ambos</option>
        </select>
        <div>
          <InputPadraoTitle
            htmlFortitle={'Número de clientes'}
            title={'Número de clientes'}
          />
          <input
            className="form-control text-center col-md-12 border border-dark mb-3"
            type="number"
            value={numClients}
            onChange={handleNumClientsChange}
          />
        </div>
        <div>
          <InputPadraoTitle
            htmlFortitle={'Recorrência'}
            title={'Recorrência'}
          />
          <input
            className="form-control text-center col-md-12 border border-dark mb-3"
            type="number"
            value={recorrencia}
            onChange={handleRecorrenciaChange}
          />
        </div>       
        <ButtonAPI onClick={handleSubmit}>Procurar</ButtonAPI>
      </div>
      {clientsFound !== null && (
        <div className='py-4 mt-3'>
          <p>Número de clientes encontrados: {clientsFound}</p>
        </div>
      )}
      <div className='py-4 mt-3'>
       <MaisInfoMenosInfo text={<ProspeccaoClienteSelecionaInfo />}></MaisInfoMenosInfo>
      </div>
    </>
  );
};
export default ProspeccaoClienteSeleciona;
import React, { useState } from 'react';
import {
  LoadingModal,
  ButtonCliente
} from '@components';
import {
  FaceGuidedTutorial,
  FacePhotoCollector,
  FaceRenderResults,
  PDFGenerator
} from './partials';
import { useCliente } from 'context';
export const AnaliseFacial = () => {
  //Controle do DB
  const { cliente } = useCliente();
  const [serviceControl, setServiceControl] = useState([]);
  //Controle do serviço
  const [currentType, setCurrentType] = useState(null);
  const [photos, setPhotos] = useState({});
  const [thumbnail, setThumbnail] = useState({});
  const [imageWidth, setImageWidth] = useState(0); // Resumir essa entrada
  const [imageHeight, setImageHeight] = useState(0); // Resumir essa entrada  
  const [loading, setLoading] = useState(false);
  const [apiResults, setApiResults] = useState([]);
  const [apiImages, setApiImages] = useState([]);
  const [others, setOthers] = useState([]) // apiOthers 
  const [landmarks, setLandmarks] = useState([]);// apiLandmaks
  const [modifiedLandmarks, setModifiedLandmarks] = useState([]);
  const [modifiedLandmarksType, setModifiedLandmarksType] = useState(null);
  // Controle tutorial
  const [mostrarInformacoes, setMostrarInformacoes] = useState(false);
  const [pontoDestacado, setPontoDestacado] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [currentAction, setCurrentAction] = useState(null);
  const [tutorialStep, setTutorialStep] = useState(0);
  const [piscaMaisInfo, setPiscaMaisInfo] = useState(false);
  const [highlightStates, setHighlightStates] = useState({});
  const handleImageLoad = (e) => {
    setImageWidth(e.target.width);
    setImageHeight(e.target.height);
  };
  return (<>
    <ButtonCliente />
    <FaceRenderResults
      currentType={currentType}
      setLandmarks={setLandmarks}
      landmarks={landmarks}
      setModifiedLandmarks={setModifiedLandmarks}
      modifiedLandmarks={modifiedLandmarks}
      photos={photos}
      imageWidth={imageWidth}
      imageHeight={imageHeight}
      setPontoDestacado={setPontoDestacado}
      pontoDestacado={pontoDestacado}
      setMostrarInformacoes={setMostrarInformacoes}
      mostrarInformacoes={mostrarInformacoes}
      apiImages={apiImages}
      apiResults={apiResults}
      others={others}
      modifiedLandmarksType={modifiedLandmarksType}
      setModifiedLandmarksType={setModifiedLandmarksType}
      handleImageLoad={handleImageLoad}
    />
    <FacePhotoCollector
      currentType={currentType}
      setCurrentType={setCurrentType}
      photos={photos}
      setPhotos={setPhotos}
      thumbnail={thumbnail}
      setThumbnail={setThumbnail}
      setApiResults={setApiResults}
      setApiImages={setApiImages}
      piscaMaisInfo={piscaMaisInfo}
      highlightStates={highlightStates}
      setLandmarks={setLandmarks}
      setModifiedLandmarks={setModifiedLandmarks}
      setModifiedLandmarksType={setModifiedLandmarksType}
      setOthers={setOthers}
      setLoading={setLoading}
      modifiedLandmarksType={modifiedLandmarksType}
      serviceControl={serviceControl}
      setServiceControl={setServiceControl}
    />
    <LoadingModal
      visible={loading}
      message="Carregando dados..."
      onClose={() => setLoading(false)}
    />
    <PDFGenerator
      currentType={currentType}
      modifiedLandmarks={modifiedLandmarks}
      pontoDestacado={pontoDestacado}
      photos={photos}
      apiImages={apiImages}
      cliente={cliente}
      others={others}
      imageWidth={imageWidth}
      imageHeight={imageHeight}
    />
    <FaceGuidedTutorial
      setPiscaMaisInfo={setPiscaMaisInfo}
      setHighlightStates={setHighlightStates}
      setThumbnail={setThumbnail}
      setLoading={setLoading}
      setModalVisible={setModalVisible}
      setCurrentType={setCurrentType}
      setPhotos={setPhotos}
      setLandmarks={setLandmarks}
      setModifiedLandmarks={setModifiedLandmarks}
      setImageWidth={setImageWidth}
      setImageHeight={setImageHeight}
      setApiImages={setApiImages}
      setApiResults={setApiResults}
      setOthers={setOthers}
      setCurrentAction={setCurrentAction}
      setTutorialStep={setTutorialStep}
      currentAction={currentAction}
      modalVisible={modalVisible}
      handleImageLoad={handleImageLoad}
      tutorialStep={tutorialStep}
    />
  </>);
};
export default AnaliseFacial;
import React from 'react';
import { Row, Divider, Typography } from 'antd';
import { CreditCardOutlined, QrcodeOutlined, BarcodeOutlined } from '@ant-design/icons';
import { TitleL2 } from '@components';
const { Title, Paragraph } = Typography;
const RepasseAPIPagamento = () => {
  return (
    <Row gutter={[16, 16]} className="elementor-container elementor-column-gap-default card-verde py-3 mt-5">
      <div className='col-md-12'>  <TitleL2>Repasse API de pagamento cliente </TitleL2>
        <p><small className='text-justify'>Repasse feito de forma automática conforme o cliente escolhe a forma de pagamento. O máximo de comodidade em faturamentos automáticos. Cadastre-se informe onde você deseja receber suas vendas</small></p>
      </div>
      <div className='col-md-4'>
        <div className="col-md-12">
          <div className="elementor-widget elementor-widget-icon" data-id="68a883d0">
            <div className="elementor-widget-container">
              <CreditCardOutlined style={{ fontSize: '60px', color: 'green' }} />
            </div>
          </div>
          <div className="elementor-widget elementor-widget-heading" data-id="16d557b">
            <div className="elementor-widget-container">
              <Title level={2}>Crédito</Title>
            </div>
          </div>
          <div className="elementor-widget elementor-widget-divider" data-id="1eb6ddf">
            <div className="elementor-widget-container">
              <Divider />
            </div>
          </div>
          <div className="elementor-widget elementor-widget-text-editor" data-id="296dd4d9">
            <div className="elementor-widget-container">
              <Title level={1} className="display um">2,60%</Title>
              <Paragraph className="display tres">+ R$0,60 <span style={{ fontSize: '1rem' }}>(antifraude)</span></Paragraph>
            </div>
          </div>
          <div className="elementor-widget elementor-widget-divider" data-id="46567c50">
            <div className="elementor-widget-container">
              <Divider />
            </div>
          </div>
          <div className="elementor-widget elementor-widget-text-editor" data-id="4ebff64d">
            <div className="elementor-widget-container">
              <Title level={2} className="display dois emeio">D+30</Title>
              <Paragraph className="small">Recebimento / crédito</Paragraph>
              <Paragraph><b>De 2 a 6X – 4,99%</b></Paragraph>
              <Paragraph><b>De 7 a 12X – 5,99%</b></Paragraph>
            </div>
          </div>
        </div>
      </div>
      <div className='col-md-4'>
        <div className="col-md-12">
          <div className="elementor-widget elementor-widget-icon" data-id="3cb55b7a">
            <div className="elementor-widget-container">
              <QrcodeOutlined style={{ fontSize: '60px', color: 'green' }} />
            </div>
          </div>
          <div className="elementor-widget elementor-widget-heading" data-id="52765c9b">
            <div className="elementor-widget-container">
              <Title level={2}>PIX</Title>
            </div>
          </div>
          <div className="elementor-widget elementor-widget-divider" data-id="33216e7">
            <div className="elementor-widget-container">
              <Divider />
            </div>
          </div>
          <div className="elementor-widget elementor-widget-text-editor" data-id="249409bc">
            <div className="elementor-widget-container">
              <Title level={1} className="display um">0,99%</Title>
              <Paragraph className="small">por transação</Paragraph>
            </div>
          </div>
          <div className="elementor-widget elementor-widget-divider" data-id="7efd2ed8">
            <div className="elementor-widget-container">
              <Divider />
            </div>
          </div>
          <div className="elementor-widget elementor-widget-text-editor" data-id="2cd10161">
            <div className="elementor-widget-container">
              <Title level={2} className="display dois emeio">D+1</Title>
              <Paragraph className="small">Recebimento / crédito</Paragraph>
            </div>
          </div>
        </div>
      </div>
      <div className='col-md-4'>
        <div className="col-md-12">
          <div className="elementor-widget elementor-widget-icon" data-id="18996875">
            <div className="elementor-widget-container">
              <BarcodeOutlined style={{ fontSize: '60px', color: 'green' }} />
            </div>
          </div>
          <div className="elementor-widget elementor-widget-heading" data-id="f31f9cd">
            <div className="elementor-widget-container">
              <Title level={2}>Boleto</Title>
            </div>
          </div>
          <div className="elementor-widget elementor-widget-divider" data-id="06a32f8">
            <div className="elementor-widget-container">
              <Divider />
            </div>
          </div>
          <div className="elementor-widget elementor-widget-text-editor" data-id="dc7318d">
            <div className="elementor-widget-container">
              <Title level={1} className="display um">R$2,40</Title>
              <Paragraph className="small">por boleto pago</Paragraph>
            </div>
          </div>
          <div className="elementor-widget elementor-widget-divider" data-id="7ccdc13">
            <div className="elementor-widget-container">
              <Divider />
            </div>
          </div>
          <div className="elementor-widget elementor-widget-text-editor" data-id="c73f49f">
            <div className="elementor-widget-container">
              <Title level={2} className="display dois emeio">D+2</Title>
              <Paragraph className="small">Recebimento / crédito</Paragraph>
            </div>
          </div>
        </div>
      </div>
    </Row>
  );
};
export default RepasseAPIPagamento;
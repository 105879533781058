import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';
import { v4 as uuidv4 } from 'uuid';
import { apiToken } from '@services/contexts';
import { ButtonCadastrar, LinkHref, TitleL1, TitleL2 } from '@components';
import { MdTroubleshoot } from "react-icons/md";
export const NossosNumeros = () => {
    const [analyticsData, setAnalyticsData] = useState(null);
    const [totalUniqueIPs, setTotalUniqueIPs] = useState(0);
    const [totalUser, setTotalUser] = useState(0);
    const [totalProfessional, setTotalProfessional] = useState(0);
    const chartRef = useRef(null);
    const chartInstance = useRef(null);
    useEffect(() => {
        apiToken().get('get_analytics')
            .then(response => {
                setAnalyticsData(response.data);
            })
            .catch(error => {
                console.error('Error fetching analytics data:', error);
            });
        apiToken().get('get_analytics_total_unique_ips')
            .then(response => {
                setTotalUniqueIPs(response.data.total_unique_ips);
            })
            .catch(error => {
                console.error('Error fetching total unique IPs:', error);
            });
        apiToken().get('get_user_total')
            .then(response => {
                setTotalUser(response.data.totalUsers);
            })
            .catch(error => {
                console.error('Error fetching total unique IPs:', error);
            });
        apiToken().get('get_professional_total')
            .then(response => {
                setTotalProfessional(response.data.totalProfessional);
            })
            .catch(error => {
                console.error('Error fetching total unique IPs:', error);
            });
    }, []);
    useEffect(() => {
        const renderChart = () => {
            if (!analyticsData) return;
            const pagesToShow = [
                '',
                'servicos',
                'produtos',
                'artigos',
                'parcerias',
                'sobre',
                'carrinho',
                'login',
                'cadastrar',
                'usuario',
                'profissionais',
            ];
            const filteredTotalVisits = analyticsData.total_visits
                .filter(data => pagesToShow.includes(data.page_group))
                .sort((a, b) => pagesToShow.indexOf(a.page_group) - pagesToShow.indexOf(b.page_group));
            const filteredPageActions = analyticsData.page_actions.filter(data => pagesToShow.includes(data.page_group));
            const totalVisitsData = filteredTotalVisits.map(data => data.total_visits);
            const pageGroupLabels = filteredTotalVisits.map(data => data.page_group);
            const totalActionsData = pageGroupLabels.map(pageGroup => {
                return filteredPageActions
                    .filter(data => data.page_group === pageGroup && data.type_action !== 'page-load')
                    .reduce((acc, data) => acc + data.total_actions, 0);
            });
            const chartData = {
                labels: pageGroupLabels,
                datasets: [
                    {
                        label: 'Total de Visitas',
                        data: totalVisitsData,
                        backgroundColor: 'rgba(75, 192, 192, 0.6)',
                    },
                    {
                        label: 'Total de Ações',
                        data: totalActionsData,
                        backgroundColor: 'rgba(255, 99, 132, 0.6)',
                    }
                ]
            };
            const ctx = chartRef.current.getContext('2d');
            if (chartInstance.current) {
                chartInstance.current.destroy();
            }
            chartInstance.current = new Chart(ctx, {
                type: 'bar',
                data: chartData,
                options: {
                    scales: {
                        x: { stacked: true },
                        y: { stacked: true }
                    }
                }
            });
        };
        renderChart();
    }, [analyticsData]);
    return (
        <section className='ml-3 mr-3' style={{ minHeight: '95vh' }}>
            <TitleL1 icon={<MdTroubleshoot style={{ scale: '2' }} />}>Nossos Números</TitleL1>
            <h5 className='text-justify mb-5'>
                Nossos números são uma representação essencial do desempenho e da atividade interna em nossa plataforma.
                Eles capturam uma variedade de métricas que nos ajudam a entender melhor como nossa plataforma está sendo
                utilizada e quais áreas estão prosperando. No entanto, mais do que simples estatísticas, esses números também têm um
                significado pessoal e individual. Cada usuário, seja um profissional autônomo ou uma empresa estabelecida, pode conferir seus próprios resultados ao entrar na plataforma.
                Isso oferece uma oportunidade única para os usuários acompanharem seu próprio progresso, identificarem áreas de melhoria e
                tomarem decisões informadas com base nos dados disponíveis <br /><br />
                Portanto, nossos números não são apenas uma visão abstrata do desempenho geral da plataforma,
                mas sim uma ferramenta poderosa para capacitar e informar cada indivíduo que faz parte de nossa comunidade
            </h5>
            <TitleL2>URI - [1]</TitleL2>
            <canvas className='mb-4' ref={chartRef} id={`chart-${uuidv4()}`}></canvas>
            <small className=" mt-2 d-block text-justify">
                [1] URI significa Identificador Uniforme de Recursos. É uma string de caracteres usada para identificar um recurso na Internet. Por exemplo, o endereço de um site é uma URI, como <LinkHref to={'https://www.thotiacorp.com.br'}>https://www.thotiacorp.com.br</LinkHref>.
                A URI -[1] inclui um caminho adicional após a barra, como <LinkHref to={'https://www.thotiacorp.com.br/servicos'}>https://www.thotiacorp.com.br/servicos</LinkHref>, que especifica uma página recurso específico dentro do site
            </small>
            <div className="text-center py-3 mt-4 bg-secondary" style={{ borderRadius: '33px', position: 'relative' }}>
                <div className="glass-effect" style={{
                    position: 'absolute',
                    top: '1%',
                    left: '1%',
                    width: '98%',
                    height: '98%',
                    borderRadius: '0.25rem',
                    filter: ' blur(10px) brightness(1)',
                    backgroundColor: 'rgba(255, 255, 255, 0.2)', // Cor branca com 20% de opacidade
                    backdropFilter: 'blur(10px)' // Efeito de desfoque
                }}>
                </div>
                <div className='row'>
                    <div className='col-md-4 mt-2'>
                        <div className='card-verde py-2'>
                            <TitleL2>Visitas Únicas - {totalUniqueIPs}</TitleL2>
                        </div>
                    </div>
                    <div className='col-md-4 mt-2'>
                        <div className='card-verde py-2'><TitleL2>Usuários - {totalUser}</TitleL2>
                        </div>
                    </div>
                    <div className='col-md-4 mt-2'>
                        <div className='card-verde py-2'>
                            <TitleL2>Profissionais - {totalProfessional}</TitleL2>
                        </div>
                    </div>
                </div>
            </div>
            <small className="text-justify mt-4 d-block">
  Em nosso sistema, exibimos apenas os usuários e profissionais ativos na plataforma. Isso garante que você interaja com aqueles que estão realmente comprometidos e engajados, proporcionando uma experiência mais dinâmica e relevante. Manter um foco nos membros ativos nos permite otimizar os recursos e oferecer suporte mais eficiente, garantindo que as interações e colaborações sejam sempre com indivíduos que estão plenamente envolvidos. Nossa plataforma está projetada para destacar os usuários e profissionais mais dedicados, aqueles que estão constantemente contribuindo e aprimorando suas habilidades. Ao se concentrar nos membros ativos, você tem a certeza de estar conectado com os mais engajados e atualizados participantes da nossa comunidade, o que pode levar a oportunidades de networking, aprendizado e crescimento profissional muito mais significativas.
</small>
            <ButtonCadastrar />
        </section>
    );
};
export default NossosNumeros;
import React from 'react';
const ShowTrendSelect = ({ value, onChange }) => {
  return (
    <select name="exibir_tendencia" value={value} onChange={(e) => onChange({ value: e.target.value }, 'exibir_tendencia')}>
      <option value="tendenciazero"></option>
      <option value="Sim">Sim</option>
      <option value="Nao">Não</option>
    </select>
  );
};
export default ShowTrendSelect;
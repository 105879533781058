import React from 'react';
const ShowVersionsSelect = ({ value, onChange }) => {
  return (
    <select name="exibir_versao" value={value} onChange={(e) => onChange({ value: e.target.value }, 'exibir_versao')}>
      <option value="versaozero"></option>
      <option value="Todas as Versões">Todas as Versões</option>
      <option value="Versoes Principais">Apenas as Versões Principais</option>
      <option value="Nenhum">Nenhum</option>
    </select>
  );
};
export default ShowVersionsSelect;
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ButtonAdicionarCampo, MaisInfoMenosInfo, ButtonRemoverCampo, ButtonEditarCampo } from '@components';
import ProntuarioTomandoMedicamentoInfo from '../info/ProntuarioTomandoMedicamentoInfo';
export const ProntuarioTomandoMedicamento = () => {
  const [estaTomandoMedicamento, setEstaTomandoMedicamento] = useState(false);
  const [medicamento, setMedicamento] = useState({ finalidade: '', nome: '', dose: '', posologia: '' });
  const [medicamentosLista, setMedicamentosLista] = useState([]);
  const [editIndex, setEditIndex] = useState(-1);
  const handleCheckboxChange = (e) => {
    setEstaTomandoMedicamento(e.target.checked);
  };
  const handleMedicamentoChange = (e) => {
    const { name, value } = e.target;
    setMedicamento((prevMedicamento) => ({
      ...prevMedicamento,
      [name]: value,
    }));
  };
  const handleAdicionarMedicamento = () => {
    if (
      medicamento.finalidade.trim() !== '' &&
      medicamento.nome.trim() !== '' &&
      medicamento.dose.trim() !== '' &&
      medicamento.posologia.trim() !== ''
    ) {
      if (editIndex === -1) {
        setMedicamentosLista([...medicamentosLista, medicamento]);
      } else {
        const novaLista = [...medicamentosLista];
        novaLista[editIndex] = medicamento;
        setMedicamentosLista(novaLista);
        setEditIndex(-1); // Limpa o índice de edição
      }
      setMedicamento({ finalidade: '', nome: '', dose: '', posologia: '' });
    }
  };
  const handleRemoverMedicamento = (index) => {
    const novaLista = [...medicamentosLista];
    novaLista.splice(index, 1);
    setMedicamentosLista(novaLista);
  };
  const handleEditarMedicamento = (index) => {
    const medicamentoParaEditar = medicamentosLista[index];
    setMedicamento(medicamentoParaEditar);
    setEditIndex(index);
  };
  return (
    <>
      <h5 style={{ fontWeight: 'bold' }} className="mt-3">
        - Está tomando algum medicamento?
      </h5>
      <label>
        <input
          type="checkbox"
          defaultChecked={estaTomandoMedicamento}
          onChange={handleCheckboxChange} /> Sim
      </label>
      <br />
      <label>
        <input
          type="checkbox"
          defaultChecked={!estaTomandoMedicamento}
          onChange={() => setEstaTomandoMedicamento(false)} /> Não
      </label>
      {estaTomandoMedicamento && (
        <div>
          *Se Sim, quais medicamentos (finalidade/nome/dose/posologia):<br /><br />
          <input
            type="text"
            name="finalidade"
            value={medicamento.finalidade}
            onChange={handleMedicamentoChange}
            placeholder="Finalidade"
          />
          <input
            type="text"
            name="nome"
            value={medicamento.nome}
            onChange={handleMedicamentoChange}
            placeholder="Nome"
          />
          <input
            type="text"
            name="dose"
            value={medicamento.dose}
            onChange={handleMedicamentoChange}
            placeholder="Dose"
          />
          <input
            type="text"
            name="posologia"
            value={medicamento.posologia}
            onChange={handleMedicamentoChange}
            placeholder="Posologia"
          />
          <ButtonAdicionarCampo onClick={handleAdicionarMedicamento}></ButtonAdicionarCampo>
        </div>
      )}
      {medicamentosLista.length > 0 && (
        <div className='py-3'>
          <h6>Medicamentos Adicionados:</h6>
          <ul>
            {medicamentosLista.map((medicamento, index) => (
              <li key={index}>
                <strong>Finalidade:</strong> {medicamento.finalidade},
                <strong> Nome: </strong>{medicamento.nome},
                <strong> Dose: </strong>{medicamento.dose},
                <strong> Posologia: </strong>{medicamento.posologia}
                <ButtonEditarCampo onClick={() => handleEditarMedicamento(index)} />
                <ButtonRemoverCampo onClick={() => handleRemoverMedicamento(index)} />
              </li>
            ))}
          </ul>
        </div>
      )}
      <div className="col-md-12 mt-2 mb-5 d-flex">
        <Link className="btn btn-primary mx-auto col-md-6" href="#">
          Enviar Informações
        </Link>
      </div>
      <MaisInfoMenosInfo text={<ProntuarioTomandoMedicamentoInfo />}></MaisInfoMenosInfo>
    </>
  );
};
export default ProntuarioTomandoMedicamento;
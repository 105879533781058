import React from 'react';
import { ButtonCadastrar, TitleL1, TitleL3 } from '@components'
import ColorPalette from './ColorPalette';
import {
    MdOutlineCoPresent,
} from "react-icons/md";
export const Significados = () => {
    return (<>
        <section className='mr-2 ml-2' style={{ minHeight: '95dvh' }}>
            <TitleL1 icon={<MdOutlineCoPresent style={{ scale: '2' }} />}>Significados</TitleL1>
            <h5 className='text-justify'> Esta seção apresenta o branding empresarial, um processo estratégico que gerencia a imagem e a identidade da empresa.
                O objetivo é fortalecer a marca, tornando-a mais coerente, reconhecida, desejada e bem posicionada no mercado, construindo uma reputação sólida e confiável</h5>
            <div className="py-3">
                <TitleL3>⏣ Nome</TitleL3>
                <h5 className="text-justify text-dark">
                    Como a empresa é baseada principalmente em auxiliar  o próximo e I.A. com versões de  visão computacional surgiu a ideia do nome THOT I.A. CORP., sendo CORP.
                    significado de corporação. Em uma outra visão simples o nome poderia ser descrito como: Corporação de Inteligência Artificial da THOT.
                    O THOT é um "Deus" egípcio da Sabedoria é conhecido de várias formas e sempre como  mensageiro de  Deus; outras denominações, nomes de THOT: Metraton,
                    Enoque, Hermes, tantos olhos que ninguém consegue contar
                </h5>
                <TitleL3>⏣ Logo</TitleL3>
                <h5 className="text-justify text-dark">
                    A parte azul da logo da empresa representa a forma de um planeta, o branco é uma letra T modificada a frente do planeta.
                    A letra T modificada é para lembrar a letra inicial da empresa. Os adornos são a proteção do planeta veja como exitem lentes nas margens para mostrar o cuidado contínuo.
                    Sendo assim ao ver esta logo lembre-se auto nível de assistência computacional
                </h5>
                <TitleL3>⏣ Cores</TitleL3>
                <h5 className="text-justify text-dark">
                    Cores, a cor azul da logo faz a representação de planeta cor azul; o azul fica no menor raio de visão humana RGB é muito confortável,
                    também pode representar água um elemento fundamental para a vida; ou ainda o nascimento de uma estrela, a época de energia em potencial
                </h5>
                <ColorPalette />
            </div>
            <ButtonCadastrar />
        </section>
    </>);
};
export default Significados;
import React, { useState } from 'react';
import TeamCard from './TeamCard';
import { ButtonCadastrar, InputPadraoSearch, MaisInfoMenosInfo } from '@components';
import TeamListInfo from '../info/TeamListInfo';
import { Pagination } from 'antd';
export const TeamList = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(6); // Define quantos itens por página
    const teamMembers = [
        {
            name: 'Ronnei Peres',
            title: 'Cirurgião Dentista - Cientista Desenvolvedor de I.A. \n CEO - Fundador',
            imageSource: require('../assets/team/ronnei.jpg'),
            lattesLink: 'http://lattes.cnpq.br/7937274666220353',
            linkedinLink: 'https://www.linkedin.com/in/ronnei-borges-739187211/',
            githubLink: 'https://github.com/THOTIACORP',
        },
        {
            name: 'Flávia Ferrari',
            title: 'Engenheira Civil - Desenhos estruturais dos equipamentos',
            imageSource: require('../assets/team/flavia.jpg'),
            lattesLink: 'http://lattes.cnpq.br/2100680702016283',
            linkedinLink: '',
            githubLink: '',
        },
        {
            name: 'Jhonatas Valério',
            title: 'Engenheiro Eletricista - Desenvolvedor de hardwares',
            imageSource: require('../assets/team/jhonatas.png'),
            lattesLink: 'http://lattes.cnpq.br/9926734619142203',
            linkedinLink: 'https://www.linkedin.com/in/jhonatasvalerio/',
            githubLink: '',
        },
        // Adicione outros membros da equipe conforme necessário
    ];
    // Função para atualizar a página atual
    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };
    // Filtra os membros da equipe com base no termo de pesquisa
    const filteredTeamMembers = teamMembers.filter((member) =>
        member.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    // Calcula o índice do primeiro e último item na página atual
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredTeamMembers.slice(indexOfFirstItem, indexOfLastItem);
    // Calcula o número total de páginas
    const totalPages = Math.ceil(filteredTeamMembers.length / itemsPerPage);
    return (
        <>
            <section style={{ minHeight: '95dvh' }}>
                <InputPadraoSearch
                    type="text"
                    placeholder="Pesquisar por nome..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                <div>
                    {currentItems.map((member, index) => (
                        <TeamCard
                            key={index}
                            name={member.name}
                            title={member.title}
                            imageSource={member.imageSource}
                            lattesLink={member.lattesLink}
                            linkedinLink={member.linkedinLink}
                            githubLink={member.githubLink}
                        />
                    ))}
                </div>
                <div className="pagination text-center col-md-12 mx-auto justify-content-center py-4">
                    {totalPages > 1 && (
                        <Pagination
                            current={currentPage}
                            pageSize={itemsPerPage}
                            total={filteredTeamMembers.length}
                            onChange={handlePageChange}
                        />
                    )}
                </div>
                <div className='py-4 text-center'>
                    <MaisInfoMenosInfo text={<><TeamListInfo /></>} />
                </div>
                <ButtonCadastrar />
            </section>
        </>
    );
};
export default TeamList;